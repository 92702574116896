import api from "./api";

class AttributeService {
  getAllAttributes() {
    return api.get("/Attribute/get-all");
  }

  getAttributeById(id) {
    return api.get("/Attribute/get-by-id?id=" + id);
  }

  insert(newAttribute) {
    return api.post("/Attribute/insert", newAttribute);
  }

  update(updatedAttribute) {
    return api.post("/Attribute/update", updatedAttribute);
  }

  activate(id) {
    return api.post("/Attribute/activate?id=" + id);
  }

  deactivate(id) {
    return api.post("/Attribute/deactivate?id=" + id);
  }

  delete(id) {
    return api.post("/Attribute/delete?id=" + id);
  }
}

export default new AttributeService();
