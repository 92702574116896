import "./polyfills";
import React from "react";
import ReactDOM from "react-dom";

import * as serviceWorker from "./serviceWorker";

import { BrowserRouter, HashRouter, Route } from "react-router-dom";
import "./assets/base.scss";
import Pages from "./Pages/index";
import configureStore from "./config/configureStore";
import { Provider } from "react-redux";
import "./i18n";
import process from "process/browser.js";
window.process = process;

const store = configureStore();
const rootElement = document.getElementById("root");

const renderApp = (Component) => {
  ReactDOM.render(
    <Provider store={store}>
      <BrowserRouter>
        <Route path="/" component={Pages} />
      </BrowserRouter>
    </Provider>,
    rootElement
  );
};

renderApp(Pages);

if (module.hot) {
  module.hot.accept("./Pages", () => {
    const NextApp = require("./Pages").default;
    renderApp(NextApp);
  });
}
serviceWorker.register();
