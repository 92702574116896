import React, { Fragment, Component } from "react";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import { withTranslation } from "react-i18next";
import PageTitle from "../../../Layout/AppMain/PageTitle";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Chips } from "primereact/chips";
import PerfectScrollbar from "react-perfect-scrollbar";
import {
  UncontrolledDropdown,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
  Row,
  Col,
  Card,
  CardBody,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input,
  FormGroup,
  Form,
  Label,
  Button,
  CardTitle,
} from "reactstrap";
import AttributeService from "../../../service/AttributeService";
import { FilterMatchMode } from "primereact/api";
import authService from "../../../service/authService";
import Flag from "react-flagkit";
import Select from "react-select";
import "./DataTable.css";
import { classNames } from "primereact/utils";
import { TriStateCheckbox } from "primereact/tristatecheckbox";
import { InputText } from "primereact/inputtext";
import LearningObjectService from "../../../service/LearningObjectService";
import moment from "moment/moment";
import languages from "../../../models/Languages";
import { CSVLink } from "react-csv";
import tokenService from "../../../service/tokenService";
import { Multiselect } from "react-widgets";

class Attribute extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loggedUser: tokenService.getUser(),
      attributes: [],
      filteredAttributes: [],
      selectedAttribute: {},
      learningObjects: [],
      modal: false,
      deleteModal: false,
      activeModal: false,

      selectedFilteringTableLanguage: languages.find((obj) => obj.lang === this.props.i18n.language),
      filterAttributeDropdownLanguages: languages,

      creatingAttributeTranslationLanguages: languages,
      selectedCreatingAttributeTranslationLanguage: languages[0],
      selectedEditingAttributeTranslationLanguage: "",

      creatingAttributeTranslationName: "", //need join
      creatingAttributeTranslationComment: "", //
      creatingAttributeTranslationValues: [], //

      filterAttributesTemplate: {
        name: { value: null, matchMode: FilterMatchMode.CONTAINS },
        userName: { value: null, matchMode: FilterMatchMode.CONTAINS },
        comment: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
        values: { value: null, matchMode: FilterMatchMode.CONTAINS },
        createdDate: { value: null, matchMode: FilterMatchMode.DATE_IS },
        createdByUser: { value: null, matchMode: FilterMatchMode.CONTAINS },
        isPublic: { value: tokenService.getCompany().showPublicAttribute ? null : false, matchMode: FilterMatchMode.EQUALS },
        isActive: { value: null, matchMode: FilterMatchMode.EQUALS },
      },
      isError: {},

      attributeTranslations: [],

      csvHeaders: [
        { label: "Name", key: "name" },
        { label: "Created By", key: "createdByUser" },
      ],
    };
  }

  componentDidMount() {
    this.getAllAttributes();
  }

  getAttributeById(id) {
    return AttributeService.getAttributeById(id)
      .then((response) => {
        this.setState({ selectedAttribute: response.data, attributeTranslations: response.data.translations });
        this.filterAttributeDropdownLanguages();
      })
      .catch((e) => {
        console.log(e);
      });
  }

  getLearningObjectByAttributeId(id) {
    return LearningObjectService.getByAttributeId(id)
      .then((response) => {
        var filteredLearningObjects = response.data.map(({ translations, createdByUser, isActive, isPublic }) => ({
          name:
            translations.find((obj) => obj.language === this.state.selectedFilteringTableLanguage.lang)?.name + (!isActive ? " (inactive) " : " ") + (isPublic ? " (public)" : ""),
          createdByUser: createdByUser,
        }));

        this.setState({
          learningObjects: filteredLearningObjects.filter((t) => !t.name.startsWith("undefined")),
        });
      })
      .catch((e) => {
        console.log(e);
      });
  }

  confirmDeactivateAttribute = () => {
    AttributeService.deactivate(this.state.selectedAttribute.id)
      .then((response) => {
        var selected = this.state.selectedAttribute;
        selected.isActive = false;
        this.setState({ selectedAttribute: selected });
        this.changeActiveModalState();
        this.getAllAttributes();
      })
      .catch((e) => {
        console.log(e);
      });
  };

  confirmActivateAttribute = () => {
    AttributeService.activate(this.state.selectedAttribute.id).then(() => {
      var selected = this.state.selectedAttribute;
      selected.isActive = true;
      this.setState({ selectedAttribute: selected });
      this.changeActiveModalState();
      this.getAllAttributes();
    });
  };

  confirmDeleteAttribute = () => {
    AttributeService.delete(this.state.selectedAttribute.id)
      .then((response) => {
        this.getAllAttributes().then(() => {
          this.changeDeleteModalState();
          this.changeModalState();
        });
      })
      .catch((e) => {
        console.log(e);
      });
  };

  getAllAttributes = () => {
    return AttributeService.getAllAttributes()
      .then((response) => {
        this.setState({
          attributes: response.data,
        });
        this.filterAttributes(this.state.selectedFilteringTableLanguage.lang);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  filterAttributes(lang) {
    var filteredAttributes = this.state.attributes.map(({ id, translations, createdDate, isPublic, isActive, createdByUser }) => ({
      language: translations.find((obj) => obj.language === lang)?.language,
      name: translations.find((obj) => obj.language === lang)?.name,
      comment: translations.find((obj) => obj.language === lang)?.comment,
      values: translations.find((obj) => obj.language === lang)?.values.join(", "),
      createdByUser: createdByUser,
      isPublic: isPublic,
      isActive: isActive,
      id: id,
      createdDate: moment(new Date(createdDate)).format("DD.MM.YYYY"),
    }));
    this.setState({
      filteredAttributes: filteredAttributes.filter((obj) => obj.language),
    });
  }

  updateAttribute(e) {
    e.preventDefault();
    let attribute = this.state.selectedAttribute;
    attribute.isActive = true;
    attribute.translations = this.state.attributeTranslations;
    AttributeService.update(attribute)
      .then((response) => {
        this.changeModalState();
        this.setState({
          selectedAttribute: {},
        });
        this.getAllAttributes();
      })
      .catch((e) => {
        console.log(e);
      });
  }

  createNewAttribute(e) {
    e.preventDefault();
    var cat = this.state.selectedAttribute;
    cat.translations = this.state.attributeTranslations;
    this.setState({
      selectedAttribute: cat,
    });
    this.setState((prevState) => ({
      selectedAttribute: {
        ...prevState.selectedAttribute,
        isPublic: this.state.selectedAttribute.isPublic,
      },
    }));

    AttributeService.insert(this.state.selectedAttribute)
      .then((response) => {
        this.changeModalState();
        this.setState({ selectedAttribute: {} });
        this.getAllAttributes();
      })
      .catch((e) => {
        console.log(e);
      });
  }

  isEditable = () => {
    return !this.state.selectedAttribute.isPublic || this.isM2LAdmin();
  };

  isM2LAdmin = () => {
    return this.state.loggedUser?.currentRole === "SystemAdmin" && this.state.loggedUser?.currentCompanyId === null;
  };

  changeModalState = () => {
    this.setState({
      modal: !this.state.modal,
      isError: {},
      creatingAttributeTranslationName: "",
      creatingAttributeTranslationComment: "",
      creatingAttributeTranslationValues: [],
    });
  };

  changeActiveModalState = () => {
    this.setState({
      activeModal: !this.state.activeModal,
    });
  };

  changeDeleteModalState = () => {
    this.setState({
      deleteModal: !this.state.deleteModal,
    });
  };

  changePublic = (e) => {
    this.setState((prevState) => ({
      selectedAttribute: {
        ...prevState.selectedAttribute,
        isPublic: e.target.checked,
      },
    }));
  };

  onChangeCreatingAttributeTranslationLanguage = (e) => {
    this.setState({ selectedCreatingAttributeTranslationLanguage: e });
  };

  onChangeEditingAttributeTranslationLanguage = (e) => {
    this.setState({ selectedEditingAttributeTranslationLanguage: e });
  };

  onChangeFilteringTableLanguage = (e) => {
    this.setState({ selectedFilteringTableLanguage: e });
    this.filterAttributes(e.lang);
  };

  changeCreatingAttributeTranslationComment = (e) => {
    this.setState({ creatingAttributeTranslationComment: e.target.value });
    this.createNewTranslationValueChange(e);
  };

  changeCreatingAttributeTranslationValues = (e) => {
    this.setState({ creatingAttributeTranslationValues: e.filter((value, index, array) => array.indexOf(value) === index) });
  };

  changeCreatingAttributeTranslationName = (e) => {
    this.setState({ creatingAttributeTranslationName: e.target.value });
    this.createNewTranslationValueChange(e);
  };

  filterAttributeDropdownLanguages = (e) => {
    let filteredAttributeLanguages = this.state.attributeTranslations.map((obj) => {
      return obj.language;
    });
    let creatingAttributeTranslationLanguages = languages.filter((obj) => !filteredAttributeLanguages.includes(obj.lang));

    this.setState({
      creatingAttributeTranslationLanguages: creatingAttributeTranslationLanguages,
      selectedCreatingAttributeTranslationLanguage: creatingAttributeTranslationLanguages[0],
    });
  };

  createNewAttributeTranslation = (e) => {
    e.preventDefault();
    var isError = { ...this.state.isError };
    isError.name = this.state.creatingAttributeTranslationName.length < 1 ? "Required" : "";
    isError.values = this.state.creatingAttributeTranslationValues.length < 1 ? "Required" : "";
    this.setState({ isError });
    if (this.createNewTranslationFormValid({ isError: isError })) {
      var newAttributeTranslation = {
        language: this.state.selectedCreatingAttributeTranslationLanguage.lang,
        name: this.state.creatingAttributeTranslationName,
        comment: this.state.creatingAttributeTranslationComment,
        values: this.state.creatingAttributeTranslationValues,
      };
      this.state.attributeTranslations.push(newAttributeTranslation);
      this.setState({
        attributeTranslations: this.state.attributeTranslations,
      });
      this.filterAttributeDropdownLanguages();

      this.setState({ creatingAttributeTranslationComment: "", creatingAttributeTranslationName: "", creatingAttributeTranslationValues: [] });
    }
  };

  dateAttributesTemplate = (rowData) => {
    return rowData.createdDate;
  };
  createNewTranslationFormValid = ({ isError, ...rest }) => {
    let isValid = true;
    Object.values(isError).forEach((val) => {
      if (isValid === false) {
        return;
      }
      if (val.length > 0) {
        isValid = false;
      } else {
        isValid = true;
      }
    });
    Object.values(rest).forEach((val) => {
      if (isValid === false) {
        return;
      }
      if (val === null) {
        isValid = false;
      } else {
        isValid = true;
      }
    });
    return isValid;
  };
  createNewTranslationValueChange = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    let isError = { ...this.state.isError };
    switch (name) {
      case "name":
        isError.name = value.length < 1 ? "Required" : "";
        break;
      case "values":
        isError.values = value.length < 1 ? "Required" : "";
        break;
      default:
        break;
    }
    this.setState({
      isError,
      [name]: value,
    });
  };

  openDeactivateModel = () => {
    this.getLearningObjectByAttributeId(this.state.selectedAttribute.id).then(() => {
      this.changeActiveModalState();
    });
  };

  openDeleteModel = () => {
    this.getLearningObjectByAttributeId(this.state.selectedAttribute.id).then(() => {
      this.changeDeleteModalState();
    });
  };

  render() {
    const { t } = this.props;

    const renderActiveDeactivateButton = () => {
      if (this.state.selectedAttribute.id) {
        if (this.state.selectedAttribute.isActive) {
          return (
            <Button className="mb-2 me-2 " outline color="danger" onClick={this.openDeactivateModel}>
              {t("ATTRIBUTE.DEACTIVATE_BUTTON")}
            </Button>
          );
        } else {
          return (
            <Button className="mb-2 me-2 " outline color="success" onClick={this.changeActiveModalState}>
              {t("ATTRIBUTE.ACTIVATE_BUTTON")}
            </Button>
          );
        }
      }
    };

    const countryBodyTemplate = (rowData) => {
      return (
        <div className="country-item" style={{ display: "flex" }}>
          <div>{languages.find((obj) => obj.lang === rowData.language)?.name}</div>
        </div>
      );
    };

    const languageEditorTemplate = (options) => {
      return (
        <Select
          options={this.state.creatingAttributeTranslationLanguages}
          onChange={this.onChangeEditingAttributeTranslationLanguage}
          getOptionLabel={(option) => option.name}
          getOptionValue={(option) => option.name}
          key={languages.find((obj) => obj.lang === options.rowData?.language)}
          defaultValue={languages.find((obj) => obj.lang === options.rowData?.language)}
        />
      );
    };

    const openUpdateAttributeTranslationModal = (id) => {
      this.getAttributeById(id);
      this.changeModalState();
    };

    const openCreateAttribute = () => {
      var attribute = {};
      attribute.createdByUser = this.state.loggedUser.firstName + " " + this.state.loggedUser.lastName;
      attribute.isPublic = this.isM2LAdmin();
      attribute.isActive = true;
      attribute.createdDate = new Date();
      this.setState(
        {
          selectedAttribute: attribute,
          attributeTranslations: [],
          creatingAttributeTranslationLanguages: languages,
          selectedCreatingAttributeTranslationLanguage: this.state.selectedFilteringTableLanguage,
        },
        () => {
          this.changeModalState();
        }
      );
    };

    const deleteAttributeTranslation = (language) => {
      this.state.creatingAttributeTranslationLanguages.push(languages.find((obj) => obj.lang === language));
      this.setState({
        attributeTranslations: this.state.attributeTranslations.filter((obj) => obj.language !== language),
        filterAttributeDropdownLanguages: this.state.creatingAttributeTranslationLanguages,
        selectedCreatingAttributeTranslationLanguage: this.state.creatingAttributeTranslationLanguages[0],
      });
    };

    const buttonDeleteAttributeTranslation = (rowData) => {
      if (this.isEditable()) {
        return (
          <Button className=" " outline color="primary" onClick={() => deleteAttributeTranslation(rowData.language)}>
            {t("ATTRIBUTE.DELETE_BUTTON")}
          </Button>
        );
      }
    };

    const buttonUpdateAttributeTranslation = (rowData) => {
      return (
        <Button className="me-2" size="sm" outline color="primary" onClick={() => openUpdateAttributeTranslationModal(rowData.id)}>
          {t("ATTRIBUTE.OPEN_BUTTON")}
        </Button>
      );
    };

    const formatIsPublicCheckBox = (rowData) => {
      return (
        <i
          className={classNames("pi", {
            "true-icon pi-check-circle": rowData.isPublic,
            "false-icon pi-times-circle": !rowData.isPublic,
          })}
        ></i>
      );
    };

    const formatIsActiveCheckBox = (rowData) => {
      return (
        <i
          className={classNames("pi", {
            "true-icon pi-check-circle": rowData.isActive,
            "false-icon pi-times-circle": !rowData.isActive,
          })}
        ></i>
      );
    };

    const formatValues = (rowData) => {
      return <Chips value={rowData.values} disabled />;
    };

    const languageTableFilter = (options) => {
      return (
        <div>
          <UncontrolledDropdown>
            <DropdownToggle className="p-0 me-2" color="black">
              <div className="icon-wrapper-bg bg-focus" />
              <div className="language-icon">
                <Flag className="me-3 opacity-8" country={this.state.selectedFilteringTableLanguage?.flag} size="30" />
              </div>
            </DropdownToggle>
            <DropdownMenu end className="rm-pointers">
              {languages.map((language, id) => (
                <DropdownItem key={id} value={language} onClick={() => this.onChangeFilteringTableLanguage(language)}>
                  <Flag className="me-3 opacity-8" country={language.flag} />
                  {language.name}
                </DropdownItem>
              ))}
            </DropdownMenu>
          </UncontrolledDropdown>
        </div>
      );
    };

    const languageBody = (option) => {
      return (
        <Flag
          className="me-3 opacity-8"
          style={{
            marginLeft: "0.75rem",
          }}
          country={languages.find((obj) => obj.lang === option.language)?.flag}
          size="30"
        />
      );
    };

    const verifiedRowFilterTemplate = (options) => {
      return <TriStateCheckbox value={options.value} onChange={(e) => options.filterApplyCallback(e.value)} />;
    };

    const textEditor = (options) => {
      return <InputText type="text" value={options.value} onChange={(e) => options.editorCallback(e.target.value)} />;
    };

    const textValueEditor = (options) => {
      return <Chips value={options.value.filter((value, index, array) => array.indexOf(value) === index)} onChange={(e) => options.editorCallback(e.value)} />;
    };

    const onRowEditComplete = (e) => {
      let updateAttributes = this.state.attributeTranslations;
      let { newData, index } = e;
      newData.values = newData.values.includes(",") ? newData.values.split(",") : newData.values;
      newData.language = this.state.selectedEditingAttributeTranslationLanguage.lang ? this.state.selectedEditingAttributeTranslationLanguage.lang : e.data.language;

      updateAttributes[index] = newData;
      this.setState({
        attributeTranslations: updateAttributes,
      });
      this.filterAttributeDropdownLanguages();
    };

    const { isError } = this.state;
    return (
      <Fragment>
        <TransitionGroup>
          <CSSTransition component="div" classNames="TabsAnimation" appear={true} timeout={1500} enter={false} exit={false}>
            <div>
              <PageTitle heading={t("ATTRIBUTE.TITLE")} subheading={t("ATTRIBUTE.DESCRIPTION")} icon="pe-7s-medal icon-gradient bg-tempting-azure" />
              <Row>
                <Col md="12">
                  <Button className="mb-2 me-2  " outline color="primary" onClick={() => openCreateAttribute()}>
                    {t("ATTRIBUTE.CREATE_BUTTON")}
                  </Button>
                  <Modal isOpen={this.state.modal} fade={false} toggle={this.changeModalState}>
                    <ModalHeader toggle={this.changeModalState}>
                      {this.state.selectedAttribute?.id ? t("ATTRIBUTE.EDIT_MODAL_TITLE") : t("ATTRIBUTE.CREATE_MODAL_TITLE")}
                    </ModalHeader>
                    <ModalBody>
                      <div className="card p-fluid" style={{ fontWeight: "bold", textAlign: "center", padding: "10px" }}>
                        <Row>
                          <Col md="4">
                            <Label for="createdByUser">
                              {t("ATTRIBUTE.CREATED_BY")} {this.state.selectedAttribute?.createdByUser}
                            </Label>
                          </Col>
                          <Col md="4">
                            <Label for="createdDate">
                              {t("ATTRIBUTE.CREATED_DATE")} {moment(new Date(this.state.selectedAttribute?.createdDate)).format("DD.MM.YYYY")}
                            </Label>
                          </Col>
                          <Col md="4">
                            {this.isM2LAdmin() && (
                              <FormGroup check>
                                <Label check>
                                  <Input
                                    type="checkbox"
                                    checked={this.state.selectedAttribute?.isPublic}
                                    disabled={!this.state.selectedAttribute.isActive || this.state.loggedUser.currentRole !== "SystemAdmin"}
                                    onChange={this.changePublic}
                                  />
                                  {t("ATTRIBUTE.IS_PUBLIC")}
                                </Label>
                              </FormGroup>
                            )}
                          </Col>
                        </Row>
                      </div>
                      {this.isEditable() && (
                        <div className="card p-fluid">
                          <Form onSubmit={this.createNewAttributeTranslation} style={{ margin: "10px" }} noValidate>
                            <Row>
                              <Col>
                                <FormGroup>
                                  <Label for="exampleSelect">{t("ATTRIBUTE.LANGUAGE")}</Label>
                                  <Select
                                    options={this.state.creatingAttributeTranslationLanguages}
                                    value={this.state.selectedCreatingAttributeTranslationLanguage ? this.state.selectedCreatingAttributeTranslationLanguage : " "}
                                    onChange={this.onChangeCreatingAttributeTranslationLanguage}
                                    getOptionLabel={(option) => option.name}
                                    getOptionValue={(option) => option.lang}
                                  />
                                </FormGroup>
                              </Col>
                              <Col>
                                <FormGroup>
                                  <Label for="name"> {t("ATTRIBUTE.NAME")}</Label>
                                  <Input
                                    type="text"
                                    name="name"
                                    id="name"
                                    onChange={this.changeCreatingAttributeTranslationName}
                                    value={this.state.creatingAttributeTranslationName}
                                    className={isError.name?.length > 0 ? "is-invalid form-control" : "form-control"}
                                  />
                                  {isError.name?.length > 0 && <span className="invalid-feedback">{isError?.name}</span>}
                                </FormGroup>
                              </Col>
                              <Col>
                                <FormGroup>
                                  <Label for="comment">{t("ATTRIBUTE.COMMENT")}</Label>
                                  <Input
                                    type="text"
                                    name="comment"
                                    id="comment"
                                    onChange={this.changeCreatingAttributeTranslationComment}
                                    value={this.state.creatingAttributeTranslationComment}
                                    className={"form-control"}
                                  />
                                </FormGroup>
                              </Col>
                              <Col>
                                <Label for="value">{t("ATTRIBUTE.VALUES")}</Label>
                                <Chips
                                  value={this.state.creatingAttributeTranslationValues}
                                  onChange={(e) => this.changeCreatingAttributeTranslationValues(e.value)}
                                  invalid={isError.values?.length > 0}
                                />
                                {isError.values?.length > 0 && <span className="invalid-feedback">{isError?.values}</span>}
                              </Col>

                              <Col>
                                <Button className="mb-2 me-2  " outline color="primary" style={{ marginTop: "30px" }} type="submit">
                                  {t("ATTRIBUTE.CREATE_BUTTON")}
                                </Button>
                              </Col>
                            </Row>
                          </Form>
                        </div>
                      )}

                      <div className="card p-fluid">
                        <DataTable
                          editMode="row"
                          value={this.state.attributeTranslations}
                          dataKey="id"
                          className="p-datatable-customers"
                          onRowEditComplete={onRowEditComplete}
                          // responsiveLayout="scroll"
                        >
                          <Column
                            field="language"
                            header={t("ATTRIBUTE.LANGUAGE_COLUMN_NAME")}
                            body={countryBodyTemplate}
                            editor={(options) => languageEditorTemplate(options)}
                            style={{ zIndex: "2" }}
                          ></Column>
                          <Column field="name" header={t("ATTRIBUTE.NAME_COLUMN_NAME")} editor={(options) => textEditor(options)}></Column>
                          <Column field="comment" header={t("ATTRIBUTE.COMMENT_COLUMN_NAME")} editor={(options) => textEditor(options)}></Column>
                          <Column field="values" header={t("ATTRIBUTE.VALUES")} body={formatValues} editor={(options) => textValueEditor(options)}></Column>
                          <Column rowEditor={this.isEditable()} headerStyle={{ width: "10%", minWidth: "8rem" }} bodyStyle={{ textAlign: "center" }}></Column>
                          <Column body={buttonDeleteAttributeTranslation} />
                        </DataTable>
                      </div>
                    </ModalBody>
                    <ModalFooter>
                      <Modal isOpen={this.state.activeModal} toggle={this.changeActiveModalState} style={{ marginTop: "50px", width: "60%" }}>
                        <ModalHeader>
                          {this.state.selectedAttribute.isActive ? t("ATTRIBUTE.CONFIRM_DEACTIVATE_MODAL_TITLE") : t("ATTRIBUTE.CONFIRM_ACTIVATE_MODAL_TITLE")}
                        </ModalHeader>
                        <ModalBody style={{ minHeight: "auto" }}>
                          {this.state.selectedAttribute.isActive && this.state.learningObjects.length > 0 ? (
                            <div>
                              <PerfectScrollbar>
                                {this.state.learningObjects.length > 0 ? (
                                  <Card className="main-card mb-3">
                                    <CardTitle>{t("ATTRIBUTE.LEARNING_OBJECTS")}</CardTitle>
                                    <DataTable value={this.state.learningObjects} paginator rows={10}>
                                      <Column field="name" header={t("ATTRIBUTE.LEARNING_OBJECT_NAME_COLUMN_NAME")}></Column>
                                      <Column field="createdByUser" header={t("ATTRIBUTE.LEARNING_OBJECT_CREATED_BY_COLUMN_NAME")}></Column>
                                    </DataTable>
                                  </Card>
                                ) : (
                                  ""
                                )}
                              </PerfectScrollbar>
                            </div>
                          ) : (
                            ""
                          )}
                        </ModalBody>
                        <ModalFooter>
                          <Col>
                            {this.state.selectedAttribute.isActive && this.state.learningObjects.length > 0 ? (
                              <CSVLink
                                data={this.state.learningObjects}
                                filename={
                                  "deactivate_attribute_" +
                                  this.state.attributeTranslations.find((a) => a.language === this.state.selectedFilteringTableLanguage.lang)?.name +
                                  ".csv"
                                }
                                headers={this.state.csvHeaders}
                                className="mb-2 me-2 btn btn-success"
                                target="_blank"
                              >
                                {t("ATTRIBUTE.EXPORT_BUTTON")}
                              </CSVLink>
                            ) : (
                              ""
                            )}
                          </Col>
                          <Button className="mb-2 me-2 " color="secondary" onClick={this.changeActiveModalState}>
                            {t("ATTRIBUTE.NO_BUTTON")}
                          </Button>
                          <Button
                            className="mb-2 me-2 "
                            color="primary"
                            onClick={this.state.selectedAttribute.isActive ? this.confirmDeactivateAttribute : this.confirmActivateAttribute}
                          >
                            {t("ATTRIBUTE.YES_BUTTON")}
                          </Button>
                        </ModalFooter>
                      </Modal>

                      <Modal isOpen={this.state.deleteModal} toggle={this.changeDeleteModalState} style={{ marginTop: "50px", width: "60%" }}>
                        <ModalHeader> {this.state.learningObjects.length > 0 ? t("ATTRIBUTE.DELETE_MODAL_TITLE") : t("ATTRIBUTE.CONFIRM_DELETE_MODAL_TITLE")}</ModalHeader>
                        <ModalBody style={{ minHeight: "auto" }}>
                          {this.state.learningObjects.length > 0 ? (
                            <div>
                              <PerfectScrollbar>
                                {this.state.learningObjects.length > 0 ? (
                                  <Card className="main-card mb-3">
                                    <CardTitle>{t("ATTRIBUTE.LEARNING_OBJECTS")}</CardTitle>
                                    <DataTable value={this.state.learningObjects} paginator rows={10}>
                                      <Column field="name" header={t("ATTRIBUTE.LEARNING_OBJECT_NAME_COLUMN_NAME")}></Column>
                                      <Column field="createdByUser" header={t("ATTRIBUTE.LEARNING_OBJECT_CREATED_BY_COLUMN_NAME")}></Column>
                                    </DataTable>
                                  </Card>
                                ) : (
                                  ""
                                )}
                              </PerfectScrollbar>
                            </div>
                          ) : (
                            ""
                          )}
                        </ModalBody>
                        <ModalFooter>
                          <Col>
                            {this.state.learningObjects.length > 0 ? (
                              <CSVLink
                                data={this.state.learningObjects}
                                filename={
                                  "delete_attribute_" + this.state.attributeTranslations.find((a) => a.language === this.state.selectedFilteringTableLanguage.lang)?.name + ".csv"
                                }
                                headers={this.state.csvHeaders}
                                className="mb-2 me-2 btn btn-success"
                                target="_blank"
                              >
                                {t("ATTRIBUTE.EXPORT_BUTTON")}
                              </CSVLink>
                            ) : (
                              ""
                            )}
                          </Col>
                          {this.state.learningObjects.length > 0 ? (
                            <div>
                              <Button className="mb-2 me-2 " color="secondary" onClick={this.changeDeleteModalState}>
                                {t("ATTRIBUTE.CANCEL_BUTTON")}
                              </Button>
                            </div>
                          ) : (
                            <div>
                              <Button className="mb-2 me-2 " color="secondary" onClick={this.changeDeleteModalState}>
                                {t("ATTRIBUTE.NO_BUTTON")}
                              </Button>
                              <Button className="mb-2 me-2 " color="primary" onClick={this.confirmDeleteAttribute}>
                                {t("ATTRIBUTE.YES_BUTTON")}
                              </Button>
                            </div>
                          )}
                        </ModalFooter>
                      </Modal>
                      {this.isEditable() ? (
                        <>
                          <Col>
                            {this.state.selectedAttribute.id ? (
                              <Button disabled={this.state.selectedAttribute.isActive} className="mb-2 me-2" color="danger" onClick={this.openDeleteModel}>
                                {t("ATTRIBUTE.DELETE_BUTTON")}
                              </Button>
                            ) : (
                              ""
                            )}
                            {renderActiveDeactivateButton()}
                          </Col>

                          <Button className="mb-2 me-2" color="secondary" onClick={this.changeModalState}>
                            {t("ATTRIBUTE.CANCEL_BUTTON")}
                          </Button>
                          {this.state.selectedAttribute?.id ? (
                            <Button className="mb-2 me-2 " color="primary" onClick={(e) => this.updateAttribute(e)}>
                              {t("ATTRIBUTE.UPDATE_BUTTON")}
                            </Button>
                          ) : (
                            <Button className="mb-2 me-2 " color="primary" onClick={(e) => this.createNewAttribute(e)}>
                              {t("ATTRIBUTE.SAVE_BUTTON")}
                            </Button>
                          )}
                        </>
                      ) : (
                        <Button className="mb-2 me-2" color="secondary" onClick={this.changeModalState}>
                          {t("ATTRIBUTE.CLOSE_BUTTON")}
                        </Button>
                      )}
                    </ModalFooter>
                  </Modal>
                </Col>
                <Col md="12">
                  {this.state.showPublic}
                  <Card className="main-card mb-3">
                    <CardBody>
                      <div className="card p-fluid">
                        <DataTable
                          value={this.state.filteredAttributes}
                          paginator
                          className="p-datatable-customers"
                          rows={10}
                          dataKey="id"
                          filterDisplay="row"
                          responsiveLayout="scroll"
                          filters={this.state.filterAttributesTemplate}
                          emptyMessage="No attributes found."
                        >
                          <Column
                            header={t("ATTRIBUTE.LANGUAGE_COLUMN_NAME")}
                            field="language"
                            showFilterMenu={false}
                            style={{ minWidth: "10rem" }}
                            body={languageBody}
                            filter
                            filterElement={languageTableFilter}
                          />
                          <Column header={t("ATTRIBUTE.NAME_COLUMN_NAME")} field="name" filter sortable />
                          <Column header={t("ATTRIBUTE.COMMENT_COLUMN_NAME")} field="comment" filter sortable />
                          <Column header={t("ATTRIBUTE.VALUES_COLUMN_NAME")} field="values" filter sortable />
                          <Column
                            field="isPublic"
                            header={t("ATTRIBUTE.IS_PUBLIC_COLUMN_NAME")}
                            dataType="boolean"
                            sortable
                            body={formatIsPublicCheckBox}
                            filter
                            filterElement={verifiedRowFilterTemplate}
                          />
                          <Column
                            field="isActive"
                            header={t("ATTRIBUTE.IS_ACTIVE_COLUMN_NAME")}
                            dataType="boolean"
                            sortable
                            body={formatIsActiveCheckBox}
                            filter
                            filterElement={verifiedRowFilterTemplate}
                          />
                          <Column field="createdByUser" header={t("ATTRIBUTE.CREATED_BY_COLUMN_NAME")} filter sortable />
                          <Column header={t("ATTRIBUTE.CREATED_DATE_COLUMN_NAME")} filterField="createdDate" dataType="date" body={this.dateAttributesTemplate} />
                          <Column body={buttonUpdateAttributeTranslation} rowEditor />
                        </DataTable>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </div>
          </CSSTransition>
        </TransitionGroup>
      </Fragment>
    );
  }
}
export default withTranslation()(Attribute);
