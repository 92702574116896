import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { Col, Row, Input, FormGroup, Label } from "reactstrap";
import "../LearningPath/style.css";
import moment from "moment/moment";
import LanguageSelect from "../../Shared/LanguageSelect";
import tokenService from "../../../service/tokenService";

class CreateUpdateTabContent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedLearningPath: props.learningPath,
      loggedUser: tokenService.getUser(),
      error: {},
    };
  }

  onChangeLanguage = (e) => {
    var selectedLP = this.state.selectedLearningPath;
    selectedLP.language = e.lang;
    this.setState({ selectedLearningPath: selectedLP });
    this.props.onChangeLearningPath(selectedLP);
  };

  changePublic = (event) => {
    var selectedLP = this.state.selectedLearningPath;
    selectedLP.isPublic = event.target.checked;
    this.setState({ selectedLearningPath: selectedLP });
    this.props.onChangeLearningPath(selectedLP);
  };

  changeSelectedLearningPathName = (e) => {
    var selectedLP = this.state.selectedLearningPath;
    selectedLP.name = e.target.value;
    var error = this.state.error;
    error.name = e.target.value ? "" : "Required";
    this.setState({ selectedLearningPath: selectedLP, error: error });
    this.props.onChangeLearningPath(selectedLP);
  };

  changeSelectedLearningPathComment = (e) => {
    var selectedLP = this.state.selectedLearningPath;
    selectedLP.comment = e.target.value;
    this.setState({ selectedLearningPath: selectedLP });
    this.props.onChangeLearningPath(selectedLP);
  };

  changeSelectedLearningPathDescription = (e) => {
    var selectedLP = this.state.selectedLearningPath;
    selectedLP.description = e.target.value;
    this.setState({ selectedLearningPath: selectedLP });
    this.props.onChangeLearningPath(selectedLP);
  };

  changeSelectedLearningPathDuration = (e) => {
    var selectedLP = this.state.selectedLearningPath;
    selectedLP.duration = e.target.value;
    this.setState({ selectedLearningPath: selectedLP });
    this.props.onChangeLearningPath(selectedLP);
  };

  changeSelectedLearningPathRequirements = (e) => {
    var selectedLP = this.state.selectedLearningPath;
    selectedLP.requirements = e.target.value;
    this.setState({ selectedLearningPath: selectedLP });
    this.props.onChangeLearningPath(selectedLP);
  };

  changeSelectedLearningPathTargetGroup = (e) => {
    var selectedLP = this.state.selectedLearningPath;
    selectedLP.targetGroup = e.target.value;
    this.setState({ selectedLearningPath: selectedLP });
    this.props.onChangeLearningPath(selectedLP);
  };

  isM2LAdmin = () => {
    return this.state.loggedUser?.currentRole === "SystemAdmin" && this.state.loggedUser?.currentCompanyId === null;
  };

  render() {
    const { t, readOnly } = this.props;

    return (
      <div>
        <div
          className="card p-fluid"
          style={{
            fontWeight: "bold",
            textAlign: "center",
            padding: "10px",
          }}
        >
          <Row>
            <Col md="2">
              <Label for="parentName">
                {t("LEARNINGPATH.PARENT_NAME")}: {this.state.selectedLearningPath.parentName}
              </Label>
            </Col>
            <Col md="3">
              <Label for="loggedUserName">
                {t("LEARNINGPATH.CREATED_BY")} {this.state.selectedLearningPath.createdByUser}
              </Label>
            </Col>
            <Col md="2">
              <Label for="createdDate">
                {t("LEARNINGPATH.CREATED_DATE")} {moment(new Date(this.state.selectedLearningPath.createdDate)).format("DD.MM.YYYY")}
              </Label>
            </Col>
            <Col md="2">
              {this.isM2LAdmin() && (
                <FormGroup check>
                  <Label check>
                    <Input
                      type="checkbox"
                      disabled={!this.state.selectedLearningPath.isActive || readOnly}
                      checked={this.state.selectedLearningPath.isPublic}
                      onChange={this.changePublic}
                    />
                    <div className="title-text-size">{t("LEARNINGPATH.IS_PUBLIC")}</div>
                  </Label>
                </FormGroup>
              )}
            </Col>
            <Col md="3">
              <LanguageSelect lang={this.state.selectedLearningPath.language} onChangeLanguage={this.onChangeLanguage} readOnly={readOnly} />
            </Col>
          </Row>
        </div>
        <div className="card p-fluid">
          <div style={{ margin: "10px" }}>
            <Row>
              <Col md="6">
                <FormGroup>
                  <Label for="name"> {t("LEARNINGPATH.NAME")}*</Label>
                  <Input
                    type="text"
                    name="name"
                    id="name"
                    readOnly={readOnly}
                    onChange={this.changeSelectedLearningPathName}
                    value={this.state.selectedLearningPath.name}
                    className={this.state.error?.name ? "is-invalid form-control" : "form-control"}
                  />
                  {this.state.error?.name && <span className="invalid-feedback">{this.state.error?.name}</span>}
                </FormGroup>
              </Col>
              <Col md="6">
                <FormGroup>
                  <Label for="targetGroup">{t("LEARNINGPATH.TARGET_GROUP")}</Label>
                  <Input
                    type="text"
                    name="targetGroup"
                    id="targetGroup"
                    readOnly={readOnly}
                    onChange={this.changeSelectedLearningPathTargetGroup}
                    value={this.state.selectedLearningPath.targetGroup}
                    className={"form-control"}
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col md="6">
                <Row>
                  <FormGroup>
                    <Label for="comment">{t("LEARNINGPATH.COMMENT")}</Label>
                    <Input
                      type="text"
                      name="comment"
                      id="comment"
                      readOnly={readOnly}
                      onChange={this.changeSelectedLearningPathComment}
                      value={this.state.selectedLearningPath.comment}
                      className={"form-control"}
                    />
                  </FormGroup>
                </Row>
                <Row>
                  <Col>
                    <FormGroup>
                      <Label for="duration">{t("LEARNINGPATH.DURATION")}</Label>
                      <Input
                        type="text"
                        name="duration"
                        id="duration"
                        readOnly={readOnly}
                        onChange={this.changeSelectedLearningPathDuration}
                        value={this.state.selectedLearningPath.duration}
                        className={"form-control"}
                      />
                    </FormGroup>
                  </Col>
                </Row>
              </Col>
              <Col md="6">
                <FormGroup>
                  <Label for="description">{t("LEARNINGPATH.DESCRIPTION_MODAL")}</Label>
                  <textarea
                    rows="5"
                    style={{ height: "100%" }}
                    type="text"
                    name="description"
                    id="description"
                    readOnly={readOnly}
                    onChange={this.changeSelectedLearningPathDescription}
                    value={this.state.selectedLearningPath.description}
                    className={"form-control"}
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col md="6">
                <FormGroup>
                  <Label for="requirements">{t("LEARNINGPATH.REQUIREMENTS")}</Label>
                  <Input
                    type="text"
                    name="requirements"
                    id="requirements"
                    readOnly={readOnly}
                    onChange={this.changeSelectedLearningPathRequirements}
                    value={this.state.selectedLearningPath.requirements}
                    className={"form-control"}
                  />
                </FormGroup>
              </Col>
              <Col md="6">
                {readOnly ?? (
                  <FormGroup>
                    <Label for="version">{t("LEARNINGPATH.VERSION")}</Label>
                    <Input type="text" name="version" id="version" value={this.state.selectedLearningPath.version} className="form-control" readOnly />
                  </FormGroup>
                )}
              </Col>
            </Row>
          </div>
        </div>
      </div>
    );
  }
}
export default withTranslation()(CreateUpdateTabContent);
