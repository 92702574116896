import React, { Fragment, Component } from "react";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import { withTranslation } from "react-i18next";
import PageTitle from "../../../Layout/AppMain/PageTitle";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import PerfectScrollbar from "react-perfect-scrollbar";
import {
  UncontrolledDropdown,
  DropdownMenu,
  DropdownToggle,
  DropdownItem,
  Row,
  Col,
  Card,
  CardBody,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input,
  FormGroup,
  Form,
  Label,
  Button,
  CardTitle,
} from "reactstrap";
import { FilterMatchMode } from "primereact/api";
import { Calendar } from "primereact/calendar";
import Select from "react-select";
import "../Attribute/DataTable.css";
import { classNames } from "primereact/utils";
import { TriStateCheckbox } from "primereact/tristatecheckbox";
import { InputText } from "primereact/inputtext";
import CompetenceService from "../../../service/CompetenceService";
import CompetenceFrameService from "../../../service/CompetenceFrameService";
import languages from "../../../models/Languages";
import moment from "moment/moment";
import Flag from "react-flagkit";
import tokenService from "../../../service/tokenService";
import LearningObjectService from "../../../service/LearningObjectService";
import { CSVLink } from "react-csv";

class Competence extends Component {
  constructor(props) {
    super(props);
    this.state = {
      learningObjects: [],
      competences: [],
      selectedCompetence: {},
      selectedFilteringTableLanguage: languages.find((obj) => obj.lang === this.props.i18n.language),
      filterCompetenceDropdownLanguages: languages,

      competenceFrames: [],
      filteredCompetenceFrames: [],

      creatingCompetenceTranslationLanguages: languages,
      selectedCreatingCompetenceTranslationLanguage: languages[0],
      selectedEditingCompetenceTranslationLanguage: "",

      creatingCompetenceTranslationName: "", //need join
      creatingCompetenceTranslationComment: "", //

      competenceTranslations: [],
      filteredCompetences: [],
      loggedUser: tokenService.getUser(),
      isUpdate: true,
      modal: false,
      deleteModal: false,
      activeModal: false,
      filterCompetencesTemplate: {
        name: { value: null, matchMode: FilterMatchMode.CONTAINS },
        userName: { value: null, matchMode: FilterMatchMode.CONTAINS },
        comment: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
        createdDate: { value: null, matchMode: FilterMatchMode.DATE_IS },
        createdByUser: { value: null, matchMode: FilterMatchMode.CONTAINS },
        isPublic: { value: tokenService.getCompany().showPublicCompetence ? null : false, matchMode: FilterMatchMode.EQUALS },
        isActive: { value: null, matchMode: FilterMatchMode.EQUALS },
      },

      error: {},

      csvHeaders: [
        { label: "Name", key: "name" },
        { label: "Created By", key: "createdByUser" },
      ],
    };
  }

  componentDidMount() {
    this.getAllCompetences();
    this.getActiveCompetenceFrames();
  }

  getCompetenceById(id) {
    return CompetenceService.getById(id)
      .then((response) => {
        this.setState({
          competenceTranslations: response.data.translations,
          selectedCompetence: response.data,
          isUpdate: true,
        });
        this.filterCompetenceDropdownLanguages();
      })
      .catch((e) => {
        console.log(e);
      });
  }

  getAllCompetences = () => {
    CompetenceService.getAll()
      .then((response) => {
        this.setState(
          {
            competences: response.data,
          },
          () => {
            this.filterCompetences(this.state.selectedFilteringTableLanguage.lang);
          }
        );
      })
      .catch((e) => {
        console.log(e);
      });
  };

  filterCompetences(lang) {
    var filteredCompetences = this.state.competences.map(({ id, translations, createdDate, isPublic, isActive, createdByUser }) => ({
      language: translations.find((obj) => obj.language === lang)?.language,
      name: translations.find((obj) => obj.language === lang)?.name,
      comment: translations.find((obj) => obj.language === lang)?.comment,
      createdByUser: createdByUser,
      isPublic: isPublic,
      isActive: isActive,
      id: id,
      createdDate: moment(new Date(createdDate)).format("DD.MM.YYYY"),
    }));
    this.setState({
      filteredCompetences: filteredCompetences.filter((obj) => obj.language),
    });
  }

  getLearningObjectByCompetenceId(id) {
    return LearningObjectService.getByCompetenceId(id)
      .then((response) => {
        var filteredLearningObjects = response.data.map(({ translations, createdByUser, isActive, isPublic }) => ({
          name:
            translations.find((obj) => obj.language === this.state.selectedFilteringTableLanguage.lang)?.name + (!isActive ? " (inactive) " : " ") + (isPublic ? " (public)" : ""),
          createdByUser: createdByUser,
        }));
        this.setState({
          learningObjects: filteredLearningObjects.filter((t) => !t.name.startsWith("undefined")),
        });
      })
      .catch((e) => {
        console.log(e);
      });
  }

  getActiveCompetenceFrames() {
    CompetenceFrameService.getAll()
      .then((response) => {
        this.setState(
          {
            competenceFrames: response.data,
          },
          () => {
            this.filterCompetenceFrames(this.state.selectedFilteringTableLanguage.lang);
          }
        );
      })
      .catch((e) => {
        console.log(e);
      });
  }

  filterCompetenceFrames(lang) {
    var filteredCompetenceFrames = this.state.competenceFrames
      .filter((r) => r.isActive || this.state.selectedCompetence.competenceFrameId)
      .map(({ translations, id, isActive, isPublic }) => ({
        name: translations.find((obj) => obj.language === lang)?.name + (!isActive ? " (inactive) " : " ") + (isPublic ? " (public)" : ""),
        id: id,
        isActive: isActive,
        isPublic: isPublic,
      }))
      .filter((obj) => obj.name && !obj.name.startsWith("undefined"))
      .sort((a, b) => {
        if (a.isPublic !== b.isPublic) {
          return a.isPublic ? 1 : -1;
        } else {
          return a.name.localeCompare(b.name);
        }
      });
    this.setState({
      filteredCompetenceFrames: filteredCompetenceFrames,
    });
  }

  isEditable = () => {
    return !this.state.selectedCompetence.isPublic || this.isM2LAdmin();
  };

  isM2LAdmin = () => {
    return this.state.loggedUser?.currentRole === "SystemAdmin" && this.state.loggedUser?.currentCompanyId === null;
  };

  updateCompetence(e) {
    e.preventDefault();
    var error = { ...this.state.error };
    error.competenceFrame = this.state.selectedCompetence?.competenceFrameId ? "" : "Required";
    this.setState({ error });

    if (this.checkValid({ error: error })) {
      let competence = this.state.selectedCompetence;
      competence.isActive = true;
      competence.translations = this.state.competenceTranslations;
      var updateCompetence = competence;
      CompetenceService.update(updateCompetence).then(() => {
        this.changeModalState();
        this.getAllCompetences();
      });
    }
  }

  createNewCompetence(e) {
    e.preventDefault();
    var error = { ...this.state.error };
    error.competenceFrame = this.state.selectedCompetence?.competenceFrameId ? "" : "Required";
    this.setState({ error });

    if (this.checkValid({ error: error })) {
      let competence = this.state.selectedCompetence;
      competence.translations = this.state.competenceTranslations;
      competence.isActive = true;
      CompetenceService.insert(competence).then(() => {
        this.changeModalState();
        this.getAllCompetences();
      });
    }
  }

  changeModalState = () => {
    this.setState({
      modal: !this.state.modal,
      error: {},
      creatingCompetenceTranslationName: "",
      creatingCompetenceTranslationComment: "",
    });
  };

  changePublic = (event) => {
    let st = this.state.selectedCompetence;
    st.isPublic = event.target.checked;
    this.setState({
      selectedCompetence: st,
    });
  };

  openDeactivateModel = () => {
    this.getLearningObjectByCompetenceId(this.state.selectedCompetence.id).then(() => {
      this.changeActiveModalState();
    });
  };

  openDeleteModel = () => {
    this.getLearningObjectByCompetenceId(this.state.selectedCompetence.id).then(() => {
      this.changeDeleteModalState();
    });
  };

  onChangeCreatingCompetencesTranslationLanguage = (e) => {
    this.setState({ selectedCreatingCompetenceTranslationLanguage: e });
  };

  onChangeEditingCompetenceTranslationLanguage = (e) => {
    this.setState({ selectedEditingCompetenceTranslationLanguage: e });
  };

  onChangeFilteringTableLanguage = (e) => {
    this.setState({ selectedFilteringTableLanguage: e });
    this.filterCompetences(e.lang);
  };

  changeCreatingCompetenceTranslationComment = (e) => {
    this.setState({ creatingCompetenceTranslationComment: e.target.value });
    this.createNewTranslationValueChange(e);
  };

  changeCreatingCompetenceTranslationName = (e) => {
    this.setState({ creatingCompetenceTranslationName: e.target.value });
    this.createNewTranslationValueChange(e);
  };

  filterCompetenceDropdownLanguages() {
    let filteredCompetenceLanguages = this.state.competenceTranslations.map((obj) => obj.language);
    let creatingCompetenceTranslationLanguages = languages.filter((obj) => !filteredCompetenceLanguages.includes(obj.lang));
    this.setState({
      creatingCompetenceTranslationLanguages: creatingCompetenceTranslationLanguages,
      selectedCreatingCompetenceTranslationLanguage: creatingCompetenceTranslationLanguages[0],
    });
  }

  createNewCompetenceTranslation = (e) => {
    e.preventDefault();
    var error = { ...this.state.error };
    error.creatingCompetenceTranslationName = this.state.creatingCompetenceTranslationName.length < 1 ? "Required" : "";
    this.setState({ error });
    if (!error.creatingCompetenceTranslationName) {
      var newCompetenceTranslation = {
        language: this.state.selectedCreatingCompetenceTranslationLanguage.lang,
        name: this.state.creatingCompetenceTranslationName,
        comment: this.state.creatingCompetenceTranslationComment,
      };
      this.state.competenceTranslations.push(newCompetenceTranslation);
      this.setState({
        competenceTranslations: this.state.competenceTranslations,
      });
      this.filterCompetenceDropdownLanguages();

      this.setState({ creatingCompetenceTranslationName: "", creatingCompetenceTranslationComment: "" });
    }
  };

  changeActiveModalState = () => {
    this.setState({
      activeModal: !this.state.activeModal,
    });
  };

  confirmDeactivateCompetence = () => {
    CompetenceService.deactivate(this.state.selectedCompetence.id).then(() => {
      var selected = this.state.selectedCompetence;
      selected.isActive = false;
      this.setState({ selectedCompetence: selected });
      this.changeActiveModalState();
      this.getAllCompetences();
    });
  };

  confirmActivateCompetence = () => {
    CompetenceService.activate(this.state.selectedCompetence.id).then(() => {
      var selected = this.state.selectedCompetence;
      selected.isActive = true;
      this.setState({ selectedCompetence: selected });
      this.changeActiveModalState();
      this.getAllCompetences();
    });
  };

  changeDeleteModalState = () => {
    this.setState({
      deleteModal: !this.state.deleteModal,
    });
  };

  confirmDeleteCompetence = () => {
    CompetenceService.delete(this.state.selectedCompetence.id).then(() => {
      this.getAllCompetences();
      this.changeDeleteModalState();
      this.changeModalState();
    });
  };

  dateFilterTemplate = (options) => {
    return (
      <Calendar value={new Date(options.value).toLocaleString()} onChange={(e) => options.filterCallback(e.value, options.index)} dateFormat="mm/dd/yy" placeholder="mm/dd/yyyy" />
    );
  };
  dateCompetencesTemplate = (rowData) => {
    return rowData.createdDate;
  };

  createNewTranslationValueChange = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    let error = { ...this.state.error };
    switch (name) {
      case "name":
        error.name = value.length < 1 ? "Required" : "";
        break;
      default:
        break;
    }
    this.setState({
      error,
      [name]: value,
    });
  };

  onChangeCompetenceFrame = (e) => {
    var competence = this.state.selectedCompetence;
    competence.competenceFrameId = e.id;

    var error = this.state.error;
    error.competenceFrame = this.state.selectedCompetence?.competenceFrameId ? "" : "Required";
    this.setState({ selectedCompetence: competence, error: error });
  };

  checkValid = ({ error, ...rest }) => {
    let isValid = true;
    Object.values(error).forEach((val) => {
      if (isValid === false) {
        return;
      }
      if (val.length > 0) {
        isValid = false;
      } else {
        isValid = true;
      }
    });
    if (rest) {
      Object.values(rest).forEach((val) => {
        if (isValid === false) {
          return;
        }
        if (val === null) {
          isValid = false;
        } else {
          isValid = true;
        }
      });
    }
    return isValid;
  };

  render() {
    const { t } = this.props;
    const { error } = this.state;

    const countryBodyTemplate = (rowData) => {
      return (
        <div className="country-item">
          <div>{languages.find((obj) => obj.lang === rowData.language)?.name}</div>
        </div>
      );
    };

    const languageEditorTemplate = (options) => {
      return (
        <Select
          options={this.state.creatingCompetenceTranslationLanguages}
          onChange={this.onChangeEditingCompetenceTranslationLanguage}
          getOptionLabel={(option) => option.name}
          getOptionValue={(option) => option.name}
          key={languages.find((obj) => obj.lang === options.rowData?.language)}
          defaultValue={languages.find((obj) => obj.lang === options.rowData?.language)}
        />
      );
    };

    const openUpdateCompetenceTranslationModal = (id) => {
      this.getCompetenceById(id).then(() => {
        this.filterCompetenceFrames(this.state.selectedFilteringTableLanguage.lang);
        this.changeModalState();
      });
    };

    const openCreateCompetence = () => {
      this.filterCompetenceFrames(this.state.selectedFilteringTableLanguage.lang);
      var competence = {};
      competence.createdByUser = this.state.loggedUser.firstName + " " + this.state.loggedUser.lastName;
      competence.isPublic = this.isM2LAdmin();
      competence.isActive = true;
      competence.createdDate = new Date();
      this.setState(
        {
          selectedCompetence: competence,
          competenceTranslations: [],
          creatingCompetenceTranslationLanguages: languages,
          selectedCreatingCompetenceTranslationLanguage: this.state.selectedFilteringTableLanguage,
          isUpdate: false,
        },
        () => {
          this.changeModalState();
        }
      );
    };

    const deleteCompetenceTranslation = (language) => {
      this.state.creatingCompetenceTranslationLanguages.push(languages.find((obj) => obj.lang === language));
      this.setState({
        competenceTranslations: this.state.competenceTranslations.filter((obj) => obj.language !== language),
        selectedCreatingCompetenceTranslationLanguage: this.state.creatingCompetenceTranslationLanguages[0],
        filterCompetenceDropdownLanguages: this.state.creatingCompetenceTranslationLanguages,
      });
    };

    const buttonDeleteCompetenceTranslation = (rowData) => {
      if (this.isEditable()) {
        return (
          <Button outline color="primary" onClick={() => deleteCompetenceTranslation(rowData.language)}>
            {t("COMPETENCE.DELETE_BUTTON")}
          </Button>
        );
      }
    };

    const buttonUpdateCompetenceTranslation = (rowData) => {
      return (
        <Button className="me-2" size="sm" outline color="primary" onClick={() => openUpdateCompetenceTranslationModal(rowData.id)}>
          {t("COMPETENCE.OPEN_BUTTON")}
        </Button>
      );
    };

    const formatIsPublicCheckBox = (rowData) => {
      return (
        <i
          className={classNames("pi", {
            "true-icon pi-check-circle": rowData.isPublic,
            "false-icon pi-times-circle": !rowData.isPublic,
          })}
        ></i>
      );
    };
    const formatIsActiveCheckBox = (rowData) => {
      return (
        <i
          className={classNames("pi", {
            "true-icon pi-check-circle": rowData.isActive,
            "false-icon pi-times-circle": !rowData.isActive,
          })}
        ></i>
      );
    };

    const languageTableFilter = () => {
      return (
        <div>
          <UncontrolledDropdown>
            <DropdownToggle className="p-0 me-2" color="black">
              <div className="icon-wrapper-bg bg-focus" />
              <div className="language-icon">
                <Flag className="me-3 opacity-8" country={this.state.selectedFilteringTableLanguage?.flag} size="30" />
              </div>
            </DropdownToggle>
            <DropdownMenu end className="rm-pointers">
              {languages.map((language, id) => (
                <DropdownItem key={id} value={language} onClick={() => this.onChangeFilteringTableLanguage(language)}>
                  <Flag className="me-3 opacity-8" country={language.flag} />
                  {language.name}
                </DropdownItem>
              ))}
            </DropdownMenu>
          </UncontrolledDropdown>
        </div>
      );
    };

    const languageBody = (option) => {
      return (
        <Flag
          className="me-3 opacity-8"
          style={{
            marginLeft: "0.75rem",
          }}
          country={languages.find((obj) => obj.lang === option.language)?.flag}
          size="30"
        />
      );
    };

    const verifiedRowFilterTemplate = (options) => {
      return <TriStateCheckbox value={options.value} onChange={(e) => options.filterApplyCallback(e.value)} />;
    };

    const textEditor = (options) => {
      return <InputText type="text" value={options.value} onChange={(e) => options.editorCallback(e.target.value)} />;
    };

    const onRowEditComplete = (e) => {
      let updateCompetences = this.state.competenceTranslations;
      let { newData, index } = e;
      newData.language = this.state.selectedEditingCompetenceTranslationLanguage.lang ? this.state.selectedEditingCompetenceTranslationLanguage.lang : e.data.language;
      updateCompetences[index] = newData;
      this.setState({
        competenceTranslations: updateCompetences,
      });
      this.filterCompetenceDropdownLanguages();
    };

    const renderActiveDeactivateButton = () => {
      if (this.state.selectedCompetence.id) {
        if (this.state.selectedCompetence.isActive) {
          return (
            <Button className="mb-2 me-2 " outline color="danger" onClick={this.openDeactivateModel}>
              {t("COMPETENCE.DEACTIVATE_BUTTON")}
            </Button>
          );
        } else {
          return (
            <Button className="mb-2 me-2 " outline color="success" onClick={this.changeActiveModalState}>
              {t("COMPETENCE.ACTIVATE_BUTTON")}
            </Button>
          );
        }
      }
    };

    return (
      <Fragment>
        <TransitionGroup>
          <CSSTransition component="div" classNames="TabsAnimation" appear={true} timeout={1500} enter={false} exit={false}>
            <div>
              <PageTitle heading={t("COMPETENCE.TITLE")} subheading={t("COMPANY.DESCRIPTION")} icon="pe-7s-medal icon-gradient bg-tempting-azure" />
              <Row>
                <Col md="12">
                  <Button className="mb-2 me-2  " outline color="primary" onClick={() => openCreateCompetence()}>
                    {t("COMPETENCE.CREATE_BUTTON")}
                  </Button>
                  <Modal isOpen={this.state.modal} fade={false} toggle={this.changeModalState}>
                    <ModalHeader toggle={this.changeModalState}> {this.state.isUpdate ? t("COMPETENCE.EDIT_MODAL_TITLE") : t("COMPETENCE.CREATE_MODAL_TITLE")}</ModalHeader>
                    <ModalBody>
                      <div className="card p-fluid">
                        <Row style={{ fontWeight: "bold", textAlign: "center", padding: "10px" }}>
                          <Col md="4">
                            <Label for="createdByUser">
                              {t("COMPETENCE.CREATED_BY")} {this.state.selectedCompetence.createdByUser}
                            </Label>
                          </Col>
                          <Col md="4">
                            <Label for="createdDate">
                              {t("COMPETENCE.CREATED_DATE")} {moment(this.state.selectedCompetence.createdDate).format("DD.MM.YYYY")}
                            </Label>
                          </Col>
                          <Col md="4">
                            {this.isM2LAdmin() && (
                              <FormGroup>
                                <Label>
                                  <Input
                                    type="checkbox"
                                    checked={this.state.selectedCompetence.isPublic}
                                    disabled={!this.state.selectedCompetence.isActive || this.state.loggedUser.currentRole !== "SystemAdmin"}
                                    onChange={this.changePublic}
                                  />
                                  {t("COMPETENCE.IS_PUBLIC")}
                                </Label>
                              </FormGroup>
                            )}
                          </Col>
                        </Row>
                        <Row>
                          <Col md="2" style={{ fontWeight: "bold", textAlign: "center", padding: "10px" }}>
                            <Label for="exampleSelect">{t("COMPETENCE.COMPETENCEFRAME")}*:</Label>
                          </Col>
                          <Col md="4">
                            <FormGroup>
                              <Select
                                options={this.state.filteredCompetenceFrames}
                                isOptionDisabled={(option) => !option.isActive}
                                isDisabled={!this.isEditable()}
                                value={this.state.filteredCompetenceFrames.find((l) => this.state.selectedCompetence?.competenceFrameId === l.id) || ""}
                                onChange={this.onChangeCompetenceFrame}
                                getOptionLabel={(option) => option.name}
                                getOptionValue={(option) => option.id}
                                className={this.state.error.competenceFrame?.length > 0 ? "is-invalid form-control" : ""}
                              />
                              {this.state.error.competenceFrame?.length > 0 && <span className="invalid-feedback">{this.state.error?.competenceFrame}</span>}
                            </FormGroup>
                          </Col>
                        </Row>
                      </div>
                      {this.isEditable() && (
                        <div className="card p-fluid">
                          <Form onSubmit={this.createNewCompetenceTranslation} style={{ margin: "10px" }}>
                            <Row>
                              <Col>
                                <FormGroup>
                                  <Label for="exampleSelect">{t("COMPETENCE.LANGUAGE")}</Label>
                                  <Select
                                    options={this.state.creatingCompetenceTranslationLanguages}
                                    value={this.state.selectedCreatingCompetenceTranslationLanguage ? this.state.selectedCreatingCompetenceTranslationLanguage : " "}
                                    onChange={this.onChangeCreatingCompetencesTranslationLanguage}
                                    getOptionLabel={(option) => option.name}
                                    getOptionValue={(option) => option.lang}
                                  />
                                </FormGroup>
                              </Col>
                              <Col>
                                <FormGroup>
                                  <Label for="name"> {t("COMPETENCE.NAME")}</Label>
                                  <Input
                                    type="text"
                                    name="name"
                                    id="name"
                                    onChange={this.changeCreatingCompetenceTranslationName}
                                    value={this.state.creatingCompetenceTranslationName}
                                    className={error.name ? "is-invalid form-control" : "form-control"}
                                  />
                                  {error.name && <span className="invalid-feedback">{error.name}</span>}
                                </FormGroup>
                              </Col>
                              <Col>
                                <FormGroup>
                                  <Label for="comment">{t("COMPETENCE.COMMENT")}</Label>
                                  <Input
                                    type="text"
                                    name="comment"
                                    id="comment"
                                    onChange={this.changeCreatingCompetenceTranslationComment}
                                    value={this.state.creatingCompetenceTranslationComment}
                                    className="form-control"
                                  />
                                </FormGroup>
                              </Col>

                              <Col>
                                <Button className="mb-2 me-2  " outline color="primary" style={{ marginTop: "30px" }} type="submit">
                                  {t("COMPETENCE.CREATE_BUTTON")}
                                </Button>
                              </Col>
                            </Row>
                          </Form>
                        </div>
                      )}

                      <div className="card p-fluid">
                        <DataTable
                          editMode="row"
                          value={this.state.competenceTranslations}
                          dataKey="id"
                          className="p-datatable-customers"
                          onRowEditComplete={onRowEditComplete}
                          // responsiveLayout="scroll"
                        >
                          <Column
                            field="language"
                            header={t("COMPETENCE.LANGUAGE_COLUMN_NAME")}
                            body={countryBodyTemplate}
                            editor={(options) => languageEditorTemplate(options)}
                            style={{ width: "20%", zIndex: "2" }}
                          ></Column>
                          <Column field="name" header={t("COMPETENCE.NAME_COLUMN_NAME")} editor={(options) => textEditor(options)} style={{ width: "20%" }}></Column>
                          <Column field="comment" header={t("COMPETENCE.COMMENT_COLUMN_NAME")} editor={(options) => textEditor(options)} style={{ width: "20%" }}></Column>
                          <Column rowEditor={this.isEditable()} headerStyle={{ width: "10%", minWidth: "8rem" }} bodyStyle={{ textAlign: "center" }}></Column>
                          <Column style={{ minWidth: "6rem" }} body={buttonDeleteCompetenceTranslation} />
                        </DataTable>
                      </div>
                    </ModalBody>
                    <ModalFooter>
                      <Modal isOpen={this.state.activeModal} toggle={this.changeActiveModalState} style={{ marginTop: "50px", width: "60%" }}>
                        <ModalHeader>
                          {this.state.selectedCompetence.isActive ? t("COMPETENCE.CONFIRM_DEACTIVATE_MODAL_TITLE") : t("COMPETENCE.CONFIRM_ACTIVATE_MODAL_TITLE")}
                        </ModalHeader>
                        <ModalBody style={{ minHeight: "auto" }}>
                          {this.state.selectedCompetence.isActive && this.state.learningObjects.length > 0 ? (
                            <div>
                              <PerfectScrollbar>
                                {this.state.learningObjects.length > 0 ? (
                                  <Card className="main-card mb-3">
                                    <CardTitle>{t("COMPETENCE.LEARNING_OBJECTS")}</CardTitle>
                                    <DataTable value={this.state.learningObjects} paginator rows={10}>
                                      <Column field="name" header={t("COMPETENCE.LEARNING_OBJECT_NAME_COLUMN_NAME")}></Column>
                                      <Column field="createdByUser" header={t("COMPETENCE.LEARNING_OBJECT_CREATED_BY_COLUMN_NAME")}></Column>
                                    </DataTable>
                                  </Card>
                                ) : (
                                  ""
                                )}
                              </PerfectScrollbar>
                            </div>
                          ) : (
                            ""
                          )}
                        </ModalBody>
                        <ModalFooter>
                          <Col>
                            {this.state.selectedCompetence.isActive && this.state.learningObjects.length > 0 ? (
                              <CSVLink
                                data={this.state.learningObjects}
                                filename={
                                  "deactivate_competence_" +
                                  this.state.competenceTranslations.find((a) => a.language === this.state.selectedFilteringTableLanguage.lang)?.name +
                                  ".csv"
                                }
                                headers={this.state.csvHeaders}
                                className="mb-2 me-2 btn btn-success"
                                target="_blank"
                              >
                                {t("COMPETENCE.EXPORT_BUTTON")}
                              </CSVLink>
                            ) : (
                              ""
                            )}
                          </Col>
                          <Button className="mb-2 me-2 " color="secondary" onClick={this.changeActiveModalState}>
                            {t("COMPETENCE.NO_BUTTON")}
                          </Button>
                          <Button
                            className="mb-2 me-2 "
                            color="primary"
                            onClick={this.state.selectedCompetence.isActive ? this.confirmDeactivateCompetence : this.confirmActivateCompetence}
                          >
                            {t("COMPETENCE.YES_BUTTON")}
                          </Button>
                        </ModalFooter>
                      </Modal>

                      <Modal isOpen={this.state.deleteModal} toggle={this.changeDeleteModalState} style={{ marginTop: "50px", width: "60%" }}>
                        <ModalHeader> {this.state.learningObjects.length > 0 ? t("COMPETENCE.DELETE_MODAL_TITLE") : t("COMPETENCE.CONFIRM_DELETE_MODAL_TITLE")}</ModalHeader>
                        <ModalBody style={{ minHeight: "auto" }}>
                          {this.state.learningObjects.length > 0 ? (
                            <div>
                              <PerfectScrollbar>
                                {this.state.learningObjects.length > 0 ? (
                                  <Card className="main-card mb-3">
                                    <CardTitle>{t("COMPETENCE.LEARNING_OBJECTS")}</CardTitle>
                                    <DataTable value={this.state.learningObjects} paginator rows={10}>
                                      <Column field="name" header={t("COMPETENCE.LEARNING_OBJECT_NAME_COLUMN_NAME")}></Column>
                                      <Column field="createdByUser" header={t("COMPETENCE.LEARNING_OBJECT_CREATED_BY_COLUMN_NAME")}></Column>
                                    </DataTable>
                                  </Card>
                                ) : (
                                  ""
                                )}
                              </PerfectScrollbar>
                            </div>
                          ) : (
                            ""
                          )}
                        </ModalBody>
                        <ModalFooter>
                          <Col>
                            {this.state.learningObjects.length > 0 ? (
                              <CSVLink
                                data={this.state.learningObjects}
                                filename={
                                  "delete_competence_" + this.state.competenceTranslations.find((a) => a.language === this.state.selectedFilteringTableLanguage.lang)?.name + ".csv"
                                }
                                headers={this.state.csvHeaders}
                                className="mb-2 me-2 btn btn-success"
                                target="_blank"
                              >
                                {t("COMPETENCE.EXPORT_BUTTON")}
                              </CSVLink>
                            ) : (
                              ""
                            )}
                          </Col>
                          {this.state.learningObjects.length > 0 ? (
                            <div>
                              <Button className="mb-2 me-2 " color="secondary" onClick={this.changeDeleteModalState}>
                                {t("COMPETENCE.CANCEL_BUTTON")}
                              </Button>
                            </div>
                          ) : (
                            <div>
                              <Button className="mb-2 me-2 " color="secondary" onClick={this.changeDeleteModalState}>
                                {t("COMPETENCE.NO_BUTTON")}
                              </Button>
                              <Button className="mb-2 me-2 " color="primary" onClick={this.confirmDeleteCompetence}>
                                {t("COMPETENCE.YES_BUTTON")}
                              </Button>
                            </div>
                          )}
                        </ModalFooter>
                      </Modal>

                      {this.isEditable() ? (
                        <>
                          <Col>
                            {this.state.selectedCompetence.id ? (
                              <Button disabled={this.state.selectedCompetence.isActive} className="mb-2 me-2" color="danger" onClick={this.openDeleteModel}>
                                {t("COMPETENCE.DELETE_BUTTON")}
                              </Button>
                            ) : (
                              ""
                            )}
                            {renderActiveDeactivateButton()}
                          </Col>

                          <Button className="mb-2 me-2   " color="secondary" onClick={this.changeModalState}>
                            {t("COMPETENCE.CANCEL_BUTTON")}
                          </Button>
                          {this.state.isUpdate ? (
                            <Button className="mb-2 me-2  " color="primary" onClick={(e) => this.updateCompetence(e)}>
                              {t("COMPETENCE.UPDATE_BUTTON")}
                            </Button>
                          ) : (
                            <Button className="mb-2 me-2  " color="primary" onClick={(e) => this.createNewCompetence(e)}>
                              {t("COMPETENCE.SAVE_BUTTON")}
                            </Button>
                          )}
                        </>
                      ) : (
                        <Button className="mb-2 me-2" color="secondary" onClick={this.changeModalState}>
                          {t("COMPETENCE.CLOSE_BUTTON")}
                        </Button>
                      )}
                    </ModalFooter>
                  </Modal>
                </Col>
                <Col md="12">
                  <Card className="main-card mb-3">
                    <CardBody>
                      <div className="card p-fluid">
                        <DataTable
                          value={this.state.filteredCompetences}
                          paginator
                          className="p-datatable-customers"
                          rows={10}
                          dataKey="id"
                          filterDisplay="row"
                          responsiveLayout="scroll"
                          filters={this.state.filterCompetencesTemplate}
                          emptyMessage={t("COMPETENCE.TABLE_MESSAGE")}
                        >
                          <Column
                            header={t("COMPETENCE.LANGUAGE_COLUMN_NAME")}
                            field="language"
                            showFilterMenu={false}
                            style={{ minWidth: "10rem" }}
                            body={languageBody}
                            filter
                            filterElement={languageTableFilter}
                          />
                          <Column header={t("COMPETENCE.NAME_COLUMN_NAME")} field="name" filter sortable />
                          <Column header={t("COMPETENCE.COMMENT_COLUMN_NAME")} field="comment" filter sortable />
                          <Column
                            field="isPublic"
                            header={t("COMPETENCE.IS_PUBLIC_COLUMN_NAME")}
                            dataType="boolean"
                            sortable
                            body={formatIsPublicCheckBox}
                            filter
                            filterElement={verifiedRowFilterTemplate}
                          />
                          <Column
                            field="isActive"
                            header={t("COMPETENCE.IS_ACTIVE_COLUMN_NAME")}
                            dataType="boolean"
                            sortable
                            body={formatIsActiveCheckBox}
                            filter
                            filterElement={verifiedRowFilterTemplate}
                          />
                          <Column field="createdByUser" header={t("COMPETENCE.CREATED_BY_COLUMN_NAME")} filter sortable />
                          <Column header={t("COMPETENCE.CREATED_DATE_COLUMN_NAME")} filterField="createdDate" dataType="date" body={this.dateCompetencesTemplate} />
                          <Column body={buttonUpdateCompetenceTranslation} rowEditor />
                        </DataTable>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </div>
          </CSSTransition>
        </TransitionGroup>
      </Fragment>
    );
  }
}
export default withTranslation()(Competence);
