import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from "reactstrap";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { TriStateCheckbox } from "primereact/tristatecheckbox";

class LearningObjectAttributeModel extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.learningObjectAttributes = [];
  }

  componentDidMount() {
    this.setState({ learningObjectAttributes: this.props.learningObjectAttributes });
  }

  toggleIsSelected = (rowData) => {
    var learningObjectAttributes = this.state.learningObjectAttributes;
    let currentAttribute = learningObjectAttributes.find((a) => JSON.stringify(a) === JSON.stringify(rowData));
    if (this.props.material.id) {
      if (currentAttribute.materialIds.find((id) => id === this.props.material.id)) {
        currentAttribute.materialIds = currentAttribute.materialIds.filter((id) => id !== this.props.material.id);
      } else {
        currentAttribute.materialIds.push(this.props.material.id);
      }
    } else {
      if (currentAttribute.materialIds.find((id) => id === this.props.material.fileName)) {
        currentAttribute.materialIds = currentAttribute.materialIds.filter((id) => id !== this.props.material.fileName);
      } else {
        currentAttribute.materialIds.push(this.props.material.fileName);
      }
    }
    this.setState({ learningObjectAttributes: this.props.learningObjectAttributes });
  };

  isEditable = () => {
    return !this.props.learningObject.isPublic || this.isM2LAdmin();
  };

  isM2LAdmin = () => {
    return this.state.loggedUser?.currentRole === "SystemAdmin" && this.state.loggedUser?.currentCompanyId === null;
  };

  render() {
    const { t, onCloseAttributeModel, language, material } = this.props;
    const { learningObjectAttributes } = this.state;

    const formatIsSelectedCheckBox = (attribute) => {
      var isChecked = null;
      var value = attribute.materialIds.find((id) => id === material.id || id === material.fileName);
      if (value) {
        isChecked = true;
      }
      return <TriStateCheckbox value={isChecked} onChange={() => this.toggleIsSelected(attribute)} />;
    };

    return (
      <Modal isOpen={true} fade={false} toggle={() => onCloseAttributeModel(learningObjectAttributes)} style={{ marginTop: "5rem", width: "50%" }}>
        <ModalHeader toggle={() => onCloseAttributeModel(learningObjectAttributes)}>{t("LEARNING_OBJECT.ATTRIBUTES")}</ModalHeader>
        <ModalBody style={{ minHeight: "auto" }}>
          <div className="card p-fluid">
            <DataTable
              value={learningObjectAttributes?.filter((a) => a.language === language)}
              paginator
              className="p-datatable-customers"
              rows={4}
              dataKey="id"
              filterDisplay="row"
              responsiveLayout="scroll"
              emptyMessage={t("LEARNING_OBJECT.ATTRIBUTE_TABLE_MESSAGE")}
            >
              <Column field="isSelected" dataType="boolean" body={formatIsSelectedCheckBox} />
              <Column header={t("LEARNING_OBJECT.ATTRIBUTE_TABLE_NAME")} field="attributeName" filter sortable />
              <Column header={t("LEARNING_OBJECT.ATTRIBUTE_TABLE_VALUE")} field="value" filter sortable />
              <Column header={t("LEARNING_OBJECT.ATTRIBUTE_TABLE_COMMENT")} field="comment" filter sortable />
              <Column header={t("LEARNING_OBJECT.ATTRIBUTE_TABLE_DURATION")} field="duration" filter sortable />
            </DataTable>
          </div>
        </ModalBody>
        <ModalFooter>
          <Button className="mb-2 me-2" color="primary" onClick={() => onCloseAttributeModel(learningObjectAttributes)}>
            {t("LEARNING_OBJECT.SAVE_ATTRIBUTE_MODEL")}
          </Button>
        </ModalFooter>
      </Modal>
    );
  }
}
export default withTranslation()(LearningObjectAttributeModel);
