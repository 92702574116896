import api from "./api";

class TopicService {
  getAllTopics() {
    return api.get("/Topic/get-all");
  }

  getActiveTopicByCategoryId(categoryId) {
    return api.get("/Topic/get-active-by-category-id?categoryId=" + categoryId);
  }

  getTopicByCategoryId(categoryId) {
    return api.get("/Topic/get-by-category-id?categoryId=" + categoryId);
  }

  getTopicById(id) {
    return api.get("/Topic/get-by-id?id=" + id);
  }

  insert(newTopic) {
    return api.post("/Topic/insert", newTopic);
  }

  update(updatedTopic) {
    return api.post("/Topic/update", updatedTopic);
  }

  deactivate(id) {
    return api.post("/Topic/deactivate?id=" + id);
  }

  activate(id) {
    return api.post("/Topic/activate?id=" + id);
  }

  delete(id) {
    return api.post("/Topic/delete?Id=" + id);
  }
}

export default new TopicService();
