import React, { Fragment, Component } from "react";
import Slider from "react-slick";
import bg1 from "../../../assets/utils/images/originals/city.jpg";
import bg2 from "../../../assets/utils/images/originals/citydark.jpg";
import bg3 from "../../../assets/utils/images/originals/citynights.jpg";
import { Col, Row, Button, Form, FormGroup, Label, Input } from "reactstrap";
import { Link } from "react-router-dom";
import { withTranslation } from "react-i18next";
import HeaderLogin from "../../../Layout/AppHeader/Components/HeaderLogin";
import AuthService from "../../../service/authService";
import tokenService from "../../../service/tokenService";
import CompanyService from "../../../service/CompanyService";
import LearningPathService from "../../../service/LearningPathService";
import authService from "../../../service/authService";

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      login: "",
      password: "",
      error: false,
    };
  }

  onChangeLogin = (e) => {
    this.setState({
      login: e.target.value,
      error: false,
    });
  };
  onChangePassword = (e) => {
    this.setState({
      password: e.target.value,
      error: false,
    });
  };

  getMyInfo() {
    return authService
      .getMyInfo()
      .then((response) => {
        this.setState({
          user: response.data,
        });
        tokenService.setUser(response.data);
      })
      .catch((e) => {
        console.log(e);
      });
  }

  getCompanyById(id) {
    return CompanyService.getCompanyById(id)
      .then((response) => {
        this.setState({
          company: response.data,
        });
        tokenService.setCompany(response.data);
      })
      .catch((e) => {
        console.log(e);
      });
  }

  getFavorites() {
    return LearningPathService.getFavorite().then((response) => {
      tokenService.setTrainings(response.data);
    });
  }
  onSubmit = (e) => {
    e.preventDefault();
    AuthService.login(this.state.login, this.state.password)
      .then((response) => {
        if (response) {
          AuthService.getMyInfo().then((infoResponce) => {
            tokenService.setUser(infoResponce.data);
            this.props.i18n.changeLanguage(infoResponce.data.language);
            let promises = [];
            let getCompanyPromise = this.getCompanyById(infoResponce.data.currentCompanyId);
            promises.push(getCompanyPromise);
            let getFavoritesPromise = this.getFavorites();
            promises.push(getFavoritesPromise);

            Promise.all(promises).then(() => {
              this.props.history.push("/");
            });
          });
        }
      })
      .catch((e) => {
        this.setState({
          error: true,
        });
        console.log(e);
      });
  };

  render() {
    const { t } = this.props;
    let settings = {
      dots: true,
      infinite: true,
      speed: 500,
      arrows: true,
      slidesToShow: 1,
      slidesToScroll: 1,
      fade: true,
      initialSlide: 0,
      autoplay: true,
      adaptiveHeight: true,
    };
    return (
      <Fragment>
        <div className="h-100">
          <Row className="h-100 g-0">
            <Col lg="4" className="d-none d-lg-block">
              <div className="slider-light">
                <Slider {...settings}>
                  <div className="h-100 d-flex justify-content-center align-items-center bg-plum-plate">
                    <div
                      className="slide-img-bg"
                      style={{
                        backgroundImage: "url(" + bg1 + ")",
                      }}
                    />
                    <div className="slider-content">
                      <h3>Perfect Balance</h3>
                      <p>ArchitectUI is like a dream. Some think it's too good to be true! Extensive collection of unified React Boostrap Components and Elements.</p>
                    </div>
                  </div>
                  <div className="h-100 d-flex justify-content-center align-items-center bg-premium-dark">
                    <div
                      className="slide-img-bg"
                      style={{
                        backgroundImage: "url(" + bg3 + ")",
                      }}
                    />
                    <div className="slider-content">
                      <h3>Scalable, Modular, Consistent</h3>
                      <p>Easily exclude the components you don't require. Lightweight, consistent Bootstrap based styles across all elements and components</p>
                    </div>
                  </div>
                  <div className="h-100 d-flex justify-content-center align-items-center bg-sunny-morning">
                    <div
                      className="slide-img-bg opacity-6"
                      style={{
                        backgroundImage: "url(" + bg2 + ")",
                      }}
                    />
                    <div className="slider-content">
                      <h3>Complex, but lightweight</h3>
                      <p>We've included a lot of components that cover almost all use cases for any type of application.</p>
                    </div>
                  </div>
                </Slider>
              </div>
            </Col>
            <Col lg="7" md="12" className="h-100 d-flex bg-white justify-content-space-beetwen align-items-center">
              <Col lg="8" md="10" sm="12" className="mx-auto app-login-box">
                <h4 style={{ marginTop: "8px" }}>Matrix2Learn</h4>

                <h4 className="mb-0">
                  <div>Welcome back,</div>
                  <span>Please sign in to your account.</span>
                </h4>
                <h6 className="mt-3">
                  No account?
                  <a href="https://colorlib.com/" onClick={(e) => e.preventDefault()} className="text-primary">
                    Sign up now
                  </a>
                </h6>
                <Row className="divider" />
                <div>
                  <Form onSubmit={this.onSubmit}>
                    <Row>
                      <Col md={6}>
                        <FormGroup>
                          <Label for="exampleEmail">Email</Label>
                          <Input type="login" name="login" id="exampleEmail" placeholder="Email here..." value={this.state.login} onChange={this.onChangeLogin} />
                        </FormGroup>
                      </Col>
                      <Col md={6}>
                        <FormGroup>
                          <Label for="examplePassword">Password</Label>
                          <Input type="password" name="password" id="examplePassword" placeholder="Password here..." value={this.state.password} onChange={this.onChangePassword} />
                        </FormGroup>
                      </Col>
                      {this.state.error ? (
                        <Col style={{ height: "20px" }}>
                          <span style={{ color: "red" }}>Email or password is invalide!</span>
                        </Col>
                      ) : (
                        <Col style={{ height: "20px" }}></Col>
                      )}
                    </Row>

                    <Row className="divider" />
                    <div className="d-flex align-items-center">
                      <div className="ms-auto">
                        <Link to="/auth/forgot-password"> Recover Password</Link>
                        <Button color="primary" type="submit" size="lg">
                          Login
                        </Button>
                      </div>
                    </div>
                  </Form>
                </div>
              </Col>
            </Col>

            <Col lg="1" md="12" className="h-100 bg-white">
              <div style={{ display: "flex", justifyContent: "flex-end", alignItems: "flex-start", margin: "20px" }}>
                <HeaderLogin />
              </div>
            </Col>
          </Row>
        </div>
      </Fragment>
    );
  }
}
export default withTranslation()(Login);
