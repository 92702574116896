import api from "./api";

class LearningObjectMaterialService {
  getAll() {
    return api.get("/LearningObjectMaterial/get-all");
  }

  getById(id) {
    return api.get("LearningObjectMaterial/get-by-id?id=" + id);
  }

  getByLearninObjectId(learningObjectId) {
    return api.get("LearningObjectMaterial/get-by-learning-object-id?learningObjectId=" + learningObjectId);
  }

  getByLearningPathItemId(learningPathItemId) {
    return api.get("LearningObjectMaterial/get-by-learning-path-item-id?learningPathItemId=" + learningPathItemId);
  }

  getLearningObjectMaterialById(id) {
    return api.get("/LearningObjectMaterial/get-by-id?id=" + id);
  }
  getActiveByAttributeId(id) {
    return api.get("/LearningObjectMaterial/get-active-by-attribute-id?attributeId=" + id);
  }

  getByAttributeById(id) {
    return api.get("/LearningObjectMaterial/get-by-attribute-id?attributeId=" + id);
  }

  insert(newLearningObjectMaterial) {
    return api.post("/LearningObjectMaterial/insert", newLearningObjectMaterial);
  }

  update(updateLearningObjectMaterial) {
    return api.post("/LearningObjectMaterial/update", updateLearningObjectMaterial);
  }

  download(id) {
    return api.get("/LearningObjectMaterial/download?id=" + id, {
      responseType: "blob",
    });
  }

  delete(id) {
    return api.post("/LearningObjectMaterial/delete?id=" + id);
  }
}

export default new LearningObjectMaterialService();
