import api from "./api";

class CompetenceFrameService {
  getAll() {
    return api.get("/CompetenceFrame/get-all");
  }

  getById(id) {
    return api.get("/CompetenceFrame/get-by-id?id=" + id);
  }

  insert(model) {
    return api.post("/CompetenceFrame/insert", model);
  }

  update(model) {
    return api.post("/CompetenceFrame/update", model);
  }

  deactivate(id) {
    return api.post("/CompetenceFrame/deactivate?id=" + id);
  }

  activate(id) {
    return api.post("/CompetenceFrame/activate?id=" + id);
  }

  delete(id) {
    return api.post("/CompetenceFrame/delete?Id=" + id);
  }
}

export default new CompetenceFrameService();
