import api from "./api";

class ConvertService {
  textToSpeech(text) {
    return api.post("/Convert/text-to-speech", text, {
      responseType: "blob",
    });
  }
  
  translate(text, fromLanguage, toLanguage) {
    return api.post("/Convert/translate", {text: text, fromLanguage: fromLanguage,toLanguage: toLanguage});
  }
}

export default new ConvertService();
