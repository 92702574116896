import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { Col, Row, Button, Modal, TabPane, ModalHeader, ModalBody, TabContent, ModalFooter, Input, FormGroup, Nav, NavItem, NavLink, Card, CardTitle, Label } from "reactstrap";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import moment from "moment/moment";
import Select from "react-select";
import { TriStateCheckbox } from "primereact/tristatecheckbox";
import PerfectScrollbar from "react-perfect-scrollbar";
import LearningObjectService from "../../../service/LearningObjectService";
import tokenService from "../../../service/tokenService";
import LearningObjectModel from "../../../models/LearningObjectModel";
import TopicService from "../../../service/TopicService";
import AvatarPicker from "../../Shared/AvatarPicker";
import LearningObjectAttributeService from "../../../service/LearningObjectAttributeService";
import LearningObjectMaterialService from "../../../service/LearningObjectMaterialService";
import LearningPathService from "../../../service/LearningPathService";
import CategoryService from "../../../service/CategoryService";
import LanguageSelect from "../../Shared/LanguageSelect";
import LearningObjectAttribute from "./LearningObjectAttribute";
import LearningObjectMaterial from "./LearningObjectMaterial";
import { CSVLink } from "react-csv";
import classnames from "classnames";
import { classNames } from "primereact/utils";
import { FilterMatchMode } from "primereact/api";
import RecommendationService from "../../../service/RecommendationService";
import LearningObjectAttributeModel from "./LearningObjectAttributeModel";
import "../LearningPath/style.css";
import "../../Metadata/Attribute/DataTable.css";
import { Loader } from "react-loaders";
import LoadingOverlay from "react-loading-overlay-ts";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import CompetenceService from "../../../service/CompetenceService";
import LearningPathTypeService from "../../../service/LearningPathTypeService";
import AttributeService from "../../../service/AttributeService";
import ConvertService from "../../../service/ConvertService";

class MainModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      learningObject: new LearningObjectModel(),
      selectedTranslation: { language: this.props.defaultLang },
      selectedMaterial: {},
      learningObjectAttributes: [],
      attributes: [],
      materials: [],
      needDeleteMeterials: [],
      topics: [],
      categories: [],
      recommendations: [],
      competences: [],
      loggedUser: tokenService.getUser(),
      activeModal: false,
      deleteModal: false,
      attributeModal: false,
      error: {},
      descriptionTab: "propertyTab",
      activeTab: "imageTab",
      saving: false,

      csvHeaders: [
        { label: "Name", key: "name" },
        { label: "Version", key: "version" },
        { label: "Created By", key: "createdByUser" },
      ],

      learningPaths: [],
      bindLearningPaths: [],
      learningPathTypes: [],
      filterLearningPathsTemplate: {
        name: { value: null, matchMode: FilterMatchMode.CONTAINS },
        comment: { value: null, matchMode: FilterMatchMode.CONTAINS },
        createdByUser: { value: null, matchMode: FilterMatchMode.CONTAINS },
        version: { value: null, matchMode: FilterMatchMode.CONTAINS },
        parentName: { value: null, matchMode: FilterMatchMode.CONTAINS },
        createdDate: { value: null, matchMode: FilterMatchMode.DATE_IS },
        isPublic: { value: null, matchMode: FilterMatchMode.EQUALS },
        isActive: { value: null, matchMode: FilterMatchMode.EQUALS },
        isTraining: { value: null, matchMode: FilterMatchMode.EQUALS },
      },
    };
  }

  componentDidMount() {
    this.getActiveAttributes();
    if (this.props.learningObjectId) {
      this.getLearningObjectById(this.props.learningObjectId).then(() => {
        this.getActiveTopics();
        this.getActiveRecommendations();
        this.getActiveCompetences();
        if (this.state.learningObject.topicId) {
          this.getAllCategories(this.state.learningObject.topicId);
        }
        this.getAttributesByLearningObjectId(this.props.learningObjectId);
        this.getAllLearningPathTypes().then(() => {
          this.getLeariningPathsByLearningObjectId();
        });
      });
    } else {
      this.getActiveTopics();
      this.getActiveRecommendations();
      this.getActiveCompetences();
      console.log( this.props.defaultLang)
      let selectedTranslation = {
        language: this.props.defaultLang,
        name: "",
        comment: "",
        levelInfo: null,
        longDescription: "",
        shortDescription: "",
        shortText: "",
      };
      let learningObject = {
        createdBy: this.state.loggedUser.id,
        createdByUser: this.state.loggedUser.firstName + " " + this.state.loggedUser.lastName,
        createdDate: new Date(),
        isActive: true,
        masterLanguage: this.props.defaultLang,
        isPublic: this.isM2LAdmin(),
        translations: [selectedTranslation],
      };

      this.setState({ selectedTranslation: selectedTranslation, learningObject: learningObject });
    }
  }

  handleTabChange(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab,
      });
    }
  }

  handleDescriptionTabChange(tab) {
    if (this.state.descriptionTab !== tab) {
      this.setState({
        descriptionTab: tab,
      });
    }
  }

  getActiveAttributes = () => {
    return AttributeService.getAllAttributes().then((response) => {
      let attributes = response.data;
      this.setState({ attributes: attributes });
    });
  };

  getAllLearningPathTypes() {
    return LearningPathTypeService.getAllLearningPathTypes()
      .then((response) => {
        this.setState({
          learningPathTypes: response.data,
        });
      })
      .catch((e) => {
        console.log(e);
      });
  }

  getLeariningPathsByLearningObjectId = () => {
    LearningPathService.getByLearningObjectId(this.props.learningObjectId)
      .then((response) => {
        this.setState({
          learningPaths: response.data,
        });
        this.bindLearningPaths(this.state.selectedTranslation?.language);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  bindLearningPaths(lang) {
    var bindLearningPaths = this.state.learningPaths.map(
      ({
        id,
        name,
        comment,
        language,
        description,
        duration,
        learningPathTypeId,
        createdDate,
        isPublic,
        isActive,
        createdByUser,
        version,
        requirements,
        targetGroup,
        parentName,
      }) => ({
        name: name,
        description: description,
        language: language,
        duration: duration,
        comment: comment,
        createdByUser: createdByUser,
        isPublic: isPublic,
        isActive: isActive,
        isTraining: this.state.learningPathTypes.find((t) => t.id === learningPathTypeId)?.name === "Training",
        id: id,
        version: this.state.learningPathTypes.find((t) => t.id === learningPathTypeId)?.name === "Training" ? version : "",
        requirements: requirements,
        targetGroup: targetGroup,
        parentName: parentName,
        createdDate: moment(new Date(createdDate)).format("DD.MM.YYYY"),
      })
    );

    if (lang) {
      bindLearningPaths = bindLearningPaths.filter((lp) => lp.language === lang);
    }
    this.setState({
      bindLearningPaths: bindLearningPaths,
    });
  }

  isEditable = () => {
    return !this.props.readOnly && (!this.state.learningObject.isPublic || this.isM2LAdmin());
  };

  isM2LAdmin = () => {
    return this.state.loggedUser?.currentRole === "SystemAdmin" && this.state.loggedUser?.currentCompanyId === null;
  };

  getActiveTopics() {
    return TopicService.getAllTopics()
      .then((response) => {
        var topics = response.data
          .filter((t) => t.isActive || t.id === this.state.learningObject?.topicId)
          .map(({ translations, id, isActive, isPublic }) => ({
            name: translations.find((obj) => obj.language === this.state.selectedTranslation.language)?.name + (!isActive ? " (inactive) " : " ") + (isPublic ? " (public)" : ""),
            id: id,
            isActive: isActive,
            isPublic: isPublic,
          }))
          .filter((obj) => obj.name && !obj.name.startsWith("undefined"))
          .sort((a, b) => {
            if (a.isPublic !== b.isPublic) {
              return a.isPublic ? 1 : -1;
            } else {
              return a.name.localeCompare(b.name);
            }
          });
        this.setState({
          topics: topics,
        });
      })
      .catch((e) => {
        console.log(e);
      });
  }

  getAttributesByLearningObjectId = (learningObjectId) => {
    return LearningObjectAttributeService.getByLearningObjectId(learningObjectId)
      .then((response) => {
        this.setState({
          learningObjectAttributes: response.data,
        });
      })
      .catch((e) => {
        console.log(e);
      });
  };

  getActiveRecommendations() {
    return RecommendationService.getAllRecommendations()
      .then((response) => {
        var recommendations = response.data
          .filter((r) => r.isActive || (this.state.learningObject.recommendationIds && this.state.learningObject.recommendationIds.find((lr) => lr === r.id)))
          .map(({ translations, id, isActive, isPublic }) => ({
            name: translations.find((obj) => obj.language === this.state.selectedTranslation.language)?.name + (!isActive ? " (inactive) " : " ") + (isPublic ? " (public)" : ""),
            id: id,
            isActive: isActive,
            isPublic: isPublic,
          }))
          .filter((obj) => obj.name && !obj.name.startsWith("undefined"))
          .sort((a, b) => {
            if (a.isPublic !== b.isPublic) {
              return a.isPublic ? 1 : -1;
            } else {
              return a.name.localeCompare(b.name);
            }
          });
        this.setState({
          recommendations: recommendations,
        });
      })
      .catch((e) => {
        console.log(e);
      });
  }

  getActiveCompetences() {
    return CompetenceService.getAll()
      .then((response) => {
        var competences = response.data
          .filter((r) => (r.isActive && r.isActiveFrame) || (this.state.learningObject.competenceIds && this.state.learningObject.competenceIds.find((lr) => lr === r.id)))
          .map(({ translations, id, isActive, isActiveFrame, isPublic }) => ({
            name: translations.find((obj) => obj.language === this.state.selectedTranslation.language)?.name + (!isActive ? " (inactive) " : " ") + (isPublic ? " (public)" : ""),
            competenceFrameName: translations.find((obj) => obj.language === this.state.selectedTranslation.language)?.competenceFrameName,
            id: id,
            isActive: isActive && isActiveFrame,
            isPublic: isPublic,
          }))
          .filter((obj) => obj.name && !obj.name.startsWith("undefined"))
          .sort((a, b) => {
            if (a.isPublic !== b.isPublic) {
              return a.isPublic ? 1 : -1;
            } else {
              return a.name.localeCompare(b.name);
            }
          });
        this.setState({
          competences: competences,
        });
      })
      .catch((e) => {
        console.log(e);
      });
  }

  groupBy(array, key) {
    return array.reduce((result, currentItem) => {
      const groupKey = currentItem[key];

      // If the group key doesn't exist in the result object, create an array for it
      if (!result[groupKey]) {
        result[groupKey] = { label: groupKey, options: [] };
      }

      // Push the current item to the array associated with the group key
      result[groupKey].options.push(currentItem);

      return result;
    }, {});
  }

  getAllCategories(topicId) {
    CategoryService.getByTopicId(topicId)
      .then((response) => {
        var categories = response.data
          .filter((c) => c.isActive || (this.state.learningObject.categoryIds && this.state.learningObject.categoryIds.find((lr) => lr === c.id)))
          .map(({ translations, id, isActive, isPublic }) => ({
            name: translations.find((obj) => obj.language === this.state.selectedTranslation.language)?.name + (!isActive ? " (inactive) " : " ") + (isPublic ? " (public)" : ""),
            id: id,
            isActive: isActive,
            isPublic: isPublic,
          }))
          .filter((obj) => obj.name && !obj.name.startsWith("undefined"))
          .sort((a, b) => {
            if (a.isPublic !== b.isPublic) {
              return a.isPublic ? 1 : -1;
            } else {
              return a.name.localeCompare(b.name);
            }
          });
        this.setState({
          categories: categories,
        });
      })
      .catch((e) => {
        console.log(e);
      });
  }

  getLearningObjectById(id) {
    return LearningObjectService.getById(id)
      .then((response) => {
        var learningObject = response.data;
        var selectedTranslation = learningObject.translations.find((obj) => obj.language === this.state.selectedTranslation.language);
        this.setState({
          learningObject: learningObject,
          selectedTranslation: selectedTranslation,
        });
      })
      .catch((e) => {
        console.log(e);
      });
  }

  updateLearningObject(e) {
    e.preventDefault();
    var error = { ...this.state.error };
    error.name = !this.state.selectedTranslation?.name || this.state.selectedTranslation.name.length < 1 ? "Required" : "";
    error.category = !this.state.learningObject?.categoryIds || this.state.learningObject.categoryIds.length < 1 ? "Required" : "";
    error.topic = !this.state.learningObject?.topicId ? "Required" : "";

    this.setState({ error });

    if (this.checkValid({ isError: error })) {
      e.preventDefault();
      let learningObject = this.state.learningObject;
      learningObject.translations = learningObject.translations.filter((t) => t.language !== this.state.selectedTranslation.language);
      learningObject.translations.push(this.state.selectedTranslation);

      if (!learningObject.topicId) return;

      this.setState({ learningObject: learningObject, saving: true });

      let updatePromise = LearningObjectService.update(this.state.learningObject).catch((e) => console.log(e));
      var promises = this.uploadMaterials(this.state.learningObject.id);

      promises.push(updatePromise);

      Promise.all(promises).then(() => {
        this.batchUpsertAttribute(this.state.learningObject.id).then(() => this.props.changeModalState());
      });
    }
  }

  createLearningObject(e) {
    var error = { ...this.state.error };
    error.name = !this.state.selectedTranslation?.name || this.state.selectedTranslation.name.length < 1 ? "Required" : "";
    error.category = !this.state.learningObject?.categoryIds || this.state.learningObject.categoryIds.length < 1 ? "Required" : "";
    error.topic = !this.state.learningObject?.topicId ? "Required" : "";

    this.setState({ error });

    if (this.checkValid({ isError: error })) {
      e.preventDefault();
      let learningObject = this.state.learningObject;
      learningObject.translations = learningObject.translations.filter((t) => t.language !== this.state.selectedTranslation.language);
      learningObject.translations.push(this.state.selectedTranslation);

      if (!learningObject.topicId) return;

      this.setState({ learningObject: learningObject, saving: true });

      LearningObjectService.insert(learningObject)
        .then((response) => {
          var promises = this.uploadMaterials(response.data.id);

          Promise.all(promises).then(() => {
            this.batchUpsertAttribute(response.data.id).then(() => this.props.changeModalState());
          });
        })
        .catch((e) => {
          console.log(e);
        });
    }
  }

  batchUpsertAttribute(learningObjectId) {
    var batch = this.state.learningObjectAttributes.map(({ id, attributeId, value, duration, comment, language, materialIds }) => ({
      id: id,
      learningObjectId: learningObjectId,
      duration: isNaN(Number(duration)) ? 0 : Number(duration),
      language: language,
      comment: comment,
      attributeId: attributeId,
      value: value,
      materialIds: materialIds,
    }));

    if (batch && batch.length > 0) {
      return LearningObjectAttributeService.batchUpsert(batch).catch((e) => {
        console.log(e);
      });
    } else {
      return LearningObjectAttributeService.deleteByLearningObjectId(learningObjectId).catch((e) => {
        console.log(e);
      });
    }
  }

  uploadMaterials(learningObjectId) {
    let promises = [];
    if (this.state.needDeleteMeterials) {
      this.state.needDeleteMeterials.forEach((id) => {
        let deletePromise = LearningObjectMaterialService.delete(id).catch((e) => {
          console.log(e);
        });

        promises.push(deletePromise);
      });
    }

    this.state.materials.forEach((material) => {
      if (!material.id) {
        const formData = new FormData();
        formData.append("learningObjectId", learningObjectId);
        formData.append("materialTypeId", material.materialTypeId);
        formData.append("AllowDownload", material.allowDownload);
        formData.append("AllowLink", material.allowLink);
        formData.append("Link", material.link);
        formData.append("language", material.language);
        formData.append("file", material.file);

        let insertPromise = LearningObjectMaterialService.insert(formData)
          .then((response) => {
            this.state.learningObjectAttributes.forEach((attribute) => {
              if (attribute.materialIds.find((id) => id === material.fileName)) {
                attribute.materialIds = attribute.materialIds.filter((id) => id !== material.fileName);
                attribute.materialIds.push(response.data.id);
              }
            });
          })
          .catch((e) => {
            console.log(e);
          });

        promises.push(insertPromise);
      }
    });

    return promises;
  }

  changeActiveModalState = () => {
    this.setState({
      activeModal: !this.state.activeModal,
    });
  };
  changeAttributeModalState = () => {
    this.setState({
      attributeModal: !this.state.attributeModal,
    });
  };

  changeDeleteModalState = () => {
    this.setState({
      deleteModal: !this.state.deleteModal,
    });
  };

  openDeactivateModel = () => {
    this.changeActiveModalState();
  };

  openDeleteModel = () => {
    this.changeDeleteModalState();
  };

  onOpenAttributeModel = (material) => {
    this.setState({ selectedMaterial: material });
    this.changeAttributeModalState();
  };

  onCloseAttributeModel = (learningObjectAttributes) => {
    this.setState({ learningObjectAttributes: learningObjectAttributes });
    this.changeAttributeModalState();
  };

  confirmDeactivateLearningObject = () => {
    LearningObjectService.deactivate(this.state.learningObject.id)
      .then(() => {
        var learningObject = this.state.learningObject;
        learningObject.isActive = false;
        this.setState({ learningObject: learningObject });
        this.changeActiveModalState();
      })
      .catch((e) => {
        console.log(e);
      });
  };

  confirmActivateLearningObject = () => {
    LearningObjectService.activate(this.state.learningObject.id).then(() => {
      var learningObject = this.state.learningObject;
      learningObject.isActive = true;
      this.setState({ learningObject: learningObject });
      this.changeActiveModalState();
    });
  };

  confirmDeleteLearningObject = () => {
    this.setState({ saving: true });
    LearningObjectService.delete(this.state.learningObject.id)
      .then((response) => {
        this.props.changeModalState();
      })
      .catch((e) => {
        console.log(e);
      });
  };

  checkValid = ({ isError, ...rest }) => {
    let isValid = true;
    Object.values(isError).forEach((val) => {
      if (isValid === false) {
        return;
      }
      if (val.length > 0) {
        isValid = false;
      } else {
        isValid = true;
      }
    });
    if (rest) {
      Object.values(rest).forEach((val) => {
        if (isValid === false) {
          return;
        }
        if (val === null) {
          isValid = false;
        } else {
          isValid = true;
        }
      });
    }
    return isValid;
  };

  onChangeLanguage = (e) => {
    let learningObject = this.state.learningObject;
    let selectedTranslation = this.state.selectedTranslation;
    var currentLanguage = selectedTranslation.language;
    var masterLanguage = this.state.learningObject.masterLanguage ?? this.state.selectedTranslation.language;
    var newLanguage = e.lang;
    learningObject.translations = learningObject.translations.filter((t) => t.language !== currentLanguage);
    learningObject.translations.push(this.state.selectedTranslation);
    let newSelectedTranslation = learningObject.translations.find((t) => t.language === newLanguage);
    if (!newSelectedTranslation) {
      newSelectedTranslation = { language: newLanguage, name: "", comment: "", levelInfo: null, longDescription: "", shortDescription: "", shortText: "" };
  
        ConvertService.translate(selectedTranslation.name, selectedTranslation.language, newLanguage).then((nameResponse) => {
          newSelectedTranslation.name = nameResponse.data.text;
          ConvertService.translate(selectedTranslation.shortDescription, selectedTranslation.language, newLanguage).then((shortDescriptionResponse) => {
            newSelectedTranslation.shortDescription = shortDescriptionResponse.data.text;
            ConvertService.translate(selectedTranslation.longDescription, selectedTranslation.language, newLanguage).then((longDescriptionResponse) => {
              newSelectedTranslation.longDescription = longDescriptionResponse.data.text;
              this.setState({ selectedTranslation: newSelectedTranslation });
            });
          });
        });
       }

    this.setState({ selectedTranslation: newSelectedTranslation, learningObject: learningObject });

    this.getActiveTopics(); 
    if (learningObject.topicId) {
      this.getAllCategories(learningObject.topicId);
    }
    this.bindLearningPaths(newLanguage);
    this.getActiveRecommendations();
    this.getActiveCompetences();

    var learningObjectAttributes = this.state.learningObjectAttributes;
    var currentLanguageLearningObjectAttributes = learningObjectAttributes.filter((i) => i.language === masterLanguage);
    currentLanguageLearningObjectAttributes.forEach((lob) => {
      var currentAttribute = this.state.attributes.find((a) => a.id === lob.attributeId);
      if (currentAttribute) {
        var currentLanguageAttribute = currentAttribute.translations.find((a) => a.language === masterLanguage);
        if (currentAttribute) {
          var valueIndex = currentLanguageAttribute.values.indexOf(lob.value);
          if (valueIndex >= 0) {
            var newLanguageAttribute = currentAttribute.translations.find((a) => a.language === newLanguage);
            if (newLanguageAttribute) {
              var newValue = newLanguageAttribute.values.at(valueIndex);
              if (newValue) {
                if (!this.state.learningObjectAttributes.find((a) => a.attributeId === lob.attributeId && a.language === newLanguage && a.value === newValue)) {
                  learningObjectAttributes.push({
                    attributeId: lob.attributeId,
                    attributeName: newLanguageAttribute.name,
                    comment: newLanguageAttribute.comment,
                    duration: lob.duration,
                    language: newLanguage,
                    learningObjectId: lob.learningObjectId,
                    materialIds: lob.materialIds,
                    value: newValue,
                  });
                }
              }
            }
          }
        }
      }
    });
    this.setState({ learningObjectAttributes: learningObjectAttributes });
  };

  changePublic = (event) => {
    var learningObject = this.state.learningObject;
    learningObject.isPublic = event.target.checked;
    this.setState({ learningObject: learningObject });
  };

  changeMasterLanguage = (event) => {
    var learningObject = this.state.learningObject;
    learningObject.masterLanguage = this.state.selectedTranslation.language;
    this.setState({ learningObject: learningObject });
  };

  changeImage = (imageFile) => {
    this.setState((prevState) => ({
      learningObject: {
        ...prevState.learningObject,
        image: imageFile?.name,
      },
    }));
  };

  onChangeTopic = (e) => {
    var learningObject = this.state.learningObject;
    learningObject.topicId = e.id;
    learningObject.categoryIds = [];

    var error = this.state.error;
    error.topic = learningObject.topicId ? "" : "Required";

    this.setState({ learningObject: learningObject, error: error });
    this.getAllCategories(e.id);
  };

  onChangeCategories = (e) => {
    var learningObject = this.state.learningObject;
    learningObject.categoryIds = e.map(({ id }) => id);

    var error = this.state.error;
    error.category = learningObject.categoryIds ? "" : "Required";

    this.setState({ learningObject: learningObject, error: error });
  };

  onChangeRecommendations = (e) => {
    var learningObject = this.state.learningObject;
    learningObject.recommendationIds = e.map(({ id }) => id);
    this.setState({ learningObject: learningObject });
  };

  onChangeCompetences = (e) => {
    var learningObject = this.state.learningObject;
    learningObject.competenceIds = e.map(({ id }) => id);
    this.setState({ learningObject: learningObject });
  };

  changeTranslationName = (e) => {
    var selectedTranslation = this.state.selectedTranslation;
    selectedTranslation.name = e.target.value;
    var error = this.state.error;
    error.name = e.target.value ? "" : "Required";
    this.setState({ selectedTranslation: selectedTranslation, error: error });
  };

  changeTranslationShortDescription = (value) => {
    var selectedTranslation = this.state.selectedTranslation;
    selectedTranslation.shortDescription = value;
    this.setState({ selectedTranslation: selectedTranslation });
  };

  changeTranslationLongDescription = (value) => {
    var selectedTranslation = this.state.selectedTranslation;
    selectedTranslation.longDescription = value;
    this.setState({ selectedTranslation: selectedTranslation });
  };

  dateLearningPathTemplate = (rowData) => {
    return rowData.createdDate;
  };

  render() {
    const { t, changeModalState } = this.props;
    const { saving } = this.state;

    const formatIsPublicCheckBox = (rowData) => {
      return (
        <i
          className={classNames("pi", {
            "true-icon pi-check-circle": rowData.isPublic,
            "false-icon pi-times-circle": !rowData.isPublic,
          })}
        ></i>
      );
    };

    const formatIsTrainingCheckBox = (rowData) => {
      return (
        <i
          className={classNames("pi", {
            "true-icon pi-check-circle": rowData.isTraining,
            "false-icon pi-times-circle": !rowData.isTraining,
          })}
        ></i>
      );
    };

    const formatIsActiveCheckBox = (rowData) => {
      return (
        <i
          className={classNames("pi", {
            "true-icon pi-check-circle": rowData.isActive,
            "false-icon pi-times-circle": !rowData.isActive,
          })}
        ></i>
      );
    };

    const verifiedRowFilterTemplate = (options) => {
      return <TriStateCheckbox value={options.value} onChange={(e) => options.filterApplyCallback(e.value)} />;
    };

    const renderActiveDeactivateButton = () => {
      if (this.state.learningObject.id) {
        if (this.state.learningObject.isActive) {
          return (
            <Button className="mb-2 me-2 " outline color="danger" onClick={this.openDeactivateModel}>
              {t("LEARNING_OBJECT.DEACTIVATE_BUTTON")}
            </Button>
          );
        } else {
          return (
            <Button className="mb-2 me-2 " outline color="success" onClick={this.changeActiveModalState}>
              {t("LEARNING_OBJECT.ACTIVATE_BUTTON")}
            </Button>
          );
        }
      }
    };

    const languageSelectStyle = {
      alignItems: "center",
      justifyContent: "center",
      display: "flex",
      paddingTop: "15px",
      paddingLeft: "45px",
    };
    const colHeaderStyle = {
      alignItems: "center",
      justifyContent: "center",
      display: "flex",
      padding: "3px",
    };
    const labelStyle = {
      justifyContent: "flex-start",
      display: "flex",
      marginTop: "0.5rem",
    };
    return (
      <Modal isOpen={true} fade={false} toggle={changeModalState}>
        <ModalHeader toggle={changeModalState}>
          {this.props.readOnly ? t("LEARNING_OBJECT.TITLE") : this.props.learningObjectId ? t("LEARNING_OBJECT.EDIT_MODAL_TITLE") : t("LEARNING_OBJECT.CREATE_MODAL_TITLE")}
        </ModalHeader>
        <ModalBody>
          <LoadingOverlay
            tag="div"
            active={saving}
            styles={{
              overlay: (base) => ({
                ...base,
                background: "#fff",
                opacity: 0.5,
              }),
            }}
            spinner={<Loader active />}
          >
            <div
              className="card p-fluid"
              style={{
                fontWeight: "bold",
                paddingBottom: "10px",
                paddingLeft: "10px",
                paddingRight: "10px",
              }}
            >
              <Row>
                <Col md="3">
                  <Row>
                    <Col>
                      <LanguageSelect style={languageSelectStyle} lang={this.state.selectedTranslation?.language} onChangeLanguage={this.onChangeLanguage} />
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      {this.isM2LAdmin() && (
                        <FormGroup check>
                          <Label style={colHeaderStyle} check>
                            <Input
                              type="checkbox"
                              disabled={!this.state.learningObject.isActive || this.state.loggedUser.currentRole !== "SystemAdmin"}
                              checked={this.state.learningObject.isPublic}
                              onChange={this.changePublic}
                              style={{ marginRight: "5px", marginBottom: "3px" }}
                            />
                            <div className="title-text-size">{t("LEARNING_OBJECT.IS_PUBLIC")}</div>
                          </Label>
                        </FormGroup>
                      )}
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                        <FormGroup check>
                          <Label style={colHeaderStyle} check>
                            <Input
                              type="checkbox"
                              disabled={this.state.loggedUser.currentRole !== "SystemAdmin"}
                              checked={this.state.learningObject.masterLanguage === this.state.selectedTranslation.language}
                              onChange={this.changeMasterLanguage}
                              style={{ marginRight: "5px", marginBottom: "3px" }}
                            />
                            <div className="title-text-size">{t("LEARNING_OBJECT.MASTER_LANGUAGE")}</div>
                          </Label>
                        </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Label style={colHeaderStyle} for="createdDate">
                        {t("LEARNING_OBJECT.CREATED_DATE")} {moment(new Date(this.state.learningObject.createdDate)).format("DD.MM.YYYY")}
                      </Label>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Label style={colHeaderStyle} for="loggedUserName">
                        {t("LEARNING_OBJECT.CREATED_BY")} {this.state.learningObject.createdByUser}
                      </Label>
                    </Col>
                  </Row>
                </Col>
                <Col md="3">
                  <div>
                    <Label style={labelStyle} for="name">
                      {t("LEARNING_OBJECT.NAME")}*
                    </Label>
                    <Input
                      readOnly={!this.isEditable()}
                      value={this.state.selectedTranslation?.name}
                      onChange={this.changeTranslationName}
                      className={this.state.error.name?.length > 0 ? "is-invalid form-control" : "form-control"}
                    />
                    {this.state.error.name?.length > 0 && <span className="invalid-feedback">{this.state.error?.name}</span>}
                  </div>
                </Col>
                <Col md="6">
                  <Row>
                    <Col>
                      <FormGroup>
                        <Label style={labelStyle} for="exampleSelect">
                          {t("LEARNING_OBJECT.TOPICS")}*
                        </Label>
                        <Select
                          options={this.state.topics}
                          isDisabled={!this.isEditable()}
                          value={this.state.topics.find((t) => t.id === this.state.learningObject.topicId) || ""}
                          onChange={this.onChangeTopic}
                          getOptionLabel={(option) => option.name}
                          getOptionValue={(option) => option.id}
                          isOptionDisabled={(option) => !option.isActive}
                          className={this.state.error.topic?.length > 0 ? "is-invalid form-control" : ""}
                        />
                        {this.state.error.topic?.length > 0 && <span className="invalid-feedback">{this.state.error?.topic}</span>}
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <FormGroup>
                        <Label style={labelStyle} for="exampleSelect">
                          {t("LEARNING_OBJECT.CATEGORIES")}*
                        </Label>
                        <Select
                          options={this.state.categories}
                          isDisabled={!this.isEditable()}
                          value={
                            (this.state.learningObject.categoryIds &&
                              this.state.categories?.filter((t) => t.id === this.state.learningObject.categoryIds.find((id) => t.id === id))) ||
                            ""
                          }
                          onChange={this.onChangeCategories}
                          isMulti
                          getOptionLabel={(option) => option.name}
                          getOptionValue={(option) => option.id}
                          isOptionDisabled={(option) => !option.isActive}
                          className={this.state.error.category?.length > 0 ? "is-invalid form-control" : ""}
                        />
                        {this.state.error.category?.length > 0 && <span className="invalid-feedback">{this.state.error?.category}</span>}
                      </FormGroup>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </div>

            <div className="card p-fluid">
              <div style={{ margin: "10px", marginBottom: "0px" }}>
                <Row>
                  <Nav style={{ padding: "0rem 0.75rem" }} pills>
                    <NavItem>
                      <NavLink
                        href="#"
                        className={classnames({
                          active: this.state.activeTab === "imageTab",
                        })}
                        onClick={() => {
                          this.handleTabChange("imageTab");
                        }}
                      >
                        {t("LEARNING_OBJECT.IMAGE")}
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        href="#"
                        className={classnames({
                          active: this.state.activeTab === "mediaTab",
                        })}
                        onClick={() => {
                          this.handleTabChange("mediaTab");
                        }}
                      >
                        {t("LEARNING_OBJECT.MEDIA")}
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        href="#"
                        className={classnames({
                          active: this.state.activeTab === "attributeCompetenceTab",
                        })}
                        onClick={() => {
                          this.handleTabChange("attributeCompetenceTab");
                        }}
                      >
                        {t("LEARNING_OBJECT.ATTRIBUTE_COMTEPENCE")}
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        href="#"
                        className={classnames({
                          active: this.state.activeTab === "trainingTab",
                        })}
                        onClick={() => {
                          this.handleTabChange("trainingTab");
                        }}
                      >
                        {t("LEARNINGPATH.TRAINING")}
                      </NavLink>
                    </NavItem>
                  </Nav>

                  <TabContent activeTab={this.state.activeTab} style={{ padding: "1rem", paddingTop: "0rem", minHeight: "20rem" }}>
                    <TabPane tabId="imageTab">
                      <Row>
                        <Col>
                          <AvatarPicker
                            readOnly={!this.isEditable()}
                            handleChangeImage={this.changeImage}
                            base64Image={this.state.learningObject?.image}
                            style={{
                              borderRadius: "0%",
                              width: "40rem",
                              minHeight: "17rem",
                            }}
                          />
                        </Col>
                      </Row>
                    </TabPane>
                    <TabPane tabId="mediaTab">
                      <Row>
                        <Col md="7">
                          <Row>
                            <Nav style={{ padding: "0.4rem 0.75rem", marginBottom: "0rem" }} pills>
                              <NavItem>
                                <NavLink
                                  href="#"
                                  className={classnames({
                                    active: this.state.descriptionTab === "propertyTab",
                                  })}
                                  onClick={() => {
                                    this.handleDescriptionTabChange("propertyTab");
                                  }}
                                >
                                  {t("LEARNINGPATH.PROPERTIES")}
                                </NavLink>
                              </NavItem>
                              <NavItem>
                                <NavLink
                                  href="#"
                                  className={classnames({
                                    active: this.state.descriptionTab === "trainingTab",
                                  })}
                                  onClick={() => {
                                    this.handleDescriptionTabChange("trainingTab");
                                  }}
                                >
                                  {t("LEARNINGPATH.CONTENT")}
                                </NavLink>
                              </NavItem>
                            </Nav>
                            <TabContent activeTab={this.state.descriptionTab}>
                              <TabPane tabId="propertyTab">
                                <ReactQuill
                                  readOnly={!this.isEditable()}
                                  value={this.state.selectedTranslation?.shortDescription}
                                  onChange={this.changeTranslationShortDescription}
                                />
                              </TabPane>
                              <TabPane tabId="trainingTab">
                                <ReactQuill
                                  readOnly={!this.isEditable()}
                                  value={this.state.selectedTranslation?.longDescription}
                                  onChange={this.changeTranslationLongDescription}
                                />
                              </TabPane>
                            </TabContent>
                          </Row>
                        </Col>
                        <Col md="5">
                          <LearningObjectMaterial
                            readOnly={!this.isEditable()}
                            learningObjectId={this.props.learningObjectId}
                            language={this.state.selectedTranslation?.language}
                            learningObjectAttributes={this.state.learningObjectAttributes}
                            onChange={(materials, needDeleteMeterials) => this.setState({ materials: materials, needDeleteMeterials: needDeleteMeterials })}
                            onOpenAttributeModel={(material) => this.onOpenAttributeModel(material)}
                          />
                        </Col>
                      </Row>
                    </TabPane>
                    <TabPane tabId="attributeCompetenceTab">
                      <Row>
                        <Col md="8">
                          <LearningObjectAttribute
                            readOnly={!this.isEditable()}
                            learningObjectAttributes={this.state.learningObjectAttributes}
                            learningObjectId={this.props.learningObjectId}
                            language={this.state.selectedTranslation?.language}
                            onChange={(learningObjectAttributes) => this.setState({ learningObjectAttributes: learningObjectAttributes })}
                          />
                        </Col>
                        <Col md="4">
                          <div style={{ paddingBottom: "1rem" }}>
                            <Label for="exampleSelect">{t("LEARNING_OBJECT.COMPETENCES")} </Label>
                            <Select
                              options={this.state.competences}
                              isDisabled={!this.isEditable()}
                              value={
                                (this.state.learningObject.competenceIds &&
                                  this.state.competences?.filter((t) => t.id === this.state.learningObject.competenceIds.find((id) => t.id === id))) ||
                                ""
                              }
                              onChange={this.onChangeCompetences}
                              isMulti
                              isOptionDisabled={(option) => !option.isActive}
                              getOptionLabel={(option) => option.competenceFrameName + ": " + option.name}
                              getOptionValue={(option) => option.id}
                            />
                          </div>
                          <div>
                            <Label for="exampleSelect">{t("LEARNING_OBJECT.RECOMMENDATIONS")} </Label>
                            <Select
                              options={this.state.recommendations}
                              isOptionDisabled={(option) => !option.isActive}
                              isDisabled={!this.isEditable()}
                              value={
                                (this.state.learningObject.recommendationIds &&
                                  this.state.recommendations?.filter((t) => t.id === this.state.learningObject.recommendationIds.find((id) => t.id === id))) ||
                                ""
                              }
                              onChange={this.onChangeRecommendations}
                              isMulti
                              getOptionLabel={(option) => option.name}
                              getOptionValue={(option) => option.id}
                            />
                          </div>
                        </Col>
                      </Row>
                    </TabPane>
                    <TabPane tabId="trainingTab">
                      <div className="card p-fluid">
                        <DataTable
                          value={this.state.bindLearningPaths}
                          paginator
                          className="p-datatable-customers"
                          rows={5}
                          dataKey="id"
                          filterDisplay="row"
                          responsiveLayout="scroll"
                          filters={this.state.filterLearningPathsTemplate}
                          emptyMessage={t("LEARNINGPATH.TABLE_MESSAGE")}
                        >
                          <Column header={t("LEARNINGPATH.NAME_COLUMN_NAME")} field="name" filter sortable />
                          <Column header={t("LEARNINGPATH.COMMENT_COLUMN_NAME")} field="comment" filter sortable />
                          <Column field="version" header={t("LEARNINGPATH.VERSION")} filter sortable />
                          <Column
                            field="isTraining"
                            header={t("LEARNINGPATH.IS_TRAINING_COLUMN_NAME")}
                            dataType="boolean"
                            sortable
                            body={formatIsTrainingCheckBox}
                            filter
                            filterElement={verifiedRowFilterTemplate}
                          />
                          <Column
                            field="isPublic"
                            header={t("LEARNINGPATH.IS_PUBLIC_COLUMN_NAME")}
                            dataType="boolean"
                            sortable
                            body={formatIsPublicCheckBox}
                            filter
                            filterElement={verifiedRowFilterTemplate}
                          />
                          <Column
                            field="isActive"
                            header={t("LEARNINGPATH.IS_ACTIVE_COLUMN_NAME")}
                            dataType="boolean"
                            sortable
                            body={formatIsActiveCheckBox}
                            filter
                            filterElement={verifiedRowFilterTemplate}
                          />
                          <Column field="createdByUser" header={t("LEARNINGPATH.CREATED_BY_COLUMN_NAME")} filter sortable />
                          <Column header={t("LEARNINGPATH.CREATED_DATE_COLUMN_NAME")} filterField="createdDate" dataType="date" body={this.dateLearningPathTemplate} />
                        </DataTable>
                      </div>
                    </TabPane>
                  </TabContent>
                </Row>
              </div>
            </div>
          </LoadingOverlay>
        </ModalBody>
        <ModalFooter>
          {this.state.attributeModal ? (
            <LearningObjectAttributeModel
              material={this.state.selectedMaterial}
              learningObjectAttributes={this.state.learningObjectAttributes}
              language={this.state.selectedTranslation.language}
              learningObject={this.state.learningObject}
              onCloseAttributeModel={(learningObjectAttributes) => this.onCloseAttributeModel(learningObjectAttributes)}
            />
          ) : (
            ""
          )}

          <Modal isOpen={this.state.activeModal} toggle={this.changeActiveModalState} style={{ marginTop: "3rem", width: "50%" }}>
            <ModalHeader>
              {this.state.learningObject.isActive ? t("LEARNING_OBJECT.CONFIRM_DEACTIVATE_MODAL_TITLE") : t("LEARNING_OBJECT.CONFIRM_ACTIVATE_MODAL_TITLE")}
            </ModalHeader>
            <ModalBody style={{ minHeight: "auto" }}>
              {this.state.learningObject.isActive && this.state.learningPaths.filter((lp) => lp.isActive).length > 0 ? (
                <div style={{ minHeight: "25rem" }}>
                  <PerfectScrollbar>
                    {this.state.learningPaths.filter((lp) => lp.isActive).length > 0 ? (
                      <Card className="main-card mb-3">
                        <CardTitle>{t("LEARNING_OBJECT.LEARNING_PATHS")}</CardTitle>
                        <DataTable value={this.state.learningPaths.filter((lp) => lp.isActive)} paginator rows={10}>
                          <Column field="name" header={t("LEARNING_OBJECT.LEARNING_PATH_ITEM_NAME_COLUMN_NAME")}></Column>
                          <Column field="version" header={t("LEARNINGPATH.VERSION")}></Column>
                          <Column field="createdByUser" header={t("LEARNING_OBJECT.LEARNING_PATH_ITEM_CREATED_BY_COLUMN_NAME")}></Column>
                        </DataTable>
                      </Card>
                    ) : (
                      ""
                    )}
                  </PerfectScrollbar>
                </div>
              ) : (
                ""
              )}
            </ModalBody>
            <ModalFooter>
              <Col>
                {this.state.learningObject.isActive && this.state.learningPaths.filter((lp) => lp.isActive).length > 0 ? (
                  <CSVLink
                    data={this.state.learningPaths.filter((lp) => lp.isActive)}
                    filename={"deactivate_learning_object_" + this.state.selectedTranslation?.name + ".csv"}
                    headers={this.state.csvHeaders}
                    className="mb-2 me-2 btn btn-success"
                    target="_blank"
                  >
                    {t("LEARNING_OBJECT.EXPORT_BUTTON")}
                  </CSVLink>
                ) : (
                  ""
                )}
              </Col>
              <Button className="mb-2 me-2   " color="secondary" onClick={this.changeActiveModalState}>
                {t("LEARNING_OBJECT.NO_BUTTON")}
              </Button>
              <Button
                className="mb-2 me-2"
                color="primary"
                onClick={this.state.learningObject.isActive ? this.confirmDeactivateLearningObject : this.confirmActivateLearningObject}
              >
                {t("LEARNING_OBJECT.YES_BUTTON")}
              </Button>
            </ModalFooter>
          </Modal>

          <Modal isOpen={this.state.deleteModal} toggle={this.changeDeleteModalState} style={{ marginTop: "3rem", width: "50%" }}>
            <ModalHeader> {this.state.learningPaths.length > 0 ? t("LEARNING_OBJECT.DELETE_MODAL_TITLE") : t("LEARNING_OBJECT.CONFIRM_DELETE_MODAL_TITLE")}</ModalHeader>
            <ModalBody style={{ minHeight: "auto" }}>
              {this.state.learningPaths.length > 0 ? (
                <div style={{ minHeight: "25rem" }}>
                  <PerfectScrollbar>
                    <Card className="main-card mb-3">
                      <CardTitle>{t("LEARNING_OBJECT.LEARNING_PATHS")}</CardTitle>
                      <DataTable value={this.state.learningPaths} paginator rows={10}>
                        <Column field="name" header={t("LEARNING_OBJECT.LEARNING_PATH_ITEM_NAME_COLUMN_NAME")}></Column>
                        <Column field="version" header={t("LEARNINGPATH.VERSION")}></Column>
                        <Column field="createdByUser" header={t("LEARNING_OBJECT.LEARNING_PATH_ITEM_CREATED_BY_COLUMN_NAME")}></Column>
                      </DataTable>
                    </Card>
                  </PerfectScrollbar>
                </div>
              ) : (
                ""
              )}
            </ModalBody>
            <ModalFooter>
              <Col>
                {this.state.learningPaths.length > 0 ? (
                  <CSVLink
                    data={this.state.learningPaths}
                    filename={"delete_learning_object_" + this.state.selectedTranslation?.name + ".csv"}
                    headers={this.state.csvHeaders}
                    className="mb-2 me-2 btn btn-success"
                    target="_blank"
                  >
                    {t("LEARNING_OBJECT.EXPORT_BUTTON")}
                  </CSVLink>
                ) : (
                  ""
                )}
              </Col>
              {this.state.learningPaths.length > 0 ? (
                <div>
                  <Button className="mb-2 me-2 " color="secondary" onClick={this.changeDeleteModalState}>
                    {t("LEARNING_OBJECT.CANCEL_BUTTON")}
                  </Button>
                </div>
              ) : (
                <div>
                  <Button className="mb-2 me-2 " color="secondary" onClick={this.changeDeleteModalState}>
                    {t("LEARNING_OBJECT.NO_BUTTON")}
                  </Button>
                  <Button className="mb-2 me-2 " color="primary" onClick={this.confirmDeleteLearningObject}>
                    {t("LEARNING_OBJECT.YES_BUTTON")}
                  </Button>
                </div>
              )}
            </ModalFooter>
          </Modal>

          {this.isEditable() ? (
            <>
              <Col>
                {this.state.learningObject.id ? (
                  <Button disabled={this.state.learningObject.isActive} className="mb-2 me-2" color="danger" onClick={this.openDeleteModel}>
                    {t("LEARNING_OBJECT.DELETE_BUTTON")}
                  </Button>
                ) : (
                  ""
                )}
                {renderActiveDeactivateButton()}
              </Col>

              <Button className="mb-2 me-2" color="secondary" onClick={changeModalState}>
                {t("LEARNING_OBJECT.CANCEL_BUTTON")}
              </Button>
              {this.props.learningObjectId ? (
                <Button className="mb-2 me-2" color="primary" onClick={(e) => this.updateLearningObject(e)}>
                  {t("LEARNING_OBJECT.UPDATE_BUTTON")}
                </Button>
              ) : (
                <Button className="mb-2 me-2 " color="primary" onClick={(e) => this.createLearningObject(e)}>
                  {t("LEARNING_OBJECT.SAVE_BUTTON")}
                </Button>
              )}
            </>
          ) : (
            <Button className="mb-2 me-2" color="secondary" onClick={changeModalState}>
              {t("LEARNING_OBJECT.CLOSE_BUTTON")}
            </Button>
          )}
        </ModalFooter>
      </Modal>
    );
  }
}
export default withTranslation()(MainModal);
