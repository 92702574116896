import React, { Fragment, Component } from "react";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import { withTranslation } from "react-i18next";
import PageTitle from "../../../Layout/AppMain/PageTitle";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { UncontrolledDropdown, DropdownItem, DropdownToggle, DropdownMenu, Col, Row, Button, Card, CardBody } from "reactstrap";
import { FilterMatchMode } from "primereact/api";
import "../../Metadata/Attribute/DataTable.css";
import "../LearningPath/style.css";
import { classNames } from "primereact/utils";
import { TriStateCheckbox } from "primereact/tristatecheckbox";
import moment from "moment/moment";
import LearningPathService from "../../../service/LearningPathService";
import LearningPathTypeService from "../../../service/LearningPathTypeService";
import languages from "../../../models/Languages";
import Flag from "react-flagkit";
import MainModal from "./MainModal";
import SweetAlert from "react-bootstrap-sweetalert";
import tokenService from "../../../service/tokenService";

class LearningPath extends Component {
  constructor(props) {
    super(props);
    this.state = {
      learningPaths: [],
      selectedLearningPathId: null,
      bindLearningPaths: [],
      learningPathTypes: [],
      selectedFilteringTableLanguage: languages.find((obj) => obj.lang === this.props.i18n.language),
      modal: false,
      show: false,
      filterLearningPathsTemplate: {
        name: { value: null, matchMode: FilterMatchMode.CONTAINS },
        comment: { value: null, matchMode: FilterMatchMode.CONTAINS },
        createdByUser: { value: null, matchMode: FilterMatchMode.CONTAINS },
        version: { value: null, matchMode: FilterMatchMode.CONTAINS },
        parentName: { value: null, matchMode: FilterMatchMode.CONTAINS },
        createdDate: { value: null, matchMode: FilterMatchMode.DATE_IS },
        isPublic: { value: tokenService.getCompany().showPublicLearningPath ? null : false, matchMode: FilterMatchMode.EQUALS },
        isActive: { value: null, matchMode: FilterMatchMode.EQUALS },
        isTraining: { value: null, matchMode: FilterMatchMode.EQUALS },
      },
    };
  }

  componentDidMount() {
    this.getAllLearningPathTypes().then(() => {
      this.getAllLearningPaths();
    });
  }

  getAllLearningPathTypes() {
    return LearningPathTypeService.getAllLearningPathTypes()
      .then((response) => {
        this.setState({
          learningPathTypes: response.data,
        });
      })
      .catch((e) => {
        console.log(e);
      });
  }

  getAllLearningPaths = () => {
    LearningPathService.getAllLearningPaths()
      .then((response) => {
        this.setState({
          learningPaths: response.data,
        });
        this.bindLearningPaths(this.state.selectedFilteringTableLanguage.lang);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  bindLearningPaths(lang) {
    var bindLearningPaths = this.state.learningPaths.map(
      ({
        id,
        name,
        comment,
        language,
        description,
        duration,
        learningPathTypeId,
        createdDate,
        isPublic,
        isActive,
        createdByUser,
        version,
        requirements,
        targetGroup,
        parentName,
      }) => ({
        name: name,
        description: description,
        language: language,
        duration: duration,
        comment: comment,
        createdByUser: createdByUser,
        isPublic: isPublic,
        isActive: isActive,
        isTraining: this.state.learningPathTypes.find((t) => t.id === learningPathTypeId)?.name === "Training",
        id: id,
        version: this.state.learningPathTypes.find((t) => t.id === learningPathTypeId)?.name === "Training" ? version : "",
        requirements: requirements,
        targetGroup: targetGroup,
        parentName: parentName,
        createdDate: moment(new Date(createdDate)).format("DD.MM.YYYY"),
      })
    );

    if (lang) {
      bindLearningPaths = bindLearningPaths.filter((lp) => lp.language === lang);
    }
    this.setState({
      bindLearningPaths: bindLearningPaths,
    });
  }

  changeModalState = (show) => {
    this.setState({ show: show });
    if (this.state.modal) {
      this.getAllLearningPaths();
      this.setState({
        selectedLearningPathId: null,
      });
    }
    this.setState({
      modal: !this.state.modal,
    });
  };

  dateLearningPathTemplate = (rowData) => {
    return rowData.createdDate;
  };

  onChangeFilteringTableLanguage = (language) => {
    this.setState({ selectedFilteringTableLanguage: language });
    this.bindLearningPaths(language.lang);
  };

  openUpdateLearningPath = (id) => {
    this.setState({ selectedLearningPathId: id }, () => {
      this.changeModalState();
    });
  };

  openCreateLearningPathModal = () => {
    this.changeModalState();
  };

  render() {
    const { t } = this.props;

    const buttonUpdateLearningPath = (rowData) => {
      return (
        <Button className="me-2" size="sm" outline color="primary" onClick={() => this.openUpdateLearningPath(rowData.id)}>
          {t("LEARNINGPATH.OPEN_BUTTON")}
        </Button>
      );
    };

    const formatIsPublicCheckBox = (rowData) => {
      return (
        <i
          className={classNames("pi", {
            "true-icon pi-check-circle": rowData.isPublic,
            "false-icon pi-times-circle": !rowData.isPublic,
          })}
        ></i>
      );
    };

    const formatIsTrainingCheckBox = (rowData) => {
      return (
        <i
          className={classNames("pi", {
            "true-icon pi-check-circle": rowData.isTraining,
            "false-icon pi-times-circle": !rowData.isTraining,
          })}
        ></i>
      );
    };

    const formatIsActiveCheckBox = (rowData) => {
      return (
        <i
          className={classNames("pi", {
            "true-icon pi-check-circle": rowData.isActive,
            "false-icon pi-times-circle": !rowData.isActive,
          })}
        ></i>
      );
    };

    const verifiedRowFilterTemplate = (options) => {
      return <TriStateCheckbox value={options.value} onChange={(e) => options.filterApplyCallback(e.value)} />;
    };

    const languageTableFilter = () => {
      return (
        <div>
          <UncontrolledDropdown>
            <DropdownToggle className="p-0 me-2" color="black">
              <div className="icon-wrapper-bg bg-focus" />
              <div className="language-icon">
                <Flag className="me-3 opacity-8" country={this.state.selectedFilteringTableLanguage?.flag} size="30" />
              </div>
            </DropdownToggle>
            <DropdownMenu end className="rm-pointers">
              {languages.map((language, id) => (
                <DropdownItem key={id} value={language} onClick={() => this.onChangeFilteringTableLanguage(language)}>
                  <Flag className="me-3 opacity-8" country={language.flag} />
                  {language.name}
                </DropdownItem>
              ))}
            </DropdownMenu>
          </UncontrolledDropdown>
        </div>
      );
    };

    const languageBody = (option) => {
      return (
        <Flag
          className="me-3 opacity-8"
          style={{
            marginLeft: "0.75rem",
          }}
          country={languages.find((obj) => obj.lang === option.language)?.flag}
          size="30"
        />
      );
    };

    return (
      <Fragment>
        <TransitionGroup>
          <CSSTransition component="div" classNames="TabsAnimation" appear={true} timeout={1500} enter={false} exit={false}>
            <div>
              <PageTitle heading={t("LEARNINGPATH.TITLE")} subheading={t("LEARNINGPATH.DESCRIPTION")} icon="pe-7s-medal icon-gradient bg-tempting-azure" />

              <SweetAlert title={t("SUCCESS")} show={this.state.show} type="success" onConfirm={() => this.setState({ show: false })} />
              <Row>
                <Col md="12">
                  <Button className="mb-2 me-2" outline color="primary" onClick={() => this.openCreateLearningPathModal()}>
                    {t("LEARNINGPATH.CREATE_BUTTON")}
                  </Button>
                  {this.state.modal ? (
                    <MainModal
                      changeModalState={this.changeModalState}
                      learningPathId={this.state.selectedLearningPathId}
                      defaultLang={this.state.selectedFilteringTableLanguage.lang}
                    />
                  ) : (
                    ""
                  )}
                </Col>
                <Col md="12">
                  <Card className="main-card mb-3">
                    <CardBody>
                      <div className="card p-fluid">
                        <DataTable
                          value={this.state.bindLearningPaths}
                          paginator
                          className="p-datatable-customers"
                          rows={10}
                          dataKey="id"
                          filterDisplay="row"
                          responsiveLayout="scroll"
                          filters={this.state.filterLearningPathsTemplate}
                          emptyMessage={t("LEARNINGPATH.TABLE_MESSAGE")}
                        >
                          <Column
                            header={t("LEARNINGPATH.LANGUAGE_COLUMN_NAME")}
                            field="language"
                            showFilterMenu={false}
                            style={{ minWidth: "10rem" }}
                            body={languageBody}
                            filter
                            filterElement={languageTableFilter}
                          />
                          <Column header={t("LEARNINGPATH.NAME_COLUMN_NAME")} field="name" filter sortable />
                          <Column header={t("LEARNINGPATH.COMMENT_COLUMN_NAME")} field="comment" filter sortable />
                          <Column field="version" header={t("LEARNINGPATH.VERSION")} filter sortable />
                          <Column
                            field="isTraining"
                            header={t("LEARNINGPATH.IS_TRAINING_COLUMN_NAME")}
                            dataType="boolean"
                            sortable
                            body={formatIsTrainingCheckBox}
                            filter
                            filterElement={verifiedRowFilterTemplate}
                          />
                          <Column
                            field="isPublic"
                            header={t("LEARNINGPATH.IS_PUBLIC_COLUMN_NAME")}
                            dataType="boolean"
                            sortable
                            body={formatIsPublicCheckBox}
                            filter
                            filterElement={verifiedRowFilterTemplate}
                          />
                          <Column
                            field="isActive"
                            header={t("LEARNINGPATH.IS_ACTIVE_COLUMN_NAME")}
                            dataType="boolean"
                            sortable
                            body={formatIsActiveCheckBox}
                            filter
                            filterElement={verifiedRowFilterTemplate}
                          />
                          <Column field="createdByUser" header={t("LEARNINGPATH.CREATED_BY_COLUMN_NAME")} filter sortable />
                          <Column header={t("LEARNINGPATH.CREATED_DATE_COLUMN_NAME")} filterField="createdDate" dataType="date" body={this.dateLearningPathTemplate} />
                          <Column body={buttonUpdateLearningPath} rowEditor />
                        </DataTable>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </div>
          </CSSTransition>
        </TransitionGroup>
      </Fragment>
    );
  }
}
export default withTranslation()(LearningPath);
