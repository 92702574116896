import api from "./api";

class MaterialTypeService {
  getAll() {
    return api.get("/MaterialType/get-all");
  }

  insert(materialType) {
    return api.post("/MaterialType/insert", materialType);
  }

  update(updatedMaterialType) {
    return api.post("/MaterialType/update", updatedMaterialType);
  }
}

export default new MaterialTypeService();
