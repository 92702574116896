import React, { Component } from "react";

class VideoPreview extends Component {
  constructor(props) {
    super(props);
    this.state = {
      videoURL: null,
    };
  }

  componentDidMount() {
    const videoURL = URL.createObjectURL(this.props.file);
    this.setState({
      videoURL: videoURL,
    });
  }
  render() {
    const { file } = this.props;

    return (
      <div
        style={{
          textAlign: "center",
        }}
      >
        {this.state.videoURL && <video src={this.state.videoURL} width="750" height="500" controls />}
      </div>
    );
  }
}
export default VideoPreview;
