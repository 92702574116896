import api from "./api";

class LearningObjectAttributeService {
  getByLearningObjectId(learningObjectId) {
    return api.get("/LearningObjectAttribute/get-by-learning-object-id?learningObjectId=" + learningObjectId);
  }

  batchUpsert(learningObjectAttributes) {
    return api.post("/LearningObjectAttribute/batch-upsert", learningObjectAttributes);
  }

  deleteByLearningObjectId(learningObjectId) {
    return api.post("/LearningObjectAttribute/delete-by-learning-object-id?learningObjectId=" + learningObjectId);
  }
  deactivate(id) {
    return api.post("/LearningObjectAttribute/deactivate", id);
  }

  delete(id) {
    return api.post("/LearningObjectAttribute/delete?id=" + id);
  }
}

export default new LearningObjectAttributeService();
