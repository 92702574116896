import React, { Fragment, Component } from "react";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import { withTranslation } from "react-i18next";
import PageTitle from "../../../Layout/AppMain/PageTitle";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Col, Row, Modal, ModalHeader, UncontrolledDropdown, ModalFooter, DropdownMenu, DropdownToggle, DropdownItem, Card, CardBody, Button } from "reactstrap";
import { FilterMatchMode } from "primereact/api";
import "../../Metadata/Attribute/DataTable.css";
import "../../Learning/LearningPath/style.css";
import moment from "moment/moment";
import LearningPathService from "../../../service/LearningPathService";
import LearningPathTypeService from "../../../service/LearningPathTypeService";
import languages from "../../../models/Languages";
import Flag from "react-flagkit";
import { eventMediator } from "../../../service/eventMediator.js";
import tokenService from "../../../service/tokenService";

class TrainingList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      trainings: [],
      selectedTraining: null,
      bindTrainings: [],
      learningPathTypes: [],
      selectedFilteringTableLanguage: languages.find((obj) => obj.lang === this.props.i18n.language),
      favoriteModal: false,
      filterLearningPathsTemplate: {
        name: { value: null, matchMode: FilterMatchMode.CONTAINS },
        version: { value: null, matchMode: FilterMatchMode.CONTAINS },
        duration: { value: null, matchMode: FilterMatchMode.CONTAINS },
        requirements: { value: null, matchMode: FilterMatchMode.CONTAINS },
        description: { value: null, matchMode: FilterMatchMode.CONTAINS },
        createdDate: { value: null, matchMode: FilterMatchMode.DATE_IS },
      },
    };
  }

  componentDidMount() {
    this.getAllLearningPathTypes().then(() => {
      this.getTraining();
    });
  }

  getAllLearningPathTypes() {
    return LearningPathTypeService.getAllLearningPathTypes()
      .then((response) => {
        this.setState({
          learningPathTypes: response.data,
        });
      })
      .catch((e) => {
        console.log(e);
      });
  }

  getTraining = () => {
    LearningPathService.getTraining()
      .then((response) => {
        this.setState({
          trainings: response.data,
        });
        this.bindTrainings(this.state.selectedFilteringTableLanguage.lang);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  bindTrainings(lang) {
    var bindTrainings = this.state.trainings.map(
      ({
        id,
        name,
        comment,
        language,
        description,
        duration,
        learningPathTypeId,
        createdDate,
        isPublic,
        isActive,
        createdByUser,
        version,
        requirements,
        targetGroup,
        isFavorite,
        parentName,
      }) => ({
        name: name,
        description: description,
        language: language,
        duration: duration,
        comment: comment,
        createdByUser: createdByUser,
        isPublic: isPublic,
        isActive: isActive,
        id: id,
        version: this.state.learningPathTypes.find((t) => t.id === learningPathTypeId)?.name === "Training" ? version : "",
        requirements: requirements,
        targetGroup: targetGroup,
        parentName: parentName,
        isFavorite: isFavorite,
        createdDate: moment(new Date(createdDate)).format("DD.MM.YYYY"),
      })
    );

    if (lang) {
      bindTrainings = bindTrainings.filter((lp) => lp.language === lang);
    }
    this.setState({
      bindTrainings: bindTrainings,
    });
  }

  changeFavoriteModalState = () => {
    this.setState({
      favoriteModal: !this.state.favoriteModal,
    });
  };

  dateLearningPathTemplate = (rowData) => {
    return rowData.createdDate;
  };

  onChangeFilteringTableLanguage = (language) => {
    this.setState({ selectedFilteringTableLanguage: language });
    this.bindTrainings(language.lang);
  };

  openFavoriteModel = (training) => {
    this.setState({ selectedTraining: training }, () => {
      this.changeFavoriteModalState();
    });
  };

  getFavorites() {
    return LearningPathService.getFavorite().then((response) => {
      tokenService.setTrainings(response.data);
      eventMediator.dispatch("updateMenu");
    });
  }

  confirmMarkAsFavorite = () => {
    LearningPathService.markAsFavorite(this.state.selectedTraining.id).then(() => {
      this.changeFavoriteModalState();
      this.getFavorites();
      this.getTraining();
    });
  };

  confirmUnmarkFromFavorite = () => {
    LearningPathService.unmarkFromFavorite(this.state.selectedTraining.id).then(() => {
      this.changeFavoriteModalState();
      this.getFavorites();
      this.getTraining();
    });
  };

  render() {
    const { t } = this.props;

    const buttonFavoriteTraining = (rowData) => {
      if (rowData.isFavorite) {
        return (
          <>
            <Button className="me-2" size="sm" outline color="primary" onClick={() => this.props.history.push("/training/trainings/" + rowData.id)}>
              {t("NAV.TRAINING")}
            </Button>
            <Button className="me-2" size="sm" style={{ width: "150px" }} outline color="secondary" onClick={() => this.openFavoriteModel(rowData)}>
              {t("TRAINING.UNMARK_FROM_FAVORITE")}
            </Button>
          </>
        );
      } else {
        return (
          <>
            <Button className="me-2" size="sm" outline color="primary" onClick={() => this.props.history.push("/training/trainings/" + rowData.id)}>
              {t("NAV.TRAINING")}
            </Button>
            <Button className="me-2" size="sm" style={{ width: "150px" }} outline color="success" onClick={() => this.openFavoriteModel(rowData)}>
              {t("TRAINING.MARK_AS_FAVORITE")}
            </Button>
          </>
        );
      }
    };

    const languageTableFilter = () => {
      return (
        <div>
          <UncontrolledDropdown>
            <DropdownToggle className="p-0 me-2" color="black">
              <div className="icon-wrapper-bg bg-focus" />
              <div className="language-icon">
                <Flag className="me-3 opacity-8" country={this.state.selectedFilteringTableLanguage?.flag} size="30" />
              </div>
            </DropdownToggle>
            <DropdownMenu end className="rm-pointers">
              {languages.map((language, id) => (
                <DropdownItem key={id} value={language} onClick={() => this.onChangeFilteringTableLanguage(language)}>
                  <Flag className="me-3 opacity-8" country={language.flag} />
                  {language.name}
                </DropdownItem>
              ))}
            </DropdownMenu>
          </UncontrolledDropdown>
        </div>
      );
    };

    const languageBody = (option) => {
      return (
        <Flag
          className="me-3 opacity-8"
          style={{
            marginLeft: "0.75rem",
          }}
          country={languages.find((obj) => obj.lang === option.language)?.flag}
          size="30"
        />
      );
    };

    return (
      <Fragment>
        <TransitionGroup>
          <CSSTransition component="div" classNames="TabsAnimation" appear={true} timeout={1500} enter={false} exit={false}>
            <div>
              <PageTitle heading={t("TRAINING.TITLE")} subheading={t("TRAINING.DESCRIPTION")} icon="pe-7s-medal icon-gradient bg-tempting-azure" />
              <Row>
                <Col md="12">
                  <Card className="main-card mb-3">
                    <CardBody>
                      <div className="card p-fluid">
                        <DataTable
                          value={this.state.bindTrainings}
                          paginator
                          className="p-datatable-customers"
                          rows={10}
                          dataKey="id"
                          filterDisplay="row"
                          responsiveLayout="scroll"
                          filters={this.state.filterLearningPathsTemplate}
                          emptyMessage={t("LEARNINGPATH.TABLE_MESSAGE")}
                        >
                          <Column
                            header={t("LEARNINGPATH.LANGUAGE_COLUMN_NAME")}
                            field="language"
                            showFilterMenu={false}
                            style={{ minWidth: "2rem" }}
                            body={languageBody}
                            filter
                            filterElement={languageTableFilter}
                          />
                          <Column header={t("LEARNINGPATH.NAME_COLUMN_NAME")} field="name" filter sortable />
                          <Column field="version" header={t("LEARNINGPATH.VERSION")} filter sortable />
                          <Column header={t("LEARNINGPATH.COMMENT_COLUMN_NAME")} field="comment" filter sortable />
                          <Column header={t("LEARNINGPATH.DURATION")} field="duration" filter sortable />
                          <Column header={t("LEARNINGPATH.REQUIREMENTS")} field="requirements" filter sortable />
                          <Column header={t("LEARNINGPATH.TARGET_GROUP")} field="targetGroup" filter sortable />
                          <Column header={t("LEARNINGPATH.DESCRIPTION_MODAL")} field="description" filter sortable />
                          <Column header={t("LEARNINGPATH.CREATED_DATE_COLUMN_NAME")} filterField="createdDate" dataType="date" body={this.dateLearningPathTemplate} />
                          <Column body={buttonFavoriteTraining} rowEditor style={{ minWidth: "250px" }} />
                        </DataTable>
                      </div>
                    </CardBody>
                  </Card>
                </Col>

                <Modal isOpen={this.state.favoriteModal} toggle={this.changeFavoriteModalState} style={{ marginTop: "3rem", width: "50%" }}>
                  <ModalHeader>{this.state.selectedTraining?.isFavorite ? t("TRAINING.CONFIRM_UNMARK_FROM_FAVORITE") : t("TRAINING.CONFIRM_MARK_AS_FAVORITE")}</ModalHeader>
                  <ModalFooter>
                    <Button className="mb-2 me-2   " color="secondary" onClick={this.changeFavoriteModalState}>
                      {t("LEARNINGPATH.NO_BUTTON")}
                    </Button>
                    <Button className="mb-2 me-2  " color="primary" onClick={this.state.selectedTraining?.isFavorite ? this.confirmUnmarkFromFavorite : this.confirmMarkAsFavorite}>
                      {t("LEARNINGPATH.YES_BUTTON")}
                    </Button>
                  </ModalFooter>
                </Modal>
              </Row>
            </div>
          </CSSTransition>
        </TransitionGroup>
      </Fragment>
    );
  }
}
export default withTranslation()(TrainingList);
