import React, { Fragment, Component } from "react";
import { withTranslation } from "react-i18next";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import validator from "validator";
import { VerticalTimeline, VerticalTimelineElement } from "react-vertical-timeline-component";
import { Col, Row, Button, Card, CardBody, CardFooter, Modal, ModalHeader, ModalBody, ModalFooter, CardTitle, Input, FormGroup, Label } from "reactstrap";
import { faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import PerfectScrollbar from "react-perfect-scrollbar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FilterMatchMode } from "primereact/api";
import { classNames } from "primereact/utils";
import "../LearningPath/style.css";
import "../../Metadata/Attribute/DataTable.css";
import { TriStateCheckbox } from "primereact/tristatecheckbox";
import Select from "react-select";
import LearningPathService from "../../../service/LearningPathService";
import LearningPathItemService from "../../../service/LearningPathItemService";
import LearningObjectService from "../../../service/LearningObjectService";
import LearningObjectAttributeService from "../../../service/LearningObjectAttributeService";
import LearningObjectMaterialService from "../../../service/LearningObjectMaterialService";
import languages from "../../../models/Languages";
import Flag from "react-flagkit";
import tokenService from "../../../service/tokenService";
import CreateUpdateModal from "../LearningObject/MainModal";
import { Tabs, Tab } from "react-bootstrap";

class ModuleTabContent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedLearningPath: props.learningPath,
      learningPathItems: props.learningPathItems,
      trainings: [],
      dropdownLearningPaths: [],
      selectedDropdownLearningPath: null,
      deletedLearninPathItems: props.deletedLearninPathItems,
      selectedTab: "learningObjectTab",
      learningObjects: [],
      filteredLearningObjects: [],
      learningObjectAttributes: [],
      learningObjectMaterials: [],
      selectedLearningObjectId: null,
      learningObjectModal: false,
      selectedLearningPathItem: "",
      loggedUser: tokenService.getUser(),
      editedLearningPathItem: "",
      filterLearningObjectTemplate: {
        name: { value: null, matchMode: FilterMatchMode.CONTAINS },
        topic: { value: null, matchMode: FilterMatchMode.CONTAINS },
        category: { value: null, matchMode: FilterMatchMode.CONTAINS },
        attribute: { value: null, matchMode: FilterMatchMode.CONTAINS },
        isPublic: { value: null, matchMode: FilterMatchMode.EQUALS },
      },
      error: {},
    };
  }

  componentDidMount() {
    this.getLearningObjects();
    this.getTraining();
    this.getDropdownLearningPaths();

    var pos = this.state.learningPathItems.length + 1;
    var learningPathItem = {
      position: pos,
      learningPathId: this.state.selectedLearningPath.id,
      attributeIds: [],
      materialIds: [],
      createdBy: this.state.loggedUser.id,
    };
    this.setState({
      selectedLearningPathItem: learningPathItem,
    });
  }

  getTraining() {
    return LearningPathService.getTraining().then((response) => {
      var trainings = this.distinctTrainings(response.data);

      this.setState({
        trainings: trainings,
      });
    });
  }

  distinctTrainings(trainings) {
    trainings = trainings.filter(
      (t) =>
        t.id !== this.state.selectedLearningPath.id &&
        t.parentId !== this.state.selectedLearningPath.id &&
        !this.state.learningPathItems.filter((i) => i.id !== this.state.selectedLearningPathItem.id).find((i) => i.trainingId === t.id)
    );

    const groupedData = trainings.reduce((acc, curr) => {
      if (!acc[curr.name] || acc[curr.name].version < curr.version) {
        acc[curr.name] = curr;
      }
      return acc;
    }, {});

    trainings = Object.values(groupedData);

    return trainings;
  }

  getDropdownLearningPaths() {
    return LearningPathService.getAllLearningPaths().then((response) => {
      this.setState({
        dropdownLearningPaths: response.data.filter((t) => t.id !== this.state.selectedLearningPath.id && t.parentId !== this.state.selectedLearningPath.id),
      });
    });
  }

  getLearningObjects(exceptLearningObjectId) {
    return LearningObjectService.getAllLearningObjects().then((response) => {
      var learningObjects = response.data
        .map(({ id, translations, topicNames, categoryNames, attributeNames, isPublic, isActive }) => ({
          name: translations.find((obj) => obj.language === this.state.selectedLearningPath.language)?.name,
          isSelected: exceptLearningObjectId === id ? true : null,
          id: id,
          isPublic: isPublic,
          isActive: isActive,
          translations: translations,
          topic: topicNames?.find((obj) => obj.language === this.state.selectedLearningPath.language)?.name,
          category: categoryNames?.find((obj) => obj.language === this.state.selectedLearningPath.language)?.name,
          attribute: attributeNames?.find((obj) => obj.language === this.state.selectedLearningPath.language)?.name,
        }))
        .filter((l) => l.name);
      this.setState({
        learningObjects: learningObjects,
        filteredLearningObjects: exceptLearningObjectId
          ? learningObjects.filter((l) => exceptLearningObjectId === l.id)
          : learningObjects.filter((l) => l.isActive && !this.state.learningPathItems.find((i) => i.learningObjectId === l.id)),
      });
    });
  }

  getLearningObjectAttributes(learningObjectId) {
    return LearningObjectAttributeService.getByLearningObjectId(learningObjectId).then((response) => {
      this.setState({
        learningObjectAttributes: response.data.filter((a) => a.language === this.state.selectedLearningPath.language),
      });
    });
  }
  getLearningObjectMaterials(learningObjectId) {
    return LearningObjectMaterialService.getByLearninObjectId(learningObjectId).then((response) => {
      this.setState({
        learningObjectMaterials: response.data
          .filter((a) => a.language === this.state.selectedLearningPath.language)
          .map(({ id, link, fileName }) => ({
            id: id,
            displayName: fileName ? fileName : link,
          })),
      });
    });
  }

  onCancelUpdate = () => {
    let pos = this.state.learningPathItems.length + 1;
    let learningPathItem = this.getEmptyLearningPathItem(pos);
    let filteredLearningObjects = this.state.learningObjects.filter((l) => !this.state.learningPathItems.find((i) => i.learningObjectId === l.id));
    this.setState(
      {
        editedLearningPathItem: "",
        selectedLearningPathItem: learningPathItem,
        selectedDropdownLearningPath: null,
        filteredLearningObjects: this.props.readOnly ? [] : filteredLearningObjects,
        learningObjectAttributes: [],
        learningObjectMaterials: [],
      },
      () => {
        this.getTraining();
      }
    );
  };

  changeLearningPathItemLearningObject = () => {
    let selectedLearningObjects = this.state.filteredLearningObjects.filter((lo) => lo.isSelected);
    if (selectedLearningObjects.length === 0) return;

    let comment = this.state.selectedLearningPathItem.comment;
    var learningPathItems = this.state.learningPathItems;
    var pos = this.state.learningPathItems.length + 1;
    let promises = [];
    if (selectedLearningObjects.length > 1) {
      selectedLearningObjects.forEach((learningObject, index) => {
        let position = pos + index;
        let learningPathItem = this.getEmptyLearningPathItem(position);
        learningPathItem.learningObjectId = learningObject.id;
        learningPathItem.learningObjectNames = learningObject.translations;
        learningPathItem.comment = comment;

        this.InitDisplayNameAndTab(learningPathItem);

        var attributePromise = this.getLearningObjectAttributes(learningObject.id).then(() => {
          learningPathItem.attributeIds = this.state.learningObjectAttributes.map(({ id }) => id);
        });

        var materialPromise = this.getLearningObjectMaterials(learningObject.id).then(() => {
          learningPathItem.materialIds = this.state.learningObjectMaterials.map(({ id }) => id);

          learningPathItems.push(learningPathItem);
        });
        promises.push(attributePromise);
        promises.push(materialPromise);
      });
    } else {
      var selectedLearningObject = selectedLearningObjects[0];

      var selectedLearningPathItem = this.state.selectedLearningPathItem;
      selectedLearningPathItem.link = "";
      selectedLearningPathItem.placeholder = "";
      selectedLearningPathItem.trainingId = null;

      selectedLearningPathItem.learningObjectId = selectedLearningObject.id;
      selectedLearningPathItem.learningObjectNames = selectedLearningObject.translations;
      this.InitDisplayNameAndTab(selectedLearningPathItem);
      if (this.state.editedLearningPathItem) {
        let index = learningPathItems.indexOf(this.state.editedLearningPathItem);
        learningPathItems[index] = selectedLearningPathItem;
        learningPathItems = learningPathItems.sort((a, b) => (a.position > b.position ? 1 : b.position > a.position ? -1 : 0));
      } else {
        learningPathItems.push(selectedLearningPathItem);
      }
    }

    Promise.all(promises).then(() => {
      learningPathItems = learningPathItems.sort((a, b) => (a.position > b.position ? 1 : b.position > a.position ? -1 : 0));

      this.setState({ learningPathItems: learningPathItems });
      this.props.onLearningPathItemChange(learningPathItems);

      let filteredLearningObjects = this.state.learningObjects.filter((l) => !learningPathItems.find((i) => i.learningObjectId === l.id));
      pos = learningPathItems.length + 1;
      let learningPathItem = this.getEmptyLearningPathItem(pos);
      this.setState({
        filteredLearningObjects: this.props.readOnly ? [] : filteredLearningObjects,
        editedLearningPathItem: "",
        selectedLearningPathItem: learningPathItem,
        selectedDropdownLearningPath: null,
      });
    });
  };

  changeLearningPathItemLearningPath = () => {
    if (this.state.selectedDropdownLearningPath) {
      LearningPathItemService.getByLearningPathId(this.state.selectedDropdownLearningPath.id)
        .then((response) => {
          let learningPathItems = this.state.learningPathItems;
          response.data.forEach((learningPathItem) => {
            learningPathItem.id = null;
            learningPathItem.learningPathId = this.state.selectedLearningPath.id;
            learningPathItem.position = learningPathItems.length + 1;
            learningPathItem.comment = "Copy from " + this.state.selectedDropdownLearningPath.name;
            this.InitDisplayNameAndTab(learningPathItem);

            learningPathItems.push(learningPathItem);
          });
          this.setState({
            learningPathItems: learningPathItems,
            selectedDropdownLearningPath: null,
          });
          this.props.onLearningPathItemChange(learningPathItems);
        })
        .catch((e) => {
          console.log(e);
        });
    }
  };

  changeLearningPathItemTraining = () => {
    if (!this.state.selectedLearningPathItem.trainingId) return;

    var selectedLearningPathItem = this.state.selectedLearningPathItem;
    selectedLearningPathItem.learningObjectId = null;
    selectedLearningPathItem.learningObjectNames = "";
    selectedLearningPathItem.attributeIds = [];
    selectedLearningPathItem.materialIds = [];

    selectedLearningPathItem.link = "";

    selectedLearningPathItem.placeholder = "";

    var learningPathItems = this.state.learningPathItems;
    this.InitDisplayNameAndTab(selectedLearningPathItem);
    if (this.state.editedLearningPathItem) {
      let index = learningPathItems.indexOf(this.state.editedLearningPathItem);
      learningPathItems[index] = selectedLearningPathItem;
      learningPathItems = learningPathItems.sort((a, b) => (a.position > b.position ? 1 : b.position > a.position ? -1 : 0));
    } else {
      learningPathItems.push(selectedLearningPathItem);
    }

    this.getTraining();

    let filteredLearningObjects = this.state.learningObjects.filter((l) => !learningPathItems.find((i) => i.learningObjectId === l.id));
    let pos = learningPathItems.length + 1;
    let learningPathItem = this.getEmptyLearningPathItem(pos);
    this.setState({
      learningPathItems: learningPathItems,
      filteredLearningObjects: this.props.readOnly ? [] : filteredLearningObjects,
      editedLearningPathItem: "",
      selectedLearningPathItem: learningPathItem,
      selectedDropdownLearningPath: null,
    });
    this.props.onLearningPathItemChange(learningPathItems);
  };

  changeLearningPathItemLink = () => {
    if (!this.state.selectedLearningPathItem.link) return;

    var selectedLearningPathItem = this.state.selectedLearningPathItem;
    selectedLearningPathItem.learningObjectId = null;
    selectedLearningPathItem.learningObjectNames = "";
    selectedLearningPathItem.attributeIds = [];
    selectedLearningPathItem.materialIds = [];

    selectedLearningPathItem.trainingId = null;
    selectedLearningPathItem.trainingName = "";

    selectedLearningPathItem.placeholder = "";

    if (selectedLearningPathItem.link) {
      var error = this.state.error;
      error.link = validator.isURL(selectedLearningPathItem.link) ? "" : "Link is invalid";
      this.setState({ error: error });

      if (error.link) return;
    }

    var learningPathItems = this.state.learningPathItems;
    this.InitDisplayNameAndTab(selectedLearningPathItem);
    if (this.state.editedLearningPathItem) {
      let index = learningPathItems.indexOf(this.state.editedLearningPathItem);
      learningPathItems[index] = selectedLearningPathItem;
      learningPathItems = learningPathItems.sort((a, b) => (a.position > b.position ? 1 : b.position > a.position ? -1 : 0));
    } else {
      learningPathItems.push(selectedLearningPathItem);
    }

    let filteredLearningObjects = this.state.learningObjects.filter((l) => !learningPathItems.find((i) => i.learningObjectId === l.id));
    let pos = learningPathItems.length + 1;
    let learningPathItem = this.getEmptyLearningPathItem(pos);
    this.setState({
      learningPathItems: learningPathItems,
      filteredLearningObjects: this.props.readOnly ? [] : filteredLearningObjects,
      editedLearningPathItem: "",
      selectedLearningPathItem: learningPathItem,
      selectedDropdownLearningPath: null,
    });
    this.props.onLearningPathItemChange(learningPathItems);
  };

  changeLearningPathItemPlaceholder = () => {
    if (!this.state.selectedLearningPathItem.placeholder) return;

    var selectedLearningPathItem = this.state.selectedLearningPathItem;
    selectedLearningPathItem.learningObjectId = null;
    selectedLearningPathItem.learningObjectNames = "";
    selectedLearningPathItem.attributeIds = [];
    selectedLearningPathItem.materialIds = [];

    selectedLearningPathItem.trainingId = null;
    selectedLearningPathItem.trainingName = "";

    selectedLearningPathItem.link = "";

    var learningPathItems = this.state.learningPathItems;
    this.InitDisplayNameAndTab(selectedLearningPathItem);
    if (this.state.editedLearningPathItem) {
      let index = learningPathItems.indexOf(this.state.editedLearningPathItem);
      learningPathItems[index] = selectedLearningPathItem;
      learningPathItems = learningPathItems.sort((a, b) => (a.position > b.position ? 1 : b.position > a.position ? -1 : 0));
    } else {
      learningPathItems.push(selectedLearningPathItem);
    }

    let filteredLearningObjects = this.state.learningObjects.filter((l) => !learningPathItems.find((i) => i.learningObjectId === l.id));
    let pos = learningPathItems.length + 1;
    let learningPathItem = this.getEmptyLearningPathItem(pos);
    this.setState({
      learningPathItems: learningPathItems,
      filteredLearningObjects: this.props.readOnly ? [] : filteredLearningObjects,
      editedLearningPathItem: "",
      selectedLearningPathItem: learningPathItem,
      selectedDropdownLearningPath: null,
    });
    this.props.onLearningPathItemChange(learningPathItems);
  };

  getEmptyLearningPathItem = (pos) => {
    return {
      position: pos,
      placeholder: "",
      link: "",
      trainingId: null,
      trainingName: "",
      learningObjectId: null,
      learningObjectNames: "",
      attributeIds: [],
      materialIds: [],
      comment: "",
      learningPathId: this.state.selectedLearningPath.id,
      createdBy: this.state.loggedUser.id,
    };
  };

  InitDisplayNameAndTab(learningPathItem) {
    if (learningPathItem.learningObjectId) {
      learningPathItem.displayName = learningPathItem.learningObjectNames.find((obj) => obj.language === this.state.selectedLearningPath.language)?.name;
      learningPathItem.tab = "learningObjectTab";
    } else if (learningPathItem.trainingId) {
      learningPathItem.displayName = learningPathItem.trainingName;
      learningPathItem.tab = "trainingTab";
    } else if (learningPathItem.placeholder) {
      learningPathItem.displayName = learningPathItem.placeholder;
      learningPathItem.tab = "placeholderTab";
    } else {
      learningPathItem.displayName = learningPathItem.link;
      learningPathItem.tab = "linkTab";
    }
  }

  onChangeLearninObjectAttribute = (e) => {
    var selectedLPI = this.state.selectedLearningPathItem;
    selectedLPI.attributeIds = e.map(({ id }) => id);
    this.setState({ selectedLearningPathItem: selectedLPI });
  };

  onChangeLearninObjectMaterial = (e) => {
    var selectedLPI = this.state.selectedLearningPathItem;
    selectedLPI.materialIds = e.map(({ id }) => id);
    this.setState({ selectedLearningPathItem: selectedLPI });
  };

  onChangeTraining = (e) => {
    var selectedLPI = this.state.selectedLearningPathItem;
    selectedLPI.trainingId = e.id;
    selectedLPI.trainingName = this.state.trainings.find((t) => t.id === e.id).name;
    this.setState({ selectedLearningPathItem: selectedLPI });
  };

  onChangeDropdownLearningPath = (e) => {
    this.setState({ selectedDropdownLearningPath: e });
  };

  changeLink = (e) => {
    var selectedLPI = this.state.selectedLearningPathItem;
    selectedLPI.link = e.target.value;
    this.setState({ selectedLearningPathItem: selectedLPI });
  };

  changePlaceholder = (e) => {
    var selectedLPI = this.state.selectedLearningPathItem;
    selectedLPI.placeholder = e.target.value;
    this.setState({ selectedLearningPathItem: selectedLPI });
  };

  changeComment = (e) => {
    var selectedLPI = this.state.selectedLearningPathItem;
    selectedLPI.comment = e.target.value;
    this.setState({ selectedLearningPathItem: selectedLPI });
  };

  handleTabChange = (key) => {
    this.setState({ selectedTab: key });
  };

  onChangeLearningPathItem = (learningPathItem) => {
    if (learningPathItem.tab === "learningObjectTab") {
      this.getLearningObjects(learningPathItem.learningObjectId).then(() => {
        this.getLearningObjectAttributes(learningPathItem.learningObjectId);
        this.getLearningObjectMaterials(learningPathItem.learningObjectId);

        this.setState({
          editedLearningPathItem: learningPathItem,
          selectedTab: learningPathItem.tab,
          selectedLearningPathItem: { ...learningPathItem },
        });
      });
    } else {
      this.setState(
        {
          editedLearningPathItem: learningPathItem,
          selectedTab: learningPathItem.tab,
          selectedLearningPathItem: { ...learningPathItem },
        },
        () => {
          this.getTraining();
        }
      );
    }
  };

  onDeleteLearningPathItem = (e, learningPathItem) => {
    e.preventDefault();
    if (learningPathItem === this.state.editedLearningPathItem) {
      var p = this.state.learningPathItems.length + 1;
      var emptyLearningPathItem = this.getEmptyLearningPathItem(p);
      this.setState({
        editedLearningPathItem: "",
        selectedLearningPathItem: emptyLearningPathItem,
      });
    }
    var deletedLearninPathItems = this.state.deletedLearninPathItems;
    if (learningPathItem.id) {
      deletedLearninPathItems.push(learningPathItem.id);
    }
    var learningPathItems = [];
    var pos = 1;
    this.state.learningPathItems.forEach((lpi) => {
      if (lpi !== learningPathItem) {
        lpi.position = pos;
        learningPathItems.push(lpi);
        pos++;
      }
    });

    let filteredLearningObjects = this.state.learningObjects.filter((l) => !learningPathItems.find((i) => i.learningObjectId === l.id));
    if (learningPathItem.learningObjectId) {
      var deletedLearningObjet = filteredLearningObjects.find((lo) => lo.id === learningPathItem.learningObjectId);
      if (deletedLearningObjet) {
        deletedLearningObjet.isSelected = null;
      }
    }
    this.setState({
      filteredLearningObjects: this.props.readOnly ? [] : filteredLearningObjects,
      deletedLearninPathItems: deletedLearninPathItems,
      learningPathItems: learningPathItems,
    });
    this.props.onLearningPathItemChange(learningPathItems);
  };

  toggleIsSelected = (rowData) => {
    if (this.props.readOnly) return;

    let filteredLearningObjects = this.state.filteredLearningObjects;
    let selectedRow = this.state.filteredLearningObjects.find((i) => i.id === rowData.id);
    selectedRow.isSelected = rowData.isSelected ? null : true;
    this.setState({ filteredLearningObjects: filteredLearningObjects });
    let selectedLearningObjects = filteredLearningObjects.filter((lo) => lo.isSelected);
    if (selectedLearningObjects.length === 1) {
      let selected = selectedLearningObjects[0];
      this.getLearningObjectAttributes(selected.id).then(() => {
        let attributeIds = this.state.learningObjectAttributes.map(({ id }) => id);
        this.setState((prevState) => ({
          selectedLearningPathItem: {
            ...prevState.selectedLearningPathItem,
            attributeIds: attributeIds,
          },
        }));
      });

      this.getLearningObjectMaterials(selected.id).then(() => {
        let materialIds = this.state.learningObjectMaterials.map(({ id }) => id);
        this.setState((prevState) => ({
          selectedLearningPathItem: {
            ...prevState.selectedLearningPathItem,
            materialIds: materialIds,
          },
        }));
      });
    } else {
      this.setState({
        learningObjectMaterials: [],
        learningObjectAttributes: [],
      });
    }
  };

  openLearningPath = (id) => {
    this.setState({ selectedLearningObjectId: id }, () => {
      this.changeLearningObjectModalState();
    });
  };

  changeLearningObjectModalState = () => {
    this.setState({
      learningObjectModal: !this.state.learningObjectModal,
    });
  };

  render() {
    const { t, readOnly } = this.props;

    const renderListItem = (tab) => {
      if (tab === "learningObjectTab") {
        return <i className="badge badge-dot badge-dot-xl bg-learning-object"> </i>;
      } else if (tab === "trainingTab") {
        return <i className="badge badge-dot badge-dot-xl bg-warning"> </i>;
      } else if (tab === "linkTab") {
        return <i className="badge badge-dot badge-dot-xl bg-success"> </i>;
      } else {
        return <i className="badge badge-dot badge-dot-xl bg-danger"> </i>;
      }
    };

    const buttonShowLearningObject = (rowData) => {
      return (
        <Button className="me-2" size="sm" outline color="primary" onClick={() => this.openLearningPath(rowData.id)}>
          {t("LEARNINGPATH.OPEN_BUTTON")}
        </Button>
      );
    };

    const formatIsSelectedCheckBox = (rowData) => {
      return <TriStateCheckbox value={rowData.isSelected} onChange={() => this.toggleIsSelected(rowData)} />;
    };

    const dragItem = {};
    const dragOverItem = {};

    const onDragStart = (e, index) => {
      dragItem.current = index;
      // e.preventDefault();
      // e.stopPropagation();
    };

    const onDragEnter = (e, index) => {
      dragOverItem.current = index;
      // e.preventDefault();
      // e.stopPropagation();
    };

    const handleSort = (e) => {
      let learningPathItems = this.state.learningPathItems;

      //remove and save the dragged item content
      const draggedItemContent = learningPathItems.splice(dragItem.current, 1)[0];

      //switch the position
      learningPathItems.splice(dragOverItem.current, 0, draggedItemContent);

      //reset the position ref
      dragItem.current = null;
      dragOverItem.current = null;

      let pos = 1;
      learningPathItems.forEach((learningPathItem) => {
        learningPathItem.position = pos;
        pos++;
      });
      //update the actual array
      this.setState({ learningPathItems: learningPathItems });
      this.props.onLearningPathItemChange(learningPathItems);
      // e.preventDefault();
      // e.stopPropagation();
    };

    const onDragOver = (e) => {
      e.preventDefault();
      // e.stopPropagation();
    };

    const listLearningPathItems = this.state.learningPathItems.map((learningPathItem, index) => (
      <VerticalTimelineElement key={index} className="vertical-timeline-item" icon={renderListItem(learningPathItem.tab)}>
        <div
          className="widget-content p-0"
          draggable={!readOnly}
          onDragStart={(e) => onDragStart(e, index)}
          onDragEnter={(e) => onDragEnter(e, index)}
          onDragEnd={(e) => handleSort(e)}
          onDragOver={onDragOver}
        >
          <div className="widget-content-wrapper">
            <div className="widget-content-left" onClick={() => this.onChangeLearningPathItem(learningPathItem)}>
              <div className="widget-heading ">
                <h4 className="timeline-title wordwrap">
                  {learningPathItem.displayName +
                    (learningPathItem.learningObjectId && !this.state.learningObjects.find((lo) => lo.id === learningPathItem.learningObjectId)?.isActive ? " (inactive)" : "")}
                </h4>
              </div>
              <p className="wordwrap">{learningPathItem.comment}</p>
            </div>
            {!readOnly && (
              <div className="widget-content-right" style={{ padding: "0px 30px 0px 0px" }}>
                <Button className="border-0 btn-transition" style={{ padding: "0px" }} onClick={(e) => this.onDeleteLearningPathItem(e, learningPathItem)} outline color="danger">
                  <FontAwesomeIcon icon={faTrashAlt} />
                </Button>
              </div>
            )}
          </div>
        </div>
      </VerticalTimelineElement>
    ));

    const formatIsPublicCheckBox = (rowData) => {
      return (
        <i
          className={classNames("pi", {
            "true-icon pi-check-circle": rowData.isPublic,
            "false-icon pi-times-circle": !rowData.isPublic,
          })}
        ></i>
      );
    };

    const verifiedRowFilterTemplate = (options) => {
      return <TriStateCheckbox value={options.value} onChange={(e) => options.filterApplyCallback(e.value)} />;
    };

    return (
      <Fragment>
        {this.state.learningObjectModal ? (
          <CreateUpdateModal
            changeModalState={this.changeLearningObjectModalState}
            learningObjectId={this.state.selectedLearningObjectId}
            defaultLang={this.state.selectedLearningPath.language}
            readOnly={true}
          />
        ) : (
          ""
        )}

        <Row>
          <Col md="8" style={{ height: "477px" }}>
            <Row style={{ marginLeft: "-4px" }}>
              <div className="container">
                <ul className="nav nav-pills" style={{ marginBottom: "0", marginLeft: "10px" }} id="myTab" role="tablist">
                  <li className="nav-item">
                    <button className={`nav-link ${this.state.selectedTab === "learningObjectTab" ? "active" : ""}`} onClick={() => this.handleTabChange("learningObjectTab")}>
                      {" "}
                      <i className="badge badge-dot badge-dot-xl bg-learning-object " style={{ marginLeft: "0px" }}>
                        {" "}
                      </i>{" "}
                      <span className="center-tab"></span>
                      {t("LEARNINGPATH.LEARNING_OBJECT")}
                    </button>
                  </li>
                  {!readOnly && (
                    <li className="nav-item">
                      <button className={`nav-link ${this.state.selectedTab === "learningPathTab" ? "active" : ""}`} onClick={() => this.handleTabChange("learningPathTab")}>
                        {t("LEARNINGPATH.LEARNINGPATH")}
                      </button>
                    </li>
                  )}
                  <li className="nav-item">
                    <button className={`nav-link ${this.state.selectedTab === "trainingTab" ? "active" : ""}`} onClick={() => this.handleTabChange("trainingTab")}>
                      <i className="badge badge-dot badge-dot-xl bg-warning" style={{ marginLeft: "0px" }}>
                        {" "}
                      </i>{" "}
                      <span className="center-tab"></span>
                      {t("LEARNINGPATH.TRAINING")}
                    </button>
                  </li>
                  <li className="nav-item">
                    <button className={`nav-link ${this.state.selectedTab === "linkTab" ? "active" : ""}`} onClick={() => this.handleTabChange("linkTab")}>
                      {" "}
                      <i className="badge badge-dot badge-dot-xl bg-success" style={{ marginLeft: "0px" }}>
                        {" "}
                      </i>{" "}
                      <span className="center-tab"></span>
                      {t("LEARNINGPATH.LINK")}
                    </button>
                  </li>
                  <li className="nav-item">
                    <button className={`nav-link ${this.state.selectedTab === "placeholderTab" ? "active" : ""}`} onClick={() => this.handleTabChange("placeholderTab")}>
                      {" "}
                      <i className="badge badge-dot badge-dot-xl bg-danger" style={{ marginLeft: "0px" }}>
                        {" "}
                      </i>{" "}
                      <span className="center-tab"></span>
                      {t("LEARNINGPATH.PLACEHOLDER")}
                    </button>
                  </li>
                </ul>

                <div className="tab-content">
                  <div className={`tab-pane fade ${this.state.selectedTab === "learningObjectTab" ? "show active" : ""}`} role="tabpanel">
                    <Card className="main-card">
                      <CardBody style={{ padding: "0.3rem 0.7rem" }}>
                        <div className="scroll-area" style={{ height: "360px" }}>
                          <PerfectScrollbar>
                            <Row>
                              <Col>
                                <div className="card p-fluid">
                                  <DataTable
                                    value={this.state.filteredLearningObjects}
                                    paginator
                                    className="p-datatable-customers"
                                    rows={4}
                                    dataKey="id"
                                    filterDisplay="row"
                                    responsiveLayout="scroll"
                                    filters={this.state.filterLearningObjectTemplate}
                                    emptyMessage={t("LEARNINGPATH.LEARNING_OBJECT_TABLE_MESSAGE")}
                                  >
                                    <Column field="isSelected" dataType="boolean" body={formatIsSelectedCheckBox} />
                                    <Column header={t("LEARNINGPATH.NAME_COLUMN_NAME")} field="name" filter sortable />
                                    <Column header={t("LEARNINGPATH.TOPIC_COLUMN_NAME")} field="topic" filter sortable />
                                    <Column header={t("LEARNINGPATH.CATEGORY_COLUMN_NAME")} field="category" filter sortable />
                                    <Column header={t("LEARNINGPATH.ATTRIBUTE_COLUMN_NAME")} field="attribute" filter sortable />
                                    <Column
                                      field="isPublic"
                                      header={t("LEARNING_OBJECT.IS_PUBLIC_COLUMN_NAME")}
                                      dataType="boolean"
                                      sortable
                                      body={formatIsPublicCheckBox}
                                      filter
                                      filterElement={verifiedRowFilterTemplate}
                                    />
                                    <Column body={buttonShowLearningObject} rowEditor />
                                  </DataTable>
                                </div>
                              </Col>
                            </Row>
                            <Row style={{ minHeight: "9rem", marginTop: "2px" }}>
                              <Col>
                                <Row>
                                  <Col md="6">
                                    <div className="md-1" style={{ zIndex: "2", position: "relative" }}>
                                      <Label for="name"> {t("LEARNINGPATH.LEARNING_OBJECT_ATTRIBUTE") + ":"}</Label>
                                      <Select
                                        options={this.state.learningObjectAttributes}
                                        value={this.state.learningObjectAttributes.filter((l) => this.state.selectedLearningPathItem.attributeIds?.find((id) => l.id === id)) || ""}
                                        onChange={this.onChangeLearninObjectAttribute}
                                        isDisabled={readOnly}
                                        isMulti
                                        getOptionLabel={(option) => option.attributeName + "(" + option.value + ")"}
                                        getOptionValue={(option) => option.id}
                                      />
                                    </div>
                                  </Col>
                                  <Col md="6">
                                    <div className="md-1" style={{ zIndex: "2", position: "relative" }}>
                                      <Label for="name"> {t("LEARNINGPATH.LEARNING_OBJECT_MATERIAL") + ":"}</Label>
                                      <Select
                                        options={this.state.learningObjectMaterials}
                                        value={this.state.learningObjectMaterials.filter((l) => this.state.selectedLearningPathItem.materialIds.find((id) => l.id === id)) || ""}
                                        onChange={this.onChangeLearninObjectMaterial}
                                        isDisabled={readOnly}
                                        isMulti
                                        getOptionLabel={(option) => option.displayName}
                                        getOptionValue={(option) => option.id}
                                      />
                                    </div>
                                  </Col>
                                </Row>
                              </Col>
                            </Row>
                          </PerfectScrollbar>
                        </div>
                      </CardBody>

                      <CardFooter className="d-block text-end" style={{ marginTop: "0.25rem" }}>
                        <Row>
                          <Col md="2">
                            <Label for="name"> {t("LEARNINGPATH.COMMENT") + ":"}</Label>
                          </Col>
                          <Col md="6">
                            <FormGroup>
                              <Input
                                type="text"
                                name="comment"
                                id="comment"
                                readOnly={readOnly}
                                onChange={this.changeComment}
                                value={this.state.selectedLearningPathItem.comment}
                                className="form-control"
                              />
                            </FormGroup>
                          </Col>
                          <Col md="4">
                            {this.state.editedLearningPathItem ? (
                              <Button className="me-2" color="secondary" onClick={() => this.onCancelUpdate()}>
                                {t("LEARNINGPATH.CANCEL_BUTTON")}
                              </Button>
                            ) : (
                              ""
                            )}
                            {!readOnly && (
                              <Button className="me-2" outline color="primary" onClick={() => this.changeLearningPathItemLearningObject()}>
                                {this.state.editedLearningPathItem ? t("LEARNINGPATH.UPDATE_BUTTON") : t("LEARNINGPATH.ADD_BUTTON")}
                              </Button>
                            )}
                          </Col>
                        </Row>
                      </CardFooter>
                    </Card>
                  </div>
                  {!readOnly && (
                    <div className={`tab-pane fade ${this.state.selectedTab === "learningPathTab" ? "show active" : ""}`} role="tabpanel">
                      <Card className="card-height main-card">
                        <CardBody style={{ padding: "0.3rem 0.7rem" }}>
                          <div className="scroll-area" style={{ height: "360px" }}>
                            <PerfectScrollbar>
                              <FormGroup>
                                <Label for="learningPathDropdown"> {t("LEARNINGPATH.LEARNINGPATH") + ":"}</Label>
                                <Select
                                  id="learningPathDropdown"
                                  options={this.state.dropdownLearningPaths}
                                  value={this.state.dropdownLearningPaths.find((t) => t.id === this.state.selectedDropdownLearningPath?.id) || ""}
                                  onChange={this.onChangeDropdownLearningPath}
                                  isDisabled={readOnly}
                                  getOptionLabel={(option) => option.name + " (" + option.version + ")"}
                                  getOptionValue={(option) => option.id}
                                />
                              </FormGroup>
                            </PerfectScrollbar>
                          </div>
                        </CardBody>
                        <CardFooter className="d-block text-end" style={{ marginTop: "0.25rem" }}>
                          <Row>
                            <Col md="2"></Col>
                            <Col md="6"></Col>
                            <Col md="4">
                              <Button className="me-2" outline color="primary" onClick={() => this.changeLearningPathItemLearningPath()}>
                                {t("LEARNINGPATH.ADD_BUTTON")}
                              </Button>
                            </Col>
                          </Row>
                        </CardFooter>
                      </Card>
                    </div>
                  )}
                  <div className={`tab-pane fade ${this.state.selectedTab === "trainingTab" ? "show active" : ""}`} role="tabpanel">
                    <Card className="card-height main-card">
                      <CardBody style={{ padding: "0.3rem 0.7rem" }}>
                        <div className="scroll-area" style={{ height: "360px" }}>
                          <PerfectScrollbar>
                            <FormGroup>
                              <Label for="name"> {t("LEARNINGPATH.TRAINING") + ":"}</Label>
                              <Select
                                options={this.state.trainings}
                                value={this.state.trainings.find((t) => t.id === this.state.selectedLearningPathItem?.trainingId) || ""}
                                onChange={this.onChangeTraining}
                                isDisabled={readOnly}
                                getOptionLabel={(option) => option.name + " (" + option.version + ")"}
                                getOptionValue={(option) => option.id}
                              />
                            </FormGroup>
                          </PerfectScrollbar>
                        </div>
                      </CardBody>
                      <CardFooter className="d-block text-end" style={{ marginTop: "0.25rem" }}>
                        <Row>
                          <Col md="2">
                            <Label for="name"> {t("LEARNINGPATH.COMMENT") + ":"}</Label>
                          </Col>
                          <Col md="6">
                            <FormGroup>
                              <Input
                                type="text"
                                name="comment"
                                id="comment"
                                readOnly={readOnly}
                                onChange={this.changeComment}
                                value={this.state.selectedLearningPathItem.comment}
                                className="form-control"
                              />
                            </FormGroup>
                          </Col>
                          <Col md="4">
                            {this.state.editedLearningPathItem ? (
                              <Button className="me-2" color="secondary" onClick={() => this.onCancelUpdate()}>
                                {t("LEARNINGPATH.CANCEL_BUTTON")}
                              </Button>
                            ) : (
                              ""
                            )}
                            {!readOnly && (
                              <Button className="me-2" outline color="primary" onClick={() => this.changeLearningPathItemTraining()}>
                                {this.state.editedLearningPathItem ? t("LEARNINGPATH.UPDATE_BUTTON") : t("LEARNINGPATH.ADD_BUTTON")}
                              </Button>
                            )}
                          </Col>
                        </Row>
                      </CardFooter>
                    </Card>
                  </div>
                  <div className={`tab-pane fade ${this.state.selectedTab === "linkTab" ? "show active" : ""}`} role="tabpanel">
                    <Card className="card-height main-card">
                      <CardBody style={{ padding: "0.3rem 0.7rem" }}>
                        <div className="scroll-area" style={{ height: "360px" }}>
                          <PerfectScrollbar>
                            <Label for="name"> {t("LEARNINGPATH.LINK") + ":"}</Label>
                            <FormGroup>
                              <Input
                                type="text"
                                name="link"
                                id="link"
                                readOnly={readOnly}
                                onChange={this.changeLink}
                                value={this.state.selectedLearningPathItem.link}
                                className={this.state.error?.link ? "is-invalid form-control" : "form-control"}
                              />
                              {this.state.error?.link && <span className="invalid-feedback">{this.state.error?.link}</span>}
                            </FormGroup>
                          </PerfectScrollbar>
                        </div>
                      </CardBody>
                      <CardFooter className="d-block text-end" style={{ marginTop: "0.25rem" }}>
                        <Row>
                          <Col md="2">
                            <Label for="name"> {t("LEARNINGPATH.COMMENT") + ":"}</Label>
                          </Col>
                          <Col md="6">
                            <FormGroup>
                              <Input
                                type="text"
                                name="comment"
                                id="comment"
                                readOnly={readOnly}
                                onChange={this.changeComment}
                                value={this.state.selectedLearningPathItem.comment}
                                className="form-control"
                              />
                            </FormGroup>
                          </Col>
                          <Col md="4">
                            {this.state.editedLearningPathItem ? (
                              <Button className="me-2" color="secondary" onClick={() => this.onCancelUpdate()}>
                                {t("LEARNINGPATH.CANCEL_BUTTON")}
                              </Button>
                            ) : (
                              ""
                            )}
                            {!readOnly && (
                              <Button className="me-2" outline color="primary" onClick={() => this.changeLearningPathItemLink()}>
                                {this.state.editedLearningPathItem ? t("LEARNINGPATH.UPDATE_BUTTON") : t("LEARNINGPATH.ADD_BUTTON")}
                              </Button>
                            )}
                          </Col>
                        </Row>
                      </CardFooter>
                    </Card>
                  </div>
                  <div className={`tab-pane fade ${this.state.selectedTab === "placeholderTab" ? "show active" : ""}`} role="tabpanel">
                    <Card className="card-height main-card">
                      <CardBody style={{ padding: "0.3rem 0.7rem" }}>
                        <div className="scroll-area" style={{ height: "360px" }}>
                          <PerfectScrollbar>
                            <FormGroup>
                              <Label for="name"> {t("LEARNINGPATH.PLACEHOLDER") + ":"}</Label>
                              <Input
                                type="text"
                                name="placeholder"
                                id="placeholder"
                                readOnly={readOnly}
                                onChange={this.changePlaceholder}
                                value={this.state.selectedLearningPathItem.placeholder}
                                className={this.state.error?.placeholder ? "is-invalid form-control" : "form-control"}
                              />
                              {this.state.error?.placeholder && <span className="invalid-feedback">{this.state.error?.placeholder}</span>}
                            </FormGroup>
                          </PerfectScrollbar>
                        </div>
                      </CardBody>
                      <CardFooter className="d-block text-end" style={{ marginTop: "0.25rem" }}>
                        <Row>
                          <Col md="2">
                            <Label for="name"> {t("LEARNINGPATH.COMMENT") + ":"}</Label>
                          </Col>
                          <Col md="6">
                            <FormGroup>
                              <Input
                                type="text"
                                name="comment"
                                id="comment"
                                readOnly={readOnly}
                                onChange={this.changeComment}
                                value={this.state.selectedLearningPathItem.comment}
                                className="form-control"
                              />
                            </FormGroup>
                          </Col>
                          <Col md="4">
                            {this.state.editedLearningPathItem ? (
                              <Button className="me-2" color="secondary" onClick={() => this.onCancelUpdate()}>
                                {t("LEARNINGPATH.CANCEL_BUTTON")}
                              </Button>
                            ) : (
                              ""
                            )}
                            {!readOnly && (
                              <Button className="me-2" outline color="primary" onClick={() => this.changeLearningPathItemPlaceholder()}>
                                {this.state.editedLearningPathItem ? t("LEARNINGPATH.UPDATE_BUTTON") : t("LEARNINGPATH.ADD_BUTTON")}
                              </Button>
                            )}
                          </Col>
                        </Row>
                      </CardFooter>
                    </Card>
                  </div>
                </div>
              </div>
            </Row>
          </Col>
          <Col md="4">
            <div className="header-dots justify-content-center">
              <div className="language-icon">
                <Flag className="me-3 opacity-8" country={languages.find((obj) => obj.lang === this.state.selectedLearningPath?.language)?.flag} size="30" />
                {languages.find((obj) => obj.lang === this.state.selectedLearningPath?.language)?.name}
              </div>
            </div>
            <CardBody className="items-border" style={{ padding: "0.25rem" }}>
              <CardTitle style={{ paddingLeft: "0.5rem" }}>{this.state.selectedLearningPath.name}</CardTitle>
              <div className="scroll-area" style={{ height: "25rem" }}>
                <PerfectScrollbar>
                  {this.state.learningObjects && (
                    <div>
                      <VerticalTimeline className="vertical-without-time todo-list-wrapper" layout="1-column">
                        {listLearningPathItems}
                      </VerticalTimeline>
                    </div>
                  )}
                </PerfectScrollbar>
              </div>
            </CardBody>
          </Col>
        </Row>
      </Fragment>
    );
  }
}
export default withTranslation()(ModuleTabContent);
