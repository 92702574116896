import React, { Fragment } from "react";

import { UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem } from "reactstrap";

import Flag from "react-flagkit";

import { withTranslation } from "react-i18next";
import AccountService from "../../../service/AccountService";
import languages from "../../../models/Languages";
import tokenService from "../../../service/tokenService";

class LanguageDots extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      active: false,
      country: this.country,
    };
  }

  render() {
    const { t, i18n } = this.props;

    const changeLanguage = (lang) => {
      if (i18n.language !== lang) {
        AccountService.setLanguage(lang).then(() => {
          i18n.changeLanguage(lang);
          var user = tokenService.getUser();
          if (user) {
            user.language = lang;
            tokenService.setUser(user);
          } else {
            window.location.href = "/auth/login";
          }
        });
      }
    };
    return (
      <Fragment>
        <div className="header-dots">
          <UncontrolledDropdown>
            <DropdownToggle className="p-0" color="link">
              <div className="icon-wrapper icon-wrapper-alt rounded-circle">
                <div className="icon-wrapper-bg bg-focus" />
                <div className="language-icon">
                  <Flag className="me-3 opacity-8" country={languages.find((obj) => obj.lang === this.props.i18n.language).flag} size="40" />
                </div>
              </div>
            </DropdownToggle>
            <DropdownMenu end className="rm-pointers">
              {languages.map((language, id) => (
                <DropdownItem key={id} value={language} onClick={() => changeLanguage(language.lang)}>
                  <Flag className="me-3 opacity-8" country={language.flag} />
                  {language.name}
                </DropdownItem>
              ))}
            </DropdownMenu>
          </UncontrolledDropdown>
        </div>
      </Fragment>
    );
  }
}

export default withTranslation()(LanguageDots);
