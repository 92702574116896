import api from "./api";

class CategoryService {
  getAllCategories() {
    return api.get("/Category/get-all");
  }

  getByTopicId(topicId) {
    return api.get("/Category/get-by-topic-id?topicId=" + topicId);
  }

  getCategoryById(id) {
    return api.get("/Category/get-by-id?id=" + id);
  }

  insert(newCategory) {
    return api.post("/Category/insert", newCategory);
  }

  update(updatedCategory) {
    return api.post("/Category/update", updatedCategory);
  }

  activate(id) {
    return api.post("/Category/activate?id=" + id);
  }

  deactivate(id) {
    return api.post("/Category/deactivate?id=" + id);
  }

  delete(id) {
    return api.post("/Category/delete?id=" + id);
  }
}

export default new CategoryService();
