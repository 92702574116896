class LearningPathModel {
  constructor(learningPath) {
    if (learningPath !== undefined) {
      this.id = learningPath.id;
      this.language = learningPath.language;
      this.name = learningPath.name;
      this.comment = learningPath.comment;
      this.isActive = learningPath.isActive;
      this.isPublic = learningPath.isPublic;
      this.createdDate = learningPath.createdDate;
      this.createdByUser = learningPath.createdByUser;
      this.description = learningPath.description;
      this.duration = learningPath.duration;
      this.version = learningPath.version;
      this.program = learningPath.program;
      this.requirements = learningPath.requirements;
      this.targetGroup = learningPath.targetGroup;
      this.parentName = learningPath.parentName;
      this.learningPathTypeId = learningPath.learningPathTypeId;
      this.isTraining = learningPath.isTraining;
      this.hasChildren = learningPath.hasChildren;
    }
  }
}
export default LearningPathModel;
