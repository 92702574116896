import React, { Fragment, Component } from "react";
import { Row, Col, Button, FormGroup, Label, Input, Form } from "reactstrap";
import AvatarPicker from "../../Shared/AvatarPicker";
import AccountService from "../../../service/AccountService";
import LearningPathService from "../../../service/LearningPathService";
import CompanyBox from "../../../Layout/AppHeader/Components/CompanyBox";
import tokenService from "../../../service/tokenService";
import CompanyService from "../../../service/CompanyService";
import { withTranslation } from "react-i18next";
import authService from "../../../service/authService";

class Profile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      user: null,
      companies: null,
      currentCompany: null,
    };
  }

  componentDidMount() {
    this.getMyInfo();
  }

  getMyInfo() {
    return authService
      .getMyInfo()
      .then((response) => {
        this.setState({
          user: response.data,
        });
        tokenService.setUser(response.data);
        this.getActiveCompanyByUserId(response.data.id);
      })
      .catch((e) => {
        console.log(e);
      });
  }

  getActiveCompanyByUserId(userId) {
    CompanyService.getActiveCompanyByUserId(userId)
      .then((response) => {
        this.setState({
          companies: response.data,
          currentCompany: response.data.find((c) => c.id === this.state.user.currentCompanyId),
        });
      })
      .catch((e) => {
        console.log(e);
      });
  }

  getFavorites() {
    return LearningPathService.getFavorite().then((response) => {
      tokenService.setTrainings(response.data);
    });
  }

  updateProfile = (e) => {
    e.preventDefault();

    let user = {
      firstName: this.state.user.firstName,
      lastName: this.state.user.lastName,
      photo: this.state.user.photo,
    };

    AccountService.update(user).then(() => {
      AccountService.setCurrentCompany(this.state.user.currentCompanyId).then(() => {
        let promises = [];
        tokenService.setCompany(this.state.currentCompany);
        let userInfoPromise = this.getMyInfo();
        promises.push(userInfoPromise);
        let getTrainingPromise = this.getFavorites();
        promises.push(getTrainingPromise);

        if (this.state.currentCompany?.role === "SystemAdmin" && this.state.currentCompany?.id) {
          CompanyService.update(this.state.currentCompany).then(() => {
            Promise.all(promises).then(() => {
              this.props.history.push("/");
            });
          });
        } else {
          Promise.all(promises).then(() => {
            this.props.history.push("/");
          });
        }
      });
    });
  };

  onChangeFirstName = (e) => {
    this.setState((prevState) => ({
      user: {
        ...prevState.user,
        firstName: e.target.value,
      },
    }));
  };

  onChangeLastName = (e) => {
    this.setState((prevState) => ({
      user: {
        ...prevState.user,
        lastName: e.target.value,
      },
    }));
  };

  onChangeCurrentCompany = (currentCompany) => {
    this.setState((prevState) => ({
      user: {
        ...prevState.user,
        currentCompanyId: currentCompany.id,
      },
      currentCompany: currentCompany,
    }));
  };

  handleImageChange = (imageFile) => {
    this.setState((prevState) => ({
      user: {
        ...prevState.user,
        photo: imageFile?.name,
      },
    }));
  };

  render() {
    const { t } = this.props;
    return (
      <Fragment>
        <Row>
          <Col className="bg-white ">
            <Form style={{ padding: "50px" }}>
              <Row>
                <Col md="4">
                  <FormGroup>
                    <AvatarPicker handleChangeImage={this.handleImageChange} base64Image={this.state.user?.photo} />
                  </FormGroup>
                  <FormGroup>
                    <Label style={{ fontWeight: "bold", justifyContent: "center", alignItems: "center", display: "flex" }}>{this.state.user?.email} </Label>
                  </FormGroup>
                </Col>
                <Col md="8">
                  <Row>
                    <Col md="6">
                      <FormGroup>
                        <Label for="firstName">{t("PROFILE.FIRST_NAME")}</Label>
                        <Input type="text" name="firstName" id="firstName" value={this.state.user?.firstName} onChange={this.onChangeFirstName} />
                      </FormGroup>
                    </Col>
                    <Col md="6">
                      <FormGroup>
                        <Label for="lastName">{t("PROFILE.LAST_NAME")}</Label>
                        <Input type="text" name="lastName" id="lastName" value={this.state.user?.lastName} onChange={this.onChangeLastName} />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col md="12">
                      {this.state.user && this.state.companies && (
                        <CompanyBox
                          user={this.state.user}
                          companies={this.state.companies}
                          onChangeCurrentCompany={(currentCompany) => this.onChangeCurrentCompany(currentCompany)}
                        />
                      )}
                    </Col>
                  </Row>
                </Col>
              </Row>
              <Col md="12">
                <div className="d-flex align-items-end">
                  <div className="ms-auto">
                    <Button color="primary" type="submit" size="lg" onClick={this.updateProfile}>
                      {t("PROFILE.UPDATE_BUTTON")}
                    </Button>
                  </div>
                </div>
              </Col>
            </Form>
          </Col>
        </Row>
      </Fragment>
    );
  }
}

export default withTranslation()(Profile);
