import React, { Fragment, Component } from "react";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import { withTranslation } from "react-i18next";
import PageTitle from "../../../Layout/AppMain/PageTitle";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import PerfectScrollbar from "react-perfect-scrollbar";
import {
  UncontrolledDropdown,
  DropdownMenu,
  DropdownToggle,
  DropdownItem,
  Row,
  Col,
  Card,
  CardBody,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input,
  FormGroup,
  Form,
  Label,
  Button,
  CardTitle,
} from "reactstrap";
import { FilterMatchMode } from "primereact/api";
import { Calendar } from "primereact/calendar";
import Select from "react-select";
import "../Attribute/DataTable.css";
import { classNames } from "primereact/utils";
import { TriStateCheckbox } from "primereact/tristatecheckbox";
import { InputText } from "primereact/inputtext";
import TopicService from "../../../service/TopicService";
import TopicModel from "../../../models/TopicModel";
import CategoryService from "../../../service/CategoryService";
import LearningObjectService from "../../../service/LearningObjectService";
import languages from "../../../models/Languages";
import moment from "moment/moment";
import Flag from "react-flagkit";
import tokenService from "../../../service/tokenService";
import { CSVLink } from "react-csv";

class Topic extends Component {
  constructor(props) {
    super(props);
    this.state = {
      topics: [],
      learningObjects: [],
      selectedTopic: {},
      categories: [],
      filteredCategories: [],
      selectedFilteringTableLanguage: languages.find((obj) => obj.lang === this.props.i18n.language),
      filterTopicDropdownLanguages: languages,

      creatingTopicTranslationLanguages: languages,
      selectedCreatingTopicTranslationLanguage: languages[0],
      selectedEditingTopicTranslationLanguage: "",

      creatingTopicTranslationName: "", //need join
      creatingTopicTranslationComment: "", //

      topicTranslations: [],
      filteredTopics: [],
      loggedUser: tokenService.getUser(),
      isUpdate: true,
      modal: false,
      deleteModal: false,
      activeModal: false,
      filterTopicsTemplate: {
        name: { value: null, matchMode: FilterMatchMode.CONTAINS },
        userName: { value: null, matchMode: FilterMatchMode.CONTAINS },
        comment: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
        createdDate: { value: null, matchMode: FilterMatchMode.DATE_IS },
        createdByUser: { value: null, matchMode: FilterMatchMode.CONTAINS },
        isPublic: { value: tokenService.getCompany().showPublicTopic ? null : false, matchMode: FilterMatchMode.EQUALS },
        isActive: { value: null, matchMode: FilterMatchMode.EQUALS },
      },

      error: {},

      csvHeaders: [
        { label: "Name", key: "name" },
        { label: "Created By", key: "createdByUser" },
      ],
    };
  }

  componentDidMount() {
    this.getAllTopics();
    this.getActiveCategories();
  }

  getTopicById(id) {
    return TopicService.getTopicById(id)
      .then((response) => {
        var topic = new TopicModel(response.data);
        this.setState({
          topicTranslations: response.data.translations,
          selectedTopic: topic,
          isUpdate: true,
        });
        this.filterTopicDropdownLanguages();
      })
      .catch((e) => {
        console.log(e);
      });
  }

  getActiveCategories() {
    CategoryService.getAllCategories()
      .then((response) => {
        this.setState(
          {
            categories: response.data,
          },
          () => {
            this.filterCategories(this.state.selectedFilteringTableLanguage.lang);
          }
        );
      })
      .catch((e) => {
        console.log(e);
      });
  }

  getLearningObjectByTopicId(id) {
    return LearningObjectService.getByTopicId(id)
      .then((response) => {
        var filteredLearningObjects = response.data.map(({ translations, createdByUser, isActive, isPublic }) => ({
          name:
            translations.find((obj) => obj.language === this.state.selectedFilteringTableLanguage.lang)?.name + (!isActive ? " (inactive) " : " ") + (isPublic ? " (public)" : ""),
          createdByUser: createdByUser,
        }));

        this.setState({
          learningObjects: filteredLearningObjects.filter((t) => !t.name.startsWith("undefined")),
        });
      })
      .catch((e) => {
        console.log(e);
      });
  }

  filterCategories(lang) {
    var filteredCategories = this.state.categories
      .filter((r) => r.isActive || (this.state.selectedTopic.categoryIds && this.state.selectedTopic.categoryIds.find((lr) => lr === r.id)))
      .map(({ translations, id, isActive, isPublic }) => ({
        name: translations.find((obj) => obj.language === lang)?.name + (!isActive ? " (inactive) " : " ") + (isPublic ? " (public)" : ""),
        id: id,
        isActive: isActive,
        isPublic: isPublic,
      }))
      .filter((obj) => obj.name && !obj.name.startsWith("undefined"))
      .sort((a, b) => {
        if (a.isPublic !== b.isPublic) {
          return a.isPublic ? 1 : -1;
        } else {
          return a.name.localeCompare(b.name);
        }
      });
    this.setState({
      filteredCategories: filteredCategories,
    });
  }

  getAllTopics = () => {
    TopicService.getAllTopics()
      .then((response) => {
        this.setState(
          {
            topics: response.data,
          },
          () => {
            this.filterTopics(this.state.selectedFilteringTableLanguage.lang);
          }
        );
      })
      .catch((e) => {
        console.log(e);
      });
  };

  filterTopics(lang) {
    var filteredTopics = this.state.topics.map(({ id, translations, createdDate, isPublic, isActive, createdByUser }) => ({
      language: translations.find((obj) => obj.language === lang)?.language,
      name: translations.find((obj) => obj.language === lang)?.name,
      comment: translations.find((obj) => obj.language === lang)?.comment,
      createdByUser: createdByUser,
      isPublic: isPublic,
      isActive: isActive,
      id: id,
      createdDate: moment(new Date(createdDate)).format("DD.MM.YYYY"),
    }));
    this.setState({
      filteredTopics: filteredTopics.filter((obj) => obj.language),
    });
  }

  isEditable = () => {
    return !this.state.selectedTopic.isPublic || this.isM2LAdmin();
  };

  isM2LAdmin = () => {
    return this.state.loggedUser?.currentRole === "SystemAdmin" && this.state.loggedUser?.currentCompanyId === null;
  };

  updateTopic(e) {
    e.preventDefault();

    let topic = this.state.selectedTopic;
    topic.isActive = true;
    topic.translations = this.state.topicTranslations;
    var updateTopic = new TopicModel(topic);
    TopicService.update(updateTopic).then(() => {
      this.changeModalState();
      this.getAllTopics();
    });
  }

  createNewTopic(e) {
    e.preventDefault();
    let topic = this.state.selectedTopic;
    topic.translations = this.state.topicTranslations;
    topic.isActive = true;
    TopicService.insert(topic).then(() => {
      this.changeModalState();
      this.getAllTopics();
    });
  }

  changeModalState = () => {
    this.setState({
      modal: !this.state.modal,
      error: {},
      creatingTopicTranslationName: "",
      creatingTopicTranslationComment: "",
    });
  };

  changePublic = (event) => {
    let st = this.state.selectedTopic;
    st.isPublic = event.target.checked;
    this.setState({
      selectedTopic: st,
    });
  };

  onChangeCreatingTopicsTranslationLanguage = (e) => {
    this.setState({ selectedCreatingTopicTranslationLanguage: e });
  };

  onChangeEditingTopicTranslationLanguage = (e) => {
    this.setState({ selectedEditingTopicTranslationLanguage: e });
  };

  onChangeFilteringTableLanguage = (e) => {
    this.setState({ selectedFilteringTableLanguage: e });
    this.filterTopics(e.lang);
  };

  changeCreatingTopicTranslationComment = (e) => {
    this.setState({ creatingTopicTranslationComment: e.target.value });
    this.createNewTranslationValueChange(e);
  };

  changeCreatingTopicTranslationName = (e) => {
    this.setState({ creatingTopicTranslationName: e.target.value });
    this.createNewTranslationValueChange(e);
  };

  filterTopicDropdownLanguages() {
    let filteredTopicLanguages = this.state.topicTranslations.map((obj) => obj.language);
    let creatingTopicTranslationLanguages = languages.filter((obj) => !filteredTopicLanguages.includes(obj.lang));
    this.setState({
      creatingTopicTranslationLanguages: creatingTopicTranslationLanguages,
      selectedCreatingTopicTranslationLanguage: creatingTopicTranslationLanguages[0],
    });
  }

  createNewTopicTranslation = (e) => {
    e.preventDefault();
    var error = { ...this.state.error };
    error.name = this.state.creatingTopicTranslationName.length < 1 ? "Required" : "";
    this.setState({ error });
    if (this.createNewTranslationFormValid({ error: error })) {
      var newTopicTranslation = {
        language: this.state.selectedCreatingTopicTranslationLanguage.lang,
        name: this.state.creatingTopicTranslationName,
        comment: this.state.creatingTopicTranslationComment,
      };
      this.state.topicTranslations.push(newTopicTranslation);
      this.setState({
        topicTranslations: this.state.topicTranslations,
      });
      this.filterTopicDropdownLanguages();

      this.setState({ creatingTopicTranslationName: "", creatingTopicTranslationComment: "" });
    }
  };

  changeActiveModalState = () => {
    this.setState({
      activeModal: !this.state.activeModal,
    });
  };

  confirmDeactivateTopic = () => {
    TopicService.deactivate(this.state.selectedTopic.id).then(() => {
      var selected = this.state.selectedTopic;
      selected.isActive = false;
      this.setState({ selectedTopic: selected });
      this.changeActiveModalState();
      this.getAllTopics();
    });
  };

  confirmActivateTopic = () => {
    TopicService.activate(this.state.selectedTopic.id).then(() => {
      var selected = this.state.selectedTopic;
      selected.isActive = true;
      this.setState({ selectedTopic: selected });
      this.changeActiveModalState();
      this.getAllTopics();
    });
  };

  changeDeleteModalState = () => {
    this.setState({
      deleteModal: !this.state.deleteModal,
    });
  };

  confirmDeleteTopic = () => {
    TopicService.delete(this.state.selectedTopic.id).then(() => {
      this.getAllTopics();
      this.changeDeleteModalState();
      this.changeModalState();
    });
  };

  dateFilterTemplate = (options) => {
    return (
      <Calendar value={new Date(options.value).toLocaleString()} onChange={(e) => options.filterCallback(e.value, options.index)} dateFormat="mm/dd/yy" placeholder="mm/dd/yyyy" />
    );
  };
  dateTopicsTemplate = (rowData) => {
    return rowData.createdDate;
  };

  createNewTranslationFormValid = ({ error, ...rest }) => {
    let isValid = true;
    Object.values(error).forEach((val) => {
      if (isValid === false) {
        return;
      }
      if (val.length > 0) {
        isValid = false;
      } else {
        isValid = true;
      }
    });
    Object.values(rest).forEach((val) => {
      if (isValid === false) {
        return;
      }
      if (val === null) {
        isValid = false;
      } else {
        isValid = true;
      }
    });
    return isValid;
  };
  createNewTranslationValueChange = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    let error = { ...this.state.error };
    switch (name) {
      case "name":
        error.name = value.length < 1 ? "Required" : "";
        break;
      default:
        break;
    }
    this.setState({
      error,
      [name]: value,
    });
  };

  onChangeCategory = (e) => {
    var topic = this.state.selectedTopic;
    topic.categoryIds = e.map(({ id }) => id);
    this.setState({ selectedTopic: topic });
  };

  openDeactivateModel = () => {
    this.getLearningObjectByTopicId(this.state.selectedTopic.id).then(() => {
      this.changeActiveModalState();
    });
  };

  openDeleteModel = () => {
    this.getLearningObjectByTopicId(this.state.selectedTopic.id).then(() => {
      this.changeDeleteModalState();
    });
  };

  render() {
    const { t } = this.props;
    const { error } = this.state;

    const countryBodyTemplate = (rowData) => {
      return (
        <div className="country-item">
          <div>{languages.find((obj) => obj.lang === rowData.language)?.name}</div>
        </div>
      );
    };

    const languageEditorTemplate = (options) => {
      return (
        <Select
          options={this.state.creatingTopicTranslationLanguages}
          onChange={this.onChangeEditingTopicTranslationLanguage}
          getOptionLabel={(option) => option.name}
          getOptionValue={(option) => option.name}
          key={languages.find((obj) => obj.lang === options.rowData?.language)}
          defaultValue={languages.find((obj) => obj.lang === options.rowData?.language)}
        />
      );
    };

    const openUpdateTopic = (id) => {
      this.getTopicById(id).then(() => {
        this.filterCategories(this.state.selectedFilteringTableLanguage.lang);
        this.changeModalState();
      });
    };

    const openCreateTopic = () => {
      this.filterCategories(this.state.selectedFilteringTableLanguage.lang);
      var topic = new TopicModel();
      topic.createdByUser = this.state.loggedUser.firstName + " " + this.state.loggedUser.lastName;
      topic.isPublic = this.isM2LAdmin();
      topic.isActive = true;
      topic.createdDate = new Date();
      this.setState(
        {
          selectedTopic: topic,
          topicTranslations: [],
          creatingTopicTranslationLanguages: languages,
          selectedCreatingTopicTranslationLanguage: this.state.selectedFilteringTableLanguage,
          isUpdate: false,
        },
        () => {
          this.changeModalState();
        }
      );
    };

    const deleteTopic = (language) => {
      this.state.creatingTopicTranslationLanguages.push(languages.find((obj) => obj.lang === language));
      this.setState({
        topicTranslations: this.state.topicTranslations.filter((obj) => obj.language !== language),
        selectedCreatingTopicTranslationLanguage: this.state.creatingTopicTranslationLanguages[0],
        filterTopicDropdownLanguages: this.state.creatingTopicTranslationLanguages,
      });
    };

    const buttonDeleteTopic = (rowData) => {
      if (this.isEditable()) {
        return (
          <Button outline color="primary" onClick={() => deleteTopic(rowData.language)}>
            {t("TOPIC.DELETE_BUTTON")}
          </Button>
        );
      }
    };

    const buttonUpdateTopic = (rowData) => {
      return (
        <Button className="me-2" size="sm" outline color="primary" onClick={() => openUpdateTopic(rowData.id)}>
          {t("TOPIC.OPEN_BUTTON")}
        </Button>
      );
    };

    const formatIsPublicCheckBox = (rowData) => {
      return (
        <i
          className={classNames("pi", {
            "true-icon pi-check-circle": rowData.isPublic,
            "false-icon pi-times-circle": !rowData.isPublic,
          })}
        ></i>
      );
    };
    const formatIsActiveCheckBox = (rowData) => {
      return (
        <i
          className={classNames("pi", {
            "true-icon pi-check-circle": rowData.isActive,
            "false-icon pi-times-circle": !rowData.isActive,
          })}
        ></i>
      );
    };

    const languageTableFilter = () => {
      return (
        <div>
          <UncontrolledDropdown>
            <DropdownToggle className="p-0 me-2" color="black">
              <div className="icon-wrapper-bg bg-focus" />
              <div className="language-icon">
                <Flag className="me-3 opacity-8" country={this.state.selectedFilteringTableLanguage?.flag} size="30" />
              </div>
            </DropdownToggle>
            <DropdownMenu end className="rm-pointers">
              {languages.map((language, id) => (
                <DropdownItem key={id} value={language} onClick={() => this.onChangeFilteringTableLanguage(language)}>
                  <Flag className="me-3 opacity-8" country={language.flag} />
                  {language.name}
                </DropdownItem>
              ))}
            </DropdownMenu>
          </UncontrolledDropdown>
        </div>
      );
    };

    const languageBody = (option) => {
      return (
        <Flag
          className="me-3 opacity-8"
          style={{
            marginLeft: "0.75rem",
          }}
          country={languages.find((obj) => obj.lang === option.language)?.flag}
          size="30"
        />
      );
    };

    const verifiedRowFilterTemplate = (options) => {
      return <TriStateCheckbox value={options.value} onChange={(e) => options.filterApplyCallback(e.value)} />;
    };

    const textEditor = (options) => {
      return <InputText type="text" value={options.value} onChange={(e) => options.editorCallback(e.target.value)} />;
    };

    const onRowEditComplete = (e) => {
      let updateTopics = this.state.topicTranslations;
      let { newData, index } = e;
      newData.language = this.state.selectedEditingTopicTranslationLanguage.lang ? this.state.selectedEditingTopicTranslationLanguage.lang : e.data.language;
      updateTopics[index] = newData;
      this.setState({
        topicTranslations: updateTopics,
      });
      this.filterTopicDropdownLanguages();
    };

    const renderActiveDeactivateButton = () => {
      if (this.state.selectedTopic.id) {
        if (this.state.selectedTopic.isActive) {
          return (
            <Button className="mb-2 me-2 " outline color="danger" onClick={this.openDeactivateModel}>
              {t("TOPIC.DEACTIVATE_BUTTON")}
            </Button>
          );
        } else {
          return (
            <Button className="mb-2 me-2 " outline color="success" onClick={this.changeActiveModalState}>
              {t("TOPIC.ACTIVATE_BUTTON")}
            </Button>
          );
        }
      }
    };

    return (
      <Fragment>
        <TransitionGroup>
          <CSSTransition component="div" classNames="TabsAnimation" appear={true} timeout={1500} enter={false} exit={false}>
            <div>
              <PageTitle heading={t("TOPIC.TITLE")} subheading={t("COMPANY.DESCRIPTION")} icon="pe-7s-medal icon-gradient bg-tempting-azure" />
              <Row>
                <Col md="12">
                  <Button className="mb-2 me-2  " outline color="primary" onClick={() => openCreateTopic()}>
                    {t("TOPIC.CREATE_BUTTON")}
                  </Button>
                  <Modal isOpen={this.state.modal} fade={false} toggle={this.changeModalState}>
                    <ModalHeader toggle={this.changeModalState}> {this.state.isUpdate ? t("TOPIC.EDIT_MODAL_TITLE") : t("TOPIC.CREATE_MODAL_TITLE")}</ModalHeader>
                    <ModalBody>
                      <div className="card p-fluid">
                        <Row style={{ fontWeight: "bold", textAlign: "center", padding: "10px" }}>
                          <Col md="4">
                            <Label for="createdByUser">
                              {t("TOPIC.CREATED_BY")} {this.state.selectedTopic.createdByUser}
                            </Label>
                          </Col>
                          <Col md="4">
                            <Label for="createdDate">
                              {t("TOPIC.CREATED_DATE")} {moment(this.state.selectedTopic.createdDate).format("DD.MM.YYYY")}
                            </Label>
                          </Col>
                          <Col md="4">
                            {this.isM2LAdmin() && (
                              <FormGroup>
                                <Label>
                                  <Input
                                    type="checkbox"
                                    checked={this.state.selectedTopic.isPublic}
                                    disabled={!this.state.selectedTopic.isActive || this.state.loggedUser.currentRole !== "SystemAdmin"}
                                    onChange={this.changePublic}
                                  />
                                  {t("TOPIC.IS_PUBLIC")}
                                </Label>
                              </FormGroup>
                            )}
                          </Col>
                        </Row>
                        <Row>
                          <Col md="1" style={{ fontWeight: "bold", textAlign: "center", padding: "10px" }}>
                            <Label for="exampleSelect">{t("TOPIC.CATEGORY")}:</Label>
                          </Col>
                          <Col md="5">
                            <FormGroup>
                              <Select
                                options={this.state.filteredCategories}
                                isOptionDisabled={(option) => !option.isActive}
                                isDisabled={!this.isEditable()}
                                value={this.state.filteredCategories.filter((l) => this.state.selectedTopic?.categoryIds?.find((id) => l.id === id)) || ""}
                                onChange={this.onChangeCategory}
                                isMulti
                                getOptionLabel={(option) => option.name}
                                getOptionValue={(option) => option.id}
                              />
                            </FormGroup>
                          </Col>
                        </Row>
                      </div>
                      {this.isEditable() && (
                        <div className="card p-fluid">
                          <Form onSubmit={this.createNewTopicTranslation} style={{ margin: "10px" }}>
                            <Row>
                              <Col>
                                <FormGroup>
                                  <Label for="exampleSelect">{t("TOPIC.LANGUAGE")}</Label>
                                  <Select
                                    options={this.state.creatingTopicTranslationLanguages}
                                    value={this.state.selectedCreatingTopicTranslationLanguage ? this.state.selectedCreatingTopicTranslationLanguage : " "}
                                    onChange={this.onChangeCreatingTopicsTranslationLanguage}
                                    getOptionLabel={(option) => option.name}
                                    getOptionValue={(option) => option.lang}
                                  />
                                </FormGroup>
                              </Col>
                              <Col>
                                <FormGroup>
                                  <Label for="name"> {t("TOPIC.NAME")}</Label>
                                  <Input
                                    type="text"
                                    name="name"
                                    id="name"
                                    onChange={this.changeCreatingTopicTranslationName}
                                    value={this.state.creatingTopicTranslationName}
                                    className={error.name ? "is-invalid form-control" : "form-control"}
                                  />
                                  {error.name && <span className="invalid-feedback">{error.name}</span>}
                                </FormGroup>
                              </Col>
                              <Col>
                                <FormGroup>
                                  <Label for="comment">{t("TOPIC.COMMENT")}</Label>
                                  <Input
                                    type="text"
                                    name="comment"
                                    id="comment"
                                    onChange={this.changeCreatingTopicTranslationComment}
                                    value={this.state.creatingTopicTranslationComment}
                                    className="form-control"
                                  />
                                </FormGroup>
                              </Col>

                              <Col>
                                <Button className="mb-2 me-2  " outline color="primary" style={{ marginTop: "30px" }} type="submit">
                                  {t("TOPIC.CREATE_BUTTON")}
                                </Button>
                              </Col>
                            </Row>
                          </Form>
                        </div>
                      )}

                      <div className="card p-fluid">
                        <DataTable
                          editMode="row"
                          value={this.state.topicTranslations}
                          dataKey="id"
                          className="p-datatable-customers"
                          onRowEditComplete={onRowEditComplete}
                          // responsiveLayout="scroll"
                        >
                          <Column
                            field="language"
                            header={t("TOPIC.LANGUAGE_COLUMN_NAME")}
                            body={countryBodyTemplate}
                            editor={(options) => languageEditorTemplate(options)}
                            style={{ width: "20%", zIndex: "2" }}
                          ></Column>
                          <Column field="name" header={t("TOPIC.NAME_COLUMN_NAME")} editor={(options) => textEditor(options)} style={{ width: "20%" }}></Column>
                          <Column field="comment" header={t("TOPIC.COMMENT_COLUMN_NAME")} editor={(options) => textEditor(options)} style={{ width: "20%" }}></Column>
                          <Column rowEditor={this.isEditable()} headerStyle={{ width: "10%", minWidth: "8rem" }} bodyStyle={{ textAlign: "center" }}></Column>
                          <Column style={{ minWidth: "6rem" }} body={buttonDeleteTopic} />
                        </DataTable>
                      </div>
                    </ModalBody>
                    <ModalFooter>
                      <Modal isOpen={this.state.activeModal} toggle={this.changeActiveModalState} style={{ marginTop: "50px", width: "60%" }}>
                        <ModalHeader> {this.state.selectedTopic.isActive ? t("TOPIC.CONFIRM_DEACTIVATE_MODAL_TITLE") : t("TOPIC.CONFIRM_ACTIVATE_MODAL_TITLE")}</ModalHeader>
                        <ModalBody style={{ minHeight: "auto" }}>
                          {this.state.selectedTopic.isActive && this.state.learningObjects.length > 0 ? (
                            <div>
                              <PerfectScrollbar>
                                {this.state.learningObjects.length > 0 ? (
                                  <Card className="main-card mb-3">
                                    <CardTitle>{t("TOPIC.LEARNING_OBJECTS")}</CardTitle>
                                    <DataTable value={this.state.learningObjects} paginator rows={10}>
                                      <Column field="name" header={t("TOPIC.LEARNING_OBJECT_NAME_COLUMN_NAME")}></Column>
                                      <Column field="createdByUser" header={t("TOPIC.LEARNING_OBJECT_CREATED_BY_COLUMN_NAME")}></Column>
                                    </DataTable>
                                  </Card>
                                ) : (
                                  ""
                                )}
                              </PerfectScrollbar>
                            </div>
                          ) : (
                            ""
                          )}
                        </ModalBody>
                        <ModalFooter>
                          <Col>
                            {this.state.selectedTopic.isActive && this.state.learningObjects.length > 0 ? (
                              <CSVLink
                                data={this.state.learningObjects}
                                filename={
                                  "deactivate_topic_" + this.state.topicTranslations.find((a) => a.language === this.state.selectedFilteringTableLanguage.lang)?.name + ".csv"
                                }
                                headers={this.state.csvHeaders}
                                className="mb-2 me-2 btn btn-success"
                                target="_blank"
                              >
                                {t("TOPIC.EXPORT_BUTTON")}
                              </CSVLink>
                            ) : (
                              ""
                            )}
                          </Col>
                          <Button className="mb-2 me-2 " color="secondary" onClick={this.changeActiveModalState}>
                            {t("TOPIC.NO_BUTTON")}
                          </Button>
                          <Button className="mb-2 me-2 " color="primary" onClick={this.state.selectedTopic.isActive ? this.confirmDeactivateTopic : this.confirmActivateTopic}>
                            {t("TOPIC.YES_BUTTON")}
                          </Button>
                        </ModalFooter>
                      </Modal>

                      <Modal isOpen={this.state.deleteModal} toggle={this.changeDeleteModalState} style={{ marginTop: "50px", width: "60%" }}>
                        <ModalHeader> {this.state.learningObjects.length > 0 ? t("TOPIC.DELETE_MODAL_TITLE") : t("TOPIC.CONFIRM_DELETE_MODAL_TITLE")}</ModalHeader>
                        <ModalBody style={{ minHeight: "auto" }}>
                          {this.state.learningObjects.length > 0 ? (
                            <div>
                              <PerfectScrollbar>
                                {this.state.learningObjects.length > 0 ? (
                                  <Card className="main-card mb-3">
                                    <CardTitle>{t("TOPIC.LEARNING_OBJECTS")}</CardTitle>
                                    <DataTable value={this.state.learningObjects} paginator rows={10}>
                                      <Column field="name" header={t("TOPIC.LEARNING_OBJECT_NAME_COLUMN_NAME")}></Column>
                                      <Column field="createdByUser" header={t("TOPIC.LEARNING_OBJECT_CREATED_BY_COLUMN_NAME")}></Column>
                                    </DataTable>
                                  </Card>
                                ) : (
                                  ""
                                )}
                              </PerfectScrollbar>
                            </div>
                          ) : (
                            ""
                          )}
                        </ModalBody>
                        <ModalFooter>
                          <Col>
                            {this.state.learningObjects.length > 0 ? (
                              <CSVLink
                                data={this.state.learningObjects}
                                filename={"delete_topic_" + this.state.topicTranslations.find((a) => a.language === this.state.selectedFilteringTableLanguage.lang)?.name + ".csv"}
                                headers={this.state.csvHeaders}
                                className="mb-2 me-2 btn btn-success"
                                target="_blank"
                              >
                                {t("TOPIC.EXPORT_BUTTON")}
                              </CSVLink>
                            ) : (
                              ""
                            )}
                          </Col>
                          {this.state.learningObjects.length > 0 ? (
                            <div>
                              <Button className="mb-2 me-2 " color="secondary" onClick={this.changeDeleteModalState}>
                                {t("TOPIC.CANCEL_BUTTON")}
                              </Button>
                            </div>
                          ) : (
                            <div>
                              <Button className="mb-2 me-2 " color="secondary" onClick={this.changeDeleteModalState}>
                                {t("TOPIC.NO_BUTTON")}
                              </Button>
                              <Button className="mb-2 me-2 " color="primary" onClick={this.confirmDeleteTopic}>
                                {t("TOPIC.YES_BUTTON")}
                              </Button>
                            </div>
                          )}
                        </ModalFooter>
                      </Modal>

                      {this.isEditable() ? (
                        <>
                          <Col>
                            {this.state.selectedTopic.id ? (
                              <Button disabled={this.state.selectedTopic.isActive} className="mb-2 me-2" color="danger" onClick={this.openDeleteModel}>
                                {t("TOPIC.DELETE_BUTTON")}
                              </Button>
                            ) : (
                              ""
                            )}
                            {renderActiveDeactivateButton()}
                          </Col>

                          <Button className="mb-2 me-2   " color="secondary" onClick={this.changeModalState}>
                            {t("TOPIC.CANCEL_BUTTON")}
                          </Button>
                          {this.state.isUpdate ? (
                            <Button className="mb-2 me-2  " color="primary" onClick={(e) => this.updateTopic(e)}>
                              {t("TOPIC.UPDATE_BUTTON")}
                            </Button>
                          ) : (
                            <Button className="mb-2 me-2  " color="primary" onClick={(e) => this.createNewTopic(e)}>
                              {t("TOPIC.SAVE_BUTTON")}
                            </Button>
                          )}
                        </>
                      ) : (
                        <Button className="mb-2 me-2" color="secondary" onClick={this.changeModalState}>
                          {t("TOPIC.CLOSE_BUTTON")}
                        </Button>
                      )}
                    </ModalFooter>
                  </Modal>
                </Col>
                <Col md="12">
                  <Card className="main-card mb-3">
                    <CardBody>
                      <div className="card p-fluid">
                        <DataTable
                          value={this.state.filteredTopics}
                          paginator
                          className="p-datatable-customers"
                          rows={10}
                          dataKey="id"
                          filterDisplay="row"
                          responsiveLayout="scroll"
                          filters={this.state.filterTopicsTemplate}
                          emptyMessage={t("COMPANY.TABLE_MESSAGE")}
                        >
                          <Column
                            header={t("LEARNINGPATH.LANGUAGE_COLUMN_NAME")}
                            field="language"
                            showFilterMenu={false}
                            style={{ minWidth: "10rem" }}
                            body={languageBody}
                            filter
                            filterElement={languageTableFilter}
                          />
                          <Column header={t("TOPIC.NAME_COLUMN_NAME")} field="name" filter sortable />
                          <Column header={t("TOPIC.COMMENT_COLUMN_NAME")} field="comment" filter sortable />
                          <Column
                            field="isPublic"
                            header={t("TOPIC.IS_PUBLIC_COLUMN_NAME")}
                            dataType="boolean"
                            sortable
                            body={formatIsPublicCheckBox}
                            filter
                            filterElement={verifiedRowFilterTemplate}
                          />
                          <Column
                            field="isActive"
                            header={t("TOPIC.IS_ACTIVE_COLUMN_NAME")}
                            dataType="boolean"
                            sortable
                            body={formatIsActiveCheckBox}
                            filter
                            filterElement={verifiedRowFilterTemplate}
                          />
                          <Column field="createdByUser" header={t("TOPIC.CREATED_BY_COLUMN_NAME")} filter sortable />
                          <Column header={t("TOPIC.CREATED_DATE_COLUMN_NAME")} filterField="createdDate" dataType="date" body={this.dateTopicsTemplate} />
                          <Column body={buttonUpdateTopic} rowEditor />
                        </DataTable>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </div>
          </CSSTransition>
        </TransitionGroup>
      </Fragment>
    );
  }
}
export default withTranslation()(Topic);
