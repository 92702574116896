import api from "./api";

class AccountService {
  update(update) {
    return api.post("/Account/update", update);
  }

  setLanguage(lang) {
    return api.post("/Account/set-language", { language: lang });
  }

  setCurrentCompany(currentCompanyId) {
    return api.post("/Account/set-current-company", { currentCompanyId: currentCompanyId });
  }

  forgotPassword(email) {
    return api.post("/Account/forgot-password", { login: email });
  }

  resetPassword(model) {
    return api.post("/Account/reset-password", model);
  }
}

export default new AccountService();
