export const OrganizationNav = (user, t) => [
  {
    isActive: user?.currentRole === "SystemAdmin" && user?.currentCompanyId === null,
    icon: "pe-7s-browser",
    label: t("NAV.COMPANY"),
    to: "/organization/company",
  },
  {
    isActive: user?.currentRole === "SystemAdmin",
    icon: "pe-7s-browser",
    label: t("NAV.USER"),
    to: "/organization/user",
  },
];
export const MetadataNav = (user, t) => [
  {
    isActive: user?.currentRole === "SystemAdmin" || user?.currentRole === "ContentAdmin",
    icon: "pe-7s-browser",
    label: t("NAV.TOPIC"),
    to: "/metadata/topic",
  },
  {
    isActive: user?.currentRole === "SystemAdmin" || user?.currentRole === "ContentAdmin",
    icon: "pe-7s-browser",
    label: t("NAV.CATEGORY"),
    to: "/metadata/category",
  },
  {
    isActive: user?.currentRole === "SystemAdmin" || user?.currentRole === "ContentAdmin",
    icon: "pe-7s-browser",
    label: t("NAV.ATTRIBUTE"),
    to: "/metadata/attribute",
  },
  {
    isActive: user?.currentRole === "SystemAdmin" || user?.currentRole === "ContentAdmin",
    icon: "pe-7s-browser",
    label: t("NAV.RECOMMENDATION"),
    to: "/metadata/recommendation",
  },
  {
    isActive: user?.currentRole === "SystemAdmin" || user?.currentRole === "ContentAdmin",
    icon: "pe-7s-browser",
    label: t("NAV.COMPETENCEFRAME"),
    to: "/metadata/competence-frame",
  },
  {
    isActive: user?.currentRole === "SystemAdmin" || user?.currentRole === "ContentAdmin",
    icon: "pe-7s-browser",
    label: t("NAV.COMPETENCE"),
    to: "/metadata/competence",
  },
];

export const LearningNav = (user, t) => [
  {
    isActive: user?.currentRole !== "Trainer",
    icon: "pe-7s-browser",
    label: t("NAV.LEARNING_PATH"),
    to: "/learning/learning-path",
  },
  {
    isActive: user?.currentRole !== "Trainer",
    icon: "pe-7s-browser",
    label: t("NAV.LEARNING_OBJECT"),
    to: "/learning/learning-object",
  },
];

export const TrainingNav = (user, t) => [
  {
    isActive: true,
    icon: "pe-7s-browser",
    label: t("NAV.TRAININGLIST"),
    to: "/training/training-list",
  },
  {
    isActive: true,
    icon: "pe-7s-browser",
    label: t("NAV.FAVORITES"),
    subItems: [],
  },
];
