import api from "./api";
import TokenService from "./tokenService";

class AuthService {
  login(login, password) {
    return api
      .post("/Account/login", {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        login,
        password,
      })
      .then((response) => {
        if (response.data.accessToken) {
          TokenService.setToken(response.data);
        }

        return response.data;
      });
  }

  logout() {
    TokenService.remove();
  }

  getMyInfo() {
    return api.get("/Account/get-my-info");
  }

  register(username, email, password) {
    return api.post("/auth/signup", {
      username,
      email,
      password,
    });
  }

  getCurrentUser() {
    return TokenService.getUser();
  }
}

export default new AuthService();
