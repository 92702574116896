import React, { Fragment, Component } from "react";
import { withTranslation } from "react-i18next";
import { withRouter } from "react-router-dom";
import { eventMediator } from "../../../../service/eventMediator.js";
import urlIcon from "../../../../assets/images/url-icon.svg";

class LinkModule extends Component {
  openLink = () => {
    eventMediator.dispatch("allowComplete", true);
  };

  render() {
    const { learningPathItem } = this.props;
    return (
      <Fragment>
        <div className="form-wizard-content">
          <div className="no-results">
            <div className="mt-3 mb-3" />
            <a style={{ fontSize: "1.2em" }} href={learningPathItem?.displayName} target="_blank" rel="noreferrer" onClick={() => this.openLink()}>
              {learningPathItem?.displayName}
            </a>
          </div>
          <a
            style={{
              width: "35%",
              display: "block",
              margin: "auto",
              paddingTop: "4rem",
            }}
            href={learningPathItem?.displayName}
            target="_blank"
            rel="noreferrer"
            onClick={() => this.openLink()}
          >
            <img alt={learningPathItem?.displayName} src={urlIcon} onClick={() => this.openLink()} />{" "}
          </a>
        </div>
      </Fragment>
    );
  }
}
export default withTranslation()(withRouter(LinkModule));
