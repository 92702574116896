class TopicModel {
  constructor(topic) {
    if (topic) {
      this.id = topic.id;
      this.isActive = topic.isActive;
      this.isPublic = topic.isPublic;
      this.translations = topic.translations;
      this.createdDate = topic.createdDate;
      this.createdByUser = topic.createdByUser;
      this.categoryIds = topic.categoryIds;
    }
  }
}
export default TopicModel;
