import React, { Fragment } from "react";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import { Row, Col, Card, CardBody, CardTitle, Container, FormGroup, Label, Input } from "reactstrap";
import CompanyService from "../../../service/CompanyService";
import "../Components/style.css";
import { withTranslation } from "react-i18next";

class CompanyBox extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      user: props.user,
      companies: props.companies,
      currentCompany: props.companies.find((c) => c.id === props.user.currentCompanyId),
    };
  }

  changeCompany = (currentCompany) => {
    this.setState((prevState) => ({
      user: {
        ...prevState.user,
        currentCompanyId: currentCompany.id,
      },
      currentCompany: currentCompany,
    }));

    this.props.onChangeCurrentCompany(currentCompany);
  };

  changeShowPublicTopic = (event) => {
    var company = this.state.currentCompany;
    company.showPublicTopic = event.target.checked;
    this.setState({ currentCompany: company });
  };
  changeShowPublicCategory = (event) => {
    var company = this.state.currentCompany;
    company.showPublicCategory = event.target.checked;
    this.setState({ currentCompany: company });
  };
  changeShowPublicAttribute = (event) => {
    var company = this.state.currentCompany;
    company.showPublicAttribute = event.target.checked;
    this.setState({ currentCompany: company });
  };
  changeShowPublicRecommendation = (event) => {
    var company = this.state.currentCompany;
    company.showPublicRecommendation = event.target.checked;
    this.setState({ currentCompany: company });
  };
  changeShowPublicCompetenceFrame = (event) => {
    var company = this.state.currentCompany;
    company.showPublicCompetenceFrame = event.target.checked;
    this.setState({ currentCompany: company });
  };
  changeShowPublicCompetence = (event) => {
    var company = this.state.currentCompany;
    company.showPublicCompetence = event.target.checked;
    this.setState({ currentCompany: company });
  };
  changeShowPublicLearningPath = (event) => {
    var company = this.state.currentCompany;
    company.showPublicLearningPath = event.target.checked;
    this.setState({ currentCompany: company });
  };
  changeShowPublicLearningObject = (event) => {
    var company = this.state.currentCompany;
    company.showPublicLearningObject = event.target.checked;
    this.setState({ currentCompany: company });
  };

  render() {
    const { t } = this.props;

    const renderCompanies = () => {
      return (
        <>
          {this.state.companies.map((company, i) => (
            <Col md="2" key={i} className={this.state.user.currentCompanyId === company.id ? "border rounded company-background" : ""} onClick={() => this.changeCompany(company)}>
              <p className="company-name" style={{ fontWeight: "bold" }}>
                {company.name}
              </p>
              {company.logo ? (
                <img className="center company-image" src={"data:image/png;base64," + company.logo} alt={company.name} />
              ) : (
                <div className="center company-image"></div>
              )}
              <p className="company-name">{company.role}</p>
            </Col>
          ))}
        </>
      );
    };

    return (
      <Fragment>
        <TransitionGroup>
          <CSSTransition component="div" classNames="TabsAnimation" appear={true} timeout={0} enter={false} exit={false}>
            <Container fluid style={{ padding: "0" }}>
              <Row>
                <Col md="12">
                  <Card className="main-card mb-3">
                    <CardBody>
                      <CardTitle>{t("PROFILE.COMPANIES")}</CardTitle>
                      <Row>{renderCompanies()}</Row>

                      {this.state.currentCompany?.role === "SystemAdmin" && this.state.currentCompany?.id && (
                        <Row>
                          <Col md="12" className="mt-3">
                            <Row>
                              <Col md="6">
                                <FormGroup>
                                  <Label>
                                    <Input
                                      type="checkbox"
                                      style={{ marginRight: "6px" }}
                                      checked={this.state.currentCompany.showPublicTopic}
                                      onChange={this.changeShowPublicTopic}
                                    />
                                    {t("COMPANY.SHOWPUBLICTOPIC")}
                                  </Label>
                                </FormGroup>
                              </Col>
                              <Col md="6">
                                <FormGroup>
                                  <Label>
                                    <Input
                                      type="checkbox"
                                      style={{ marginRight: "6px" }}
                                      checked={this.state.currentCompany.showPublicCategory}
                                      onChange={this.changeShowPublicCategory}
                                    />
                                    {t("COMPANY.SHOWPUBLICCATEGOTY")}
                                  </Label>
                                </FormGroup>
                              </Col>
                            </Row>
                            <Row>
                              <Col md="6">
                                <FormGroup>
                                  <Label>
                                    <Input
                                      type="checkbox"
                                      style={{ marginRight: "6px" }}
                                      checked={this.state.currentCompany.showPublicAttribute}
                                      onChange={this.changeShowPublicAttribute}
                                    />
                                    {t("COMPANY.SHOWPUBLICATTRIBUTE")}
                                  </Label>
                                </FormGroup>
                              </Col>
                              <Col md="6">
                                <FormGroup>
                                  <Label>
                                    <Input
                                      type="checkbox"
                                      style={{ marginRight: "6px" }}
                                      checked={this.state.currentCompany.showPublicRecommendation}
                                      onChange={this.changeShowPublicRecommendation}
                                    />
                                    {t("COMPANY.SHOWPUBLICRECOMMENDATION")}
                                  </Label>
                                </FormGroup>
                              </Col>
                            </Row>
                            <Row>
                              <Col md="6">
                                <FormGroup>
                                  <Label>
                                    <Input
                                      type="checkbox"
                                      style={{ marginRight: "6px" }}
                                      checked={this.state.currentCompany.showPublicCompetenceFrame}
                                      onChange={this.changeShowPublicCompetenceFrame}
                                    />
                                    {t("COMPANY.SHOWPUBLICCOMPETENCEFRAME")}
                                  </Label>
                                </FormGroup>
                              </Col>
                              <Col md="6">
                                <FormGroup>
                                  <Label>
                                    <Input
                                      type="checkbox"
                                      style={{ marginRight: "6px" }}
                                      checked={this.state.currentCompany.showPublicCompetence}
                                      onChange={this.changeShowPublicCompetence}
                                    />
                                    {t("COMPANY.SHOWPUBLICCOMPETENCE")}
                                  </Label>
                                </FormGroup>
                              </Col>
                            </Row>
                            <Row>
                              <Col md="6">
                                <FormGroup>
                                  <Label>
                                    <Input
                                      type="checkbox"
                                      style={{ marginRight: "6px" }}
                                      checked={this.state.currentCompany.showPublicLearningPath}
                                      onChange={this.changeShowPublicLearningPath}
                                    />
                                    {t("COMPANY.SHOWPUBLICLEARNINGPATH")}
                                  </Label>
                                </FormGroup>
                              </Col>
                              <Col md="6">
                                <FormGroup>
                                  <Label>
                                    <Input
                                      type="checkbox"
                                      style={{ marginRight: "6px" }}
                                      checked={this.state.currentCompany.showPublicLearningObject}
                                      onChange={this.changeShowPublicLearningObject}
                                    />
                                    {t("COMPANY.SHOWPUBLICLEARNINGOBJECT")}
                                  </Label>
                                </FormGroup>
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                      )}
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </Container>
          </CSSTransition>
        </TransitionGroup>
      </Fragment>
    );
  }
}
export default withTranslation()(CompanyBox);
