import api from "./api";

class UserService {
  getAllUsers() {
    return api.get("/User/get-all");
  }

  getUserById(id) {
    return api.get("/User/get-by-id?id=" + id);
  }

  getUsersByCompanyId(companyId) {
    return api.get("/User/get-by-company-id?companyId=" + companyId);
  }

  getActiveUsersByCompanyId(companyId) {
    return api.get("/User/get-by-active-company-id?companyId=" + companyId);
  }

  insert(user) {
    return api.post("/User/insert", user);
  }

  update(user) {
    return api.post("/User/update", user);
  }

  activate(id) {
    return api.post("/User/activate?id=" + id);
  }

  deactivate(id) {
    return api.post("/User/deactivate?id=" + id);
  }

  delete(model) {
    return api.post("/User/delete", model);
  }

  assignToCompany(models) {
    return api.post("/User/assign-to-company", models);
  }
  unassignFromCompany(models) {
    return api.post("/User/unassign-from-company", models);
  }
}

export default new UserService();
