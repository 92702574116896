import api from "./api";

class CompetenceService {
  getAll() {
    return api.get("/Competence/get-all");
  }

  getById(id) {
    return api.get("/Competence/get-by-id?id=" + id);
  }

  getByCompetenceFrameId(competenceFrameId) {
    return api.get("/Competence/get-by-competence-frame-id?competenceFrameId=" + competenceFrameId);
  }

  getActiveByCompetenceFrameId(competenceFrameId) {
    return api.get("/Competence/get-active-by-competence-frame-id?competenceFrameId=" + competenceFrameId);
  }

  insert(model) {
    return api.post("/Competence/insert", model);
  }

  update(model) {
    return api.post("/Competence/update", model);
  }

  deactivate(id) {
    return api.post("/Competence/deactivate?id=" + id);
  }

  activate(id) {
    return api.post("/Competence/activate?id=" + id);
  }

  delete(id) {
    return api.post("/Competence/delete?Id=" + id);
  }
}

export default new CompetenceService();
