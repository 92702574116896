import axios from "axios";
import TokenService from "./tokenService";

//const baseUrl = "https://localhost:7225/api";
const baseUrl = "/api";

const instance = axios.create({
  baseURL: baseUrl,
  headers: {
    "Content-Type": "application/json",
  },
});

instance.interceptors.request.use(
  (config) => {
    const token = TokenService.getLocalAccessToken();
    if (token) {
      config.headers["Authorization"] = "Bearer " + token;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// for multiple requests
let isRefreshing = false;
let failedQueue = [];

const processQueue = (error, token = null) => {
  failedQueue
    .slice()
    .reverse()
    .forEach((prom) => {
      if (error) {
        prom.reject(error);
      } else {
        prom.resolve(token);
      }
    });

  failedQueue = [];
};

instance.interceptors.response.use(
  (response) => response,
  (error) => {
    const originalRequest = error.config;
    if (originalRequest.url !== "/Account/login" && error.response) {
      if (error.response.status === 405 && !originalRequest._retry) {
        if (isRefreshing) {
          return new Promise(function (resolve, reject) {
            failedQueue.push({ resolve, reject });
          })
            .then((token) => {
              originalRequest.headers["Authorization"] = "Bearer " + token;
              return axios(originalRequest);
            })
            .catch((err) => {
              return Promise.reject(err);
            });
        }

        originalRequest._retry = true;
        isRefreshing = true;

        return new Promise(function (resolve, reject) {
          axios
            .post(baseUrl + "/Account/refresh-token", {
              accessToken: TokenService.getLocalAccessToken(),
              refreshToken: TokenService.getLocalRefreshToken(),
            })
            .then(({ data }) => {
              TokenService.updateToken(data);
              axios.defaults.headers.common["Authorization"] = "Bearer " + data.accessToken;
              originalRequest.headers["Authorization"] = "Bearer " + data.accessToken;
              processQueue(null, data.accessToken);
              resolve(axios(originalRequest));
            })
            .catch((err) => {
              processQueue(err, null);
              TokenService.remove();
              window.location.href = "/auth/login";
              reject(err);
            })
            .finally(() => {
              isRefreshing = false;
            });
        });
      }
    }

    return Promise.reject(error);
  }
);

export default instance;
