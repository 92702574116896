import React, { Fragment, Component } from "react";
import { withTranslation } from "react-i18next";
import { eventMediator } from "../../../../service/eventMediator.js";
import LearningPathService from "../../../../service/LearningPathService";

class TrainingModule extends Component {
  constructor(props) {
    super(props);
    this.state = {
      training: null,
      learningPathItem: props.learningPathItem,
    };
  }

  getTraining = (learingPathId) => {
    return LearningPathService.getLearningPathById(learingPathId).then((response) => {
      this.setState({ training: response.data });
    });
  };

  componentDidMount() {
    this.checkCompleted(this.props.learningPathItem.trainingId);
    this.getTraining(this.props.learningPathItem.trainingId);
  }

  componentDidUpdate() {
    if (this.props.learningPathItem.trainingId !== this.state.learningPathItem?.trainingId) {
      this.checkCompleted(this.props.learningPathItem.trainingId);
      this.getTraining(this.props.learningPathItem.trainingId);
      this.setState({ learningPathItem: this.props.learningPathItem });
    }
  }

  checkCompleted = (trainingId) => {
    LearningPathService.isCompleted(trainingId).then((response) => {
      eventMediator.dispatch("allowComplete", response.data.completed);
    });
  };

  render() {
    const { training } = this.state;
    return (
      <Fragment>
        <div className="form-wizard-content">
          <div className="no-results">
            <div className="mt-3 mb-3" />
            <div className="results-title">
              {training?.name}:{training?.version}
            </div>
            <div className="results-title">{training?.description}</div>
          </div>
        </div>
      </Fragment>
    );
  }
}
export default withTranslation()(TrainingModule);
