import React, { Suspense, useEffect, Fragment } from "react";
import ThemeOptions from "../../Layout/ThemeOptions";
import MaterialPreview from "../Shared/MaterialPreview";
import { Component } from "react-image-crop";
import LearningObjectMaterialService from "../../service/LearningObjectMaterialService";
import AudioPreview from "../Shared/MaterialPreview/AudioPreview";
import ImagePreview from "../Shared/MaterialPreview/ImagePreview";
import PfdPreview from "../Shared/MaterialPreview/PfdPreview";
import VideoPreview from "../Shared/MaterialPreview/VideoPreview";
import XlsxPreview from "../Shared/MaterialPreview/XlsxPreview";

class ViewerPages extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: props.location.pathname.replace("/viewer/", ""),
      file: null,
      material: null,
    };
  }

  componentDidMount() {
    LearningObjectMaterialService.getById(this.state.id).then((r) => {
      let material = r.data;
      this.setState({ material: material });
      LearningObjectMaterialService.download(this.state.id)
        .then((response) => {
          var blob = new Blob([response.data]);
          var file = new File([blob], material.fileName); //, { type: "mime" }
          this.setState({ file: file });
        })
        .catch((e) => {
          console.log(e);
        });
    });
  }

  render() {
    const { file, material } = this.state;

    return (
      <Fragment>
        <ThemeOptions />
        <div className="app-main" style={{ paddingTop: material?.materialTypeName === "audio" ? "4rem" : "0" }}>
          <div className="app-main__outer" style={{ paddingLeft: "0" }}>
            <div className="app-main__inner">
              {material?.materialTypeName === "audio" && this.state.file && <AudioPreview file={file} style={{ maxWidth: "60rem" }} />}
              {material?.materialTypeName === "image" && this.state.file && <ImagePreview file={file} style={{ maxWidth: "60rem" }} />}
              {material?.materialTypeName === "pdf" && this.state.file && <PfdPreview file={file} />}
              {material?.materialTypeName === "video" && this.state.file && <VideoPreview file={file} />}
              {material?.materialTypeName === "xlsx" && this.state.file && <XlsxPreview file={file} />}
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}

export default ViewerPages;
