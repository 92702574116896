import api from "./api";

class LearningObjectService {
  getByRecommendationId(recommendationId) {
    return api.get("/LearningObject/get-by-recommendation-id?recommendationId=" + recommendationId);
  }

  getActiveByRecommendationId(recommendationId) {
    return api.get("/LearningObject/get-active-by-recommendation-id?recommendationId=" + recommendationId);
  }

  getByAttributeId(attributeId) {
    return api.get("/LearningObject/get-by-attribute-id?attributeId=" + attributeId);
  }

  getActiveByAttributeId(attributeId) {
    return api.get("/LearningObject/get-active-by-attribute-id?attributeId=" + attributeId);
  }

  getByTopicId(topicId) {
    return api.get("/LearningObject/get-by-topic-id?topicId=" + topicId);
  }

  getActiveByTopicId(topicId) {
    return api.get("/LearningObject/get-active-by-topic-id?topicId=" + topicId);
  }

  getByCompetenceId(competenceId) {
    return api.get("/LearningObject/get-by-competence-id?competenceId=" + competenceId);
  }

  getActiveByCompetenceId(competenceId) {
    return api.get("/LearningObject/get-active-by-competence-id?competenceId=" + competenceId);
  }

  getActiveByCategoryId(categoryId) {
    return api.get("/LearningObject/get-active-by-category-id?categoryId=" + categoryId);
  }

  getByCategoryId(id) {
    return api.get("/LearningObject/get-by-category-id?categoryId=" + id);
  }

  getByAttributeById(id) {
    return api.get("/LearningObject/get-by-attribute-id?attributeId=" + id);
  }

  getAllLearningObjects() {
    return api.get("/LearningObject/get-all");
  }

  getById(id) {
    return api.get("/LearningObject/get-by-id?id=" + id);
  }

  insert(newLearningObject) {
    return api.post("/LearningObject/insert", newLearningObject);
  }

  update(updatedLearningObject) {
    return api.post("/LearningObject/update", updatedLearningObject);
  }

  deactivate(id) {
    return api.post("/LearningObject/deactivate?id=" + id);
  }

  activate(id) {
    return api.post("/LearningObject/activate?id=" + id);
  }

  delete(id) {
    return api.post("/LearningObject/delete?id=" + id);
  }
}

export default new LearningObjectService();
