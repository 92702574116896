const languages = [
  {
    id: 1,
    lang: "en",
    flag: "GB",
    name: "English",
  },
  {
    id: 2,
    lang: "de",
    flag: "DE",
    name: "Germany",
  },
  {
    id: 3,
    lang: "fr",
    flag: "FR",
    name: "French",
  },
];

export default languages;
