import api from "./api";

class ScheduleService {
  getByLearningPathId(learningPathId) {
    return api.get("/Schedule/get-by-learning-path-id?learningPathId=" + learningPathId);
  }

  batchUpsert(learningPathId, schedules) {
    return api.post("/Schedule/batch-upsert", { learningPathId: learningPathId, schedules: schedules });
  }
}

export default new ScheduleService();
