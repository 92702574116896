import React, { Fragment, Component } from "react";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import { withTranslation } from "react-i18next";
import PageTitle from "../../../Layout/AppMain/PageTitle";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import PerfectScrollbar from "react-perfect-scrollbar";
import {
  UncontrolledDropdown,
  DropdownMenu,
  DropdownToggle,
  DropdownItem,
  Row,
  Col,
  Card,
  CardBody,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input,
  FormGroup,
  Form,
  Label,
  Button,
  CardTitle,
} from "reactstrap";
import { FilterMatchMode } from "primereact/api";
import { Calendar } from "primereact/calendar";
import Select from "react-select";
import "../Attribute/DataTable.css";
import { classNames } from "primereact/utils";
import { TriStateCheckbox } from "primereact/tristatecheckbox";
import { InputText } from "primereact/inputtext";
import CompetenceFrameService from "../../../service/CompetenceFrameService";
import CompetenceService from "../../../service/CompetenceService";
import languages from "../../../models/Languages";
import moment from "moment/moment";
import Flag from "react-flagkit";
import tokenService from "../../../service/tokenService";
import { CSVLink } from "react-csv";

class CompetenceFrame extends Component {
  constructor(props) {
    super(props);
    this.state = {
      competences: [],
      competenceFrames: [],
      selectedCompetenceFrame: {},
      selectedFilteringTableLanguage: languages.find((obj) => obj.lang === this.props.i18n.language),
      filterCompetenceFrameDropdownLanguages: languages,

      creatingCompetenceFrameTranslationLanguages: languages,
      selectedCreatingCompetenceFrameTranslationLanguage: languages[0],
      selectedEditingCompetenceFrameTranslationLanguage: "",

      creatingCompetenceFrameTranslationName: "", //need join
      creatingCompetenceFrameTranslationComment: "", //

      competenceFrameTranslations: [],
      filteredCompetenceFrames: [],
      loggedUser: tokenService.getUser(),
      isUpdate: true,
      modal: false,
      deleteModal: false,
      activeModal: false,
      filterCompetenceFramesTemplate: {
        name: { value: null, matchMode: FilterMatchMode.CONTAINS },
        userName: { value: null, matchMode: FilterMatchMode.CONTAINS },
        comment: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
        createdDate: { value: null, matchMode: FilterMatchMode.DATE_IS },
        createdByUser: { value: null, matchMode: FilterMatchMode.CONTAINS },
        isPublic: { value: tokenService.getCompany().showPublicCompetenceFrame ? null : false, matchMode: FilterMatchMode.EQUALS },
        isActive: { value: null, matchMode: FilterMatchMode.EQUALS },
      },

      error: {},

      csvHeaders: [
        { label: "Name", key: "name" },
        { label: "Created By", key: "createdByUser" },
      ],
    };
  }

  componentDidMount() {
    this.getAllCompetenceFrames();
  }

  getCompetenceFrameById(id) {
    return CompetenceFrameService.getById(id)
      .then((response) => {
        this.setState({
          competenceFrameTranslations: response.data.translations,
          selectedCompetenceFrame: response.data,
          isUpdate: true,
        });
        this.filterCompetenceFrameDropdownLanguages();
      })
      .catch((e) => {
        console.log(e);
      });
  }

  getAllCompetenceFrames = () => {
    CompetenceFrameService.getAll()
      .then((response) => {
        this.setState(
          {
            competenceFrames: response.data,
          },
          () => {
            this.filterCompetenceFrames(this.state.selectedFilteringTableLanguage.lang);
          }
        );
      })
      .catch((e) => {
        console.log(e);
      });
  };

  filterCompetenceFrames(lang) {
    var filteredCompetenceFrames = this.state.competenceFrames.map(({ id, translations, createdDate, isPublic, isActive, createdByUser }) => ({
      language: translations.find((obj) => obj.language === lang)?.language,
      name: translations.find((obj) => obj.language === lang)?.name,
      comment: translations.find((obj) => obj.language === lang)?.comment,
      createdByUser: createdByUser,
      isPublic: isPublic,
      isActive: isActive,
      id: id,
      createdDate: moment(new Date(createdDate)).format("DD.MM.YYYY"),
    }));
    this.setState({
      filteredCompetenceFrames: filteredCompetenceFrames.filter((obj) => obj.language),
    });
  }

  getCompetencesByCompetenceFrameId(id) {
    return CompetenceService.getByCompetenceFrameId(id)
      .then((response) => {
        var filteredCompetences = response.data.map(({ translations, createdByUser, isActive, isPublic }) => ({
          name:
            translations.find((obj) => obj.language === this.state.selectedFilteringTableLanguage.lang)?.name + (!isActive ? " (inactive) " : " ") + (isPublic ? " (public)" : ""),
          createdByUser: createdByUser,
        }));

        this.setState({
          competences: filteredCompetences.filter((t) => !t.name.startsWith("undefined")),
        });
      })
      .catch((e) => {
        console.log(e);
      });
  }

  openDeactivateModel = () => {
    this.getCompetencesByCompetenceFrameId(this.state.selectedCompetenceFrame.id).then(() => {
      this.changeActiveModalState();
    });
  };

  openDeleteModel = () => {
    this.getCompetencesByCompetenceFrameId(this.state.selectedCompetenceFrame.id).then(() => {
      this.changeDeleteModalState();
    });
  };

  isEditable = () => {
    return !this.state.selectedCompetenceFrame.isPublic || this.isM2LAdmin();
  };

  isM2LAdmin = () => {
    return this.state.loggedUser?.currentRole === "SystemAdmin" && this.state.loggedUser?.currentCompanyId === null;
  };

  updateCompetenceFrame(e) {
    e.preventDefault();

    let competenceFrame = this.state.selectedCompetenceFrame;
    competenceFrame.isActive = true;
    competenceFrame.translations = this.state.competenceFrameTranslations;
    var updateCompetenceFrame = competenceFrame;
    CompetenceFrameService.update(updateCompetenceFrame).then(() => {
      this.changeModalState();
      this.getAllCompetenceFrames();
    });
  }

  createNewCompetenceFrame(e) {
    e.preventDefault();
    let competenceFrame = this.state.selectedCompetenceFrame;
    competenceFrame.translations = this.state.competenceFrameTranslations;
    competenceFrame.isActive = true;
    CompetenceFrameService.insert(competenceFrame).then(() => {
      this.changeModalState();
      this.getAllCompetenceFrames();
    });
  }

  changeModalState = () => {
    this.setState({
      modal: !this.state.modal,
      error: {},
      creatingCompetenceFrameTranslationName: "",
      creatingCompetenceFrameTranslationComment: "",
    });
  };

  changePublic = (event) => {
    let st = this.state.selectedCompetenceFrame;
    st.isPublic = event.target.checked;
    this.setState({
      selectedCompetenceFrame: st,
    });
  };

  onChangeCreatingCompetenceFramesTranslationLanguage = (e) => {
    this.setState({ selectedCreatingCompetenceFrameTranslationLanguage: e });
  };

  onChangeEditingCompetenceFrameTranslationLanguage = (e) => {
    this.setState({ selectedEditingCompetenceFrameTranslationLanguage: e });
  };

  onChangeFilteringTableLanguage = (e) => {
    this.setState({ selectedFilteringTableLanguage: e });
    this.filterCompetenceFrames(e.lang);
  };

  changeCreatingCompetenceFrameTranslationComment = (e) => {
    this.setState({ creatingCompetenceFrameTranslationComment: e.target.value });
    this.createNewTranslationValueChange(e);
  };

  changeCreatingCompetenceFrameTranslationName = (e) => {
    this.setState({ creatingCompetenceFrameTranslationName: e.target.value });
    this.createNewTranslationValueChange(e);
  };

  filterCompetenceFrameDropdownLanguages() {
    let filteredCompetenceFrameLanguages = this.state.competenceFrameTranslations.map((obj) => obj.language);
    let creatingCompetenceFrameTranslationLanguages = languages.filter((obj) => !filteredCompetenceFrameLanguages.includes(obj.lang));
    this.setState({
      creatingCompetenceFrameTranslationLanguages: creatingCompetenceFrameTranslationLanguages,
      selectedCreatingCompetenceFrameTranslationLanguage: creatingCompetenceFrameTranslationLanguages[0],
    });
  }

  createNewCompetenceFrameTranslation = (e) => {
    e.preventDefault();
    var error = { ...this.state.error };
    error.name = this.state.creatingCompetenceFrameTranslationName.length < 1 ? "Required" : "";
    this.setState({ error });
    if (this.createNewTranslationFormValid({ error: error })) {
      var newCompetenceFrameTranslation = {
        language: this.state.selectedCreatingCompetenceFrameTranslationLanguage.lang,
        name: this.state.creatingCompetenceFrameTranslationName,
        comment: this.state.creatingCompetenceFrameTranslationComment,
      };
      this.state.competenceFrameTranslations.push(newCompetenceFrameTranslation);
      this.setState({
        competenceFrameTranslations: this.state.competenceFrameTranslations,
      });
      this.filterCompetenceFrameDropdownLanguages();

      this.setState({ creatingCompetenceFrameTranslationName: "", creatingCompetenceFrameTranslationComment: "" });
    }
  };

  changeActiveModalState = () => {
    this.setState({
      activeModal: !this.state.activeModal,
    });
  };

  confirmDeactivateCompetenceFrame = () => {
    this.changeActiveModalState();
    CompetenceFrameService.deactivate(this.state.selectedCompetenceFrame.id).then(() => {
      var selected = this.state.selectedCompetenceFrame;
      selected.isActive = false;
      this.setState({ selectedCompetenceFrame: selected });
      this.getAllCompetenceFrames();
    });
  };

  confirmActivateCompetenceFrame = () => {
    this.changeActiveModalState();
    CompetenceFrameService.activate(this.state.selectedCompetenceFrame.id).then(() => {
      var selected = this.state.selectedCompetenceFrame;
      selected.isActive = true;
      this.setState({ selectedCompetenceFrame: selected });
      this.getAllCompetenceFrames();
    });
  };

  changeDeleteModalState = () => {
    this.setState({
      deleteModal: !this.state.deleteModal,
    });
  };

  confirmDeleteCompetenceFrame = () => {
    CompetenceFrameService.delete(this.state.selectedCompetenceFrame.id).then(() => {
      this.getAllCompetenceFrames();
      this.changeDeleteModalState();
      this.changeModalState();
    });
  };

  dateFilterTemplate = (options) => {
    return (
      <Calendar value={new Date(options.value).toLocaleString()} onChange={(e) => options.filterCallback(e.value, options.index)} dateFormat="mm/dd/yy" placeholder="mm/dd/yyyy" />
    );
  };
  dateCompetenceFramesTemplate = (rowData) => {
    return rowData.createdDate;
  };

  createNewTranslationFormValid = ({ error, ...rest }) => {
    let isValid = true;
    Object.values(error).forEach((val) => {
      if (isValid === false) {
        return;
      }
      if (val.length > 0) {
        isValid = false;
      } else {
        isValid = true;
      }
    });
    Object.values(rest).forEach((val) => {
      if (isValid === false) {
        return;
      }
      if (val === null) {
        isValid = false;
      } else {
        isValid = true;
      }
    });
    return isValid;
  };
  createNewTranslationValueChange = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    let error = { ...this.state.error };
    switch (name) {
      case "name":
        error.name = value.length < 1 ? "Required" : "";
        break;
      default:
        break;
    }
    this.setState({
      error,
      [name]: value,
    });
  };

  render() {
    const { t } = this.props;
    const { error } = this.state;

    const countryBodyTemplate = (rowData) => {
      return (
        <div className="country-item">
          <div>{languages.find((obj) => obj.lang === rowData.language)?.name}</div>
        </div>
      );
    };

    const languageEditorTemplate = (options) => {
      return (
        <Select
          options={this.state.creatingCompetenceFrameTranslationLanguages}
          onChange={this.onChangeEditingCompetenceFrameTranslationLanguage}
          getOptionLabel={(option) => option.name}
          getOptionValue={(option) => option.name}
          key={languages.find((obj) => obj.lang === options.rowData?.language)}
          defaultValue={languages.find((obj) => obj.lang === options.rowData?.language)}
        />
      );
    };

    const openUpdateCompetenceFrameTranslationModal = (id) => {
      this.getCompetenceFrameById(id).then(() => this.changeModalState());
    };

    const openCreateCompetenceFrame = () => {
      var competenceFrame = {};
      competenceFrame.createdByUser = this.state.loggedUser.firstName + " " + this.state.loggedUser.lastName;
      competenceFrame.isPublic = this.isM2LAdmin();
      competenceFrame.isActive = true;
      competenceFrame.createdDate = new Date();
      this.setState(
        {
          selectedCompetenceFrame: competenceFrame,
          competenceFrameTranslations: [],
          creatingCompetenceFrameTranslationLanguages: languages,
          selectedCreatingCompetenceFrameTranslationLanguage: this.state.selectedFilteringTableLanguage,
          isUpdate: false,
        },
        () => {
          this.changeModalState();
        }
      );
    };

    const deleteCompetenceFrameTranslation = (language) => {
      this.state.creatingCompetenceFrameTranslationLanguages.push(languages.find((obj) => obj.lang === language));
      this.setState({
        competenceFrameTranslations: this.state.competenceFrameTranslations.filter((obj) => obj.language !== language),
        selectedCreatingCompetenceFrameTranslationLanguage: this.state.creatingCompetenceFrameTranslationLanguages[0],
        filterCompetenceFrameDropdownLanguages: this.state.creatingCompetenceFrameTranslationLanguages,
      });
    };

    const buttonDeleteCompetenceFrameTranslation = (rowData) => {
      if (this.isEditable()) {
        return (
          <Button outline color="primary" onClick={() => deleteCompetenceFrameTranslation(rowData.language)}>
            {t("COMPETENCEFRAME.DELETE_BUTTON")}
          </Button>
        );
      }
    };

    const buttonUpdateCompetenceFrameTranslation = (rowData) => {
      return (
        <Button className="me-2" size="sm" outline color="primary" onClick={() => openUpdateCompetenceFrameTranslationModal(rowData.id)}>
          {t("COMPETENCEFRAME.OPEN_BUTTON")}
        </Button>
      );
    };

    const formatIsPublicCheckBox = (rowData) => {
      return (
        <i
          className={classNames("pi", {
            "true-icon pi-check-circle": rowData.isPublic,
            "false-icon pi-times-circle": !rowData.isPublic,
          })}
        ></i>
      );
    };
    const formatIsActiveCheckBox = (rowData) => {
      return (
        <i
          className={classNames("pi", {
            "true-icon pi-check-circle": rowData.isActive,
            "false-icon pi-times-circle": !rowData.isActive,
          })}
        ></i>
      );
    };

    const languageTableFilter = () => {
      return (
        <div>
          <UncontrolledDropdown>
            <DropdownToggle className="p-0 me-2" color="black">
              <div className="icon-wrapper-bg bg-focus" />
              <div className="language-icon">
                <Flag className="me-3 opacity-8" country={this.state.selectedFilteringTableLanguage?.flag} size="30" />
              </div>
            </DropdownToggle>
            <DropdownMenu end className="rm-pointers">
              {languages.map((language, id) => (
                <DropdownItem key={id} value={language} onClick={() => this.onChangeFilteringTableLanguage(language)}>
                  <Flag className="me-3 opacity-8" country={language.flag} />
                  {language.name}
                </DropdownItem>
              ))}
            </DropdownMenu>
          </UncontrolledDropdown>
        </div>
      );
    };

    const languageBody = (option) => {
      return (
        <Flag
          className="me-3 opacity-8"
          style={{
            marginLeft: "0.75rem",
          }}
          country={languages.find((obj) => obj.lang === option.language)?.flag}
          size="30"
        />
      );
    };

    const verifiedRowFilterTemplate = (options) => {
      return <TriStateCheckbox value={options.value} onChange={(e) => options.filterApplyCallback(e.value)} />;
    };

    const textEditor = (options) => {
      return <InputText type="text" value={options.value} onChange={(e) => options.editorCallback(e.target.value)} />;
    };

    const onRowEditComplete = (e) => {
      let updateCompetenceFrames = this.state.competenceFrameTranslations;
      let { newData, index } = e;
      newData.language = this.state.selectedEditingCompetenceFrameTranslationLanguage.lang ? this.state.selectedEditingCompetenceFrameTranslationLanguage.lang : e.data.language;
      updateCompetenceFrames[index] = newData;
      this.setState({
        competenceFrameTranslations: updateCompetenceFrames,
      });
      this.filterCompetenceFrameDropdownLanguages();
    };

    const renderActiveDeactivateButton = () => {
      if (this.state.selectedCompetenceFrame.id) {
        if (this.state.selectedCompetenceFrame.isActive) {
          return (
            <Button className="mb-2 me-2 " outline color="danger" onClick={this.openDeactivateModel}>
              {t("COMPETENCEFRAME.DEACTIVATE_BUTTON")}
            </Button>
          );
        } else {
          return (
            <Button className="mb-2 me-2 " outline color="success" onClick={this.changeActiveModalState}>
              {t("COMPETENCEFRAME.ACTIVATE_BUTTON")}
            </Button>
          );
        }
      }
    };

    return (
      <Fragment>
        <TransitionGroup>
          <CSSTransition component="div" classNames="TabsAnimation" appear={true} timeout={1500} enter={false} exit={false}>
            <div>
              <PageTitle heading={t("COMPETENCEFRAME.TITLE")} subheading={t("COMPANY.DESCRIPTION")} icon="pe-7s-medal icon-gradient bg-tempting-azure" />
              <Row>
                <Col md="12">
                  <Button className="mb-2 me-2  " outline color="primary" onClick={() => openCreateCompetenceFrame()}>
                    {t("COMPETENCEFRAME.CREATE_BUTTON")}
                  </Button>
                  <Modal isOpen={this.state.modal} fade={false} toggle={this.changeModalState}>
                    <ModalHeader toggle={this.changeModalState}>
                      {this.state.isUpdate ? t("COMPETENCEFRAME.EDIT_MODAL_TITLE") : t("COMPETENCEFRAME.CREATE_MODAL_TITLE")}
                    </ModalHeader>
                    <ModalBody>
                      <div className="card p-fluid">
                        <Row style={{ fontWeight: "bold", textAlign: "center", padding: "10px" }}>
                          <Col md="4">
                            <Label for="createdByUser">
                              {t("COMPETENCEFRAME.CREATED_BY")} {this.state.selectedCompetenceFrame.createdByUser}
                            </Label>
                          </Col>
                          <Col md="4">
                            <Label for="createdDate">
                              {t("COMPETENCEFRAME.CREATED_DATE")} {moment(this.state.selectedCompetenceFrame.createdDate).format("DD.MM.YYYY")}
                            </Label>
                          </Col>
                          <Col md="4">
                            {this.isM2LAdmin() && (
                              <FormGroup>
                                <Label>
                                  <Input
                                    type="checkbox"
                                    checked={this.state.selectedCompetenceFrame.isPublic}
                                    disabled={!this.state.selectedCompetenceFrame.isActive || this.state.loggedUser.currentRole !== "SystemAdmin"}
                                    onChange={this.changePublic}
                                  />
                                  {t("COMPETENCEFRAME.IS_PUBLIC")}
                                </Label>
                              </FormGroup>
                            )}
                          </Col>
                        </Row>
                      </div>
                      {this.isEditable() && (
                        <div className="card p-fluid">
                          <Form onSubmit={this.createNewCompetenceFrameTranslation} style={{ margin: "10px" }}>
                            <Row>
                              <Col>
                                <FormGroup>
                                  <Label for="exampleSelect">{t("COMPETENCEFRAME.LANGUAGE")}</Label>
                                  <Select
                                    options={this.state.creatingCompetenceFrameTranslationLanguages}
                                    value={this.state.selectedCreatingCompetenceFrameTranslationLanguage ? this.state.selectedCreatingCompetenceFrameTranslationLanguage : " "}
                                    onChange={this.onChangeCreatingCompetenceFramesTranslationLanguage}
                                    getOptionLabel={(option) => option.name}
                                    getOptionValue={(option) => option.lang}
                                  />
                                </FormGroup>
                              </Col>
                              <Col>
                                <FormGroup>
                                  <Label for="name"> {t("COMPETENCEFRAME.NAME")}</Label>
                                  <Input
                                    type="text"
                                    name="name"
                                    id="name"
                                    onChange={this.changeCreatingCompetenceFrameTranslationName}
                                    value={this.state.creatingCompetenceFrameTranslationName}
                                    className={error.name ? "is-invalid form-control" : "form-control"}
                                  />
                                  {error.name && <span className="invalid-feedback">{error.name}</span>}
                                </FormGroup>
                              </Col>
                              <Col>
                                <FormGroup>
                                  <Label for="comment">{t("COMPETENCEFRAME.COMMENT")}</Label>
                                  <Input
                                    type="text"
                                    name="comment"
                                    id="comment"
                                    onChange={this.changeCreatingCompetenceFrameTranslationComment}
                                    value={this.state.creatingCompetenceFrameTranslationComment}
                                    className="form-control"
                                  />
                                </FormGroup>
                              </Col>

                              <Col>
                                <Button className="mb-2 me-2  " outline color="primary" style={{ marginTop: "30px" }} type="submit">
                                  {t("COMPETENCEFRAME.CREATE_BUTTON")}
                                </Button>
                              </Col>
                            </Row>
                          </Form>
                        </div>
                      )}

                      <div className="card p-fluid">
                        <DataTable
                          editMode="row"
                          value={this.state.competenceFrameTranslations}
                          dataKey="id"
                          className="p-datatable-customers"
                          onRowEditComplete={onRowEditComplete}
                          // responsiveLayout="scroll"
                        >
                          <Column
                            field="language"
                            header={t("COMPETENCEFRAME.LANGUAGE_COLUMN_NAME")}
                            body={countryBodyTemplate}
                            editor={(options) => languageEditorTemplate(options)}
                            style={{ width: "20%", zIndex: "2" }}
                          ></Column>
                          <Column field="name" header={t("COMPETENCEFRAME.NAME_COLUMN_NAME")} editor={(options) => textEditor(options)} style={{ width: "20%" }}></Column>
                          <Column field="comment" header={t("COMPETENCEFRAME.COMMENT_COLUMN_NAME")} editor={(options) => textEditor(options)} style={{ width: "20%" }}></Column>
                          <Column rowEditor={this.isEditable()} headerStyle={{ width: "10%", minWidth: "8rem" }} bodyStyle={{ textAlign: "center" }}></Column>
                          <Column style={{ minWidth: "6rem" }} body={buttonDeleteCompetenceFrameTranslation} />
                        </DataTable>
                      </div>
                    </ModalBody>
                    <ModalFooter>
                      <Modal isOpen={this.state.activeModal} toggle={this.changeActiveModalState} style={{ marginTop: "50px", width: "60%" }}>
                        <ModalHeader>
                          {this.state.selectedCompetenceFrame.isActive ? t("COMPETENCEFRAME.CONFIRM_DEACTIVATE_MODAL_TITLE") : t("COMPETENCEFRAME.CONFIRM_ACTIVATE_MODAL_TITLE")}
                        </ModalHeader>
                        <ModalBody style={{ minHeight: "auto" }}>
                          {this.state.selectedCompetenceFrame.isActive && this.state.competences.length > 0 ? (
                            <div>
                              <PerfectScrollbar>
                                {this.state.competences.length > 0 ? (
                                  <Card className="main-card mb-3">
                                    <CardTitle>{t("COMPETENCEFRAME.COMPETENCES")}</CardTitle>
                                    <DataTable value={this.state.competences} paginator rows={10}>
                                      <Column field="name" header={t("COMPETENCEFRAME.COMPETENCE_NAME_COLUMN_NAME")}></Column>
                                      <Column field="createdByUser" header={t("COMPETENCEFRAME.COMPETENCE_CREATED_BY_COLUMN_NAME")}></Column>
                                    </DataTable>
                                  </Card>
                                ) : (
                                  ""
                                )}
                              </PerfectScrollbar>
                            </div>
                          ) : (
                            ""
                          )}
                        </ModalBody>
                        <ModalFooter>
                          <Col>
                            {this.state.selectedCompetenceFrame.isActive && this.state.competences.length > 0 ? (
                              <CSVLink
                                data={this.state.competences}
                                filename={
                                  "deactivate_competence_" +
                                  this.state.competenceFrameTranslations.find((a) => a.language === this.state.selectedFilteringTableLanguage.lang)?.name +
                                  ".csv"
                                }
                                headers={this.state.csvHeaders}
                                className="mb-2 me-2 btn btn-success"
                                target="_blank"
                              >
                                {t("COMPETENCEFRAME.EXPORT_BUTTON")}
                              </CSVLink>
                            ) : (
                              ""
                            )}
                          </Col>
                          <Button className="mb-2 me-2 " color="secondary" onClick={this.changeActiveModalState}>
                            {t("COMPETENCEFRAME.NO_BUTTON")}
                          </Button>
                          <Button
                            className="mb-2 me-2 "
                            color="primary"
                            onClick={this.state.selectedCompetenceFrame.isActive ? this.confirmDeactivateCompetenceFrame : this.confirmActivateCompetenceFrame}
                          >
                            {t("COMPETENCEFRAME.YES_BUTTON")}
                          </Button>
                        </ModalFooter>
                      </Modal>

                      <Modal isOpen={this.state.deleteModal} toggle={this.changeDeleteModalState} style={{ marginTop: "50px", width: "60%" }}>
                        <ModalHeader> {this.state.competences.length > 0 ? t("COMPETENCEFRAME.DELETE_MODAL_TITLE") : t("COMPETENCEFRAME.CONFIRM_DELETE_MODAL_TITLE")}</ModalHeader>
                        <ModalBody style={{ minHeight: "auto" }}>
                          {this.state.competences.length > 0 ? (
                            <div>
                              <PerfectScrollbar>
                                {this.state.competences.length > 0 ? (
                                  <Card className="main-card mb-3">
                                    <CardTitle>{t("COMPETENCEFRAME.COMPETENCES")}</CardTitle>
                                    <DataTable value={this.state.competences} paginator rows={10}>
                                      <Column field="name" header={t("COMPETENCEFRAME.COMPETENCE_NAME_COLUMN_NAME")}></Column>
                                      <Column field="createdByUser" header={t("COMPETENCEFRAME.COMPETENCE_CREATED_BY_COLUMN_NAME")}></Column>
                                    </DataTable>
                                  </Card>
                                ) : (
                                  ""
                                )}
                              </PerfectScrollbar>
                            </div>
                          ) : (
                            ""
                          )}
                        </ModalBody>
                        <ModalFooter>
                          <Col>
                            {this.state.competences.length > 0 ? (
                              <CSVLink
                                data={this.state.competences}
                                filename={
                                  "delete_competence_frame_" +
                                  this.state.competenceFrameTranslations.find((a) => a.language === this.state.selectedFilteringTableLanguage.lang)?.name +
                                  ".csv"
                                }
                                headers={this.state.csvHeaders}
                                className="mb-2 me-2 btn btn-success"
                                target="_blank"
                              >
                                {t("COMPETENCEFRAME.EXPORT_BUTTON")}
                              </CSVLink>
                            ) : (
                              ""
                            )}
                          </Col>
                          {this.state.competences.length > 0 ? (
                            <div>
                              <Button className="mb-2 me-2 " color="secondary" onClick={this.changeDeleteModalState}>
                                {t("COMPETENCEFRAME.CANCEL_BUTTON")}
                              </Button>
                            </div>
                          ) : (
                            <div>
                              <Button className="mb-2 me-2 " color="secondary" onClick={this.changeDeleteModalState}>
                                {t("COMPETENCEFRAME.NO_BUTTON")}
                              </Button>
                              <Button className="mb-2 me-2 " color="primary" onClick={this.confirmDeleteCompetenceFrame}>
                                {t("COMPETENCEFRAME.YES_BUTTON")}
                              </Button>
                            </div>
                          )}
                        </ModalFooter>
                      </Modal>

                      {this.isEditable() ? (
                        <>
                          <Col>
                            {this.state.selectedCompetenceFrame.id ? (
                              <Button disabled={this.state.selectedCompetenceFrame.isActive} className="mb-2 me-2" color="danger" onClick={this.openDeleteModel}>
                                {t("COMPETENCEFRAME.DELETE_BUTTON")}
                              </Button>
                            ) : (
                              ""
                            )}
                            {renderActiveDeactivateButton()}
                          </Col>

                          <Button className="mb-2 me-2   " color="secondary" onClick={this.changeModalState}>
                            {t("COMPETENCEFRAME.CANCEL_BUTTON")}
                          </Button>
                          {this.state.isUpdate ? (
                            <Button className="mb-2 me-2  " color="primary" onClick={(e) => this.updateCompetenceFrame(e)}>
                              {t("COMPETENCEFRAME.UPDATE_BUTTON")}
                            </Button>
                          ) : (
                            <Button className="mb-2 me-2  " color="primary" onClick={(e) => this.createNewCompetenceFrame(e)}>
                              {t("COMPETENCEFRAME.SAVE_BUTTON")}
                            </Button>
                          )}
                        </>
                      ) : (
                        <Button className="mb-2 me-2" color="secondary" onClick={this.changeModalState}>
                          {t("COMPETENCEFRAME.CLOSE_BUTTON")}
                        </Button>
                      )}
                    </ModalFooter>
                  </Modal>
                </Col>
                <Col md="12">
                  <Card className="main-card mb-3">
                    <CardBody>
                      <div className="card p-fluid">
                        <DataTable
                          value={this.state.filteredCompetenceFrames}
                          paginator
                          className="p-datatable-customers"
                          rows={10}
                          dataKey="id"
                          filterDisplay="row"
                          responsiveLayout="scroll"
                          filters={this.state.filterCompetenceFramesTemplate}
                          emptyMessage={t("COMPETENCEFRAME.TABLE_MESSAGE")}
                        >
                          <Column
                            header={t("COMPETENCEFRAME.LANGUAGE_COLUMN_NAME")}
                            field="language"
                            showFilterMenu={false}
                            style={{ minWidth: "10rem" }}
                            body={languageBody}
                            filter
                            filterElement={languageTableFilter}
                          />
                          <Column header={t("COMPETENCEFRAME.NAME_COLUMN_NAME")} field="name" filter sortable />
                          <Column header={t("COMPETENCEFRAME.COMMENT_COLUMN_NAME")} field="comment" filter sortable />
                          <Column
                            field="isPublic"
                            header={t("COMPETENCEFRAME.IS_PUBLIC_COLUMN_NAME")}
                            dataType="boolean"
                            sortable
                            body={formatIsPublicCheckBox}
                            filter
                            filterElement={verifiedRowFilterTemplate}
                          />
                          <Column
                            field="isActive"
                            header={t("COMPETENCEFRAME.IS_ACTIVE_COLUMN_NAME")}
                            dataType="boolean"
                            sortable
                            body={formatIsActiveCheckBox}
                            filter
                            filterElement={verifiedRowFilterTemplate}
                          />
                          <Column field="createdByUser" header={t("COMPETENCEFRAME.CREATED_BY_COLUMN_NAME")} filter sortable />
                          <Column header={t("COMPETENCEFRAME.CREATED_DATE_COLUMN_NAME")} filterField="createdDate" dataType="date" body={this.dateCompetenceFramesTemplate} />
                          <Column body={buttonUpdateCompetenceFrameTranslation} rowEditor />
                        </DataTable>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </div>
          </CSSTransition>
        </TransitionGroup>
      </Fragment>
    );
  }
}
export default withTranslation()(CompetenceFrame);
