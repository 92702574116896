import React, { Fragment, Component } from "react";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import { withTranslation } from "react-i18next";
import PageTitle from "../../../Layout/AppMain/PageTitle";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Row, Col, Card, CardBody, Modal, ModalHeader, ModalBody, ModalFooter, Input, FormGroup, Form, Label, Button, CardTitle } from "reactstrap";
import { FilterMatchMode } from "primereact/api";
import "../../Metadata/Attribute/DataTable.css";
import validator from "validator";
import { classNames } from "primereact/utils";
import { TriStateCheckbox } from "primereact/tristatecheckbox";
import CompanyService from "../../../service/CompanyService";
import AvatarPicker from "../../Shared/AvatarPicker";
import UserService from "../../../service/UserService";
import PerfectScrollbar from "react-perfect-scrollbar";
import { CSVLink } from "react-csv";

class Company extends Component {
  constructor(props) {
    super(props);
    this.state = {
      companies: [],
      selectedCompany: {},
      modal: false,
      deleteModal: false,
      activeModal: false,
      users: [],
      filterCompanyTemplate: {
        name: { value: null, matchMode: FilterMatchMode.CONTAINS },
        contactName: { value: null, matchMode: FilterMatchMode.CONTAINS },
        email: { value: null, matchMode: FilterMatchMode.CONTAINS },
        phone: { value: null, matchMode: FilterMatchMode.CONTAINS },
        isActive: { value: null, matchMode: FilterMatchMode.EQUALS },
      },

      isError: {},

      csvHeaders: [
        { label: "First Name", key: "firstName" },
        { label: "Last Name", key: "lastName" },
        { label: "Email", key: "email" },
      ],
    };
  }

  componentDidMount() {
    this.getAllCompanies();
  }

  getCompanyById(id) {
    CompanyService.getCompanyById(id)
      .then((response) => {
        this.setState({
          selectedCompany: response.data,
        });

        this.changeModalState();
      })
      .catch((e) => {
        console.log(e);
      });
  }

  getActiveUsersByCompanyId(id) {
    return UserService.getActiveUsersByCompanyId(id)
      .then((response) => {
        this.setState({
          users: response.data,
        });
      })
      .catch((e) => {
        console.log(e);
      });
  }

  confirmDeactivateCompany = () => {
    return CompanyService.deactivate(this.state.selectedCompany.id)
      .then((response) => {
        this.changeActiveModalState();
        this.getAllCompanies().then(() => {
          this.setState((prevState) => ({
            selectedCompany: {
              ...prevState.selectedCompany,
              isActive: false,
            },
          }));
        });
      })
      .catch((e) => {
        console.log(e);
      });
  };

  confirmActivateCompany = () => {
    return CompanyService.activate(this.state.selectedCompany.id)
      .then((response) => {
        this.changeActiveModalState();
        this.getAllCompanies().then(() => {
          this.setState((prevState) => ({
            selectedCompany: {
              ...prevState.selectedCompany,
              isActive: true,
            },
          }));
        });
      })
      .catch((e) => {
        console.log(e);
      });
  };

  confirmDeleteCategory = () => {
    CompanyService.delete(this.state.selectedCompany.id)
      .then((response) => {
        this.getAllCompanies().then(() => {
          this.changeDeleteModalState();
          this.changeModalState();
        });
      })
      .catch((e) => {
        console.log(e);
      });
  };

  openDeleteModal = () => {
    this.getUserByCompanyId(this.state.selectedCompany.id).then(() => {
      this.changeDeleteModalState();
    });
  };

  getUserByCompanyId(id) {
    return UserService.getUsersByCompanyId(id)
      .then((response) => {
        this.setState({
          users: response.data,
        });
      })
      .catch((e) => {
        console.log(e);
      });
  }

  changeActiveModalState = () => {
    this.setState({
      activeModal: !this.state.activeModal,
    });
  };

  changeDeleteModalState = () => {
    this.setState({
      deleteModal: !this.state.deleteModal,
    });
  };

  openDeactivateModel = () => {
    this.getActiveUsersByCompanyId(this.state.selectedCompany.id).then(() => {
      this.changeActiveModalState();
    });
  };

  getAllCompanies = () => {
    return CompanyService.getAllCompanies()
      .then((response) => {
        this.setState({
          companies: response.data,
        });
      })
      .catch((e) => {
        console.log(e);
      });
  };
  updateCompany = (e) => {
    e.preventDefault();
    var isError = { ...this.state.isError };
    isError.name = this.state.selectedCompany.name.length < 1 ? "Required" : "";
    isError.contactName = this.state.selectedCompany.contactName.length < 1 ? "Required" : "";
    isError.email = this.state.selectedCompany.email.length < 1 ? "Required" : "";
    isError.phone = this.state.selectedCompany.phone.length < 1 ? "Required" : "";
    if (!isError.email) {
      isError.email = !validator.isEmail(this.state.selectedCompany.email) ? "Email is not valid" : "";
    }
    this.setState({ isError });

    if (this.checkValid({ isError: isError })) {
      CompanyService.update(this.state.selectedCompany).then(() => {
        this.getAllCompanies();
        this.changeModalState();
      });
    }
  };

  createCompany = (e) => {
    e.preventDefault();
    var isError = { ...this.state.isError };
    isError.name = !this.state.selectedCompany?.name || this.state.selectedCompany.name.length < 1 ? "Required" : "";
    isError.contactName = !this.state.selectedCompany?.contactName || this.state.selectedCompany.contactName.length < 1 ? "Required" : "";
    isError.email = !this.state.selectedCompany?.email || this.state.selectedCompany.email.length < 1 ? "Required" : "";
    isError.phone = !this.state.selectedCompany?.phone || this.state.selectedCompany.phone.length < 1 ? "Required" : "";
    if (!isError.email) {
      isError.email = !validator.isEmail(this.state.selectedCompany.email) ? "Email is not valid" : "";
    }
    this.setState({ isError });

    if (this.checkValid({ isError: isError })) {
      CompanyService.insert(this.state.selectedCompany)
        .then((response) => {
          this.changeModalState();
          this.setState({ selectedCompany: {} });
          this.getAllCompanies();
        })
        .catch((e) => {
          console.log(e);
        });
    }
  };

  changeModalState = () => {
    this.setState({
      modal: !this.state.modal,
      isError: {},
    });
  };

  changeCreatingCompanyContactName = (e) => {
    this.setState((prevState) => ({
      selectedCompany: {
        ...prevState.selectedCompany,
        contactName: e.target.value,
      },
    }));
    this.valueChange(e);
  };

  changeCreatingCompanyEmail = (e) => {
    this.setState((prevState) => ({
      selectedCompany: {
        ...prevState.selectedCompany,
        email: e.target.value,
      },
    }));
    this.valueChange(e);
  };

  changeCreatingCompanyPhone = (e) => {
    this.setState((prevState) => ({
      selectedCompany: {
        ...prevState.selectedCompany,
        phone: e.target.value,
      },
    }));
    this.valueChange(e);
  };

  changeCreatingCompanyName = (e) => {
    this.setState((prevState) => ({ selectedCompany: { ...prevState.selectedCompany, name: e.target.value } }));
    this.valueChange(e);
  };

  checkValid = ({ isError, ...rest }) => {
    let isValid = true;
    Object.values(isError).forEach((val) => {
      if (isValid === false) {
        return;
      }
      if (val.length > 0) {
        isValid = false;
      } else {
        isValid = true;
      }
    });
    if (rest) {
      Object.values(rest).forEach((val) => {
        if (isValid === false) {
          return;
        }
        if (val === null) {
          isValid = false;
        } else {
          isValid = true;
        }
      });
    }
    return isValid;
  };

  valueChange = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    let isError = { ...this.state.isError };
    switch (name) {
      case "name":
        isError.name = value.length < 1 ? "Required" : "";
        break;
      case "contactName":
        isError.contactName = value.length < 1 ? "Required" : "";
        break;
      case "email":
        isError.email = value.length < 1 ? "Required" : "";
        break;
      case "phone":
        isError.phone = value.length < 1 ? "Required" : "";
        break;
      default:
        break;
    }
    this.setState({
      isError,
      [name]: value,
    });
  };

  openUpdateCompany = (id) => {
    this.getCompanyById(id);
  };

  openUsers = (id) => {
    let companyId = "id=" + id;
    this.props.history.push(`/organization/user?${companyId}`);
  };

  openCreateCompany = () => {
    this.setState({ selectedCompany: {} });
    this.changeModalState();
  };

  handleImageChange = (imageFile) => {
    this.setState((prevState) => ({
      selectedCompany: {
        ...prevState.selectedCompany,
        logo: imageFile?.name,
      },
    }));
  };

  render() {
    const { t } = this.props;

    const buttonUpdateCompany = (rowData) => {
      return (
        <Button className="me-2  " size="sm" outline color="primary" onClick={() => this.openUpdateCompany(rowData.id)}>
          {t("COMPANY.OPEN_BUTTON")}
        </Button>
      );
    };

    const buttonOpenUsers = (rowData) => {
      return (
        <Button className="me-2" size="sm" outline color="primary" onClick={() => this.openUsers(rowData.id)}>
          {t("COMPANY.USERS_BUTTON")}
        </Button>
      );
    };

    const formatCheckBoxCompany = (rowData) => {
      return <i className={classNames("pi", { "true-icon pi-check-circle": rowData.isActive, "false-icon pi-times-circle": !rowData.isActive })}></i>;
    };

    const formatNameCompany = (rowData) => {
      return (
        <>
          {rowData?.logo ? <img alt={rowData.name} src={"data:image/*;base64," + rowData.logo} style={{ width: "25px" }} /> : ""}
          {"  " + rowData.name}
        </>
      );
    };

    const verifiedRowFilterTemplate = (options) => {
      return <TriStateCheckbox value={options.value} onChange={(e) => options.filterApplyCallback(e.value)} />;
    };

    const renderActiveDeactivateButton = () => {
      if (this.state.selectedCompany.id) {
        if (this.state.selectedCompany.isActive) {
          return (
            <Button className="mb-2 me-2 " outline color="danger" onClick={this.openDeactivateModel}>
              {t("COMPANY.DEACTIVATE_BUTTON")}
            </Button>
          );
        } else {
          return (
            <Button className="mb-2 me-2 " outline color="success" onClick={this.changeActiveModalState}>
              {t("COMPANY.ACTIVATE_BUTTON")}
            </Button>
          );
        }
      }
    };

    const { isError } = this.state;
    return (
      <Fragment>
        <TransitionGroup>
          <CSSTransition component="div" classNames="TabsAnimation" appear={true} timeout={1500} enter={false} exit={false}>
            <div>
              <PageTitle heading={t("COMPANY.TITLE")} subheading={t("COMPANY.DESCRIPTION")} icon="pe-7s-medal icon-gradient bg-tempting-azure" />
              <Row>
                <Col md="12">
                  <Button className="mb-2 me-2  " outline color="primary" onClick={() => this.openCreateCompany()}>
                    {t("COMPANY.CREATE_BUTTON")}
                  </Button>
                  <Modal isOpen={this.state.modal} fade={false} toggle={this.changeModalState} style={{top: "1px"}}>
                    <ModalHeader toggle={this.changeModalState}>{t("COMPANY.DETAIL_MODAL_TITLE")}</ModalHeader>
                    <ModalBody style={{minHeight:"auto"}}>
                      <div>
                        <Form style={{ margin: "10px" }}>
                          <Row>
                            <Col md="1"></Col>
                            <Col md="3">
                              <FormGroup>
                                <AvatarPicker handleChangeImage={this.handleImageChange} base64Image={this.state.selectedCompany?.logo} />
                              </FormGroup>
                            </Col>
                            <Col md="5">
                              <Row>
                                <Col>
                                  <FormGroup>
                                    <Label for="name"> {t("COMPANY.NAME")}*</Label>
                                    <Input
                                      type="text"
                                      name="name"
                                      id="name"
                                      onChange={this.changeCreatingCompanyName}
                                      value={this.state.selectedCompany.name}
                                      className={isError.name?.length > 0 ? "is-invalid form-control" : "form-control"}
                                    />
                                    {isError.name?.length > 0 && <span className="invalid-feedback">{isError?.name}</span>}
                                  </FormGroup>
                                </Col>
                              </Row>
                              <Row>
                                <Col>
                                  <FormGroup>
                                    <Label for="contactName">{t("COMPANY.CONTACT_NAME")}*</Label>
                                    <Input
                                      type="text"
                                      name="contactName"
                                      id="contactName"
                                      onChange={this.changeCreatingCompanyContactName}
                                      value={this.state.selectedCompany.contactName}
                                      className={isError.contactName?.length > 0 ? "is-invalid form-control" : "form-control"}
                                    />
                                    {isError.contactName?.length > 0 && <span className="invalid-feedback">{isError?.contactName}</span>}
                                  </FormGroup>
                                </Col>
                              </Row>
                              <Row>
                                <Col>
                                  <FormGroup>
                                    <Label for="email">{t("COMPANY.EMAIL")}*</Label>
                                    <Input
                                      type="text"
                                      name="email"
                                      id="email"
                                      onChange={this.changeCreatingCompanyEmail}
                                      value={this.state.selectedCompany.email}
                                      className={isError.email?.length > 0 ? "is-invalid form-control" : "form-control"}
                                    />
                                    {isError.email?.length > 0 && <span className="invalid-feedback">{isError?.email}</span>}
                                  </FormGroup>
                                </Col>
                              </Row>
                              <Row>
                                <Col>
                                  <FormGroup>
                                    <Label for="phone">{t("COMPANY.PHONE")}*</Label>
                                    <Input
                                      type="text"
                                      name="phone"
                                      id="phone"
                                      onChange={this.changeCreatingCompanyPhone}
                                      value={this.state.selectedCompany.phone}
                                      className={isError.phone?.length > 0 ? "is-invalid form-control" : "form-control"}
                                    />
                                    {isError.phone?.length > 0 && <span className="invalid-feedback">{isError?.phone}</span>}
                                  </FormGroup>
                                </Col>
                              </Row>
                            </Col>
                          </Row>
                        </Form>
                      </div>
                    </ModalBody>
                    <ModalFooter>
                      <Col>
                        {this.state.selectedCompany.id ? (
                          <Button disabled={this.state.selectedCompany.isActive} className="mb-2 me-2" color="danger" onClick={this.openDeleteModal}>
                            {t("COMPANY.DELETE_BUTTON")}
                          </Button>
                        ) : (
                          ""
                        )}
                        {renderActiveDeactivateButton()}
                      </Col>

                      <Modal isOpen={this.state.activeModal} toggle={this.changeActiveModalState} style={{ marginTop: "50px", width: "60%" }}>
                        <ModalHeader> {this.state.selectedCompany.isActive ? t("COMPANY.CONFIRM_DEACTIVATE_MODAL_TITLE") : t("COMPANY.CONFIRM_ACTIVATE_MODAL_TITLE")}</ModalHeader>
                        <ModalBody style={{ minHeight: "auto" }}>
                          {this.state.selectedCompany.isActive && this.state.users.length > 0 ? (
                            <div>
                              <PerfectScrollbar>
                                <Card className="main-card mb-3">
                                  <CardTitle>{t("COMPANY.USERS")}</CardTitle>
                                  <DataTable value={this.state.users} paginator rows={10}>
                                    <Column field="firstName" header={t("COMPANY.USER_FIRST_NAME_COLUMN_NAME")}></Column>
                                    <Column field="lastName" header={t("COMPANY.USER_LAST_NAME_COLUMN_NAME")}></Column>
                                    <Column field="email" header={t("COMPANY.USER_EMAIL_COLUMN_NAME")}></Column>
                                  </DataTable>
                                </Card>
                              </PerfectScrollbar>
                            </div>
                          ) : (
                            ""
                          )}
                        </ModalBody>
                        <ModalFooter>
                          <Col>
                            {this.state.selectedCompany.isActive && this.state.users.length > 0 ? (
                              <CSVLink
                                data={this.state.users}
                                filename={"deactivate_company_" + this.state.selectedCompany?.name + ".csv"}
                                headers={this.state.csvHeaders}
                                className="mb-2 me-2 btn btn-success"
                                target="_blank"
                              >
                                {t("COMPANY.EXPORT_BUTTON")}
                              </CSVLink>
                            ) : (
                              ""
                            )}
                          </Col>
                          <Button className="mb-2 me-2 " color="secondary" onClick={this.changeActiveModalState}>
                            {t("COMPANY.NO_BUTTON")}
                          </Button>
                          <Button
                            className="mb-2 me-2 "
                            color="primary"
                            onClick={this.state.selectedCompany.isActive ? this.confirmDeactivateCompany : this.confirmActivateCompany}
                          >
                            {t("COMPANY.YES_BUTTON")}
                          </Button>
                        </ModalFooter>
                      </Modal>

                      <Modal isOpen={this.state.deleteModal} toggle={this.changeDeleteModalState} style={{ marginTop: "50px", width: "60%" }}>
                        <ModalHeader> {this.state.users.length > 0 ? t("COMPANY.DELETE_MODAL_TITLE") : t("COMPANY.CONFIRM_DELETE_MODAL_TITLE")}</ModalHeader>
                        <ModalBody style={{ minHeight: "auto" }}>
                          {this.state.users.length > 0 ? (
                            <div>
                              <PerfectScrollbar>
                                <Card className="main-card mb-3">
                                  <CardTitle>{t("COMPANY.USERS")}</CardTitle>
                                  <DataTable value={this.state.users} paginator rows={10}>
                                    <Column field="firstName" header={t("COMPANY.USER_FIRST_NAME_COLUMN_NAME")}></Column>
                                    <Column field="lastName" header={t("COMPANY.USER_LAST_NAME_COLUMN_NAME")}></Column>
                                    <Column field="email" header={t("COMPANY.USER_EMAIL_COLUMN_NAME")}></Column>
                                  </DataTable>
                                </Card>
                              </PerfectScrollbar>
                            </div>
                          ) : (
                            ""
                          )}
                        </ModalBody>
                        <ModalFooter>
                          <Col>
                            {this.state.users.length > 0 ? (
                              <CSVLink
                                data={this.state.users}
                                filename={"delete_company_" + this.state.selectedCompany?.name + ".csv"}
                                headers={this.state.csvHeaders}
                                className="mb-2 me-2 btn btn-success"
                                target="_blank"
                              >
                                {t("COMPANY.EXPORT_BUTTON")}
                              </CSVLink>
                            ) : (
                              ""
                            )}
                          </Col>
                          {this.state.users.length > 0 ? (
                            <div>
                              <Button className="mb-2 me-2 " color="secondary" onClick={this.changeDeleteModalState}>
                                {t("COMPANY.CANCEL_BUTTON")}
                              </Button>
                            </div>
                          ) : (
                            <div>
                              <Button className="mb-2 me-2 " color="secondary" onClick={this.changeDeleteModalState}>
                                {t("COMPANY.NO_BUTTON")}
                              </Button>
                              <Button className="mb-2 me-2 " color="primary" onClick={this.confirmDeleteCategory}>
                                {t("COMPANY.YES_BUTTON")}
                              </Button>
                            </div>
                          )}
                        </ModalFooter>
                      </Modal>

                      <Button className="mb-2 me-2 " color="secondary" onClick={this.changeModalState}>
                        {t("COMPANY.CANCEL_BUTTON")}
                      </Button>
                      {this.state.selectedCompany.id ? (
                        <Button className="mb-2 me-2  " color="primary" onClick={(e) => this.updateCompany(e)}>
                          {t("COMPANY.UPDATE_BUTTON")}
                        </Button>
                      ) : (
                        <Button className="mb-2 me-2  " color="primary" type="submit" onClick={(e) => this.createCompany(e)}>
                          {t("COMPANY.SAVE_BUTTON")}
                        </Button>
                      )}
                    </ModalFooter>
                  </Modal>
                </Col>
                <Col md="12">
                  <Card className="main-card mb-3">
                    <CardBody>
                      <div className="card p-fluid">
                        <DataTable
                          value={this.state.companies}
                          paginator
                          className="p-datatable-customers"
                          rows={10}
                          dataKey="id"
                          filterDisplay="row"
                          responsiveLayout="scroll"
                          filters={this.state.filterCompanyTemplate}
                          emptyMessage="No companies found."
                        >
                          <Column header={t("COMPANY.NAME_COLUMN_NAME")} field="name" body={formatNameCompany} filter sortable />
                          <Column header={t("COMPANY.CONTACT_NAME_COLUMN_NAME")} field="contactName" filter sortable />
                          <Column header={t("COMPANY.EMAIL_COLUMN_NAME")} field="email" filter sortable />
                          <Column header={t("COMPANY.PHONE_COLUMN_NAME")} field="phone" filter sortable />
                          <Column
                            field="isActive"
                            header={t("COMPANY.IS_ACTIVE_COLUMN_NAME")}
                            dataType="boolean"
                            sortable
                            body={formatCheckBoxCompany}
                            filter
                            filterElement={verifiedRowFilterTemplate}
                          />
                          <Column body={buttonUpdateCompany} rowEditor />
                          <Column body={buttonOpenUsers} rowEditor />
                        </DataTable>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </div>
          </CSSTransition>
        </TransitionGroup>
      </Fragment>
    );
  }
}
export default withTranslation()(Company);
