import api from "./api";

class LearningPathItemService {
  getById(id) {
    return api.get("/LearningPathItem/get-by-id?id=" + id);
  }
  getByTrainingId(trainingId) {
    return api.get("/LearningPathItem/get-by-training-id?trainingId=" + trainingId);
  }

  getByLearningPathId(learningPathId) {
    return api.get("/LearningPathItem/get-by-learning-path-id?learningPathId=" + learningPathId);
  }

  getByRecommendationId(recommendationId) {
    return api.get("/LearningPathItem/get-by-recommendation-id?recommendationId=" + recommendationId);
  }

  getActiveByLearningObjectId(learningObjectId) {
    return api.get("/LearningPathItem/get-active-by-learning-object-id?learningObjectId=" + learningObjectId);
  }

  getByLearningObjectId(learningObjectId) {
    return api.get("/LearningPathItem/get-by-learning-object-id?learningObjectId=" + learningObjectId);
  }

  insert(learningPathItem) {
    return api.post("/LearningPathItem/insert", learningPathItem);
  }

  update(learningPathItem) {
    return api.post("/LearningPathItem/update", learningPathItem);
  }

  markAsCompleted(learningPathItemId) {
    return api.post("/LearningPathItem/mask-as-completed", { learningPathItemId });
  }

  delete(id) {
    return api.post("/LearningPathItem/delete?id=" + id);
  }
}

export default new LearningPathItemService();
