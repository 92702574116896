import React, { Fragment } from "react";

import { UncontrolledDropdown, DropdownToggle, DropdownMenu, Nav, Col, Row, Button, NavItem, DropdownItem } from "reactstrap";

import Flag from "react-flagkit";

import { withTranslation } from "react-i18next";
import languages from "../../../models/Languages";

class HeaderLogin extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      active: false,
      country: "",
    };
  }

  render() {
    const { t, i18n } = this.props;

    return (
      <Fragment>
        <div className="header-dots">
          <UncontrolledDropdown>
            <DropdownToggle className="p-0 me-2" color="link">
              <div className="icon-wrapper icon-wrapper-alt rounded-circle">
                <div className="icon-wrapper-bg bg-focus" />
                <div className="language-icon">
                  <Flag className="me-3 opacity-8" country={languages.find((obj) => obj.lang === this.props.i18n.language).flag} size="40" />
                </div>
              </div>
            </DropdownToggle>
            <DropdownMenu end className="rm-pointers">
              {languages.map((language, id) => (
                <DropdownItem key={id} value={language} onClick={() => i18n.changeLanguage(language.lang)}>
                  <Flag className="me-3 opacity-8" country={language.flag} />
                  {language.name}
                </DropdownItem>
              ))}

              <DropdownItem divider />
            </DropdownMenu>
          </UncontrolledDropdown>
        </div>
      </Fragment>
    );
  }
}

export default withTranslation()(HeaderLogin);
