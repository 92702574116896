import React, { Fragment, Component } from "react";
import { withTranslation } from "react-i18next";

class GeneralInfo extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { t, training } = this.props;

    return (
      <Fragment>
        <div className="form-wizard-content">
          <div className="no-results">
            <div className="mt-3 mb-3" />
            <div className="results-title">
              <b>{training?.name}</b> ({training?.version})
            </div>
            <div className="results-title">
              <p style={{ paddingTop: "1rem", float: "left" }}>{training?.description}</p>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}
export default withTranslation()(GeneralInfo);
