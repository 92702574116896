import React, { Fragment, Component } from "react";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import { withTranslation } from "react-i18next";
import PageTitle from "../../../Layout/AppMain/PageTitle";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import validator from "validator";
import { Row, Col, Card, CardBody, Modal, ModalHeader, ModalBody, ModalFooter, Input, FormGroup, Label, Button } from "reactstrap";
import { FilterMatchMode } from "primereact/api";
import Select from "react-select";
import "../../Metadata/Attribute/DataTable.css";
import { classNames } from "primereact/utils";
import { TriStateCheckbox } from "primereact/tristatecheckbox";
import UserService from "../../../service/UserService";
import RoleService from "../../../service/RoleService";
import CompanyService from "../../../service/CompanyService";
import AvatarPicker from "../../Shared/AvatarPicker";
import tokenService from "../../../service/tokenService";

class User extends Component {
  constructor(props) {
    super(props);
    this.state = {
      users: [],
      companies: [],
      dropdownCompanies: [],
      asignedCompanies: [],
      needAsignCompanies: [],
      needUnasignCompanies: [],
      roles: [],
      dropdownRoles: [],
      dropdownEditingRoles: [],
      selectedUser: {},
      selectedCompany: {},
      selectedEditingCompany: "",
      loggedUser: tokenService.getUser(),
      activeModal: false,
      deleteModal: false,
      replaceUsers: [],
      replaceId: null,
      modal: false,
      filterUsersTemplate: {
        firstName: { value: null, matchMode: FilterMatchMode.CONTAINS },
        lastName: { value: null, matchMode: FilterMatchMode.CONTAINS },
        email: { value: null, matchMode: FilterMatchMode.CONTAINS },
        currentCompanyName: { value: null, matchMode: FilterMatchMode.CONTAINS },
        roles: { value: null, matchMode: FilterMatchMode.CONTAINS },
        companies: { value: null, matchMode: FilterMatchMode.CONTAINS },
        createdDate: { value: null, matchMode: FilterMatchMode.DATE_IS },
        isActive: { value: null, matchMode: FilterMatchMode.EQUALS },
      },
      error: {},
    };
  }

  componentDidMount() {
    this.getUsers();
    this.getAllRoles();
    this.getActiveCompanies();
  }

  isM2LAdmin = () => {
    return this.state.loggedUser?.currentRole === "SystemAdmin" && this.state.loggedUser?.currentCompanyId === null;
  };

  getUsers() {
    if (this.props.location.search) {
      var id = this.props.location.search.substring(this.props.location.search.indexOf("=") + 1);
      return this.getUsersByCompanyId(id);
    } else {
      return this.getAllUsers();
    }
  }

  getAllRoles() {
    RoleService.getAllRoles()
      .then((response) => {
        var roles = response.data.map((role, index) => {
          return {
            role: role,
            id: index,
          };
        });
        this.setState({
          roles: roles,
        });
      })

      .catch((e) => {
        console.log(e);
      });
  }

  getActiveCompanies() {
    CompanyService.getAllCompanies()
      .then((response) => {
        let companies = response.data;
        if (this.isM2LAdmin()) {
          companies.unshift({ name: "Matrix2Learn", id: null });
        } else {
          companies = companies.filter((c) => c.id === this.state.loggedUser.currentCompanyId);
        }
        this.setState({
          companies: companies,
        });
      })

      .catch((e) => {
        console.log(e);
      });
  }
  getSelectedActiveCompanies(userId) {
    return CompanyService.getCompanyByUserId(userId)
      .then((response) => {
        let asignedCompanies = this.isM2LAdmin() ? response.data : response.data.filter((c) => c.id === this.state.loggedUser.currentCompanyId);
        if (!this.isM2LAdmin()) {
          let selectedCompany = tokenService.getCompany();
          selectedCompany.role = asignedCompanies[0].role;
          this.setState({
            selectedCompany: selectedCompany,
          });
        }
        this.setState({
          asignedCompanies: this.isM2LAdmin() ? response.data : response.data.filter((c) => c.id === this.state.loggedUser.currentCompanyId),
        });
      })
      .catch((e) => {
        console.log(e);
      });
  }

  getUsersByCompanyId(id) {
    return UserService.getUsersByCompanyId(id)
      .then((response) => {
        this.setState({
          users: response.data,
        });
      })
      .catch((e) => {
        console.log(e);
      });
  }

  confirmDeleteUser = () => {
    UserService.delete({ id: this.state.selectedUser.id, replaceId: this.state.replaceId })
      .then((response) => {
        this.getAllUsers().then(() => {
          this.changeDeleteModalState();
          this.changeModalState();
        });
      })
      .catch((e) => {
        console.log(e);
      });
  };

  createUser = (e) => {
    e.preventDefault();
    if (!this.validForm(this.state.selectedUser)) return;

    UserService.insert(this.state.selectedUser)
      .then((response) => {
        let userId = response.data.id;
        let needAsignCompanies = this.state.needAsignCompanies;
        if (needAsignCompanies && needAsignCompanies.length > 0) {
          needAsignCompanies.forEach((a) => (a.userId = userId));
          UserService.assignToCompany(needAsignCompanies).then(() => {
            this.getUsers();
            this.changeModalState();
            this.setState({ selectedUser: {} });
          });
        } else {
          this.getUsers();
          this.changeModalState();
          this.setState({ selectedUser: {} });
        }
      })
      .catch((e) => {
        console.log(e);
        if (e.response?.data) {
          if (e.response.data.length > 0) {
            let firstError = e.response.data[0];
            if (firstError.code) {
              if (firstError.code.includes("Password")) {
                let error = this.state.error;
                error.password = firstError.description;
                this.setState({ error: error });
              } else if (firstError.code.includes("UserName")) {
                let error = this.state.error;
                error.userName = firstError.description;
                this.setState({ error: error });
              }
            } else if (e.response?.data) {
              if (e.response.data.includes("email")) {
                let error = this.state.error;
                error.email = e.response?.data;
                this.setState({ error: error });
              } else {
                let error = this.state.error;
                error.password = e.response?.data;
                this.setState({ error: error });
              }
            }
          }
        }
      });
  };

  getAllUsers = () => {
    return UserService.getAllUsers()
      .then((response) => {
        this.setState({
          users: response.data,
        });
      })
      .catch((e) => {
        console.log(e);
      });
  };

  changeModalState = () => {
    this.setState({
      modal: !this.state.modal,
      selectedCompany: {},
      selectedEditingCompany: "",
      needAsignCompanies: [],
      needUnasignCompanies: [],
      error: {},
    });
  };

  confirmDeactivateUser = () => {
    UserService.deactivate(this.state.selectedUser.id).then(() => {
      var user = this.state.selectedUser;
      user.isActive = false;
      this.setState({ selectedUser: user });
      this.changeActiveModalState();
      this.getUsers();
    });
  };

  confirmActivateUser = () => {
    UserService.activate(this.state.selectedUser.id).then(() => {
      var user = this.state.selectedUser;
      user.isActive = true;
      this.setState({ selectedUser: user });
      this.changeActiveModalState();
      this.getUsers();
    });
  };

  onChangeDropdownCompany = (e) => {
    var role = this.state.selectedCompany?.role;
    if (role) {
      e.role = role;
    }

    if (e.name === "Matrix2Learn") {
      this.setState({ dropdownRoles: this.state.roles.filter((c) => c.role === "SystemAdmin") });
    } else {
      this.setState({ dropdownRoles: this.state.roles });
    }
    this.setState({ selectedCompany: e });
  };

  onChangeRole = (e) => {
    if (!this.isM2LAdmin()) {
      if (this.state.selectedCompany.role !== e.role) {
        var nuac = [];
        nuac.push({ companyId: this.state.selectedCompany.id, userId: this.state.selectedUser.id });

        var nac = [];
        nac.push({ companyId: this.state.selectedCompany.id, role: e.role, userId: this.state.selectedUser.id });
        this.setState({
          needAsignCompanies: nac,
          needUnasignCompanies: nuac,
        });
      }
    }

    var sc = this.state.selectedCompany;
    sc.role = e.role;

    this.setState({ selectedCompany: sc });
  };

  onChangeReplaceUser = (e) => {
    this.setState({ replaceId: e.id });
  };

  onChangeDropdownEditingCompany = (e) => {
    var role = this.state.selectedEditingCompany?.role;
    if (role) {
      e.role = role;
    }

    if (e.name === "Matrix2Learn") {
      this.setState({ dropdownEditingRoles: this.state.roles.filter((c) => c.role === "SystemAdmin"), selectedEditingCompany: e });
    } else {
      this.setState({ dropdownEditingRoles: this.state.roles, selectedEditingCompany: e });
    }
  };

  onChangeEditingRole = (e) => {
    var sc = this.state.selectedEditingCompany;
    if (sc) {
      sc.role = e.role;
    } else {
      sc = { role: e.role };
    }
    this.setState({ selectedEditingCompany: sc });
  };

  assignCompany = () => {
    var nac = this.state.needAsignCompanies;
    nac.push({ companyId: this.state.selectedCompany.id, role: this.state.selectedCompany.role, userId: this.state.selectedUser.id });
    var ac = this.state.asignedCompanies;
    ac.push(this.state.selectedCompany);
    this.setState({
      selectedCompany: {},
      asignedCompanies: ac,
      dropdownRoles: this.state.roles,
      dropdownCompanies: this.state.companies
        .filter((c) => c.isActive)
        .filter((c) => !ac.find((sc) => sc.id === c.id))
        .sort((a, b) => a.name.localeCompare(b.name)),
      needAsignCompanies: nac,
    });
  };

  unassignCompany = (company) => {
    var nuac = this.state.needUnasignCompanies;
    nuac.push({ companyId: company.id, userId: this.state.selectedUser.id });
    var ac = this.state.asignedCompanies.filter((c) => c.id !== company.id);
    this.setState({
      asignedCompanies: ac,
      dropdownCompanies: this.state.companies
        .filter((c) => c.isActive)
        .filter((c) => !ac.find((sc) => sc.id === c.id))
        .sort((a, b) => a.name.localeCompare(b.name)),
      needUnasignCompanies: nuac,
    });
  };

  changeUserLastName = (e) => {
    this.setState((prevState) => ({
      selectedUser: {
        ...prevState.selectedUser,
        lastName: e.target.value,
      },
    }));
  };

  changeUserFirstName = (e) => {
    this.setState((prevState) => ({
      selectedUser: {
        ...prevState.selectedUser,
        firstName: e.target.value,
      },
    }));
  };

  changeUserLogin = (e) => {
    this.setState((prevState) => ({
      selectedUser: {
        ...prevState.selectedUser,
        userName: e.target.value,
      },
    }));
  };

  changeUserEmail = (e) => {
    this.setState((prevState) => ({
      selectedUser: {
        ...prevState.selectedUser,
        email: e.target.value,
      },
    }));
  };

  onChangeUserPassword = (e) => {
    this.setState((prevState) => ({
      selectedUser: {
        ...prevState.selectedUser,
        password: e.target.value,
      },
    }));
  };

  onChangeUserConfirmPassword = (e) => {
    this.setState((prevState) => ({
      selectedUser: {
        ...prevState.selectedUser,
        confirmPassword: e.target.value,
      },
    }));
  };

  changeActiveModalState = () => {
    this.setState({
      activeModal: !this.state.activeModal,
    });
  };

  changeDeleteModalState = () => {
    this.setState({
      deleteModal: !this.state.deleteModal,
    });
  };

  validForm = (user) => {
    let isSuccess = true;
    let error = this.state.error;
    if (!user?.userName) {
      isSuccess = false;
      error.userName = "Required";
    } else {
      error.userName = "";
    }
    if (!user?.email) {
      isSuccess = false;
      error.email = "Required";
    } else if (!validator.isEmail(user.email)) {
      isSuccess = false;
      error.email = "Email is not valid";
    } else {
      error.email = "";
    }
    if (!user?.firstName) {
      isSuccess = false;
      error.firstName = "Required";
    } else {
      error.firstName = "";
    }
    if (!user?.lastName) {
      isSuccess = false;
      error.lastName = "Required";
    } else {
      error.lastName = "";
    }
    if (!user.id) {
      if (!user?.password) {
        isSuccess = false;
        error.password = "Required";
      } else {
        error.lastName = "";
      }

      if (!user?.confirmPassword) {
        isSuccess = false;
        error.confirmPassword = "Required";
      } else if (user?.password !== user?.confirmPassword) {
        isSuccess = false;
        error.confirmPassword = "Passwords are not equal.";
      } else {
        error.confirmPassword = "";
      }
    }
    if (!isSuccess) {
      this.setState({ error: error });
    }
    return isSuccess;
  };

  updateUser = (e) => {
    e.preventDefault();
    if (!this.validForm(this.state.selectedUser)) return;

    UserService.update(this.state.selectedUser).then(() => {
      if (this.state.needUnasignCompanies && this.state.needUnasignCompanies.length > 0) {
        UserService.unassignFromCompany(this.state.needUnasignCompanies).then(() => {
          if (this.state.needAsignCompanies && this.state.needAsignCompanies.length > 0) {
            UserService.assignToCompany(this.state.needAsignCompanies).then(() => {
              this.getUsers();
              this.changeModalState();
            });
          } else {
            this.getUsers();
            this.changeModalState();
          }
        });
      } else if (this.state.needAsignCompanies && this.state.needAsignCompanies.length > 0) {
        UserService.assignToCompany(this.state.needAsignCompanies).then(() => {
          this.getUsers();
          this.changeModalState();
        });
      } else {
        this.getUsers();
        this.changeModalState();
      }
    });
  };

  openUpdateUser = (id) => {
    this.setState({
      selectedUser: this.state.users.find((u) => u.id === id),
    });
    this.changeModalState();
    this.getSelectedActiveCompanies(id).then(() => {
      this.setState({
        dropdownCompanies: this.state.companies
          .filter((c) => c.isActive)
          .filter((c) => !this.state.asignedCompanies.find((sc) => sc.id === c.id))
          .sort((a, b) => a.name.localeCompare(b.name)),
        dropdownRoles: this.state.roles,
        dropdownEditingRoles: this.state.roles,
      });
    });
  };

  onRowEditComplete = (e) => {
    let savedCompany = e.newData;
    let savedRole = savedCompany.role;
    let newCompany = Object.assign({}, savedCompany);
    if (this.state.selectedEditingCompany?.name) {
      newCompany = this.state.selectedEditingCompany;
    }
    newCompany.role = this.state.selectedEditingCompany?.role ? this.state.selectedEditingCompany.role : savedRole;

    let asignedCompanies = this.state.asignedCompanies;
    asignedCompanies[e.index] = newCompany;
    this.setState({
      selectedEditingCompany: "",
      asignedCompanies: asignedCompanies,
      dropdownCompanies: this.state.companies
        .filter((c) => c.isActive)
        .filter((c) => !asignedCompanies.find((sc) => sc.id === c.id))
        .sort((a, b) => a.name.localeCompare(b.name)),
    });

    if (JSON.stringify(savedCompany) !== JSON.stringify(newCompany)) {
      var nuac = this.state.needUnasignCompanies;
      nuac.push({ companyId: savedCompany.id, userId: this.state.selectedUser.id });

      var nac = this.state.needAsignCompanies;
      nac.push({ companyId: newCompany.id, role: newCompany.role, userId: this.state.selectedUser.id });
      this.setState({
        needAsignCompanies: nac,
        needUnasignCompanies: nuac,
      });
    }
  };

  openCreateUserModal = () => {
    this.changeModalState();
    let selectedUser = { language: this.props.i18n.language };

    if (!this.isM2LAdmin()) {
      let selectedCompany = tokenService.getCompany();
      selectedCompany.role = "Trainer";
      var needAsignCompanies = [];
      needAsignCompanies.push({ companyId: this.state.loggedUser.currentCompanyId, role: selectedCompany.role, userId: this.state.selectedUser.id });

      this.setState({
        needAsignCompanies: needAsignCompanies,
        selectedCompany: selectedCompany,
      });
    }
    this.setState({
      selectedUser: selectedUser,
      asignedCompanies: [],
      dropdownCompanies: this.state.companies.filter((c) => c.isActive).sort((a, b) => a.name.localeCompare(b.name)),
      dropdownRoles: this.state.roles,
      dropdownEditingRoles: this.state.roles,
    });
  };

  handleChangeImage = (imageFile) => {
    this.setState((prevState) => ({
      selectedUser: {
        ...prevState.selectedUser,
        photo: imageFile?.name,
      },
    }));
  };

  openDeleteModel = () => {
    let replaceUsers = [];
    if (this.state.selectedUser.roles.find((r) => r === "SystemAdmin")) {
      replaceUsers = this.state.users.filter((u) => u.roles.find((r) => r === "SystemAdmin"));
    } else if (this.state.selectedUser.roles.find((r) => r === "ContentAdmin")) {
      replaceUsers = this.state.users.filter((u) => u.roles.find((r) => r === "SystemAdmin" || r === "ContentAdmin"));
    }
    if (this.state.selectedUser.roles.find((r) => r === "MasterTrainer")) {
      replaceUsers = this.state.users.filter((u) => u.roles.find((r) => r === "SystemAdmin" || r === "ContentAdmin" || r === "MasterTrainer"));
    }
    replaceUsers = replaceUsers.filter((u) => u.id !== this.state.selectedUser.id);

    let replaceId = null;
    if (replaceUsers.length > 0) {
      replaceId = replaceUsers[0].id;
    }
    this.setState(
      {
        replaceUsers: replaceUsers,
        replaceId: replaceId,
      },
      () => this.changeDeleteModalState()
    );
  };

  render() {
    const { t } = this.props;

    const buttonUnassignCompany = (rowData) => {
      return (
        <Button className="" outline color="primary" onClick={() => this.unassignCompany(rowData)}>
          {t("USER.DELETE_BUTTON")}
        </Button>
      );
    };

    const userRoles = (rowData) => {
      return rowData.roles.join(", ");
    };

    const userCompanies = (rowData) => {
      return rowData.companies.join(", ");
    };

    const buttonOpenUser = (rowData) => {
      return (
        <Button className="me-2" size="sm" outline color="primary" onClick={() => this.openUpdateUser(rowData.id)}>
          {t("USER.OPEN")}
        </Button>
      );
    };

    const formatIsActiveCheckBox = (rowData) => {
      return (
        <i
          className={classNames("pi", {
            "true-icon pi-check-circle": rowData.isActive,
            "false-icon pi-times-circle": !rowData.isActive,
          })}
        ></i>
      );
    };

    const verifiedRowFilterTemplate = (options) => {
      return <TriStateCheckbox value={options.value} onChange={(e) => options.filterApplyCallback(e.value)} />;
    };

    const companyBodyTemplate = (rowData) => {
      return (
        <div className="country-item" style={{ display: "flex" }}>
          <div>{rowData?.name}</div>
        </div>
      );
    };

    const companyEditorTemplate = (options) => {
      var company = options.rowData;
      if (company.name === "Matrix2Learn") {
        let dropdownEditingRoles = this.state.roles.filter((c) => c.role === "SystemAdmin");
        if (JSON.stringify(this.state.dropdownEditingRoles) !== JSON.stringify(dropdownEditingRoles)) {
          this.setState({ dropdownEditingRoles: dropdownEditingRoles });
        }
      } else {
        let dropdownEditingRoles = this.state.roles;
        if (JSON.stringify(this.state.dropdownEditingRoles) !== JSON.stringify(dropdownEditingRoles)) {
          this.setState({ dropdownEditingRoles: dropdownEditingRoles });
        }
      }

      //let dropdownCompanies = this.state.dropdownCompanies;
      // dropdownCompanies = [company].concat(dropdownCompanies);
      let dropdownCompanies = [company];
      dropdownCompanies = dropdownCompanies.sort((a, b) => a.name.localeCompare(b.name));
      return (
        <Select
          style={{ display: "flex", zIndex: "2" }}
          options={dropdownCompanies}
          onChange={this.onChangeDropdownEditingCompany}
          defaultValue={dropdownCompanies.find((obj) => obj.id === company?.id)}
          getOptionLabel={(option) => option.name}
          getOptionValue={(option) => option.id}
          key={dropdownCompanies.find((obj) => obj.id === company?.id)}
        />
      );
    };

    const roleBodyTemplate = (rowData) => {
      return (
        <div className="country-item" style={{ display: "flex" }}>
          <div>{rowData?.role}</div>
        </div>
      );
    };

    const roleEditorTemplate = (options) => {
      var company = options.rowData;
      return (
        <Select
          style={{ display: "flex", zIndex: "2" }}
          options={this.state.dropdownEditingRoles}
          onChange={this.onChangeEditingRole}
          getOptionLabel={(option) => option.role}
          getOptionValue={(option) => option.role}
          key={this.state.dropdownEditingRoles.find((obj) => obj.role === company?.role)}
          defaultValue={this.state.dropdownEditingRoles.find((obj) => obj.role === company?.role)}
        />
      );
    };

    const renderActiveDeactivateButton = () => {
      if (this.state.selectedUser.id) {
        if (this.state.selectedUser.isActive) {
          return (
            <Button className="mb-2 me-2 " outline color="danger" onClick={this.changeActiveModalState}>
              {t("USER.DEACTIVATE_BUTTON")}
            </Button>
          );
        } else {
          return (
            <Button className="mb-2 me-2 " outline color="success" onClick={this.changeActiveModalState}>
              {t("USER.ACTIVATE_BUTTON")}
            </Button>
          );
        }
      }
    };

    return (
      <Fragment>
        <TransitionGroup>
          <CSSTransition component="div" classNames="TabsAnimation" appear={true} timeout={1500} enter={false} exit={false}>
            <div>
              <PageTitle heading={t("USER.TITLE")} subheading={t("USER.DESCRIPTION")} icon="pe-7s-medal icon-gradient bg-tempting-azure" />
              <Row>
                <Col md="12">
                  <Button className="mb-2 me-2 " outline color="primary" onClick={() => this.openCreateUserModal()}>
                    {t("USER.CREATE_BUTTON")}
                  </Button>
                  <Modal isOpen={this.state.modal} fade={false} toggle={this.changeModalState}>
                    <ModalHeader toggle={this.changeModalState}> {this.state.selectedUser?.id ? t("USER.EDIT_MODAL_TITLE") : t("USER.CREATE_MODAL_TITLE")}</ModalHeader>
                    <ModalBody>
                      <div className="card p-fluid">
                        <div style={{ margin: "0.75rem" }}>
                          <Row>
                            <Col md="3">
                              <FormGroup>
                                <AvatarPicker handleChangeImage={this.handleChangeImage} base64Image={this.state.selectedUser.photo} />
                              </FormGroup>
                            </Col>
                            <Col>
                              <Row>
                                <Col>
                                  <FormGroup>
                                    <Label for="userName"> {t("USER.USER_NAME")}</Label>
                                    <Input
                                      type="text"
                                      name="userName"
                                      id="userName"
                                      disabled={this.state.selectedUser?.id}
                                      onChange={this.changeUserLogin}
                                      value={this.state.selectedUser?.userName}
                                      className={this.state.error?.userName ? "is-invalid form-control" : "form-control"}
                                    />
                                    {this.state.error?.userName && <span className="invalid-feedback">{this.state.error?.userName}</span>}
                                  </FormGroup>
                                </Col>
                                <Col>
                                  <FormGroup>
                                    <Label for="email">{t("USER.EMAIL")}</Label>
                                    <Input
                                      type="text"
                                      name="email"
                                      id="email"
                                      onChange={this.changeUserEmail}
                                      value={this.state.selectedUser?.email}
                                      className={this.state.error?.email ? "is-invalid form-control" : "form-control"}
                                    />
                                    {this.state.error?.email && <span className="invalid-feedback">{this.state.error?.email}</span>}
                                  </FormGroup>
                                </Col>
                              </Row>
                              <Row>
                                <Col>
                                  <FormGroup>
                                    <Label for="firstName"> {t("USER.FIRST_NAME")}</Label>
                                    <Input
                                      type="text"
                                      name="firstName"
                                      id="firstName"
                                      onChange={this.changeUserFirstName}
                                      value={this.state.selectedUser?.firstName}
                                      className={this.state.error?.firstName ? "is-invalid form-control" : "form-control"}
                                    />
                                    {this.state.error?.firstName && <span className="invalid-feedback">{this.state.error?.firstName}</span>}
                                  </FormGroup>
                                </Col>
                                <Col>
                                  <FormGroup>
                                    <Label for="lastName">{t("USER.LAST_NAME")}</Label>
                                    <Input
                                      type="text"
                                      name="lastName"
                                      id="lastName"
                                      onChange={this.changeUserLastName}
                                      value={this.state.selectedUser?.lastName}
                                      className={this.state.error?.lastName ? "is-invalid form-control" : "form-control"}
                                    />
                                    {this.state.error?.lastName && <span className="invalid-feedback">{this.state.error?.lastName}</span>}
                                  </FormGroup>
                                </Col>
                              </Row>
                              {this.state.selectedUser?.id ? (
                                ""
                              ) : (
                                <Row>
                                  <Col>
                                    <FormGroup>
                                      <Label for="password"> {t("USER.PASSWORD")}</Label>
                                      <Input
                                        type="password"
                                        name="password"
                                        id="password"
                                        onChange={this.onChangeUserPassword}
                                        value={this.state.selectedUser?.password}
                                        className={this.state.error?.password ? "is-invalid form-control" : "form-control"}
                                      />
                                      {this.state.error?.password && <span className="invalid-feedback">{this.state.error?.password}</span>}
                                    </FormGroup>
                                  </Col>
                                  <Col>
                                    <FormGroup>
                                      <Label for="confirmPassword">{t("USER.CONFIRM_PASSWORD")}</Label>
                                      <Input
                                        type="password"
                                        name="confirmPassword"
                                        id="confirmPassword"
                                        onChange={this.onChangeUserConfirmPassword}
                                        value={this.state.selectedUser?.confirmPassword}
                                        className={this.state.error?.confirmPassword ? "is-invalid form-control" : "form-control"}
                                      />
                                      {this.state.error?.confirmPassword && <span className="invalid-feedback">{this.state.error?.confirmPassword}</span>}
                                    </FormGroup>
                                  </Col>
                                </Row>
                              )}

                              {!this.isM2LAdmin() && this.state.loggedUser?.id !== this.state.selectedUser?.id && (
                                <Row>
                                  <Col>
                                    <FormGroup>
                                      <Label> {t("USER.COMPANY_ROLE")}</Label>
                                      <Select
                                        options={this.state.dropdownRoles}
                                        value={this.state.dropdownRoles.find((t) => t.role === this.state.selectedCompany?.role) || ""}
                                        onChange={this.onChangeRole}
                                        getOptionLabel={(option) => option.role}
                                        getOptionValue={(option) => option.role}
                                      />
                                    </FormGroup>
                                  </Col>
                                  <Col></Col>
                                </Row>
                              )}
                            </Col>
                          </Row>
                        </div>
                      </div>
                      {this.isM2LAdmin() && (
                        <>
                          <div className="card p-fluid">
                            <div style={{ margin: "0.75rem" }}>
                              <Row>
                                <Col>
                                  <FormGroup>
                                    <Label> {t("USER.COMPANY_NAME")}</Label>
                                    <Select
                                      options={this.state.dropdownCompanies}
                                      value={this.state.dropdownCompanies.find((t) => t.id === this.state.selectedCompany?.id) || ""}
                                      onChange={this.onChangeDropdownCompany}
                                      getOptionLabel={(option) => option.name}
                                      getOptionValue={(option) => option.id}
                                    />
                                  </FormGroup>
                                </Col>
                                <Col>
                                  <FormGroup>
                                    <Label> {t("USER.COMPANY_ROLE")}</Label>
                                    <Select
                                      options={this.state.dropdownRoles}
                                      value={this.state.dropdownRoles.find((t) => t.role === this.state.selectedCompany?.role) || ""}
                                      onChange={this.onChangeRole}
                                      getOptionLabel={(option) => option.role}
                                      getOptionValue={(option) => option.role}
                                    />
                                  </FormGroup>
                                </Col>
                                <Col>
                                  <Button className="mb-2 me-2 " outline color="primary" style={{ marginTop: "30px" }} type="button" onClick={() => this.assignCompany()}>
                                    {t("USER.CREATE_BUTTON")}
                                  </Button>
                                </Col>
                              </Row>
                            </div>
                          </div>
                          <div className="card p-fluid">
                            <DataTable editMode="row" value={this.state.asignedCompanies} dataKey="id" className="p-datatable-customers" onRowEditComplete={this.onRowEditComplete}>
                              <Column
                                field="name"
                                header={t("USER.COMPANY_NAME")}
                                body={companyBodyTemplate}
                                editor={(options) => companyEditorTemplate(options)}
                                style={{ zIndex: "2" }}
                              ></Column>
                              <Column
                                field="name"
                                header={t("USER.COMPANY_ROLE")}
                                body={roleBodyTemplate}
                                editor={(options) => roleEditorTemplate(options)}
                                style={{ zIndex: "2" }}
                              ></Column>
                              <Column rowEditor headerStyle={{ width: "10%", minWidth: "8rem" }} bodyStyle={{ textAlign: "center" }}></Column>
                              <Column style={{ minWidth: "6rem" }} body={buttonUnassignCompany} />
                            </DataTable>
                          </div>
                        </>
                      )}
                    </ModalBody>
                    <ModalFooter>
                      <Col>
                        {this.state.selectedUser.id !== this.state.loggedUser.id && (
                          <>
                            {this.state.selectedUser.id ? (
                              <Button disabled={this.state.selectedUser.isActive} className="mb-2 me-2" color="danger" onClick={this.openDeleteModel}>
                                {t("USER.DELETE_BUTTON")}
                              </Button>
                            ) : (
                              ""
                            )}
                            {renderActiveDeactivateButton()}
                          </>
                        )}

                        <Modal isOpen={this.state.deleteModal} toggle={this.changeDeleteModalState} style={{ marginTop: "100px", width: "60%" }}>
                          <ModalHeader> {t("USER.CONFIRM_DELETE_MODAL_TITLE")}</ModalHeader>
                          <ModalBody style={{ minHeight: "auto" }}>
                            {!this.state.selectedUser?.roles?.find((r) => r === "Trainer") && (
                              <Card className="main-card mb-3">
                                <CardBody>
                                  <Row>
                                    <Col>
                                      <FormGroup>
                                        <Label> {t("USER.REPLACE_USER")}</Label>
                                        <Select
                                          options={this.state.replaceUsers}
                                          value={this.state.replaceUsers.find((t) => t.id === this.state.replaceId) || ""}
                                          onChange={this.onChangeReplaceUser}
                                          getOptionLabel={(option) => option.firstName + " " + option.lastName}
                                          getOptionValue={(option) => option.id}
                                        />
                                      </FormGroup>
                                    </Col>
                                  </Row>
                                </CardBody>
                              </Card>
                            )}
                          </ModalBody>
                          <ModalFooter>
                            <div>
                              <Button className="mb-2 me-2 " color="secondary" onClick={this.changeDeleteModalState}>
                                {t("USER.NO_BUTTON")}
                              </Button>
                              <Button className="mb-2 me-2 " color="primary" onClick={this.confirmDeleteUser}>
                                {t("USER.YES_BUTTON")}
                              </Button>
                            </div>
                          </ModalFooter>
                        </Modal>
                        <Modal isOpen={this.state.activeModal} toggle={this.changeActiveModalState} style={{ marginTop: "3rem", width: "50%" }}>
                          <ModalHeader>{this.state.selectedUser.isActive ? t("USER.CONFIRM_DEACTIVATE_MODAL_TITLE") : t("USER.CONFIRM_ACTIVATE_MODAL_TITLE")}</ModalHeader>
                          <ModalFooter>
                            <Button className="mb-2 me-2" color="secondary" onClick={this.changeActiveModalState}>
                              {t("USER.NO_BUTTON")}
                            </Button>
                            <Button className="mb-2 me-2" color="primary" onClick={this.state.selectedUser.isActive ? this.confirmDeactivateUser : this.confirmActivateUser}>
                              {t("USER.YES_BUTTON")}
                            </Button>
                          </ModalFooter>
                        </Modal>
                      </Col>

                      <Button className="mb-2 me-2" color="secondary" onClick={this.changeModalState}>
                        {t("USER.CANCEL_BUTTON")}
                      </Button>
                      {this.state.selectedUser?.id ? (
                        <Button className="mb-2 me-2" color="primary" onClick={(e) => this.updateUser(e)}>
                          {t("USER.UPDATE_BUTTON")}
                        </Button>
                      ) : (
                        <Button className="mb-2 me-2" color="primary" onClick={(e) => this.createUser(e)}>
                          {t("USER.SAVE_BUTTON")}
                        </Button>
                      )}
                    </ModalFooter>
                  </Modal>
                </Col>
                <Col md="12">
                  <Card className="main-card mb-3">
                    <CardBody>
                      <div className="card p-fluid">
                        <DataTable
                          value={this.state.users}
                          paginator
                          className="p-datatable-customers"
                          rows={10}
                          dataKey="id"
                          filterDisplay="row"
                          responsiveLayout="scroll"
                          filters={this.state.filterUsersTemplate}
                          emptyMessage="No users found."
                        >
                          <Column
                            field="firstName"
                            header={t("USER.FIRST_NAME_COLUMN_NAME")}
                            showFilterMenu={false}
                            filterMenuStyle={{ width: "14rem" }}
                            style={{ minWidth: "14rem" }}
                            filter
                            sortable
                          />
                          <Column field="lastName" header={t("USER.LAST_NAME_COLUMN_NAME")} filter sortable />
                          <Column field="email" header={t("USER.EMAIL_COLUMN_NAME")} filter sortable />
                          {this.isM2LAdmin() && <Column field="currentCompanyName" header={t("USER.CURRENT_COMPANY_NAME_COLUMN_NAME")} filter sortable />}
                          <Column field="roles" header={t("USER.ROLES_COLUMN_NAME")} body={userRoles} filter sortable />
                          {this.isM2LAdmin() && <Column field="companies" header={t("USER.COMPANIES_COLUMN_NAME")} body={userCompanies} filter sortable />}
                          <Column
                            field="isActive"
                            header={t("USER.IS_ACTIVE_COLUMN_NAME")}
                            dataType="boolean"
                            sortable
                            body={formatIsActiveCheckBox}
                            filter
                            filterElement={verifiedRowFilterTemplate}
                          />
                          <Column style={{ minWidth: "6rem" }} body={buttonOpenUser} rowEditor />
                        </DataTable>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </div>
          </CSSTransition>
        </TransitionGroup>
      </Fragment>
    );
  }
}
export default withTranslation()(User);
