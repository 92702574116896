import React, { useEffect, Fragment } from "react";
import { Route } from "react-router-dom";
import AppHeader from "../../Layout/AppHeader";
import AppSidebar from "../../Layout/AppSidebar";
import ThemeOptions from "../../Layout/ThemeOptions";

import Training from "./Training";
import TrainingList from "./TrainingList";

const TrainingPages = ({ match }) => {
  return (
    <Fragment>
      <ThemeOptions />
      <AppHeader />
      <div className="app-main">
        <AppSidebar />
        <div className="app-main__outer">
          <div className="app-main__inner">
            <Route path={`${match.url}/training-list`} component={TrainingList} />
            <Route path={`${match.url}/trainings/:learingPathId`} component={Training} />
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default TrainingPages;
