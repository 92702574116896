class TokenService {
  getLocalRefreshToken() {
    const user = JSON.parse(localStorage.getItem("token"));
    return user?.refreshToken;
  }

  getLocalAccessToken() {
    const user = JSON.parse(localStorage.getItem("token"));
    return user?.accessToken;
  }

  updateToken(token) {
    localStorage.removeItem("token");
    localStorage.setItem("token", JSON.stringify(token));
  }

  getToken() {
    return JSON.parse(localStorage.getItem("token"));
  }

  setToken(token) {
    localStorage.setItem("token", JSON.stringify(token));
  }

  getUser() {
    return JSON.parse(localStorage.getItem("user"));
  }

  setUser(user) {
    localStorage.setItem("user", JSON.stringify(user));
  }

  getCompany() {
    return JSON.parse(localStorage.getItem("company"));
  }

  setCompany(company) {
    localStorage.setItem("company", JSON.stringify(company));
  }

  getTrainings() {
    return JSON.parse(localStorage.getItem("trainings"));
  }

  setTrainings(trainings) {
    localStorage.setItem("trainings", JSON.stringify(trainings));
  }

  remove() {
    localStorage.removeItem("token");
    localStorage.removeItem("user");
    localStorage.removeItem("company");
    localStorage.removeItem("trainings");
  }
}

export default new TokenService();
