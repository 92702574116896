import React, { Component } from "react";
import XLSX from "xlsx";
import classnames from "classnames";
import { Col, Row, Modal, ModalHeader, ModalBody, ModalFooter, TabContent, TabPane, Nav, NavItem, NavLink, Card, CardBody, CardTitle, Button } from "reactstrap";
import "./styles.css";

class XlsxPreview extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sheets: [],
      workbook: null,
      activeTab: "",
    };
  }

  componentDidMount() {
    this.initWorkbook(this.props.file);
  }

  initWorkbook = (fileObject) => {
    const reader = new FileReader();
    reader.onload = (e) => {
      const data = e.target.result;
      const workbook = XLSX.read(data, { type: "binary" });

      var sheets = workbook.SheetNames.map((sheetName) => {
        const sheet = workbook.Sheets[sheetName];
        const rows = XLSX.utils.sheet_to_json(sheet, { header: 1 });
        return {
          sheetName: sheetName,
          rows: rows,
        };
      });

      this.setState({
        sheets: sheets,
        workbook: workbook,
        activeTab: sheets[0].sheetName,
      });
    };
    reader.readAsBinaryString(fileObject);
  };

  handleTabChange(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab,
      });
    }
  }

  render() {
    const { workbook, sheets } = this.state;

    const renderNav = () => {
      return sheets.map((sheet) => {
        return (
          <NavItem key={sheet.sheetName}>
            <NavLink
              href="#"
              className={classnames({
                active: this.state.activeTab === sheet.sheetName,
              })}
              onClick={() => {
                this.handleTabChange(sheet.sheetName);
              }}
            >
              {sheet.sheetName}
            </NavLink>
          </NavItem>
        );
      });
    };
    const renderTab = () => {
      return sheets.map((sheet) => {
        return (
          <TabPane key={sheet.sheetName} tabId={sheet.sheetName}>
            <table>
              <tbody>
                {sheet.rows.map((row, i) => (
                  <tr className="tr-xls" style={{ fontWeight: i === 0 ? "bold" : "normal" }} key={i}>
                    {row.map((cell, j) => (
                      <td className="td-xls" key={j}>
                        {cell}
                      </td>
                    ))}
                  </tr>
                ))}
              </tbody>
            </table>
          </TabPane>
        );
      });
    };

    return (
      <>
        <div>
          {workbook && (
            <div className="card p-fluid">
              <div style={{ margin: "0 10px" }}>
                <Row>
                  <Nav style={{ padding: "0.4rem 0.75rem" }} pills>
                    {renderNav()}
                  </Nav>
                  <TabContent activeTab={this.state.activeTab} style={{ padding: "0.2rem" }}>
                    {renderTab()}
                  </TabContent>
                </Row>
              </div>
            </div>
          )}
        </div>
      </>
    );
  }
}
export default XlsxPreview;
