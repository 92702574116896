import React, { Fragment, Component } from "react";
import { withTranslation } from "react-i18next";
import { VerticalTimeline, VerticalTimelineElement } from "react-vertical-timeline-component";
import { Col, Row, Button, Card, CardBody, CardFooter, CardTitle, Input, FormGroup, Label } from "reactstrap";
import { faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import PerfectScrollbar from "react-perfect-scrollbar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "../LearningPath/style.css";
import "../../Metadata/Attribute/DataTable.css";
import TimePicker from "rc-time-picker";
import "rc-time-picker/assets/index.css";
import Select from "react-select";
import tokenService from "../../../service/tokenService";
import moment from "moment";

class ScheduleTabContent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedLearningPath: props.learningPath,
      learningPathItems: props.learningPathItems,
      dropdownLearningPathItems: props.learningPathItems.filter((i) => !props.schedules.find((s) => s.learningPathItemId === i.id)),
      schedules: props.schedules,
      loggedUser: tokenService.getUser(),
      currentSchedule: { scheduleType: "Regular" },
      editedSchedule: "",
      scheduleTypes: [
        { label: "REGULAR", value: "Regular" },
        { label: "PAUSE", value: "Pause" },
        { label: "FINAL_ROUND", value: "FinalRound" },
      ],
      error: {},
      startTime: props.schedules.length > 0 ? this.getTime(props.schedules[0].time) : null,
      timeFormat: "h:mm a",
    };
  }

  getTime = (time) => {
    return moment()
      .hour(Number(time.slice(0, 2)))
      .minute(Number(time.slice(3, 5)));
  };

  componentDidMount() {}

  componentDidUpdate() {
    if (JSON.stringify(this.props.schedules) !== JSON.stringify(this.state.schedules)) {
      this.setState({ schedules: this.props.schedules }, () => this.recalculateTime());
    }

    // if (JSON.stringify(this.props.learningPathItems) !== JSON.stringify(this.state.learningPathItems)) {
    //   this.setState({learningPathItems: this.props.learningPathItems,
    //   dropdownLearningPathItems: this.props.learningPathItems.filter(i=>!this.state.schedules.find(s=>s.learningPathItemId === i.id)),
    //  });
    // }
  }

  onCancelUpdate = () => {
    this.setState({
      currentSchedule: { scheduleType: "Regular" },
      editedSchedule: "",
      dropdownLearningPathItems: this.state.learningPathItems.filter((i) => !this.state.schedules.find((s) => s.learningPathItemId === i.id)),
    });
  };

  onStartTimeChange = (value) => {
    this.setState({ startTime: value }, () => {
      this.recalculateTime();
    });
  };

  onChangeSchedule = () => {
    let schedules = this.state.schedules;
    let currentSchedule = this.state.currentSchedule;
    currentSchedule.learningPathId = this.state.selectedLearningPath.id;
    if (this.state.editedSchedule) {
      let index = schedules.indexOf(this.state.editedSchedule);
      schedules[index] = currentSchedule;
    } else {
      schedules.push(currentSchedule);
    }

    this.setState(
      {
        schedules: schedules,
        currentSchedule: { scheduleType: "Regular" },
        editedSchedule: "",
        dropdownLearningPathItems: this.state.learningPathItems.filter((i) => !this.state.schedules.find((s) => s.learningPathItemId === i.id)),
      },
      () => this.recalculateTime()
    );
  };

  onEditSchedule = (schedule) => {
    if (this.props.readOnly) {
      return;
    }
    this.setState({
      currentSchedule: JSON.parse(JSON.stringify(schedule)),
      editedSchedule: schedule,
      dropdownLearningPathItems: this.state.learningPathItems.filter(
        (i) => !this.state.schedules.find((s) => s.learningPathItemId === i.id) || schedule.learningPathItemId === i.id
      ),
    });
  };

  onChangeScheduleDuration = (e) => {
    var currentSchedule = this.state.currentSchedule;
    if (!currentSchedule) {
      currentSchedule = {};
    }
    currentSchedule.duration = e.target.value;

    this.setState({ currentSchedule: currentSchedule });
  };

  onChangeLearningPathItem = (e) => {
    var currentSchedule = this.state.currentSchedule;
    if (!currentSchedule) {
      currentSchedule = {};
    }
    currentSchedule.learningPathItemId = e.id;

    this.setState({ currentSchedule: currentSchedule });
  };

  onChangeType = (e) => {
    var currentSchedule = this.state.currentSchedule;
    if (!currentSchedule) {
      currentSchedule = {};
    }
    currentSchedule.scheduleType = e.value;
    if (currentSchedule.scheduleType !== "Regular") {
      currentSchedule.learningPathItemId = null;
    }

    this.setState({ currentSchedule: currentSchedule });
  };

  onDeleteSchedule = (e, schedule) => {
    e.preventDefault();
    if (schedule === this.state.currentSchedule) {
      this.setState({ currentSchedule: { scheduleType: "Regular" } });
    }
    var schedules = [];
    this.state.schedules.forEach((s) => {
      if (s !== schedule) {
        schedules.push(s);
      }
    });

    this.setState(
      {
        schedules: schedules,
        dropdownLearningPathItems: this.state.learningPathItems.filter(
          (i) => !this.state.schedules.find((s) => s.learningPathItemId === i.id) || schedule.learningPathItemId === i.id
        ),
      },
      () => {
        this.recalculateTime();
      }
    );
  };

  recalculateTime() {
    var startTime = this.state.startTime;
    var duration = 0;

    var schedules = this.state.schedules;

    schedules.forEach((item) => {
      let time = startTime;
      if (startTime) {
        time = time.add(duration, "minutes");
        item.time = (time.hours() < 10 ? "0" + time.hours() : time.hours()) + ":" + (time.minutes() < 10 ? "0" + time.minutes() : time.minutes()) + ":00";

        startTime = this.getTime(item.time);
      } else {
        item.time = null;
      }
      duration = item.duration;
    });

    this.setState({ schedules: schedules });
    this.props.onScheduleChange(schedules);
  }

  render() {
    const { t, readOnly } = this.props;

    const dragItem = {};
    const dragOverItem = {};

    const onDragStart = (e, index) => {
      dragItem.current = index;
      // e.preventDefault();
      // e.stopPropagation();
    };

    const onDragEnter = (e, index) => {
      dragOverItem.current = index;
      // e.preventDefault();
      // e.stopPropagation();
    };

    const handleSort = (e) => {
      let schedules = this.state.schedules;

      //remove and save the dragged item content
      const draggedItemContent = schedules.splice(dragItem.current, 1)[0];

      //switch the position
      schedules.splice(dragOverItem.current, 0, draggedItemContent);

      //reset the position ref
      dragItem.current = null;
      dragOverItem.current = null;

      //update the actual array
      this.setState({ schedules: schedules }, () => this.recalculateTime());
      // e.preventDefault();
      // e.stopPropagation();
    };

    const onDragOver = (e) => {
      e.preventDefault();
      // e.stopPropagation();
    };

    const renderIcon = (scheduleType) => {
      if (scheduleType !== "Pause") {
        return <i className="badge badge-dot badge-dot-xl bg-success"> </i>;
      } else {
        return <i className="badge badge-dot badge-dot-xl bg-warning"> </i>;
      }
    };

    const listSchedules = this.state.schedules.map((schedule, index) => (
      <VerticalTimelineElement
        key={index}
        className="vertical-timeline-item"
        style={{ margin: "0 0 0.7rem" }}
        icon={renderIcon(schedule?.scheduleType)}
        date={schedule.time?.slice(0, 5)}
      >
        <div
          className="widget-content p-0"
          draggable={!readOnly}
          fbefore-timeline
          onDragStart={(e) => onDragStart(e, index)}
          onDragEnter={(e) => onDragEnter(e, index)}
          onDragEnd={(e) => handleSort(e)}
          onDragOver={onDragOver}
        >
          <div className="widget-content-wrapper">
            <div className="widget-content-left" onClick={() => this.onEditSchedule(schedule)}>
              <div className="widget-heading ">
                <h4 className="timeline-title wordwrap" style={{ width: "29rem" }}>
                  {schedule.scheduleType === "Regular"
                    ? this.state.learningPathItems?.find((i) => i.id === schedule.learningPathItemId)?.displayName
                    : t("LEARNINGPATH." + this.state.scheduleTypes?.find((s) => s.value === schedule.scheduleType)?.label)}
                </h4>
                <p className="wordwrap">{schedule.duration} min</p>
              </div>
            </div>
            {!readOnly && (
              <div style={{ padding: "0px 30px 0px 0px" }}>
                <Button className="border-0 btn-transition" style={{ padding: "0px" }} onClick={(e) => this.onDeleteSchedule(e, schedule)} outline color="danger">
                  <FontAwesomeIcon icon={faTrashAlt} />
                </Button>
              </div>
            )}
          </div>
        </div>
      </VerticalTimelineElement>
    ));

    return (
      <Fragment>
        <Row>
          <Col md="6">
            <div className="scroll-area" style={{ height: "475px" }}>
              <Row>
                <Card className="main-card" style={{ height: "29rem" }}>
                  <CardBody style={{ padding: "0.3rem 0.7rem" }}>
                    <FormGroup>
                      <Label for="startTime">{t("LEARNINGPATH.START_TIME")}*</Label>
                      <TimePicker
                        showSecond={false}
                        disabled={readOnly}
                        id="startTime"
                        // minuteStep={5}
                        className={"form-control"}
                        defaultValue={this.state.startTime}
                        onChange={(e) => this.onStartTimeChange(e)}
                        format={this.state.format}
                        allowEmpty={false}
                      />
                    </FormGroup>
                    <FormGroup>
                      <Label for="duration">{t("LEARNINGPATH.DURATION")}*</Label>
                      <Input
                        type="number"
                        name="duration"
                        id="duration"
                        readOnly={readOnly || !this.state.startTime}
                        onChange={this.onChangeScheduleDuration}
                        value={this.state.currentSchedule.duration ?? ""}
                        className={"form-control"}
                      />
                    </FormGroup>
                    <FormGroup>
                      <Label for="name">
                        {t("LEARNINGPATH.LEARNINGPATHITEM")}
                        {this.state.currentSchedule?.scheduleType !== "Regular" ? "" : "*"}
                      </Label>
                      <Select
                        options={this.state.dropdownLearningPathItems}
                        value={this.state.dropdownLearningPathItems.find((t) => t.id === this.state.currentSchedule.learningPathItemId) || ""}
                        onChange={this.onChangeLearningPathItem}
                        isDisabled={readOnly || this.state.currentSchedule?.scheduleType !== "Regular" || !this.state.startTime}
                        getOptionLabel={(option) => option.displayName}
                        getOptionValue={(option) => option.id}
                      />
                    </FormGroup>
                    <FormGroup>
                      <Label for="name"> {t("LEARNINGPATH.TYPE")}</Label>
                      <Select
                        options={this.state.scheduleTypes}
                        value={this.state.scheduleTypes.find((t) => t.value === this.state.currentSchedule.scheduleType)}
                        onChange={this.onChangeType}
                        isDisabled={readOnly || !this.state.startTime}
                        getOptionLabel={(option) => t("LEARNINGPATH." + option.label)}
                        getOptionValue={(option) => option.value}
                      />
                    </FormGroup>
                  </CardBody>
                  <CardFooter className="d-block text-end" style={{ marginTop: "0.25rem" }}>
                    <Row>
                      <Col>
                        {this.state.editedSchedule ? (
                          <Button className="me-2" color="secondary" onClick={() => this.onCancelUpdate()}>
                            {t("LEARNINGPATH.CANCEL_BUTTON")}
                          </Button>
                        ) : (
                          ""
                        )}
                        {!readOnly && (
                          <Button
                            className="me-2"
                            outline
                            color="primary"
                            onClick={() => this.onChangeSchedule()}
                            disabled={
                              !this.state.startTime ||
                              !this.state.currentSchedule?.duration ||
                              (this.state.currentSchedule?.scheduleType === "Regular" && !this.state.currentSchedule?.learningPathItemId)
                            }
                          >
                            {this.state.editedSchedule ? t("LEARNINGPATH.UPDATE_BUTTON") : t("LEARNINGPATH.ADD_BUTTON")}
                          </Button>
                        )}
                      </Col>
                    </Row>
                  </CardFooter>
                </Card>
              </Row>
            </div>
          </Col>
          <Col md="6">
            <CardBody className="items-border">
              <div className="scroll-area" style={{ height: "25rem" }}>
                <PerfectScrollbar>
                  <div>
                    <VerticalTimeline className="vertical-without-time todo-list-wrapper before-timeline" layout="1-column">
                      {listSchedules}
                    </VerticalTimeline>
                  </div>
                </PerfectScrollbar>
              </div>
            </CardBody>
          </Col>
        </Row>
      </Fragment>
    );
  }
}
export default withTranslation()(ScheduleTabContent);
