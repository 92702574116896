import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { Col, Row, Button, Input, Label, Form, ModalFooter, Modal, ModalHeader, ModalBody, Card, CardBody, FormGroup } from "reactstrap";
import { Column, DataTable } from "primereact";
import AttributeService from "../../../service/AttributeService";
import Select from "react-select";

class LearningObjectAttribute extends Component {
  constructor(props) {
    super(props);
    this.state = {
      attribute: {},
      editAttribute: null,
      values: [],
      error: {},
      dropdownAttributes: [],
      attributes: [],
      attributeModel: false,
    };
  }

  componentDidMount() {
    this.getActiveAttributes();
  }

  getActiveAttributes = () => {
    return AttributeService.getAllAttributes().then((response) => {
      let attributes = response.data;
      this.setState({ attributes: attributes });
    });
  };

  changeAttributeName = (e) => {
    var newAttribute = this.state.attribute;
    newAttribute.attributeId = e.id;
    newAttribute.value = "";
    var values = [];
    this.state.dropdownAttributes
      .find((a) => a.id === this.state.attribute.attributeId)
      ?.translations.find((t) => t.language === this.props.language)
      ?.values.forEach((val, index) => {
        if (!this.props.learningObjectAttributes.find((a) => a.attributeId === newAttribute.attributeId && a.language === this.props.language && a.value === val)) {
          values.push({ label: val, value: index });
        }
      });

    let error = { ...this.state.error };
    error.name = newAttribute.name?.length < 1 ? "Required" : "";

    this.setState({ attribute: newAttribute, values: values, error: error });
  };

  changeAttributeValue = (e) => {
    var newAttribute = this.state.attribute;
    newAttribute.value = e.label;

    let error = { ...this.state.error };
    error.value = newAttribute.value?.length < 1 ? "Required" : "";

    this.setState({ attribute: newAttribute, error: error });
  };

  changeAttributeComment = (e) => {
    var newAttribute = this.state.attribute;
    newAttribute.comment = e.target.value;
    this.setState({ attribute: newAttribute });
  };

  changeAttributeDuration = (e) => {
    var newAttribute = this.state.attribute;
    newAttribute.duration = e.target.value;

    let error = { ...this.state.error };
    error.duration = newAttribute.duration?.length < 1 ? "Required" : "";

    this.setState({ attribute: newAttribute, error: error });
  };

  saveAttribute = (e) => {
    e.preventDefault();
    var attribute = this.state.attribute;

    var error = { ...this.state.error };
    error.name = !attribute?.attributeId || attribute?.attributeId?.length < 1 ? "Required" : "";
    error.value = !attribute?.value || attribute?.value?.length < 1 ? "Required" : "";
    error.duration = !attribute?.duration || attribute?.duration?.length < 1 ? "Required" : "";

    this.setState({ error });

    if (this.checkValid({ error: error })) {
      var learningObjectAttributes = this.props.learningObjectAttributes;
      if (this.state.editAttribute) {
        var attr = learningObjectAttributes.find((a) => JSON.stringify(a) === JSON.stringify(this.state.editAttribute));

        attr.attributeId = attribute.attributeId;
        attr.value = attribute.value;
        attr.comment = attribute.comment;
        attr.duration = attribute.duration;

        attr.language = this.props.language;
        attr.attributeName = this.state.attributes.find((a) => a.id === attr.attributeId)?.translations.find((t) => t.language === this.props.language)?.name;
      } else {
        attribute.language = this.props.language;
        attribute.materialIds = [];
        attribute.attributeName = this.state.attributes.find((a) => a.id === this.state.attribute.attributeId)?.translations.find((t) => t.language === this.props.language)?.name;
        learningObjectAttributes.push(attribute);
      }
      this.changeAttributeModalState();
      this.props.onChange(learningObjectAttributes);
    }
  };

  clearAttribute = () => {
    var clearAttribute = {};
    clearAttribute.comment = "";
    clearAttribute.duration = "";
    clearAttribute.language = "";
    clearAttribute.attributeId = "";
    clearAttribute.value = "";

    this.setState({ attribute: clearAttribute, editAttribute: null, error: {} });
  };

  deleteAttribute = (rowData) => {
    let learningObjectAttributes = this.props.learningObjectAttributes.filter((obj) => obj !== rowData);
    this.props.onChange(learningObjectAttributes);
  };

  checkValid = ({ error, ...rest }) => {
    let isValid = true;
    Object.values(error).forEach((val) => {
      if (isValid === false) {
        return;
      }
      if (val.length > 0) {
        isValid = false;
      } else {
        isValid = true;
      }
    });
    if (rest) {
      Object.values(rest).forEach((val) => {
        if (isValid === false) {
          return;
        }
        if (val === null) {
          isValid = false;
        } else {
          isValid = true;
        }
      });
    }
    return isValid;
  };

  filterDropdownAttributes = () => {
    var showAttributes = [];

    this.state.dropdownAttributes.forEach((dropdownAttribute) => {
      var translation = dropdownAttribute.translations.find((t) => t.language === this.props.language);
      if (translation) {
        let add = false;
        translation.values.forEach((value) => {
          if (!this.props.learningObjectAttributes.some((a) => (a.attributeId === dropdownAttribute.id || a.attributeId === this.state.attribute.id) && a.value === value)) {
            add = true;
          }
        });

        if (add && (dropdownAttribute.isActive || dropdownAttribute.id === this.state.attribute.attributeId)) {
          showAttributes.push(dropdownAttribute);
        }
      }
    });

    return showAttributes;
  };

  createAttribute = () => {
    var dropdownAttributes = this.state.attributes.filter((a) => a.isActive);
    this.setState({ dropdownAttributes: dropdownAttributes }, () => this.changeAttributeModalState());
  };

  editAttribute = (attribute) => {
    var values = [];
    var dropdownAttributes = this.state.attributes.filter((a) => a.isActive || a.id === attribute.attributeId);
    this.state.dropdownAttributes
      .find((a) => a.id === attribute.attributeId)
      ?.translations.find((t) => t.language === this.props.language)
      ?.values.forEach((val, index) => {
        if (!this.props.learningObjectAttributes.find((a) => a.attributeId === attribute.attributeId && a.language === this.props.language && a.value === val)) {
          values.push({ label: val, value: index });
        } else if (this.props.language === attribute.language && attribute.value === val) {
          values.push({ label: val, value: index });
        }
      });
    var currentValue = values.find((v) => v.label === attribute.value);
    if (!currentValue) {
      values.push({ label: attribute.value, value: values.length + 10 });
    }
    this.setState({ attribute: Object.assign({}, attribute), editAttribute: Object.assign({}, attribute), values: values, dropdownAttributes: dropdownAttributes }, () =>
      this.changeAttributeModalState()
    );
  };

  changeAttributeModalState = () => {
    if (this.state.attributeModel) {
      this.clearAttribute();
    }
    this.setState({
      attributeModel: !this.state.attributeModel,
    });
  };

  render() {
    const { t, readOnly } = this.props;

    const deleteButton = (rowData) => {
      return (
        <Button outline color="danger" size="sm" onClick={() => this.deleteAttribute(rowData)}>
          {t("LEARNING_OBJECT.DELETE_BUTTON")}
        </Button>
      );
    };
    const openButton = (rowData) => {
      return (
        <Button outline color="primary" size="sm" onClick={() => this.editAttribute(rowData)}>
          {t("LEARNING_OBJECT.OPEN_BUTTON")}
        </Button>
      );
    };

    const attibuteBodyColumn = (rowData) => {
      return (
        <div className="country-item">{this.state.attributes.find((a) => a.id === rowData.attributeId)?.translations.find((t) => t.language === this.props.language)?.name}</div>
      );
    };
    return (
      <div>
        {!readOnly && (
          <Button className="mb-2 me-2" outline color="primary" onClick={() => this.createAttribute()}>
            {t("LEARNING_OBJECT.CREATE_BUTTON")}
          </Button>
        )}

        <DataTable
          value={this.props.learningObjectAttributes.filter((l) => l.language === this.props?.language)}
          style={{ width: "100%" }}
          paginator
          className="p-datatable-customers"
          rows={4}
          dataKey="id"
          responsiveLayout="scroll"
        >
          <Column field="attibuteId" className="col-md-3" body={attibuteBodyColumn} header={t("LEARNING_OBJECT.ATTRIBUTE_COLUMN_NAME")}></Column>
          <Column field="value" className="col-md-2" header={t("LEARNING_OBJECT.VALUE_COLUMN_NAME")}></Column>
          <Column field="comment" className="col-md-3" header={t("LEARNING_OBJECT.COMMENT_COLUMN_NAME")}></Column>
          <Column field="duration" className="col-md-2" header={t("LEARNING_OBJECT.DURATION_COLUMN_NAME")}></Column>
          {!readOnly && <Column body={openButton} rowEditor />}
          {!readOnly && <Column body={deleteButton} rowEditor />}
        </DataTable>

        <Modal isOpen={this.state.attributeModel} toggle={this.changeAttributeModalState} style={{ marginTop: "100px", width: "60%" }}>
          <ModalHeader> {t("LEARNING_OBJECT.ATTRIBUTE")}</ModalHeader>
          <Form onSubmit={this.saveAttribute} style={{ margin: "0 0.2rem" }}>
            <ModalBody style={{ minHeight: "auto" }}>
              <Card className="main-card mb-3">
                <CardBody>
                  <Row>
                    <Col>
                      <FormGroup>
                        <Label for="name" className="mb-1">
                          {t("LEARNING_OBJECT.ATTRIBUTE")}*
                        </Label>
                        <Select
                          options={this.filterDropdownAttributes()}
                          isOptionDisabled={(option) => !option.isActive}
                          value={
                            this.state.dropdownAttributes.find(
                              (a) => a.id === this.state.attribute.attributeId && a.translations.some((t) => t.language === this.props.language)
                            ) || ""
                          }
                          onChange={this.changeAttributeName}
                          getOptionLabel={(option) =>
                            option.translations?.find((t) => t.language === this.props.language)?.name +
                            (!option.isActive ? " (inactive) " : " ") +
                            (option.isPublic ? " (public)" : "")
                          }
                          getOptionValue={(option) => option.id}
                          className={this.state.error.name?.length > 0 ? "is-invalid form-control" : ""}
                        />
                        {this.state.error.name?.length > 0 && <span className="invalid-feedback">{this.state.error?.name}</span>}
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <FormGroup>
                        <Label for="name" className="mb-1">
                          {t("LEARNING_OBJECT.VALUE")}*
                        </Label>
                        <Select
                          options={this.state.values}
                          value={this.state.values.find((v) => v.label === this.state.attribute.value) || ""}
                          onChange={this.changeAttributeValue}
                          getOptionLabel={(option) => option.label}
                          getOptionValue={(option) => option.value}
                          className={this.state.error.value?.length > 0 ? "is-invalid form-control" : ""}
                        />
                        {this.state.error.value?.length > 0 && <span className="invalid-feedback">{this.state.error?.value}</span>}
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Label for="comment">{t("LEARNING_OBJECT.COMMENT")}</Label>
                      <div style={{ justifyContent: "in-line", alignItems: "end", display: "flex" }}>
                        <Input type="text" name="comment" id="comment" onChange={this.changeAttributeComment} value={this.state.attribute.comment} />
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <FormGroup>
                        <Label for="duration">{t("LEARNINGPATH.DURATION")}*</Label>
                        <Input
                          type="number"
                          name="duration"
                          id="duration"
                          onChange={this.changeAttributeDuration}
                          value={this.state.attribute.duration}
                          className={this.state.error.duration?.length > 0 ? "is-invalid form-control" : "form-control"}
                        />
                        {this.state.error.duration?.length > 0 && <span className="invalid-feedback">{this.state.error?.duration}</span>}
                      </FormGroup>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </ModalBody>
            <ModalFooter>
              <div>
                <Button className="mb-2 me-2 " color="secondary" onClick={this.changeAttributeModalState}>
                  {t("LEARNING_OBJECT.NO_BUTTON")}
                </Button>
                <Button className="mb-2 me-2 " color="primary" type="submit">
                  {t("LEARNING_OBJECT.SAVE_BUTTON")}
                </Button>
              </div>
            </ModalFooter>
          </Form>
        </Modal>
      </div>
    );
  }
}
export default withTranslation()(LearningObjectAttribute);
