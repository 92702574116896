import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import LearningObjectMaterialService from "../../../service/LearningObjectMaterialService";
import AudioPreview from "./AudioPreview";
import ImagePreview from "./ImagePreview";
import PfdPreview from "./PfdPreview";
import VideoPreview from "./VideoPreview";
import XlsxPreview from "./XlsxPreview";

class MaterialPreview extends Component {
  constructor(props) {
    super(props);
    this.state = {
      file: null,
    };
  }

  componentDidMount() {
    if (this.props.material.id) {
      LearningObjectMaterialService.download(this.props.material.id)
        .then((response) => {
          var blob = new Blob([response.data]);
          var file = new File([blob], this.props.material.fileName); //, { type: "mime" }
          this.setState({ file: file });
        })
        .catch((e) => {
          console.log(e);
        });
    } else {
      this.setState({ file: this.props.material.file });
    }
  }

  render() {
    const { t, onClose, material } = this.props;

    return (
      <Modal isOpen={true} toggle={onClose} style={{ marginTop: "2rem", width: "100%" }}>
        <ModalHeader>{this.state.file?.name}</ModalHeader>
        <ModalBody style={{ minHeight: "auto" }}>
          {material?.materialTypeName === "audio" && this.state.file && <AudioPreview file={this.state.file} style={{ maxHeight: "30rem", maxWidth: "50rem" }} />}
          {material?.materialTypeName === "image" && this.state.file && <ImagePreview file={this.state.file} style={{ maxHeight: "30rem", maxWidth: "50rem" }} />}
          {material?.materialTypeName === "pdf" && this.state.file && <PfdPreview file={this.state.file} />}
          {material?.materialTypeName === "video" && this.state.file && <VideoPreview file={this.state.file} />}
          {material?.materialTypeName === "xlsx" && this.state.file && <XlsxPreview file={this.state.file} />}
        </ModalBody>
        <ModalFooter>
          <Button className="mb-2 me-2" color="secondary" onClick={onClose}>
            {t("LEARNING_OBJECT.CLOSE_BUTTON")}
          </Button>
        </ModalFooter>
      </Modal>
    );
  }
}
export default withTranslation()(MaterialPreview);
