import i18n from "i18next";
import { initReactI18next } from "react-i18next";

// Importing translation files

import translationEN from "./assets/i18n/en.json";
import translationDE from "./assets/i18n/de.json";
import translationFR from "./assets/i18n/fr.json";
import backend from "i18next-http-backend";
import tokenService from "./service/tokenService";
import languages from "./models/Languages";

//Creating object with the variables of imported translation files
const resources = {
  en: {
    translation: translationEN,
  },
  de: {
    translation: translationDE,
  },
  fr: {
    translation: translationFR,
  },
};

//i18N Initialization
var userLang = navigator.language || navigator.userLanguage;
var user = tokenService.getUser();
let userLng;
if (user) {
  userLng = user.language;
} else {
  userLng = languages.find((obj) => obj.lang === userLang.slice(0, 2))?.lang;
}

i18n.use(initReactI18next).init({
  resources,
  lng: userLng ? userLng : "de",
  keySeparator: false,
  interpolation: {
    escapeValue: false,
  },
});

export default i18n;
