import api from "./api";

class CompanyService {
  getAllCompanies() {
    return api.get("/Company/get-all");
  }

  getCompanyById(id) {
    return api.get("/Company/get-by-id?id=" + id);
  }

  getCompanyByUserId(userId) {
    return api.get("/Company/get-by-user-id?userId=" + userId);
  }

  getActiveCompanyByUserId(userId) {
    return api.get("/Company/get-active-by-user-id?userId=" + userId);
  }

  insert(newCompany) {
    return api.post("/Company/insert", newCompany);
  }

  update(updatedCompany) {
    return api.post("/Company/update", updatedCompany);
  }

  activate(id) {
    return api.post("/Company/activate?id=" + id);
  }

  deactivate(id) {
    return api.post("/Company/deactivate?id=" + id);
  }

  delete(id) {
    return api.post("/Company/delete?id=" + id);
  }
}

export default new CompanyService();
