import React, { Fragment, Component } from "react";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import { withTranslation } from "react-i18next";
import PageTitle from "../../../Layout/AppMain/PageTitle";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Row, Col, Card, CardBody, Button, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem } from "reactstrap";
import { FilterMatchMode } from "primereact/api";
import "../../Metadata/Attribute/DataTable.css";
import moment from "moment/moment";
import { classNames } from "primereact/utils";
import LearningObjectService from "../../../service/LearningObjectService";
import Flag from "react-flagkit";
import languages from "../../../models/Languages";
import MainModal from "./MainModal";
import { TriStateCheckbox } from "primereact/tristatecheckbox";
import tokenService from "../../../service/tokenService";

class LearningObject extends Component {
  constructor(props) {
    super(props);
    this.state = {
      language: props.i18n.language,
      learningObjects: [],
      selectedLearningObjectId: null,
      filteredLearningObjects: [],
      selectedFilteringTableLanguage: languages.find((obj) => obj.lang === this.props.i18n.language),
      modal: false,
      filterLearningObjectsTemplate: {
        name: { value: null, matchMode: FilterMatchMode.CONTAINS },
        topicName: { value: null, matchMode: FilterMatchMode.CONTAINS },
        categoryName: { value: null, matchMode: FilterMatchMode.CONTAINS },
        competenceName: { value: null, matchMode: FilterMatchMode.CONTAINS },
        comment: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
        createdDate: { value: null, matchMode: FilterMatchMode.DATE_IS },
        isPublic: { value: tokenService.getCompany().showPublicLearningObject ? null : false, matchMode: FilterMatchMode.EQUALS },
        isActive: { value: null, matchMode: FilterMatchMode.EQUALS },
        createdByUser: { value: null, matchMode: FilterMatchMode.CONTAINS },
      },
    };
  }

  componentDidMount() {
    this.getAllLearningObjects();
  }

  getAllLearningObjects = () => {
    LearningObjectService.getAllLearningObjects()
      .then((response) => {
        this.setState({
          learningObjects: response.data,
        });
        this.filteredLearningObjects(this.state.selectedFilteringTableLanguage.lang);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  filteredLearningObjects(lang) {
    var filteredLearningObjects = this.state.learningObjects.map(
      ({ id, translations, createdDate, image, createdByUser, isActive, isPublic, topicNames, categoryNames, competenceNames }) => ({
        language: translations.find((obj) => obj.language === lang)?.language,
        name: translations.find((obj) => obj.language === lang)?.name,
        comment: translations.find((obj) => obj.language === lang)?.comment,
        createdByUser: createdByUser,
        id: id,
        image: image,
        isActive: isActive,
        isPublic: isPublic,
        createdDate: moment(new Date(createdDate)).format("DD.MM.YYYY"),
        topicName: topicNames.find((obj) => obj.language === lang)?.name,
        categoryName: categoryNames.find((obj) => obj.language === lang)?.name,
        competenceName: competenceNames.find((obj) => obj.language === lang)?.name,
      })
    );

    if (lang) {
      filteredLearningObjects = filteredLearningObjects.filter((lp) => lp.language === lang);
    }
    this.setState({
      filteredLearningObjects: filteredLearningObjects,
    });
  }

  changeModalState = () => {
    if (this.state.modal) {
      this.getAllLearningObjects();
      this.setState({
        selectedLearningObjectId: null,
      });
    }
    this.setState({
      modal: !this.state.modal,
    });
  };

  dateLearningPathTemplate = (rowData) => {
    return rowData.createdDate;
  };

  onChangeFilteringTableLanguage = (language) => {
    this.setState({ selectedFilteringTableLanguage: language });
    this.filteredLearningObjects(language.lang);
  };

  openUpdateLearningObject = (id) => {
    this.setState({ selectedLearningObjectId: id }, () => {
      this.changeModalState();
    });
  };

  openCreateLearningObject = () => {
    this.changeModalState();
  };

  render() {
    const { t } = this.props;

    const buttonUpdateLearningObject = (rowData) => {
      return (
        <Button className="me-2" size="sm" outline color="primary" onClick={() => this.openUpdateLearningObject(rowData.id)}>
          {t("LEARNING_OBJECT.OPEN_BUTTON")}
        </Button>
      );
    };

    const languageTableFilter = () => {
      return (
        <div>
          <UncontrolledDropdown>
            <DropdownToggle className="p-0 me-2" color="black">
              <div className="icon-wrapper-bg bg-focus" />
              <div className="language-icon">
                <Flag className="me-3 opacity-8" country={this.state.selectedFilteringTableLanguage?.flag} size="30" />
              </div>
            </DropdownToggle>
            <DropdownMenu end className="rm-pointers">
              {languages.map((language, id) => (
                <DropdownItem key={id} value={language} onClick={() => this.onChangeFilteringTableLanguage(language)}>
                  <Flag className="me-3 opacity-8" country={language.flag} />
                  {language.name}
                </DropdownItem>
              ))}
            </DropdownMenu>
          </UncontrolledDropdown>
        </div>
      );
    };

    const languageBody = (option) => {
      return (
        <Flag
          className="me-3 opacity-8"
          style={{
            marginLeft: "0.75rem",
          }}
          country={languages.find((obj) => obj.lang === option.language)?.flag}
          size="30"
        />
      );
    };

    const formatIsPublicCheckBox = (rowData) => {
      return (
        <i
          className={classNames("pi", {
            "true-icon pi-check-circle": rowData.isPublic,
            "false-icon pi-times-circle": !rowData.isPublic,
          })}
        ></i>
      );
    };

    const formatIsActiveCheckBox = (rowData) => {
      return (
        <i
          className={classNames("pi", {
            "true-icon pi-check-circle": rowData.isActive,
            "false-icon pi-times-circle": !rowData.isActive,
          })}
        ></i>
      );
    };

    const verifiedRowFilterTemplate = (options) => {
      return <TriStateCheckbox value={options.value} onChange={(e) => options.filterApplyCallback(e.value)} />;
    };

    return (
      <Fragment>
        <TransitionGroup>
          <CSSTransition component="div" classNames="TabsAnimation" appear={true} timeout={1500} enter={false} exit={false}>
            <div>
              <PageTitle heading={t("LEARNING_OBJECT.TITLE")} subheading={t("LEARNING_OBJECT.DESCRIPTION")} icon="pe-7s-medal icon-gradient bg-tempting-azure" />
              <Row>
                <Col md="12">
                  <Button className="mb-2 me-2" outline color="primary" onClick={() => this.openCreateLearningObject()}>
                    {t("LEARNING_OBJECT.CREATE_BUTTON")}
                  </Button>
                  {this.state.modal ? (
                    <MainModal
                      changeModalState={this.changeModalState}
                      learningObjectId={this.state.selectedLearningObjectId}
                      defaultLang={this.state.selectedFilteringTableLanguage.lang}
                      readOnly={false}
                    />
                  ) : (
                    ""
                  )}
                </Col>
                <Col md="12">
                  <Card className="main-card mb-3">
                    <CardBody>
                      <div className="card p-fluid">
                        <DataTable
                          value={this.state.filteredLearningObjects}
                          paginator
                          className="p-datatable-customers"
                          rows={10}
                          dataKey="id"
                          filterDisplay="row"
                          responsiveLayout="scroll"
                          filters={this.state.filterLearningObjectsTemplate}
                          emptyMessage={t("LEARNING_OBJECT.TABLE_MESSAGE")}
                        >
                          <Column
                            header={t("LEARNING_OBJECT.LANGUAGE_COLUMN_NAME")}
                            field="language"
                            showFilterMenu={false}
                            style={{ minWidth: "3rem" }}
                            body={languageBody}
                            filter
                            filterElement={languageTableFilter}
                          />
                          <Column field="topicName" header={t("NAV.TOPIC")} sortable filter />
                          <Column field="categoryName" header={t("NAV.CATEGORY")} sortable filter />
                          <Column field="competenceName" header={t("NAV.COMPETENCE")} sortable filter />
                          <Column header={t("LEARNING_OBJECT.NAME_COLUMN_NAME")} field="name" filter sortable />
                          <Column header={t("LEARNING_OBJECT.COMMENT_COLUMN_NAME")} field="comment" filter sortable />
                          <Column
                            field="isPublic"
                            header={t("LEARNING_OBJECT.IS_PUBLIC_COLUMN_NAME")}
                            dataType="boolean"
                            sortable
                            body={formatIsPublicCheckBox}
                            filter
                            filterElement={verifiedRowFilterTemplate}
                          />
                          <Column
                            field="isActive"
                            header={t("LEARNING_OBJECT.IS_ACTIVE_COLUMN_NAME")}
                            dataType="boolean"
                            sortable
                            body={formatIsActiveCheckBox}
                            filter
                            filterElement={verifiedRowFilterTemplate}
                          />
                          <Column header={t("LEARNING_OBJECT.CREATED_BY_COLUMN_NAME")} field="createdByUser" filter sortable />
                          <Column header={t("LEARNING_OBJECT.CREATED_DATE_COLUMN_NAME")} filterField="createdDate" dataType="date" body={this.dateLearningPathTemplate} />
                          <Column body={buttonUpdateLearningObject} rowEditor />
                        </DataTable>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </div>
          </CSSTransition>
        </TransitionGroup>
      </Fragment>
    );
  }
}
export default withTranslation()(LearningObject);
