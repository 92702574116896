import api from "./api";

class RecommendationService {
  getAllRecommendations() {
    return api.get("/Recommendation/get-all");
  }

  getRecommendationById(id) {
    return api.get("/Recommendation/get-by-id?id=" + id);
  }

  insert(recommendation) {
    return api.post("/Recommendation/insert", recommendation);
  }

  update(recommendation) {
    return api.post("/Recommendation/update", recommendation);
  }

  activate(id) {
    return api.post("/Recommendation/activate?id=" + id);
  }

  deactivate(id) {
    return api.post("/Recommendation/deactivate?id=" + id);
  }

  delete(id) {
    return api.post("/Recommendation/delete?id=" + id);
  }
}

export default new RecommendationService();
