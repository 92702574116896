import api from "./api";

class LearningPathService {
  getAllLearningPaths() {
    return api.get("/LearningPath/get-all");
  }

  getTraining() {
    return api.get("/LearningPath/get-training");
  }

  getFavorite() {
    return api.get("/LearningPath/get-favorites");
  }

  getByLearningObjectId(learningObjectId) {
    return api.get("/LearningPath/get-by-learning-object-id?learningObjectId=" + learningObjectId);
  }

  getActiveByLearningObjectId(learningObjectId) {
    return api.get("/LearningPath/get-active-by-learning-object-id?learningObjectId=" + learningObjectId);
  }

  getAdviceTraining(trainingId) {
    return api.get("/LearningPath/get-advice-training?trainingId=" + trainingId);
  }

  getRecommendations(learningPathId) {
    return api.get("/LearningPath/get-recommendations?learningPathId=" + learningPathId);
  }

  getLearningPathById(id) {
    return api.get("/LearningPath/get-by-id?id=" + id);
  }

  getByParentId(parentId) {
    return api.get("/LearningPath/get-by-parent-id?parentId=" + parentId);
  }

  isCompleted(id) {
    return api.get("/LearningPath/is-completed?trainingId=" + id);
  }

  insert(newLearningPath) {
    return api.post("/LearningPath/insert", newLearningPath);
  }

  update(updatedLearningPath) {
    return api.post("/LearningPath/update", updatedLearningPath);
  }

  activate(id) {
    return api.post("/LearningPath/activate?id=" + id);
  }

  publish(id) {
    return api.post("/LearningPath/publish?id=" + id);
  }

  updatePublish(id) {
    return api.post("/LearningPath/update-publish?id=" + id);
  }

  deactivate(id) {
    return api.post("/LearningPath/deactivate?id=" + id);
  }

  markAsFavorite(id) {
    return api.post("/LearningPath/mark-as-favorite", { learningPathId: id });
  }

  unmarkFromFavorite(id) {
    return api.post("/LearningPath/unmark-from-favorite", { learningPathId: id });
  }

  delete(id) {
    return api.post("/LearningPath/delete?id=" + id);
  }

  downloadZip(id) {
    return api.get("/LearningPath/download-zip?id=" + id, {
      responseType: "blob",
    });
  }
  
  downloadScorm(id) {
    return api.get("/LearningPath/download-scorm?id=" + id, {
      responseType: "blob",
    });
  }

  downloadPdf(id) {
    return api.get("/LearningPath/download-pdf?id=" + id, {
      responseType: "blob",
    });
  }

  downloadDocx(id) {
    return api.get("/LearningPath/download-docx?id=" + id, {
      responseType: "blob",
    });
  }

  downloadExcel(id) {
    return api.get("/LearningPath/download-excel?id=" + id, {
      responseType: "blob",
    });
  }
}

export default new LearningPathService();
