import React, { Fragment, Component } from "react";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import { withTranslation } from "react-i18next";
import PageTitle from "../../../Layout/AppMain/PageTitle";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Col, Row, Modal, ModalHeader, UncontrolledDropdown, ModalFooter, ModalBody, DropdownMenu, DropdownToggle, DropdownItem, Card, CardBody, Button } from "reactstrap";
import { FilterMatchMode } from "primereact/api";
import "../../Metadata/Attribute/DataTable.css";
import "../../Learning/LearningPath/style.css";
import moment from "moment/moment";
import LearningPathService from "../../../service/LearningPathService";
import LearningPathTypeService from "../../../service/LearningPathTypeService";
import languages from "../../../models/Languages";
import Flag from "react-flagkit";
import { eventMediator } from "../../../service/eventMediator.js";
import tokenService from "../../../service/tokenService";

class AdviceTrainingModel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      adviceTrainings: props.adviceTrainings,
      selectedTraining: null,
      bindAdviceTrainings: [],
      learningPathTypes: [],
      selectedFilteringTableLanguage: languages.find((obj) => obj.lang === this.props.i18n.language),
      favoriteModal: false,
      filterLearningPathsTemplate: {
        name: { value: null, matchMode: FilterMatchMode.CONTAINS },
        version: { value: null, matchMode: FilterMatchMode.CONTAINS },
        duration: { value: null, matchMode: FilterMatchMode.CONTAINS },
        requirements: { value: null, matchMode: FilterMatchMode.CONTAINS },
        description: { value: null, matchMode: FilterMatchMode.CONTAINS },
        createdDate: { value: null, matchMode: FilterMatchMode.DATE_IS },
      },
    };
  }

  componentDidMount() {
    this.getAllLearningPathTypes();
    this.bindAdviceTrainings(this.state.selectedFilteringTableLanguage.lang);
  }

  getAllLearningPathTypes() {
    return LearningPathTypeService.getAllLearningPathTypes()
      .then((response) => {
        this.setState({
          learningPathTypes: response.data,
        });
      })
      .catch((e) => {
        console.log(e);
      });
  }

  bindAdviceTrainings(lang) {
    var bindAdviceTrainings = this.props.adviceTrainings.map(
      ({
        id,
        name,
        comment,
        language,
        description,
        duration,
        learningPathTypeId,
        createdDate,
        isPublic,
        isActive,
        createdByUser,
        version,
        requirements,
        targetGroup,
        isFavorite,
        parentName,
        similarityPercentage,
      }) => ({
        name: name,
        description: description,
        language: language,
        duration: duration,
        comment: comment,
        createdByUser: createdByUser,
        isPublic: isPublic,
        isActive: isActive,
        id: id,
        version: this.state.learningPathTypes.find((t) => t.id === learningPathTypeId)?.name === "Training" ? version : "",
        requirements: requirements,
        targetGroup: targetGroup,
        parentName: parentName,
        isFavorite: isFavorite,
        createdDate: moment(new Date(createdDate)).format("DD.MM.YYYY"),
        similarityPercentage: similarityPercentage,
      })
    );

    if (lang) {
      bindAdviceTrainings = bindAdviceTrainings.filter((lp) => lp.language === lang);
    }
    this.setState({
      bindAdviceTrainings: bindAdviceTrainings,
    });
  }

  changeFavoriteModalState = () => {
    this.setState({
      favoriteModal: !this.state.favoriteModal,
    });
  };

  similarityPercentageTemplate = (rowData) => {
    return Math.round(rowData.similarityPercentage * 100) + " %";
  };

  openFavoriteModel = (training) => {
    this.setState({ selectedTraining: training }, () => {
      this.changeFavoriteModalState();
    });
  };

  getFavorites() {
    return LearningPathService.getFavorite().then((response) => {
      tokenService.setTrainings(response.data);
      eventMediator.dispatch("updateMenu");
    });
  }

  confirmMarkAsFavorite = () => {
    LearningPathService.markAsFavorite(this.state.selectedTraining.id).then(() => {
      var adviceTrainings = this.state.adviceTrainings;
      var index = this.state.adviceTrainings.findIndex((a) => (a.id = this.state.selectedTraining.id));
      adviceTrainings[index].isFavorite = false;
      this.setState({ adviceTrainings: adviceTrainings });
      this.bindAdviceTrainings(this.state.selectedFilteringTableLanguage.lang);

      this.changeFavoriteModalState();
      this.getFavorites();
    });
  };

  confirmUnmarkFromFavorite = () => {
    LearningPathService.unmarkFromFavorite(this.state.selectedTraining.id).then(() => {
      var adviceTrainings = this.state.adviceTrainings;
      var index = this.state.adviceTrainings.findIndex((a) => (a.id = this.state.selectedTraining.id));
      adviceTrainings[index].isFavorite = false;
      this.setState({ adviceTrainings: adviceTrainings });
      this.bindAdviceTrainings(this.state.selectedFilteringTableLanguage.lang);

      this.changeFavoriteModalState();
      this.getFavorites();
    });
  };

  render() {
    const { t, changeModalState } = this.props;

    const buttonFavoriteTraining = (rowData) => {
      if (rowData.isFavorite) {
        return (
          <Button className="me-2" size="sm" style={{ width: "150px" }} outline color="secondary" onClick={() => this.openFavoriteModel(rowData)}>
            {t("TRAINING.UNMARK_FROM_FAVORITE")}
          </Button>
        );
      } else {
        return (
          <Button className="me-2" size="sm" style={{ width: "150px" }} outline color="success" onClick={() => this.openFavoriteModel(rowData)}>
            {t("TRAINING.MARK_AS_FAVORITE")}
          </Button>
        );
      }
    };

    return (
      <Fragment>
        <Modal
          isOpen={true}
          fade={false}
          toggle={() => changeModalState(false)}
          style={{
            maxWidth: "3000px",
            margin: "0.75rem 0",
          }}
        >
          <ModalHeader toggle={() => changeModalState(false)}>{t("LEARNINGPATH.SIMILARITY_PERCENTAGE_TITLE")}</ModalHeader>

          <ModalBody style={{ minHeight: "auto" }}>
            <div className="card p-fluid">
              {this.state.bindAdviceTrainings.length === 0 ? (
                <div style={{ textAlign: "center", fontSize: "large", padding: "10px" }}>Not Found</div>
              ) : (
                <DataTable
                  value={this.state.bindAdviceTrainings}
                  paginator
                  className="p-datatable-customers"
                  rows={10}
                  dataKey="id"
                  filterDisplay="row"
                  responsiveLayout="scroll"
                  filters={this.state.filterLearningPathsTemplate}
                  emptyMessage={t("LEARNINGPATH.TABLE_MESSAGE")}
                >
                  <Column header={t("LEARNINGPATH.NAME_COLUMN_NAME")} field="name" filter sortable />
                  <Column field="version" header={t("LEARNINGPATH.VERSION")} filter sortable />
                  <Column header={t("LEARNINGPATH.COMMENT_COLUMN_NAME")} field="comment" filter sortable />
                  <Column header={t("LEARNINGPATH.DURATION")} field="duration" filter sortable />
                  <Column header={t("LEARNINGPATH.REQUIREMENTS")} field="requirements" filter sortable />
                  <Column header={t("LEARNINGPATH.TARGET_GROUP")} field="targetGroup" filter sortable />
                  <Column header={t("LEARNINGPATH.DESCRIPTION_MODAL")} field="description" filter sortable />
                  <Column header={t("LEARNINGPATH.SIMILARITY_PERCENTAGE")} filterField="similarityPercentage" dataType="date" body={this.similarityPercentageTemplate} />
                  <Column body={buttonFavoriteTraining} rowEditor />
                </DataTable>
              )}
            </div>
          </ModalBody>
          <ModalFooter>
            <Modal isOpen={this.state.favoriteModal} toggle={this.changeFavoriteModalState} style={{ marginTop: "3rem", width: "50%" }}>
              <ModalHeader>{this.state.selectedTraining?.isFavorite ? t("TRAINING.CONFIRM_UNMARK_FROM_FAVORITE") : t("TRAINING.CONFIRM_MARK_AS_FAVORITE")}</ModalHeader>
              <ModalFooter>
                <Button className="mb-2 me-2   " color="secondary" onClick={this.changeFavoriteModalState}>
                  {t("LEARNINGPATH.NO_BUTTON")}
                </Button>
                <Button className="mb-2 me-2  " color="primary" onClick={this.state.selectedTraining?.isFavorite ? this.confirmUnmarkFromFavorite : this.confirmMarkAsFavorite}>
                  {t("LEARNINGPATH.YES_BUTTON")}
                </Button>
              </ModalFooter>
            </Modal>

            <Button className="mb-2 me-2" color="secondary" onClick={() => changeModalState(false)}>
              {t("LEARNINGPATH.CLOSE_BUTTON")}
            </Button>
          </ModalFooter>
        </Modal>
      </Fragment>
    );
  }
}
export default withTranslation()(AdviceTrainingModel);
