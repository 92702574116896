import React, { Fragment, Component } from "react";
import { withTranslation } from "react-i18next";
import LearningObjectModule from "./LearningObjectModule";
import LinkModule from "./LinkModule";
import PlaceholderModule from "./PlaceholderModule";
import TrainingModule from "./TrainingModule";
import LearningPathItemService from "../../../../service/LearningPathItemService";
import LearningPathService from "../../../../service/LearningPathService";

class Module extends Component {
  constructor(props) {
    super(props);
    this.state = {
      learningPathItem: null,
      training: null,
    };
  }

  componentDidMount() {
    var trainingId = this.props.match.url.replace("/training/trainings/", "").split("/")[0];
    this.getTraining(trainingId).then(() => {
      if (this.props.location.state) {
        this.setState({ learningPathItem: this.props.location.state.learningPathItem });
      } else {
        this.getLearningPathItemById(this.props.match.params.learingPathItemId);
      }
    });
  }

  componentDidUpdate() {
    if (this.props.match.params.learingPathItemId !== this.state.learningPathItem?.id && this.props.location.state) {
      this.setState({ learningPathItem: this.props.location.state.learningPathItem });
    }
  }

  getTraining = (learingPathId) => {
    return LearningPathService.getLearningPathById(learingPathId).then((response) => {
      this.setState({ training: response.data });
    });
  };

  getLearningPathItemById(learningPathId) {
    return LearningPathItemService.getById(learningPathId)
      .then((response) => {
        this.bindLearningPathItem(response.data);
      })
      .catch((e) => {
        console.log(e);
      });
  }

  bindLearningPathItem(learningPathItem) {
    var bindLearningPathItem = {
      id: learningPathItem.id,
      createdBy: learningPathItem.createdBy,
      createdByUser: learningPathItem.createdByUser,
      completedDate: learningPathItem.completedDate,
      learningObjectId: learningPathItem.learningObjectId,
      learningObjectNames: learningPathItem.learningObjectNames,
      learningPathId: learningPathItem.learningPathId,
      link: learningPathItem.link,
      placeholder: learningPathItem.placeholder,
      trainingId: learningPathItem.trainingId,
      trainingName: learningPathItem.trainingName,
      position: learningPathItem.position,
      attributeIds: learningPathItem.attributeIds,
      allowComplete: learningPathItem.learningObjectId || learningPathItem.placeholder,
      comment: learningPathItem.comment,
      materialIds: learningPathItem.materialIds,
      displayName: learningPathItem.learningObjectId
        ? learningPathItem.learningObjectNames.find((obj) => obj.language === this.training.language)?.name
        : learningPathItem.trainingId
        ? learningPathItem.trainingName
        : learningPathItem.placeholder
        ? learningPathItem.placeholder
        : learningPathItem.link,
      tab: learningPathItem.learningObjectId ? "learningObjectTab" : learningPathItem.trainingId ? "trainingTab" : learningPathItem.placeholder ? "placeholderTab" : "linkTab",
    };
    this.setState({
      learningPathItem: bindLearningPathItem,
    });
  }

  render() {
    const { learningPathItem } = this.state;
    return (
      <Fragment>
        {learningPathItem?.tab === "linkTab" && <LinkModule learningPathItem={learningPathItem} />}
        {learningPathItem?.tab === "trainingTab" && <TrainingModule learningPathItem={learningPathItem} />}
        {learningPathItem?.tab === "placeholderTab" && <PlaceholderModule learningPathItem={learningPathItem} />}
        {learningPathItem?.tab === "learningObjectTab" && this.state.training && (
          <LearningObjectModule language={this.state.training.language} learningPathItem={learningPathItem} />
        )}
      </Fragment>
    );
  }
}
export default withTranslation()(Module);
