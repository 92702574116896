import React, { Component, Fragment } from "react";
import { Link, withRouter } from "react-router-dom";
import "metismenujs/dist/metismenujs.css";
import "../AppSidebar/style.css";

class CustomMenu extends Component {
  constructor(props) {
    super(props);
    this.state = {
      originalNavItems: props.navItems,
      navItems: this.initNavItems(props.navItems),
    };
  }

  componentDidUpdate() {
    if (JSON.stringify(this.state.originalNavItems) !== JSON.stringify(this.props.navItems)) {
      let navItems = this.initNavItems(this.props.navItems);
      this.setState({
        originalNavItems: this.props.navItems,
        navItems: navItems,
      });
    }
  }

  isPathActive = (path) => {
    if (this.props.location.pathname.includes("competence-frame")) {
      return path === "/metadata/competence-frame";
    }
    return this.props.location.pathname.startsWith(path);
  };

  initNavItems(navItems) {
    return navItems.map((navItem) => {
      navItem.visible = this.isSubMenuActive(navItem);
      return navItem;
    });
  }

  isSubMenuActive = (navItem) => {
    let result = false;
    if (navItem.subItems && navItem.subItems.length > 0) {
      navItem.subItems.forEach((subItem) => {
        if (this.isPathActive(subItem.to)) {
          result = true;
        }
      });
    }
    return result;
  };

  toggleSubMenu(index) {
    if (this.state.navItems[index].subItems?.length > 0) {
      var navItems = this.state.navItems;
      navItems[index].visible = navItems[index].visible ? false : true;
      this.setState({
        navItems: navItems,
      });
    }
  }

  render() {
    let { title } = this.props;
    let { navItems } = this.state;

    const items = navItems
      .filter((i) => i.isActive)
      .map((navItem, index) => (
        <li key={index} className="metismenu-item">
          <Link
            className={"metismenu-link " + (this.isPathActive(navItem.to) ? "active" : navItem?.visible ? "has-active-child" : "")}
            to={navItem.subItems && navItem.subItems.length > 0 ? "#" : navItem.to}
            onClick={() => this.toggleSubMenu(index)}
          >
            <i className={"metismenu-icon " + navItem.icon}></i>
            {navItem.label}
            {navItem.subItems && navItem.subItems.length > 0 && (
              <i className={"metismenu-state-icon pe-7s-angle-down caret-left " + (navItem?.visible ? " rotate-minus-90" : "")}></i>
            )}
          </Link>
          {navItem.subItems && navItem.subItems.length > 0 && (
            <ul className={"metismenu-container " + (navItem?.visible ? "visible" : "")}>
              {navItem.subItems?.map((subItem, i) => (
                <li key={i} className="metismenu-item">
                  <Link className={"metismenu-link " + (this.isPathActive(subItem.to) ? "active" : "")} to={subItem.to}>
                    <i className="metismenu-icon"></i>
                    {subItem.label}
                  </Link>
                </li>
              ))}
            </ul>
          )}
        </li>
      ));
    return (
      <Fragment>
        <h5 className="app-sidebar__heading">{title}</h5>
        <div className="metismenu vertical-nav-menu">
          <ul className="metismenu-container">{items}</ul>
        </div>
      </Fragment>
    );
  }
}
export default withRouter(CustomMenu);
