import React, { Fragment } from "react";
import PerfectScrollbar from "react-perfect-scrollbar";
import { DropdownToggle, DropdownMenu, Nav, Button, NavItem, NavLink, UncontrolledButtonDropdown } from "reactstrap";

import { toast, Bounce } from "react-toastify";

import { faAngleDown } from "@fortawesome/free-solid-svg-icons";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import "react-toastify/dist/ReactToastify.css";

import city3 from "../../../assets/utils/images/dropdown-header/city3.jpg";
import AuthService from "../../../service/authService";
import { Link } from "react-router-dom";
import tokenService from "../../../service/tokenService";

class UserBox extends React.Component {
  constructor(props) {
    super(props);
    let user = tokenService.getUser();
    this.state = {
      active: false,
      user: user,
      currentCompany: user?.companies.find((c) => c.id === user?.currentCompanyId)?.name,
      avatarImage: user && user.photo ? "data:image/png;base64," + user.photo : "",
    };
  }

  componentDidMount() {
    if (!this.state.user) {
      this.getMyInfo();
    }
  }

  getMyInfo() {
    AuthService.getMyInfo()
      .then((response) => {
        this.setState({
          user: response.data,
          currentRole: response.data?.currentRole,
          currentCompany: response.data?.companies.find((c) => c.id === response.data?.currentCompanyId)?.name,
          avatarImage: "data:image/png;base64," + response.data.photo,
        });
        tokenService.setUser(response.data);
      })
      .catch((e) => {
        console.log(e);
      });
  }

  renderAvatar() {
    if (this.state.user === null) return null;
    return this.state.avatarImage !== "" ? (
      <img width={42} className="rounded-circle" src={this.state.avatarImage} alt="" />
    ) : (
      <span className="rounded-circle">
        {this.state.user?.firstName[0]?.toUpperCase()}
        {this.state.user?.lastName[0]?.toUpperCase()}
      </span>
    );
  }

  render() {
    return (
      <Fragment>
        <div className="header-btn-lg pe-0">
          <div className="widget-content p-0">
            <div className="widget-content-wrapper">
              <div className="widget-content-left">
                <UncontrolledButtonDropdown>
                  <DropdownToggle color="link" className="p-0">
                    {this.renderAvatar()}
                    <FontAwesomeIcon className="ms-2 opacity-8" icon={faAngleDown} />
                  </DropdownToggle>
                  <DropdownMenu end className="rm-pointers dropdown-menu-lg">
                    <div className="dropdown-menu-header">
                      <div className="dropdown-menu-header-inner bg-info">
                        <div
                          className="menu-header-image opacity-2"
                          style={{
                            backgroundImage: "url(" + city3 + ")",
                          }}
                        />
                        <div className="menu-header-content text-start">
                          <div className="widget-content p-0">
                            <div className="widget-content-wrapper">
                              <div className="widget-content-left me-3 ">{this.renderAvatar()}</div>
                              <div className="widget-content-left">
                                <div className="widget-heading">
                                  {this.state.user?.firstName} {this.state.user?.lastName}
                                </div>
                                <div className="widget-subheading opacity-8" style={{ fontSize: "14px" }}>
                                  {this.state?.currentCompany} ({this.state.user?.currentRole})
                                </div>
                              </div>
                              <div className="widget-content-right me-2">
                                <Link to="/auth/login">
                                  <Button className="btn-pill btn-shadow btn-shine" color="focus" onClick={() => tokenService.remove()}>
                                    Logout
                                  </Button>
                                </Link>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      className="scroll-area-xs"
                      style={{
                        height: "150px",
                      }}
                    >
                      <PerfectScrollbar>
                        <Nav vertical>
                          <NavItem className="nav-item-header">Activity</NavItem>

                          <Link to="/auth/forgot-password">
                            <NavLink>Forgot Password</NavLink>
                          </Link>

                          <NavItem className="nav-item-header">My Account</NavItem>
                          <NavItem>
                            <Link to="/organization/profile">
                              <NavLink>Open Profile</NavLink>
                            </Link>
                          </NavItem>
                        </Nav>
                      </PerfectScrollbar>
                    </div>
                  </DropdownMenu>
                </UncontrolledButtonDropdown>
              </div>
              <div className="widget-content-left  ms-3 header-user-info">
                <div className="widget-heading">
                  {this.state.user?.firstName} {this.state.user?.lastName}
                </div>
                <div className="widget-subheading" style={{ fontSize: "14px" }}>
                  {this.state?.currentCompany} ({this.state.user?.currentRole})
                </div>
              </div>
              <div className="widget-content-right header-user-info ms-3"></div>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}

export default UserBox;
