import React, { Fragment, Component } from "react";
import { withTranslation } from "react-i18next";
import commingSoon from "../../../../assets/images/coming-soon-icon.svg";

class PlaceholderModule extends Component {
  render() {
    const { learningPathItem } = this.props;
    return (
      <Fragment>
        <div className="form-wizard-content">
          <div className="no-results">
            <div className="mt-3 mb-3" />
            <div className="results-title">{learningPathItem?.displayName}</div>
          </div>
          <img
            alt={learningPathItem?.displayName}
            src={commingSoon}
            style={{
              width: "25%",
              display: "block",
              margin: "auto",
              paddingTop: "4rem",
            }}
          />
        </div>
      </Fragment>
    );
  }
}
export default withTranslation()(PlaceholderModule);
