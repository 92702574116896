import React, { Component, Fragment } from "react";
import { withTranslation } from "react-i18next";
import "metismenujs/dist/metismenujs.css";
import "../AppSidebar/style.css";
import CustomMenu from "./CustomMenu";
import { OrganizationNav, MetadataNav, LearningNav, TrainingNav } from "./NavItems";
import tokenService from "../../service/tokenService";
import { eventMediator } from "../../service/eventMediator.js";

class VerticalNavWrapper extends Component {
  constructor(props) {
    super(props);
    this.state = {
      language: props.i18n.language,
      user: tokenService.getUser(),
      trainingNav: [],
      learningNav: [],
      metadataNav: [],
      organizationNav: [],
    };
  }

  componentDidMount() {
    eventMediator.on("updateMenu", () => {
      this.updateState();
    });

    this.setState({
      trainingNav: this.getTrainingNav(),
      learningNav: LearningNav(this.state.user, this.props.t),
      metadataNav: MetadataNav(this.state.user, this.props.t),
      organizationNav: OrganizationNav(this.state.user, this.props.t),
    });
  }

  componentDidUpdate() {
    let user = tokenService.getUser();
    if (JSON.stringify(this.state.user) !== JSON.stringify(user) || this.state.language !== this.props.i18n.language) {
      this.updateState();
    }
  }

  updateState = () => {
    let user = tokenService.getUser();
    let trainingNav = this.getTrainingNav();
    this.setState({
      language: this.props.i18n.language,
      user: user,
      trainingNav: trainingNav,
      learningNav: LearningNav(this.state.user, this.props.t),
      metadataNav: MetadataNav(this.state.user, this.props.t),
      organizationNav: OrganizationNav(this.state.user, this.props.t),
    });
  };

  componentWillUnmount() {
    eventMediator.remove("updateMenu");
  }

  getTrainingNav = () => {
    let trainingNav = TrainingNav(this.state.user, this.props.t);
    let trainings = tokenService.getTrainings();
    if (!trainings) return [];
    if (trainings.length === 0) {
      return [trainingNav[0]];
    }

    let train = trainingNav[1];
    train.subItems = trainings.map(({ id, name, version }) => ({
      label: name + (version ? " (" + version + ")" : ""),
      to: "/training/trainings/" + id,
    }));

    return trainingNav;
  };

  render() {
    const { t } = this.props;
    const { trainingNav, metadataNav, organizationNav, learningNav } = this.state;
    return (
      <Fragment>
        {organizationNav.filter((i) => i.isActive).length > 0 && <CustomMenu title={t("NAV.ORGANIZATION")} navItems={organizationNav} />}
        {metadataNav.filter((i) => i.isActive).length > 0 && <CustomMenu title={t("NAV.METADATA")} navItems={metadataNav} />}
        {learningNav.filter((i) => i.isActive).length > 0 && <CustomMenu title={t("NAV.LEARNING_PATH_LEARNING_OBJECT")} navItems={learningNav} />}
        {trainingNav.filter((i) => i.isActive).length > 0 && <CustomMenu title={t("NAV.TRAINING")} navItems={trainingNav} />}
      </Fragment>
    );
  }
}
export default withTranslation()(VerticalNavWrapper);
