import React, { Component } from "react";
import { Document, Page, pdfjs } from "react-pdf";
import { withTranslation } from "react-i18next";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import "react-pdf/dist/esm/Page/TextLayer.css";
import { Button } from "reactstrap";
import PerfectScrollbar from "react-perfect-scrollbar";
import "./styles.css";

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;

class PfdPreview extends Component {
  constructor(props) {
    super(props);
    this.state = {
      numPages: null,
      pageNumber: 1,
    };
  }

  componentDidMount() {
    this.getBase64(this.props.file);
  }

  getBase64 = (fileObject) => {
    let reader = new FileReader();
    reader.readAsDataURL(fileObject);
    reader.onload = () => {
      var base64 = reader.result.substring(reader.result.indexOf(",") + 1);
      this.setState({ base64: base64 });
    };
  };

  onDocumentLoadSuccess = ({ numPages }) => {
    this.setState({ numPages });
  };

  goToPrevPage = () => {
    let pageNumber = this.state.pageNumber - 1 <= 1 ? 1 : this.state.pageNumber - 1;
    this.setState({ pageNumber: pageNumber });
  };

  goToNextPage = () => {
    let pageNumber = this.state.pageNumber + 1 >= this.state.numPages ? this.state.numPages : this.state.pageNumber + 1;
    this.setState({ pageNumber: pageNumber });
  };
  render() {
    const { t, file, style } = this.props;
    const { pageNumber, numPages } = this.state;

    return (
      <div
        className="page"
        style={{
          textAlign: "center",
        }}
      >
        <nav>
          <Button className="mb-2 me-2" disabled={pageNumber === 1} color="primary" onClick={this.goToPrevPage}>
            {t("MATERIAL.PREV")}
          </Button>
          <Button className="mb-2 me-2" disabled={pageNumber === numPages} color="primary" onClick={this.goToNextPage}>
            {t("MATERIAL.NEXT")}
          </Button>
        </nav>
        <p>
          {t("MATERIAL.PAGE")} {pageNumber} {t("MATERIAL.OF")} {numPages}
        </p>
        <div style={style}>
          <PerfectScrollbar>
            <div className="center">
              <Document file={file} onLoadSuccess={this.onDocumentLoadSuccess}>
                <Page pageNumber={pageNumber} />
              </Document>
            </div>
          </PerfectScrollbar>
        </div>
      </div>
    );
  }
}
export default withTranslation()(PfdPreview);
