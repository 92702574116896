import React, { Suspense, useEffect, Fragment } from "react";
import { Route } from "react-router-dom";
import AppHeader from "../../Layout/AppHeader";
import AppSidebar from "../../Layout/AppSidebar";
import ThemeOptions from "../../Layout/ThemeOptions";

import Attribute from "./Attribute";
import Category from "./Category";
import Recommendation from "./Recommendation";
import Topic from "./Topic";
import CompetenceFrame from "./CompetenceFrame";
import Competence from "./Competence";

const MetadataPages = ({ match }) => {
  return (
    <Fragment>
      <ThemeOptions />
      <AppHeader />
      <div className="app-main">
        <AppSidebar />
        <div className="app-main__outer">
          <div className="app-main__inner">
            <Route path={`${match.url}/topic`} component={Topic} />
            <Route path={`${match.url}/recommendation`} component={Recommendation} />
            <Route path={`${match.url}/category`} component={Category} />
            <Route path={`${match.url}/attribute`} component={Attribute} />
            <Route path={`${match.url}/competence-frame`} component={CompetenceFrame} />
            <Route path={`${match.url}/competence`} component={Competence} />
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default MetadataPages;
