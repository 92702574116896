import React, { Fragment, Component } from "react";
import { withTranslation } from "react-i18next";
import { Row, Col, Button, ListGroupItem, Card, ListGroup, ButtonGroup } from "reactstrap";
import Tabs, { TabPane } from "rc-tabs";
import TabContent from "rc-tabs/lib/SwipeableTabContent";
import ScrollableInkTabBar from "rc-tabs/lib/ScrollableInkTabBar";
import LearningObjectService from "../../../../service/LearningObjectService";
import LearningObjectMaterialService from "../../../../service/LearningObjectMaterialService";
import ConvertService from "../../../../service/ConvertService";
import MaterialPreview from "../../../Shared/MaterialPreview";
import AudioPreview from "../../../Shared/MaterialPreview/AudioPreview";
import PerfectScrollbar from "react-perfect-scrollbar";

class LearningObjectModule extends Component {
  constructor(props) {
    super(props);
    this.state = {
      language: props.language,
      learningObject: null,
      shortDescription: null,
      longDescription: null,
      materials: [],
      material: null,
      materialPreviewModel: false,
      longAudio: null,
      shortAudio: null,
      loading: false,
    };

    this.handleDownload = this.handleDownload.bind(this);
  }

  componentDidMount() {
    this.getLearningObjectById(this.props.learningPathItem.learningObjectId);
    this.getMaterialsByLearningPathItemId(this.props.learningPathItem.id);
  }

  componentDidUpdate() {
    if (this.props.learningPathItem.learningObjectId !== this.state.learningObject?.id && !this.state.loading) {
      this.getLearningObjectById(this.props.learningPathItem.learningObjectId);
      this.getMaterialsByLearningPathItemId(this.props.learningPathItem.id);
    }
  }

  getLearningObjectById(id) {
    this.setState({ loading: true });
    return LearningObjectService.getById(id)
      .then((response) => {
        if (response.data.id !== this.state.learningObject?.id) {
          this.setState({
            learningObject: response.data,
            loading: false,
            downloadUrl: null,
            longAudio: null,
            shortAudio: null,
            shortDescription: response.data?.translations.find((t) => t.language === this.state.language)?.shortDescription,
            longDescription: response.data?.translations.find((t) => t.language === this.state.language)?.longDescription,
          });
        }
      })
      .catch((e) => {
        console.log(e);
      });
  }

  getMaterialsByLearningPathItemId(id) {
    return LearningObjectMaterialService.getByLearningPathItemId(id)
      .then((response) => {
        this.setState({
          materials: response.data.filter((m) => m.language === this.state.language),
        });
      })
      .catch((e) => {
        console.log(e);
      });
  }

  async handleDownload(material) {
    await LearningObjectMaterialService.download(material.id)
      .then((response) => {
        const file = new Blob([response.data]);
        const url = window.URL.createObjectURL(file);
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", material.fileName);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        window.URL.revokeObjectURL(url);
      })
      .catch((error) => {
        console.error("Error downloading file:", error);
      });
  }

  convertShortDescription = () => {
    ConvertService.textToSpeech(this.state.shortDescription)
      .then((response) => {
        var blob = new Blob([response.data]);
        var file = new File([blob], "output", { type: "audio/mpeg" });
        this.setState({ shortAudio: file });
      })
      .catch((e) => {
        console.log(e);
      });
  };

  convertLongDescription = () => {
    ConvertService.textToSpeech(this.state.longDescription)
      .then((response) => {
        var blob = new Blob([response.data]);
        var file = new File([blob], "output", { type: "audio/mpeg" });
        this.setState({ longAudio: file });
      })
      .catch((e) => {
        console.log(e);
      });
  };

  openMaterial = (rowData) => {
    if(rowData.materialTypeName === "url"){
      var win = window.open(rowData.link, '_blank');
      win.focus();
    }
    else{
      this.setState({
        materialPreviewModel: true,
        material: rowData,
      });
    }
  };

  closeMaterial = () => {
    this.setState({
      materialPreviewModel: false,
    });
  };

  render() {
    const { t, learningPathItem } = this.props;

    const materialList = this.state.materials
      .filter((m) => (m.language = this.state.language))
      .map((material, index) => (
        <ListGroupItem key={index}>
          <div className="widget-content p-0">
            <div className="widget-content-wrapper">
              <div className="widget-content-left">
                <div className="widget-heading">{material.materialTypeName === "url" ? material.link : material.fileName}</div>
              </div>
              <div className="widget-content-right">
                
               {material.materialTypeName !== "url" && <Button outline size="sm" className="me-2" color="primary" onClick={() => this.handleDownload(material)}>
                  {t("TRAINING.DOWNLOAD_BUTTON")}
                </Button>}
                <Button outline size="sm" color="primary" onClick={() => this.openMaterial(material)}>
                  {t("TRAINING.OPEN_BUTTON")}
                </Button>

              </div>
            </div>
          </div>
        </ListGroupItem>
      ));

    return (
      <Fragment>
        {this.state.materialPreviewModel && <MaterialPreview onClose={this.closeMaterial} material={this.state.material} />}
        <div>
          <div className="no-results" style={{ paddingBottom: "0" }}>
            <div className="results-title">{learningPathItem?.displayName}</div>
            <div className="form-wizard-content" style={{ maxHeight: "32rem" }}>
              <PerfectScrollbar>
                <div className="text-center">
                  <Tabs
                    style={{
                      position: "relative",
                      zIndex: "0",
                    }}
                    defaultActiveKey="1"
                    renderTabBar={() => <ScrollableInkTabBar />}
                    renderTabContent={() => <TabContent />}
                  >
                    <TabPane tab={<b className="tab">Short</b>} key="1">
                      <div style={{ all: "initial", fontFamily: "Helvetica, Arial, sans-serif;" }} dangerouslySetInnerHTML={{ __html: this.state?.shortDescription }} />
                      {this.state?.shortDescription && (
                        <Row style={{ paddingTop: "10px", height: "68px" }}>
                          <Col md="3" style={{ display: "flex", alignItems: "center" }}>
                            <Button className="mb-2 me-2" color="primary" style={{ margin: "0 auto" }} onClick={() => this.convertShortDescription()}>
                              {t("TRAINING.AUDIO_BUTTON")}
                            </Button>
                          </Col>
                          <Col>{this.state.shortAudio && <AudioPreview file={this.state.shortAudio} style={{ maxHeight: "30rem", maxWidth: "50rem" }} />}</Col>
                        </Row>
                      )}
                    </TabPane>
                    <TabPane tab={<b className="tab">Long</b>} key="2">
                      <div style={{ all: "initial", fontFamily: "Helvetica, Arial, sans-serif;" }} dangerouslySetInnerHTML={{ __html: this.state?.longDescription }} />
                      {this.state?.longDescription && (
                        <Row style={{ paddingTop: "10px", height: "68px" }}>
                          <Col md="3" style={{ display: "flex", alignItems: "center" }}>
                            <Button className="mb-2 me-2" color="primary" style={{ margin: "0 auto" }} onClick={() => this.convertLongDescription()}>
                              {t("TRAINING.AUDIO_BUTTON")}
                            </Button>
                          </Col>
                          <Col>{this.state.longAudio && <AudioPreview file={this.state.longAudio} style={{ maxHeight: "30rem", maxWidth: "50rem" }} />}</Col>
                        </Row>
                      )}
                    </TabPane>
                  </Tabs>

                  <div className="mt-3 mb-3" />

                  <Card className="main-card mb-3">
                    <ListGroup flush>{materialList}</ListGroup>
                  </Card>
                </div>
              </PerfectScrollbar>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}
export default withTranslation()(LearningObjectModule);
