import React, { Fragment, Component } from "react";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import { withTranslation } from "react-i18next";
import PageTitle from "../../../Layout/AppMain/PageTitle";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import PerfectScrollbar from "react-perfect-scrollbar";
import {
  UncontrolledDropdown,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
  Row,
  Col,
  Card,
  CardBody,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input,
  FormGroup,
  Form,
  Label,
  Button,
  CardTitle,
} from "reactstrap";
import CategoryService from "../../../service/CategoryService";
import { FilterMatchMode } from "primereact/api";
import authService from "../../../service/authService";
import { Calendar } from "primereact/calendar";
import Flag from "react-flagkit";
import Select from "react-select";
import "../Attribute/DataTable.css";
import { classNames } from "primereact/utils";
import { TriStateCheckbox } from "primereact/tristatecheckbox";
import { InputText } from "primereact/inputtext";
import LearningObjectService from "../../../service/LearningObjectService";
import moment from "moment/moment";
import TopicService from "../../../service/TopicService";
import languages from "../../../models/Languages";
import { CSVLink } from "react-csv";
import tokenService from "../../../service/tokenService";

class Category extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loggedUser: tokenService.getUser(),
      categories: [],
      filteredCategories: [],
      selectedCategory: {},
      learningObjects: [],
      topics: [],
      modal: false,
      deleteModal: false,
      activeModal: false,
      csvData: [],

      selectedFilteringTableLanguage: languages.find((obj) => obj.lang === this.props.i18n.language),
      filterCategoryDropdownLanguages: languages,

      creatingCategoryTranslationLanguages: languages,
      selectedCreatingCategoryTranslationLanguage: languages[0],
      selectedEditingCategoryTranslationLanguage: "",

      creatingCategoryTranslationName: "", //need join
      creatingCategoryTranslationComment: "", //

      filterCategoriesTemplate: {
        name: { value: null, matchMode: FilterMatchMode.CONTAINS },
        userName: { value: null, matchMode: FilterMatchMode.CONTAINS },
        comment: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
        createdDate: { value: null, matchMode: FilterMatchMode.DATE_IS },
        createdByUser: { value: null, matchMode: FilterMatchMode.CONTAINS },
        isPublic: { value: tokenService.getCompany().showPublicCategory ? null : false, matchMode: FilterMatchMode.EQUALS },
        isActive: { value: null, matchMode: FilterMatchMode.EQUALS },
      },
      isError: {},

      categoryTranslations: [],

      csvNameLabel: "Name",
      csvCreatedByLabel: "Created By",
      csvHeaders: [
        { label: "Name", key: "name" },
        { label: "Created By", key: "createdByUser" },
      ],
    };
  }

  componentDidMount() {
    this.getAllCategories();
  }

  getCategoryById(id) {
    return CategoryService.getCategoryById(id)
      .then((response) => {
        this.setState({ selectedCategory: response.data });
        this.setState({
          categoryTranslations: response.data.translations,
        });
        this.filterCategoryDropdownLanguages();
      })
      .catch((e) => {
        console.log(e);
      });
  }

  getLearningObjectByCategoryId(id) {
    return LearningObjectService.getByCategoryId(id)
      .then((response) => {
        var filteredLearningObjects = response.data.map(({ translations, createdByUser, isActive, isPublic }) => ({
          name:
            translations.find((obj) => obj.language === this.state.selectedFilteringTableLanguage.lang)?.name + (!isActive ? " (inactive) " : " ") + (isPublic ? " (public)" : ""),
          createdByUser: createdByUser,
        }));

        this.setState({
          learningObjects: filteredLearningObjects.filter((t) => !t.name.startsWith("undefined")),
        });
      })
      .catch((e) => {
        console.log(e);
      });
  }

  getTopicByCategoryId(id) {
    return TopicService.getTopicByCategoryId(id)
      .then((response) => {
        var topics = response.data.map(({ translations, createdByUser, isActive, isPublic }) => ({
          name:
            translations.find((obj) => obj.language === this.state.selectedFilteringTableLanguage.lang)?.name + (!isActive ? " (inactive) " : " ") + (isPublic ? " (public)" : ""),
          createdByUser: createdByUser,
        }));
        this.setState({
          topics: topics.filter((t) => !t.name.startsWith("undefined")),
        });
      })
      .catch((e) => {
        console.log(e);
      });
  }

  confirmDeactivateCategory = () => {
    CategoryService.deactivate(this.state.selectedCategory.id)
      .then((response) => {
        var selected = this.state.selectedCategory;
        selected.isActive = false;
        this.setState({ selectedCategory: selected });
        this.changeActiveModalState();
        this.getAllCategories();
      })
      .catch((e) => {
        console.log(e);
      });
  };

  confirmActivateCategory = () => {
    CategoryService.activate(this.state.selectedCategory.id).then(() => {
      var selected = this.state.selectedCategory;
      selected.isActive = true;
      this.setState({ selectedCategory: selected });
      this.changeActiveModalState();
      this.getAllCategories();
    });
  };

  confirmDeleteCategory = () => {
    CategoryService.delete(this.state.selectedCategory.id)
      .then((response) => {
        this.getAllCategories().then(() => {
          this.changeDeleteModalState();
          this.changeModalState();
        });
      })
      .catch((e) => {
        console.log(e);
      });
  };

  getAllCategories = () => {
    return CategoryService.getAllCategories()
      .then((response) => {
        this.setState({
          categories: response.data,
        });
        this.filterCategories(this.state.selectedFilteringTableLanguage.lang);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  filterCategories(lang) {
    var filteredCategories = this.state.categories.map(({ id, translations, createdDate, isPublic, isActive, createdByUser }) => ({
      language: translations.find((obj) => obj.language === lang)?.language,
      name: translations.find((obj) => obj.language === lang)?.name,
      comment: translations.find((obj) => obj.language === lang)?.comment,
      createdByUser: createdByUser,
      isPublic: isPublic,
      isActive: isActive,
      id: id,
      createdDate: moment(new Date(createdDate)).format("DD.MM.YYYY"),
    }));
    this.setState({
      filteredCategories: filteredCategories.filter((obj) => obj.language),
    });
  }

  updateCategory(e) {
    e.preventDefault();
    let category = this.state.selectedCategory;
    category.isActive = true;
    category.translations = this.state.categoryTranslations;
    CategoryService.update(category)
      .then((response) => {
        this.changeModalState();
        this.setState({
          selectedCategory: {},
        });
        this.getAllCategories();
      })
      .catch((e) => {
        console.log(e);
      });
  }

  createNewCategory(e) {
    e.preventDefault();
    var cat = this.state.selectedCategory;
    cat.translations = this.state.categoryTranslations;
    this.setState({
      selectedCategory: cat,
    });
    this.setState((prevState) => ({
      selectedCategory: {
        ...prevState.selectedCategory,
        isPublic: this.state.selectedCategory.isPublic,
      },
    }));

    CategoryService.insert(this.state.selectedCategory)
      .then((response) => {
        this.changeModalState();
        this.setState({ selectedCategory: {} });
        this.getAllCategories();
      })
      .catch((e) => {
        console.log(e);
      });
  }

  isEditable = () => {
    return !this.state.selectedCategory.isPublic || this.isM2LAdmin();
  };

  isM2LAdmin = () => {
    return this.state.loggedUser?.currentRole === "SystemAdmin" && this.state.loggedUser?.currentCompanyId === null;
  };

  changeModalState = () => {
    this.setState({
      modal: !this.state.modal,
      isError: {},
      creatingCategoryTranslationName: "",
      creatingCategoryTranslationComment: "",
    });
  };

  changeActiveModalState = () => {
    this.setState({
      activeModal: !this.state.activeModal,
    });
  };

  changeDeleteModalState = () => {
    this.setState({
      deleteModal: !this.state.deleteModal,
    });
  };

  changePublic = (e) => {
    this.setState((prevState) => ({
      selectedCategory: {
        ...prevState.selectedCategory,
        isPublic: e.target.checked,
      },
    }));
  };

  onChangeCreatingCategoryTranslationLanguage = (e) => {
    this.setState({ selectedCreatingCategoryTranslationLanguage: e });
  };

  onChangeEditingCategoryTranslationLanguage = (e) => {
    this.setState({ selectedEditingCategoryTranslationLanguage: e });
  };

  onChangeFilteringTableLanguage = (e) => {
    this.setState({ selectedFilteringTableLanguage: e });
    this.filterCategories(e.lang);
  };

  changeCreatingCategoryTranslationComment = (e) => {
    this.setState({ creatingCategoryTranslationComment: e.target.value });
    this.createNewTranslationValueChange(e);
  };

  changeCreatingCategoryTranslationName = (e) => {
    this.setState({ creatingCategoryTranslationName: e.target.value });
    this.createNewTranslationValueChange(e);
  };

  filterCategoryDropdownLanguages = (e) => {
    let filteredCategoryLanguages = this.state.categoryTranslations.map((obj) => {
      return obj.language;
    });
    let creatingCategoryTranslationLanguages = languages.filter((obj) => !filteredCategoryLanguages.includes(obj.lang));

    this.setState({
      creatingCategoryTranslationLanguages: creatingCategoryTranslationLanguages,
      selectedCreatingCategoryTranslationLanguage: creatingCategoryTranslationLanguages[0],
    });
  };

  createNewCategoryTranslation = (e) => {
    e.preventDefault();
    var isError = { ...this.state.isError };
    isError.name = this.state.creatingCategoryTranslationName.length < 1 ? "Required" : "";
    this.setState({ isError });
    if (this.createNewTranslationFormValid({ isError: isError })) {
      var newCategoryTranslation = {
        language: this.state.selectedCreatingCategoryTranslationLanguage.lang,
        name: this.state.creatingCategoryTranslationName,
        comment: this.state.creatingCategoryTranslationComment,
      };
      this.state.categoryTranslations.push(newCategoryTranslation);
      this.setState({
        categoryTranslations: this.state.categoryTranslations,
      });
      this.filterCategoryDropdownLanguages();

      this.setState({ creatingCategoryTranslationComment: "" });
      this.setState({ creatingCategoryTranslationName: "" });
    }
  };

  dateFilterTemplate = (options) => {
    return (
      <Calendar value={new Date(options.value).toLocaleString()} onChange={(e) => options.filterCallback(e.value, options.index)} dateFormat="mm/dd/yy" placeholder="mm/dd/yyyy" />
    );
  };
  dateCategoriesTemplate = (rowData) => {
    return rowData.createdDate;
  };
  createNewTranslationFormValid = ({ isError, ...rest }) => {
    let isValid = true;
    Object.values(isError).forEach((val) => {
      if (isValid === false) {
        return;
      }
      if (val.length > 0) {
        isValid = false;
      } else {
        isValid = true;
      }
    });
    Object.values(rest).forEach((val) => {
      if (isValid === false) {
        return;
      }
      if (val === null) {
        isValid = false;
      } else {
        isValid = true;
      }
    });
    return isValid;
  };
  createNewTranslationValueChange = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    let isError = { ...this.state.isError };
    switch (name) {
      case "name":
        isError.name = value.length < 1 ? "Required" : "";
        break;
      default:
        break;
    }
    this.setState({
      isError,
      [name]: value,
    });
  };

  openDeactivateModel = () => {
    this.getLearningObjectByCategoryId(this.state.selectedCategory.id).then(() => {
      this.getTopicByCategoryId(this.state.selectedCategory.id).then(() => {
        this.initCSVData();
        this.changeActiveModalState();
      });
    });
  };

  openDeleteModel = () => {
    this.getLearningObjectByCategoryId(this.state.selectedCategory.id).then(() => {
      this.getTopicByCategoryId(this.state.selectedCategory.id).then(() => {
        this.initCSVData();
        this.changeDeleteModalState();
      });
    });
  };

  initCSVData = () => {
    let csvData = [];
    let csvHeaders = this.state.csvHeaders;
    if (this.state.topics.length > 0 && this.state.learningObjects.length > 0) {
      csvHeaders[0].label = "Topic";
      csvHeaders[1].label = "";
      csvData.push({ name: this.state.csvNameLabel, createdByUser: this.state.csvCreatedByLabel });
      csvData = csvData.concat(this.state.topics);
      csvData.push({ name: "", createdByUser: "" });
      csvData.push({ name: "Learning Object", createdByUser: "" });
      csvData.push({ name: this.state.csvNameLabel, createdByUser: this.state.csvCreatedByLabel });
      csvData = csvData.concat(this.state.learningObjects);
    } else if (this.state.topics.length > 0) {
      csvHeaders[0].label = "Topic";
      csvHeaders[1].label = "";
      csvData.push({ name: this.state.csvNameLabel, createdByUser: this.state.csvCreatedByLabel });
      csvData = csvData.concat(this.state.topics);
    } else {
      csvHeaders[0].label = "Learning Object";
      csvHeaders[1].label = "";
      csvData.push({ name: this.state.csvNameLabel, createdByUser: this.state.csvCreatedByLabel });
      csvData = csvData.concat(this.state.learningObjects);
    }
    this.setState({ csvData: csvData });
  };

  render() {
    const { t } = this.props;

    const renderActiveDeactivateButton = () => {
      if (this.state.selectedCategory.id) {
        if (this.state.selectedCategory.isActive) {
          return (
            <Button className="mb-2 me-2 " outline color="danger" onClick={this.openDeactivateModel}>
              {t("CATEGORY.DEACTIVATE_BUTTON")}
            </Button>
          );
        } else {
          return (
            <Button className="mb-2 me-2 " outline color="success" onClick={this.changeActiveModalState}>
              {t("CATEGORY.ACTIVATE_BUTTON")}
            </Button>
          );
        }
      }
    };

    const countryBodyTemplate = (rowData) => {
      return (
        <div className="country-item" style={{ display: "flex" }}>
          <div>{languages.find((obj) => obj.lang === rowData.language)?.name}</div>
        </div>
      );
    };

    const languageEditorTemplate = (options) => {
      return (
        <Select
          options={this.state.creatingCategoryTranslationLanguages}
          onChange={this.onChangeEditingCategoryTranslationLanguage}
          getOptionLabel={(option) => option.name}
          getOptionValue={(option) => option.name}
          key={languages.find((obj) => obj.lang === options.rowData?.language)}
          defaultValue={languages.find((obj) => obj.lang === options.rowData?.language)}
        />
      );
    };

    const openUpdateCategoryTranslationModal = (id) => {
      this.getCategoryById(id);
      this.changeModalState();
    };

    const openCreateCategory = () => {
      var category = {};
      category.createdByUser = this.state.loggedUser.firstName + " " + this.state.loggedUser.lastName;
      category.isPublic = this.isM2LAdmin();
      category.isActive = true;
      category.createdDate = new Date();
      this.setState(
        {
          selectedCategory: category,
          categoryTranslations: [],
          creatingCategoryTranslationLanguages: languages,
          selectedCreatingCategoryTranslationLanguage: this.state.selectedFilteringTableLanguage,
        },
        () => {
          this.changeModalState();
        }
      );
    };

    const deleteCategoryTranslation = (language) => {
      this.state.creatingCategoryTranslationLanguages.push(languages.find((obj) => obj.lang === language));
      this.setState({
        categoryTranslations: this.state.categoryTranslations.filter((obj) => obj.language !== language),
        filterCategoryDropdownLanguages: this.state.creatingCategoryTranslationLanguages,
        selectedCreatingCategoryTranslationLanguage: this.state.creatingCategoryTranslationLanguages[0],
      });
    };

    const buttonDeleteCategoryTranslation = (rowData) => {
      if (this.isEditable()) {
        return (
          <Button className=" " outline color="primary" onClick={() => deleteCategoryTranslation(rowData.language)}>
            {t("CATEGORY.DELETE_BUTTON")}
          </Button>
        );
      }
    };

    const buttonUpdateCategoryTranslation = (rowData) => {
      return (
        <Button className="me-2" size="sm" outline color="primary" onClick={() => openUpdateCategoryTranslationModal(rowData.id)}>
          {t("CATEGORY.OPEN_BUTTON")}
        </Button>
      );
    };

    const formatIsPublicCheckBox = (rowData) => {
      return (
        <i
          className={classNames("pi", {
            "true-icon pi-check-circle": rowData.isPublic,
            "false-icon pi-times-circle": !rowData.isPublic,
          })}
        ></i>
      );
    };

    const formatIsActiveCheckBox = (rowData) => {
      return (
        <i
          className={classNames("pi", {
            "true-icon pi-check-circle": rowData.isActive,
            "false-icon pi-times-circle": !rowData.isActive,
          })}
        ></i>
      );
    };

    const languageTableFilter = (options) => {
      return (
        <div>
          <UncontrolledDropdown>
            <DropdownToggle className="p-0 me-2" color="black">
              <div className="icon-wrapper-bg bg-focus" />
              <div className="language-icon">
                <Flag className="me-3 opacity-8" country={this.state.selectedFilteringTableLanguage?.flag} size="30" />
              </div>
            </DropdownToggle>
            <DropdownMenu end className="rm-pointers">
              {languages.map((language, id) => (
                <DropdownItem key={id} value={language} onClick={() => this.onChangeFilteringTableLanguage(language)}>
                  <Flag className="me-3 opacity-8" country={language.flag} />
                  {language.name}
                </DropdownItem>
              ))}
            </DropdownMenu>
          </UncontrolledDropdown>
        </div>
      );
    };

    const languageBody = (option) => {
      return (
        <Flag
          className="me-3 opacity-8"
          style={{
            marginLeft: "0.75rem",
          }}
          country={languages.find((obj) => obj.lang === option.language)?.flag}
          size="30"
        />
      );
    };

    const verifiedRowFilterTemplate = (options) => {
      return <TriStateCheckbox value={options.value} onChange={(e) => options.filterApplyCallback(e.value)} />;
    };

    const textEditor = (options) => {
      return <InputText type="text" value={options.value} onChange={(e) => options.editorCallback(e.target.value)} />;
    };

    const onRowEditComplete = (e) => {
      let updateCategories = this.state.categoryTranslations;
      let { newData, index } = e;
      newData.language = this.state.selectedEditingCategoryTranslationLanguage.lang ? this.state.selectedEditingCategoryTranslationLanguage.lang : e.data.language;
      updateCategories[index] = newData;
      this.setState({
        categoryTranslations: updateCategories,
      });
      this.filterCategoryDropdownLanguages();
    };

    const { isError } = this.state;
    return (
      <Fragment>
        <TransitionGroup>
          <CSSTransition component="div" classNames="TabsAnimation" appear={true} timeout={1500} enter={false} exit={false}>
            <div>
              <PageTitle heading={t("CATEGORY.TITLE")} subheading={t("CATEGORY.DESCRIPTION")} icon="pe-7s-medal icon-gradient bg-tempting-azure" />
              <Row>
                <Col md="12">
                  <Button className="mb-2 me-2  " outline color="primary" onClick={() => openCreateCategory()}>
                    {t("CATEGORY.CREATE_BUTTON")}
                  </Button>
                  <Modal isOpen={this.state.modal} fade={false} toggle={this.changeModalState}>
                    <ModalHeader toggle={this.changeModalState}> {this.state.selectedCategory?.id ? t("CATEGORY.EDIT_MODAL_TITLE") : t("CATEGORY.CREATE_MODAL_TITLE")}</ModalHeader>
                    <ModalBody>
                      <div className="card p-fluid" style={{ fontWeight: "bold", textAlign: "center", padding: "10px" }}>
                        <Row>
                          <Col md="4">
                            <Label for="createdByUser">
                              {t("CATEGORY.CREATED_BY")} {this.state.selectedCategory?.createdByUser}
                            </Label>
                          </Col>
                          <Col md="4">
                            <Label for="createdDate">
                              {t("CATEGORY.CREATED_DATE")} {moment(new Date(this.state.selectedCategory?.createdDate)).format("DD.MM.YYYY")}
                            </Label>
                          </Col>
                          <Col md="4">
                            {this.isM2LAdmin() && (
                              <FormGroup check>
                                <Label check>
                                  <Input
                                    type="checkbox"
                                    checked={this.state.selectedCategory?.isPublic}
                                    disabled={!this.state.selectedCategory.isActive || this.state.loggedUser.currentRole !== "SystemAdmin"}
                                    onChange={this.changePublic}
                                  />
                                  {t("CATEGORY.IS_PUBLIC")}
                                </Label>
                              </FormGroup>
                            )}
                          </Col>
                        </Row>
                      </div>
                      {this.isEditable() && (
                        <div className="card p-fluid">
                          <Form onSubmit={this.createNewCategoryTranslation} style={{ margin: "10px" }} noValidate>
                            <Row>
                              <Col>
                                <FormGroup>
                                  <Label for="exampleSelect">{t("CATEGORY.LANGUAGE")}</Label>
                                  <Select
                                    options={this.state.creatingCategoryTranslationLanguages}
                                    value={this.state.selectedCreatingCategoryTranslationLanguage ? this.state.selectedCreatingCategoryTranslationLanguage : " "}
                                    onChange={this.onChangeCreatingCategoryTranslationLanguage}
                                    getOptionLabel={(option) => option.name}
                                    getOptionValue={(option) => option.lang}
                                  />
                                </FormGroup>
                              </Col>
                              <Col>
                                <FormGroup>
                                  <Label for="name"> {t("CATEGORY.NAME")}</Label>
                                  <Input
                                    type="text"
                                    name="name"
                                    id="name"
                                    onChange={this.changeCreatingCategoryTranslationName}
                                    value={this.state.creatingCategoryTranslationName}
                                    className={isError.name?.length > 0 ? "is-invalid form-control" : "form-control"}
                                  />
                                  {isError.name?.length > 0 && <span className="invalid-feedback">{isError?.name}</span>}
                                </FormGroup>
                              </Col>
                              <Col>
                                <FormGroup>
                                  <Label for="comment">{t("CATEGORY.COMMENT")}</Label>
                                  <Input
                                    type="text"
                                    name="comment"
                                    id="comment"
                                    onChange={this.changeCreatingCategoryTranslationComment}
                                    value={this.state.creatingCategoryTranslationComment}
                                    className={"form-control"}
                                  />
                                </FormGroup>
                              </Col>

                              <Col>
                                <Button className="mb-2 me-2  " outline color="primary" style={{ marginTop: "30px" }} type="submit">
                                  {t("CATEGORY.CREATE_BUTTON")}
                                </Button>
                              </Col>
                            </Row>
                          </Form>
                        </div>
                      )}

                      <div className="card p-fluid">
                        <DataTable
                          editMode="row"
                          value={this.state.categoryTranslations}
                          dataKey="id"
                          className="p-datatable-customers"
                          onRowEditComplete={onRowEditComplete}
                          // responsiveLayout="scroll"
                        >
                          <Column
                            field="language"
                            header={t("CATEGORY.LANGUAGE_COLUMN_NAME")}
                            body={countryBodyTemplate}
                            editor={(options) => languageEditorTemplate(options)}
                            style={{ zIndex: "2" }}
                          ></Column>
                          <Column field="name" header={t("CATEGORY.NAME_COLUMN_NAME")} editor={(options) => textEditor(options)}></Column>
                          <Column field="comment" header={t("CATEGORY.COMMENT_COLUMN_NAME")} editor={(options) => textEditor(options)}></Column>
                          <Column rowEditor={this.isEditable()} headerStyle={{ width: "10%", minWidth: "8rem" }} bodyStyle={{ textAlign: "center" }}></Column>
                          <Column body={buttonDeleteCategoryTranslation} />
                        </DataTable>
                      </div>
                    </ModalBody>
                    <ModalFooter>
                      <Modal isOpen={this.state.activeModal} toggle={this.changeActiveModalState} style={{ marginTop: "50px", width: "60%" }}>
                        <ModalHeader>
                          {this.state.selectedCategory.isActive ? t("CATEGORY.CONFIRM_DEACTIVATE_MODAL_TITLE") : t("CATEGORY.CONFIRM_ACTIVATE_MODAL_TITLE")}
                        </ModalHeader>
                        <ModalBody style={{ minHeight: "auto" }}>
                          {this.state.selectedCategory.isActive && (this.state.topics.length > 0 || this.state.learningObjects.length > 0) ? (
                            <div>
                              <PerfectScrollbar>
                                {this.state.topics.length > 0 ? (
                                  <Card className="main-card mb-3">
                                    <CardTitle>{t("CATEGORY.TOPICS")}</CardTitle>
                                    <DataTable value={this.state.topics} paginator rows={this.state.learningObjects.length > 0 ? 5 : 10}>
                                      <Column field="name" header={t("CATEGORY.TOPIC_NAME_COLUMN_NAME")}></Column>
                                      <Column field="createdByUser" header={t("CATEGORY.TOPIC_CREATED_BY_COLUMN_NAME")}></Column>
                                    </DataTable>
                                  </Card>
                                ) : (
                                  ""
                                )}

                                {this.state.learningObjects.length > 0 ? (
                                  <Card className="main-card mb-3">
                                    <CardTitle>{t("CATEGORY.LEARNING_OBJECTS")}</CardTitle>
                                    <DataTable value={this.state.learningObjects} paginator rows={this.state.topics.length > 0 ? 5 : 10}>
                                      <Column field="name" header={t("CATEGORY.LEARNING_OBJECT_NAME_COLUMN_NAME")}></Column>
                                      <Column field="createdByUser" header={t("CATEGORY.LEARNING_OBJECT_CREATED_BY_COLUMN_NAME")}></Column>
                                    </DataTable>
                                  </Card>
                                ) : (
                                  ""
                                )}
                              </PerfectScrollbar>
                            </div>
                          ) : (
                            ""
                          )}
                        </ModalBody>
                        <ModalFooter>
                          <Col>
                            {this.state.selectedCategory.isActive && (this.state.topics.length > 0 || this.state.learningObjects.length > 0) ? (
                              <CSVLink
                                data={this.state.csvData}
                                filename={
                                  "deactivate_category_" + this.state.categoryTranslations.find((a) => a.language === this.state.selectedFilteringTableLanguage.lang)?.name + ".csv"
                                }
                                headers={this.state.csvHeaders}
                                className="mb-2 me-2 btn btn-success"
                                target="_blank"
                              >
                                {t("CATEGORY.EXPORT_BUTTON")}
                              </CSVLink>
                            ) : (
                              ""
                            )}
                          </Col>
                          <Button className="mb-2 me-2 " color="secondary" onClick={this.changeActiveModalState}>
                            {t("CATEGORY.NO_BUTTON")}
                          </Button>
                          <Button
                            className="mb-2 me-2 "
                            color="primary"
                            onClick={this.state.selectedCategory.isActive ? this.confirmDeactivateCategory : this.confirmActivateCategory}
                          >
                            {t("CATEGORY.YES_BUTTON")}
                          </Button>
                        </ModalFooter>
                      </Modal>

                      <Modal isOpen={this.state.deleteModal} toggle={this.changeDeleteModalState} style={{ marginTop: "50px", width: "60%" }}>
                        <ModalHeader> {this.state.topics.length > 0 ? t("CATEGORY.DELETE_MODAL_TITLE") : t("CATEGORY.CONFIRM_DELETE_MODAL_TITLE")}</ModalHeader>
                        <ModalBody style={{ minHeight: "auto" }}>
                          {this.state.topics.length > 0 || this.state.learningObjects.length > 0 ? (
                            <div>
                              <PerfectScrollbar>
                                {this.state.topics.length > 0 ? (
                                  <Card className="main-card mb-3">
                                    <CardTitle>{t("CATEGORY.TOPICS")}</CardTitle>
                                    <DataTable value={this.state.topics} paginator rows={10}>
                                      <Column field="name" header={t("CATEGORY.TOPIC_NAME_COLUMN_NAME")}></Column>
                                      <Column field="createdByUser" header={t("CATEGORY.TOPIC_CREATED_BY_COLUMN_NAME")}></Column>
                                    </DataTable>
                                  </Card>
                                ) : (
                                  ""
                                )}

                                {this.state.learningObjects.length > 0 ? (
                                  <Card className="main-card mb-3">
                                    <CardTitle>{t("CATEGORY.LEARNING_OBJECTS")}</CardTitle>
                                    <DataTable value={this.state.learningObjects} paginator rows={10}>
                                      <Column field="name" header={t("CATEGORY.LEARNING_OBJECT_NAME_COLUMN_NAME")}></Column>
                                      <Column field="createdByUser" header={t("CATEGORY.LEARNING_OBJECT_CREATED_BY_COLUMN_NAME")}></Column>
                                    </DataTable>
                                  </Card>
                                ) : (
                                  ""
                                )}
                              </PerfectScrollbar>
                            </div>
                          ) : (
                            ""
                          )}
                        </ModalBody>
                        <ModalFooter>
                          <Col>
                            {this.state.topics.length > 0 || this.state.learningObjects.length > 0 ? (
                              <CSVLink
                                data={this.state.csvData}
                                filename={
                                  "delete_category_" + this.state.categoryTranslations.find((a) => a.language === this.state.selectedFilteringTableLanguage.lang)?.name + ".csv"
                                }
                                headers={this.state.csvHeaders}
                                className="mb-2 me-2 btn btn-success"
                                target="_blank"
                              >
                                {t("CATEGORY.EXPORT_BUTTON")}
                              </CSVLink>
                            ) : (
                              ""
                            )}
                          </Col>

                          {this.state.learningObjects.length > 0 || this.state.topics.length > 0 ? (
                            <div>
                              <Button className="mb-2 me-2 " color="secondary" onClick={this.changeDeleteModalState}>
                                {t("CATEGORY.CANCEL_BUTTON")}
                              </Button>
                            </div>
                          ) : (
                            <div>
                              <Button className="mb-2 me-2 " color="secondary" onClick={this.changeDeleteModalState}>
                                {t("CATEGORY.NO_BUTTON")}
                              </Button>
                              <Button className="mb-2 me-2 " color="primary" onClick={() => this.confirmDeleteCategory()}>
                                {t("CATEGORY.YES_BUTTON")}
                              </Button>
                            </div>
                          )}
                        </ModalFooter>
                      </Modal>
                      {this.isEditable() ? (
                        <>
                          <Col>
                            {this.state.selectedCategory.id ? (
                              <Button disabled={this.state.selectedCategory.isActive} className="mb-2 me-2" color="danger" onClick={this.openDeleteModel}>
                                {t("CATEGORY.DELETE_BUTTON")}
                              </Button>
                            ) : (
                              ""
                            )}
                            {renderActiveDeactivateButton()}
                          </Col>

                          <Button className="mb-2 me-2" color="secondary" onClick={this.changeModalState}>
                            {t("CATEGORY.CANCEL_BUTTON")}
                          </Button>
                          {this.state.selectedCategory?.id ? (
                            <Button className="mb-2 me-2 " color="primary" onClick={(e) => this.updateCategory(e)}>
                              {t("CATEGORY.UPDATE_BUTTON")}
                            </Button>
                          ) : (
                            <Button className="mb-2 me-2 " color="primary" onClick={(e) => this.createNewCategory(e)}>
                              {t("CATEGORY.SAVE_BUTTON")}
                            </Button>
                          )}
                        </>
                      ) : (
                        <Button className="mb-2 me-2" color="secondary" onClick={this.changeModalState}>
                          {t("CATEGORY.CLOSE_BUTTON")}
                        </Button>
                      )}
                    </ModalFooter>
                  </Modal>
                </Col>
                <Col md="12">
                  <Card className="main-card mb-3">
                    <CardBody>
                      <div className="card p-fluid">
                        <DataTable
                          value={this.state.filteredCategories}
                          paginator
                          className="p-datatable-customers"
                          rows={10}
                          dataKey="id"
                          filterDisplay="row"
                          responsiveLayout="scroll"
                          filters={this.state.filterCategoriesTemplate}
                          emptyMessage="No categories found."
                        >
                          <Column
                            header={t("CATEGORY.LANGUAGE_COLUMN_NAME")}
                            field="language"
                            showFilterMenu={false}
                            style={{ minWidth: "10rem" }}
                            body={languageBody}
                            filter
                            filterElement={languageTableFilter}
                          />
                          <Column header={t("CATEGORY.NAME_COLUMN_NAME")} field="name" filter sortable />
                          <Column header={t("CATEGORY.COMMENT_COLUMN_NAME")} field="comment" filter sortable />
                          <Column
                            field="isPublic"
                            header={t("CATEGORY.IS_PUBLIC_COLUMN_NAME")}
                            dataType="boolean"
                            sortable
                            body={formatIsPublicCheckBox}
                            filter
                            filterElement={verifiedRowFilterTemplate}
                          />
                          <Column
                            field="isActive"
                            header={t("CATEGORY.IS_ACTIVE_COLUMN_NAME")}
                            dataType="boolean"
                            sortable
                            body={formatIsActiveCheckBox}
                            filter
                            filterElement={verifiedRowFilterTemplate}
                          />
                          <Column field="createdByUser" header={t("CATEGORY.CREATED_BY_COLUMN_NAME")} filter sortable />
                          <Column header={t("CATEGORY.CREATED_DATE_COLUMN_NAME")} filterField="createdDate" dataType="date" body={this.dateCategoriesTemplate} />
                          <Column body={buttonUpdateCategoryTranslation} rowEditor />
                        </DataTable>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </div>
          </CSSTransition>
        </TransitionGroup>
      </Fragment>
    );
  }
}
export default withTranslation()(Category);
