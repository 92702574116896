import React, { Fragment, Component } from "react";
import { withTranslation } from "react-i18next";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Col, Row, CardBody } from "reactstrap";
import { classNames } from "primereact/utils";
import "../LearningPath/style.css";
import "../../Metadata/Attribute/DataTable.css";
import LearningPathService from "../../../service/LearningPathService";

class RecommendationTabContent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedLearningPath: props.learningPath,
      learningPathRecommendations: [],
    };
  }

  componentDidMount() {
    this.GetLearningPathRecommendations();
  }

  GetLearningPathRecommendations() {
    return LearningPathService.getRecommendations(this.state.selectedLearningPath.id)
      .then((response) => {
        this.setState({ learningPathRecommendations: response.data });
      })
      .catch((e) => {
        console.log(e);
      });
  }

  render() {
    const { t } = this.props;
    const { learningPathRecommendations } = this.state;
    let selectedRow = 0;
    let selectedIndex = 0;

    const learningPathRecommendationColumns =
      learningPathRecommendations.length === 0
        ? []
        : learningPathRecommendations[0].recommendations.map((recommendation, index) => (
            <Column key={index} header={recommendation.recommendationName} body={() => formatCheckBox(index)} />
          ));

    const formatCheckBox = (index) => {
      if (index < selectedIndex) {
        selectedRow++;
      }
      selectedIndex = index;
      return (
        <i
          className={classNames("pi", {
            "false-icon pi-times-circle": learningPathRecommendations[selectedRow].recommendations[selectedIndex].isRecommended,
            "": !learningPathRecommendations[selectedRow].recommendations[selectedIndex].isRecommended,
          })}
        ></i>
      );
    };

    return (
      <Fragment>
        <Row>
          <Col md="12">
            <CardBody>
              <div className="card">
                <DataTable
                  value={learningPathRecommendations}
                  className="p-datatable-customers"
                  dataKey="id"
                  responsiveLayout="scroll"
                  emptyMessage={t("LEARNINGPATH.RECOMMENDATION_TABLE_MESSAGE")}
                >
                  <Column header={t("LEARNINGPATH.NAME_COLUMN_NAME")} field="name" />
                  {learningPathRecommendationColumns}
                </DataTable>
              </div>
            </CardBody>
          </Col>
        </Row>
      </Fragment>
    );
  }
}
export default withTranslation()(RecommendationTabContent);
