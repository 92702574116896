import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { Button } from "reactstrap";
import { Col, Row, Card, CardBody, ModalHeader, Modal, ModalFooter, UncontrolledButtonDropdown, DropdownMenu, DropdownItem, DropdownToggle } from "reactstrap";
import PerfectScrollbar from "react-perfect-scrollbar";
import { Link, Route } from "react-router-dom";
import Module from "./Module";
import LearningPathService from "../../../service/LearningPathService";
import LearningPathItemService from "../../../service/LearningPathItemService";
import "./style.css";
import { eventMediator } from "../../../service/eventMediator.js";
import GeneralInfo from "./GeneralInfo";
import tokenService from "../../../service/tokenService";
import AdviceTrainingModel from "./AdviceTrainingModel.js";

const styles = {
  activeModal: { marginTop: "3rem", width: "50%" },
};

class Training extends Component {
  constructor(props) {
    super(props);
    this.state = {
      training: null,
      learningPathItems: [],
      baseLearningPathItem: null,
      currentLearningPathItem: null,
      nextLearningPathItem: null,
      loggedUser: tokenService.getUser(),
      previousLearningPathItem: null,
      activeModal: false,
      adviceModal: false,
      adviceTrainings: [],
    };
  }

  downloadDocx(training) {
    LearningPathService.downloadDocx(training.id)
      .then((response) => {
        const file = new Blob([response.data]);
        const url = window.URL.createObjectURL(file);
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", training.name + ".docx");
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        window.URL.revokeObjectURL(url);
      })
      .catch((error) => {
        console.error("Error downloading file:", error);
      });
  }

  downloadExcel(training) {
    LearningPathService.downloadExcel(training.id)
      .then((response) => {
        const file = new Blob([response.data]);
        const url = window.URL.createObjectURL(file);
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", training.name + ".xlsx");
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        window.URL.revokeObjectURL(url);
      })
      .catch((error) => {
        console.error("Error downloading file:", error);
      });
  }

  downloadPdf(training) {
    LearningPathService.downloadPdf(training.id)
      .then((response) => {
        const file = new Blob([response.data]);
        const url = window.URL.createObjectURL(file);
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", training.name + ".pdf");
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        window.URL.revokeObjectURL(url);
      })
      .catch((error) => {
        console.error("Error downloading file:", error);
      });
  }

  downloadZip(training) {
    LearningPathService.downloadZip(training.id)
      .then((response) => {
        const file = new Blob([response.data]);
        const url = window.URL.createObjectURL(file);
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", training.name + ".zip");
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        window.URL.revokeObjectURL(url);
      })
      .catch((error) => {
        console.error("Error downloading file:", error);
      });
  }

  
  downloadScorm(training) {
    LearningPathService.downloadScorm(training.id)
      .then((response) => {
        const file = new Blob([response.data]);
        const url = window.URL.createObjectURL(file);
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", training.name + "-scorm.zip");
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        window.URL.revokeObjectURL(url);
      })
      .catch((error) => {
        console.error("Error downloading file:", error);
      });
  }

  onFindSimilar = (training) => {
    this.getAdviceTraining(training.id);
  };

  componentDidMount() {
    eventMediator.on("allowComplete", (data) => {
      let currentLearningPathItem = this.state.currentLearningPathItem;
      currentLearningPathItem.allowComplete = data;
      this.setState({ currentLearningPathItem: currentLearningPathItem });
    });
    this.getTraining(this.props.match.params.learingPathId);
    this.getLearningPathItemsByTrainingId(this.props.match.params.learingPathId);
  }

  componentDidUpdate() {
    if (this.props.match.params.learingPathId !== this.state.training?.id) {
      this.getTraining(this.props.match.params.learingPathId).then(() => {
        this.getLearningPathItemsByTrainingId(this.props.match.params.learingPathId);
      });
    }
  }
  componentWillUnmount() {
    eventMediator.remove("allowComplete");
  }

  getTraining = (learingPathId) => {
    return LearningPathService.getLearningPathById(learingPathId).then((response) => {
      this.setState({ training: response.data });
    });
  };

  getLearningPathItemsByTrainingId = (trainingId) => {
    return LearningPathItemService.getByTrainingId(trainingId)
      .then((response) => {
        var bindLearningPathItems = this.bindLearningPathItems(response.data, this.props.match.url);

        this.setState({
          learningPathItems: bindLearningPathItems,
          currentLearningPathItem: null,
          nextLearningPathItem: null,
          previousLearningPathItem: null,
        });
        if (!this.state.currentLearningPathItem) {
          let learningPathItemIds = this.props.location.pathname.replace(this.props.match.url + "/", "").split("/");
          if (learningPathItemIds && learningPathItemIds.length > 0) {
            this.navigateToLearningPathItem(null, bindLearningPathItems, learningPathItemIds);
          }
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };

  navigateToLearningPathItem = (baseLearningPathItem, learningPathItems, learningPathItemIds) => {
    let learningPathItemId = learningPathItemIds.shift();
    if (learningPathItemId) {
      if (learningPathItemIds.length > 0) {
        let currentLearningPathItems = baseLearningPathItem ? baseLearningPathItem.children : learningPathItems;
        let learingPathItem = currentLearningPathItems.find((l) => l.id === learningPathItemId);
        if (learingPathItem) {
          learingPathItem.showChildren = true;
          this.navigateToLearningPathItem(learingPathItem, currentLearningPathItems, learningPathItemIds);
        }
      } else {
        this.setState(
          {
            baseLearningPathItem: baseLearningPathItem,
          },
          () => {
            let currentLearningPathItems = baseLearningPathItem ? baseLearningPathItem.children : learningPathItems;
            let learingPathItem = currentLearningPathItems.find((l) => l.id === learningPathItemId);
            this.updateNavigation(currentLearningPathItems.indexOf(learingPathItem));
          }
        );
      }
    }
  };

  bindLearningPathItems = (learningPathItems, baseUrl) => {
    var items = learningPathItems.map(
      ({
        id,
        createdBy,
        createdByUser,
        completedDate,
        learningObjectId,
        attributeIds,
        materialIds,
        comment,
        learningObjectNames,
        learningPathId,
        link,
        placeholder,
        trainingId,
        trainingName,
        position,
        children,
      }) => ({
        id: id,
        createdBy: createdBy,
        createdByUser: createdByUser,
        completedDate: completedDate,
        learningObjectId: learningObjectId,
        learningObjectNames: learningObjectNames,
        learningPathId: learningPathId,
        link: link,
        allowComplete: learningObjectId || placeholder || link,
        placeholder: placeholder,
        trainingId: trainingId,
        trainingName: trainingName,
        position: position,
        attributeIds: attributeIds,
        comment: comment,
        materialIds: materialIds,
        url: baseUrl,
        children: children && children.length > 0 ? this.bindLearningPathItems(children, baseUrl + "/" + id) : [],
        displayName: learningObjectId
          ? learningObjectNames.find((obj) => obj.language === this.state.training.language)?.name
          : trainingId
          ? trainingName
          : placeholder
          ? placeholder
          : link,
        tab: learningObjectId ? "learningObjectTab" : trainingId ? "trainingTab" : placeholder ? "placeholderTab" : "linkTab",
      })
    );

    return items.filter((i) => i.displayName);
  };

  handleOnClick = (baseLearningPathItem, currentIndex) => {
    this.setState(
      {
        baseLearningPathItem: baseLearningPathItem,
      },
      () => {
        this.updateNavigation(currentIndex);
      }
    );
  };

  handleToGeneralInfo = () => {
    this.setState({
      currentLearningPathItem: null,
      nextLearningPathItem: null,
      previousLearningPathItem: null,
    });
  };

  updateNavigation = (currentIndex) => {
    let learningPathItems = this.state.baseLearningPathItem ? this.state.baseLearningPathItem.children : this.state.learningPathItems;

    if (currentIndex >= 0 && currentIndex < learningPathItems.length) {
      this.setState({
        currentLearningPathItem: learningPathItems[currentIndex],
        previousLearningPathItem: currentIndex > 0 ? learningPathItems[currentIndex - 1] : null,
        nextLearningPathItem: currentIndex < learningPathItems.length ? learningPathItems[currentIndex + 1] : null,
      });
    }
  };

  next = () => {
    let learningPathItems = this.state.baseLearningPathItem ? this.state.baseLearningPathItem.children : this.state.learningPathItems;
    this.updateNavigation(learningPathItems.indexOf(this.state.currentLearningPathItem) + 1);
  };

  previous = () => {
    let learningPathItems = this.state.baseLearningPathItem ? this.state.baseLearningPathItem.children : this.state.learningPathItems;
    this.updateNavigation(learningPathItems.indexOf(this.state.currentLearningPathItem) - 1);
  };

  complete = () => {
    if (this.state.currentLearningPathItem) {
      LearningPathItemService.markAsCompleted(this.state.currentLearningPathItem.id).then(() => {
        let lpi = this.state.currentLearningPathItem;
        lpi.completedDate = new Date();
        this.setState({ currentLearningPathItem: lpi }, () => this.next());
      });
    }
  };

  toggleTraining = (learningPathItemId) => {
    let learningPathItems = this.state.baseLearningPathItem ? this.state.baseLearningPathItem.children : this.state.learningPathItems;
    let learningPathItem = learningPathItems.find((i) => i.id === learningPathItemId);
    if (learningPathItem?.showChildren) {
      learningPathItem.showChildren = false;
    } else {
      learningPathItem.showChildren = true;
    }
  };

  isEditable = () => {
    return (!this.state.training?.isPublic && (this.state.loggedUser.currentRole === "ContenxtAdmin" || this.state.loggedUser.currentRole === "SystemAdmin")) || this.isM2LAdmin();
  };

  isM2LAdmin = () => {
    return this.state.loggedUser?.currentRole === "SystemAdmin" && this.state.loggedUser?.currentCompanyId === null;
  };

  changeActiveModalState = () => {
    this.setState({
      activeModal: !this.state.activeModal,
    });
  };

  getFavorites() {
    return LearningPathService.getFavorite().then((response) => {
      tokenService.setTrainings(response.data);
      eventMediator.dispatch("updateMenu");
      this.props.history.push("/");
    });
  }

  getAdviceTraining(trainingId) {
    return LearningPathService.getAdviceTraining(trainingId).then((response) => {
      this.setState({ adviceTrainings: response.data });
      this.changeAdviceModalState();
    });
  }

  confirmDeactivateLearningPath = () => {
    LearningPathService.deactivate(this.state.training.id).then(() => {
      this.changeActiveModalState();
      this.getFavorites();
    });
  };

  changeAdviceModalState = () => {
    this.setState({
      adviceModal: !this.state.adviceModal,
    });
  };

  render() {
    const { t, match } = this.props;
    const { learningPathItems, currentLearningPathItem, previousLearningPathItem, nextLearningPathItem, training } = this.state;

    const getClassName = (className, learningPathItem) => {
      let classes = "";
      if (currentLearningPathItem?.url === learningPathItem.url && currentLearningPathItem?.id === learningPathItem.id) {
        classes = "active-module ";
      }
      return classes + className + "-" + (learningPathItem.completedDate ? "done" : "todo");
    };

    const tree = (baseUrl, baseLearningPathItem, learningPathItems) => {
      return (
        <ul style={{ paddingLeft: "4px", paddingRight: "4px" }} key={baseLearningPathItem?.id ? "base" : baseLearningPathItem?.id}>
          {learningPathItems.map((learningPathItem, index) => treeNode(baseUrl, baseLearningPathItem, learningPathItem, index))}
        </ul>
      );
    };

    const treeNode = (baseUrl, baseLearningPathItem, learningPathItem, index) => {
      return (
        <>
          <Link
            to={{
              pathname: `${baseUrl}/${learningPathItem.id}`,
              state: { learningPathItem: learningPathItem },
            }}
          >
            <li className={getClassName("form-wizard-step", learningPathItem)} onClick={() => this.handleOnClick(baseLearningPathItem, index)}>
              <em>{index + 1}</em>
              <span
                className="wordwrap"
                style={{
                  padding: "0.3rem",
                  paddingLeft: "0.15rem",
                  display: "contents",
                }}
              >
                <i
                  style={{
                    visibility: learningPathItem.trainingId ? "visible" : "hidden",
                    fontSize: "1.5rem",
                    verticalAlign: "middle",
                  }}
                  className={learningPathItem.showChildren ? "pe-7s-less" : "pe-7s-plus"}
                  onClick={() => this.toggleTraining(learningPathItem.id)}
                ></i>
                <span
                  className="wordwrap"
                  style={{
                    paddingLeft: "0.3rem",
                  }}
                >
                  {learningPathItem.displayName}
                </span>
              </span>
            </li>
          </Link>
          <ul style={{ paddingLeft: "1.75rem" }}>{learningPathItem.showChildren && tree(`${baseUrl}/${learningPathItem.id}`, learningPathItem, learningPathItem.children)}</ul>
        </>
      );
    };

    return (
      <Row>
        <Col md="12">
          <Card className="main-card mb-3">
            <CardBody>
              {this.state.adviceModal ? <AdviceTrainingModel changeModalState={this.changeAdviceModalState} adviceTrainings={this.state.adviceTrainings} /> : ""}
              <div className="forms-wizard-vertical">
                <div>
                  <div style={{ minHeight: "34rem" }}>
                    <ol className="forms-wizard">
                      <div style={{ height: "36rem" }} className="items-border">
                        <PerfectScrollbar>
                          <ul style={{ paddingLeft: "0", borderBottom: "2px solid rgba(32, 39, 140, 0.125)", marginBottom: "4px" }}>
                            <Link
                              to={{
                                pathname: match.url,
                              }}
                            >
                              <li className="form-wizard-step" onClick={() => this.handleToGeneralInfo()}>
                                <span
                                  style={{
                                    padding: "0.3rem",
                                    color: "#495057",
                                    margin: "0 auto",
                                    fontSize: "large",
                                  }}
                                >
                                  <b>{training?.name}</b> ({training?.version})
                                </span>
                              </li>
                            </Link>
                          </ul>
                          {tree(match.url, null, learningPathItems)}
                        </PerfectScrollbar>
                      </div>
                    </ol>
                    <div>
                      {currentLearningPathItem ? <Route path={`${currentLearningPathItem?.url}/:learingPathItemId`} component={Module} /> : <GeneralInfo training={training} />}
                    </div>
                  </div>
                  <div className="divider" />
                  <div className="clearfix">
                    <div>
                      {!currentLearningPathItem && (
                        <Row className="justify-content-center">
                          <Col md="4" style={{ display: "contents" }}>
                            <UncontrolledButtonDropdown>
                              <DropdownToggle caret outline className="btn btn-shadow btn-wide text-center me-3" color="primary">
                                {t("TRAINING.DOWNLOAD_BUTTON")}
                              </DropdownToggle>
                              <DropdownMenu>
                                <DropdownItem onClick={() => this.downloadExcel(training)}>{t("TRAINING.DOWNLOAD_SHEDULE_BUTTON")}</DropdownItem>
                                <DropdownItem onClick={() => this.downloadDocx(training)}>{t("TRAINING.DOWNLOAD_DOCX_BUTTON")}</DropdownItem>
                                <DropdownItem onClick={() => this.downloadPdf(training)}>{t("TRAINING.DOWNLOAD_PDF_BUTTON")}</DropdownItem>
                                <DropdownItem onClick={() => this.downloadZip(training)}>{t("TRAINING.DOWNLOAD_ZIP_BUTTON")}</DropdownItem>
                                <DropdownItem onClick={() => this.downloadScorm(training)}>{t("TRAINING.DOWNLOAD_SCORM_BUTTON")}</DropdownItem>
                                </DropdownMenu>
                            </UncontrolledButtonDropdown>
                            <Button outline color="primary" className="btn-shadow btn-wide text-center me-3" onClick={() => this.onFindSimilar(training)}>
                              {t("TRAINING.FIND_SIMILAR_BUTTON")}
                            </Button>
                            {this.isEditable() && (
                              <Button className="me-3 " outline color="danger" onClick={this.changeActiveModalState}>
                                {t("LEARNINGPATH.DEACTIVATE_BUTTON")}
                              </Button>
                            )}
                          </Col>
                        </Row>
                      )}

                      {currentLearningPathItem && (
                        <Link
                          to={{
                            pathname: `${currentLearningPathItem?.url}/${previousLearningPathItem?.id}`,
                            state: { learningPathItem: previousLearningPathItem },
                          }}
                        >
                          <Button disabled={!previousLearningPathItem} color="secondary" style={currentLearningPathItem ? {} : { display: "none" }} onClick={this.previous}>
                            {t("TRAINING.PREVIOUS_BUTTON")}
                          </Button>
                        </Link>
                      )}

                      {currentLearningPathItem && (
                        <Link
                          to={{
                            pathname: `${currentLearningPathItem?.url}/${nextLearningPathItem ? nextLearningPathItem?.id : currentLearningPathItem?.id}`,
                            state: { learningPathItem: nextLearningPathItem ? nextLearningPathItem : currentLearningPathItem },
                          }}
                        >
                          <Button disabled={!nextLearningPathItem} color="primary" className="btn-shadow btn-wide float-end btn-hover-shine" onClick={this.next}>
                            {t("TRAINING.NEXT_BUTTON")}
                          </Button>
                        </Link>
                      )}

                      {currentLearningPathItem && (
                        <Link
                          to={{
                            pathname: `${currentLearningPathItem?.url}/${nextLearningPathItem ? nextLearningPathItem?.id : currentLearningPathItem?.id}`,
                            state: { learningPathItem: nextLearningPathItem ? nextLearningPathItem : currentLearningPathItem },
                          }}
                        >
                          <Button
                            disabled={!(currentLearningPathItem && !currentLearningPathItem?.completedDate && currentLearningPathItem?.allowComplete)}
                            color="success"
                            className="me-2 btn-shadow btn-wide float-end btn-hover-shine"
                            onClick={this.complete}
                          >
                            {t("TRAINING.COMPLETE_BUTTON")}
                          </Button>
                        </Link>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </CardBody>
          </Card>
        </Col>
        <Modal isOpen={this.state.activeModal} toggle={this.changeActiveModalState} style={styles.activeModal}>
          <ModalHeader>{t("TRAINING.CONFIRM_DEACTIVATE_MODAL_TITLE")}</ModalHeader>
          <ModalFooter>
            <Button className="mb-2 me-2   " color="secondary" onClick={this.changeActiveModalState}>
              {t("LEARNINGPATH.NO_BUTTON")}
            </Button>
            <Button className="mb-2 me-2  " color="primary" onClick={() => this.confirmDeactivateLearningPath()}>
              {t("LEARNINGPATH.YES_BUTTON")}
            </Button>
          </ModalFooter>
        </Modal>
      </Row>
    );
  }
}
export default withTranslation()(Training);
