import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { Col, Button, Input, Label, ModalFooter, Modal, ModalHeader, ModalBody, Card, CardBody, Row } from "reactstrap";
import LearningObjectMaterialService from "../../../service/LearningObjectMaterialService";
import { Column, DataTable } from "primereact";
import MaterialTypeService from "../../../service/MaterialTypeService";
import MaterialPreview from "../../Shared/MaterialPreview";
import SweetAlert from "react-bootstrap-sweetalert";
import { IoIosCheckmark } from "react-icons/io";

class LearningObjectMaterial extends Component {
  constructor(props) {
    super(props);
    this.state = {
      materials: [],
      needDeleteMeterials: [],
      materialTypes: [],
      materialPreviewModel: false,
      show: false,
      linkModal: false,
      link: null,
      error: {},
    };
  }

  componentDidMount() {
    this.getAllMaterialTypes();
    if (this.props.learningObjectId) {
      this.getMaterialsByLearningObjectId(this.props.learningObjectId);
    }
  }

  getAllMaterialTypes() {
    MaterialTypeService.getAll()
      .then((response) => {
        this.setState({ materialTypes: response.data });
      })
      .catch((e) => {
        console.log(e);
      });
  }

  getMaterialsByLearningObjectId(learningObjectId) {
    return LearningObjectMaterialService.getByLearninObjectId(learningObjectId)
      .then((response) => {
        this.setState({
          materials: response.data,
        });
      })
      .catch((e) => {
        console.log(e);
      });
  }

  handleFileEvent = (e, materialType) => {
    let file = e.target.files[0];
    let reader = new FileReader();
    if (!this.isValidFile(file, materialType)) {
      this.setState({ show: true });
      return;
    }

    reader.readAsDataURL(file);
    var materials = this.state.materials;
    reader.onload = (e) => {
      var fileName = file.name;
      var theSameNameMaterial = materials.find((m) => m.fileName === fileName);
      if (theSameNameMaterial && theSameNameMaterial.id) {
        materials = this.state.materials.filter((m) => m.fileName !== fileName);
        let needDeleteMeterials = this.state.needDeleteMeterials;
        needDeleteMeterials.push(theSameNameMaterial.id);
        this.setState({ needDeleteMeterials: needDeleteMeterials });
      }
      materials.push({ fileName, file, materialTypeId: materialType.id, materialTypeName: materialType.name, language: this.props.language });
      this.setState({ materials: materials });
      this.props.onChange(materials, this.state.needDeleteMeterials);
    };
  };

  isValidFile(file, materialType) {
    if (materialType.name === "image") {
      return file.type.startsWith("image/");
    } else if (materialType.name === "video") {
      return file.type.startsWith("video/");
    } else if (materialType.name === "audio") {
      return file.type.startsWith("audio/");
    } else if (materialType.name === "xlsx") {
      return file.type.startsWith("application/");
    } else if (materialType.name === "pdf") {
      return file.type.startsWith("application/pdf");
    }
    return true;
  }

  openMaterial = (rowData) => {
    if (rowData.materialTypeName === "url") {
      window.open(rowData.link, "_blank")?.focus();
    } else {
      this.setState({
        materialPreviewModel: true,
        material: rowData,
      });
    }
  };

  closeMaterial = () => {
    this.setState({
      materialPreviewModel: false,
    });
  };

  deleteMaterial = (rowData) => {
    let needDeleteMeterials = this.state.needDeleteMeterials;
    if (rowData.id) {
      needDeleteMeterials.push(rowData.id);
    }
    let materials = this.state.materials.filter((m) => m.fileName !== rowData.fileName);

    this.setState({
      materials: materials,
      needDeleteMeterials: needDeleteMeterials,
    });
    this.props.onChange(materials, needDeleteMeterials);
  };

  async handleDownload(material) {
    await LearningObjectMaterialService.download(material.id)
      .then((response) => {
        const file = new Blob([response.data]);
        const url = window.URL.createObjectURL(file);
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", material.fileName);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        window.URL.revokeObjectURL(url);
      })
      .catch((error) => {
        console.error("Error downloading file:", error);
      });
  }

  openLinkModel = () => {
    this.changeLinkModalState();
  };

  changeLinkModalState = () => {
    this.setState({
      linkModal: !this.state.linkModal,
    });
  };

  confirmAddLink = () => {
    var link = this.state.link;
    var materials = this.state.materials;
    var theSameLink = materials.find((m) => m.link === link);
    if (!theSameLink) {
      var materialType = this.state.materialTypes.find((m) => m.name === "url");
      materials.push({ allowLink: false, link: link, materialTypeId: materialType.id, materialTypeName: materialType.name, language: this.props.language });
      this.setState({ materials: materials });
      this.props.onChange(materials, this.state.needDeleteMeterials);
    }
    this.changeLinkModalState();
  };

  changeLink = (e) => {
    var error = this.state.error;
    error.link = e.target.value ? "" : "Required";
    this.setState({ link: e.target.value, error: error });
  };

  render() {
    const { t, readOnly, learningObjectAttributes, onOpenAttributeModel } = this.props;

    const nameColumn = (rowData) => {
      return rowData.materialTypeName === "url" ? rowData.link : rowData.fileName;
    };
    const deleteButton = (rowData) => {
      return (
        <Button outline color="danger" size="sm" onClick={() => this.deleteMaterial(rowData)}>
          {t("LEARNING_OBJECT.DELETE_BUTTON")}
        </Button>
      );
    };
    const openButton = (rowData) => {
      return (
        <Button outline color="primary" size="sm" onClick={() => this.openMaterial(rowData)}>
          {t("LEARNING_OBJECT.OPEN_BUTTON")}
        </Button>
      );
    };
    const attributeButton = (rowData) => {
      return (
        <Button outline color="primary" size="sm" onClick={() => onOpenAttributeModel(rowData)}>
          {t("LEARNING_OBJECT.ATTRIBUTE_BUTTON")}
        </Button>
      );
    };
    const attributeCheckmark = (rowData) => {
      var showCheckbox = false;
      learningObjectAttributes.forEach((attribute) => {
        if (!showCheckbox) {
          if (attribute.materialIds.find((id) => id === rowData.id)) {
            showCheckbox = true;
          }
        }
      });

      if (showCheckbox) {
        return (
          <div style={{ alignItems: "normal", display: "flex" }}>
            <IoIosCheckmark />
          </div>
        );
      }
    };
    const downloadButton = (rowData) => {
      return (
        <>
          {rowData.materialTypeName !== "url" && (
            <Button outline color="primary" size="sm" onClick={() => this.handleDownload(rowData)}>
              {t("LEARNING_OBJECT.DOWNLOAD_BUTTON")}
            </Button>
          )}
        </>
      );
    };

    return (
      <div>
        {this.state.materialPreviewModel && <MaterialPreview onClose={this.closeMaterial} material={this.state.material} />}
        <div style={{ justifyContent: "in-line", alignItems: "end", display: "flex" }}>
          {this.state.materialTypes.map((materialType, index) => (
            <Col key={index}>
              <Label htmlFor={materialType.name + index} className="mb-2 me-2">
                {materialType.name}
              </Label>
              {!readOnly && (
                <>
                  {materialType.name !== "url" ? (
                    <Input
                      id={materialType.name + index}
                      type="file"
                      accept={materialType.extensions}
                      onChange={(e) => this.handleFileEvent(e, materialType)}
                      style={{ display: "none" }}
                    />
                  ) : (
                    <Input id={materialType.name + index} type="button" onClick={(e) => this.openLinkModel()} style={{ display: "none" }} />
                  )}
                </>
              )}
            </Col>
          ))}
        </div>

        <SweetAlert title={t("NOT_ALLOW")} show={this.state.show} type="error" onConfirm={() => this.setState({ show: false })} />

        <DataTable value={this.state.materials.filter((m) => m.language === this.props?.language)} paginator rows={5} dataKey="id">
          <Column body={nameColumn} style={{ fontSize: "0.75rem", width: readOnly ? "19rem" : "14rem" }} header={t("LEARNING_OBJECT.NAME_COLUMN_NAME")}></Column>
          <Column body={attributeCheckmark} style={{ fontSize: "xx-large", width: "1.5rem", padding: "0" }} rowEditor />
          <Column body={downloadButton} rowEditor />
          <Column body={attributeButton} rowEditor />
          {!readOnly && <Column body={openButton} rowEditor />}
          <Column body={deleteButton} rowEditor />
        </DataTable>

        <Modal isOpen={this.state.linkModal} toggle={this.changeLinkModalState} style={{ marginTop: "100px", width: "60%" }}>
          <ModalHeader> {t("LEARNING_OBJECT.ADD_LINK")}</ModalHeader>
          <ModalBody style={{ minHeight: "auto" }}>
            <Card className="main-card mb-3">
              <CardBody>
                <Row>
                  <Col>
                    <div>
                      <Label for="name">{t("LEARNING_OBJECT.LINK")}* </Label>
                      <Input value={this.state.link} onChange={this.changeLink} className={this.state.error.link?.length > 0 ? "is-invalid form-control" : "form-control"} />
                      {this.state.error.link?.length > 0 && <span className="invalid-feedback">{this.state.error?.link}</span>}
                    </div>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </ModalBody>
          <ModalFooter>
            <div>
              <Button className="mb-2 me-2 " color="secondary" onClick={this.changeLinkModalState}>
                {t("LEARNING_OBJECT.NO_BUTTON")}
              </Button>
              <Button className="mb-2 me-2 " color="primary" onClick={this.confirmAddLink}>
                {t("LEARNING_OBJECT.SAVE_BUTTON")}
              </Button>
            </div>
          </ModalFooter>
        </Modal>
      </div>
    );
  }
}
export default withTranslation()(LearningObjectMaterial);
