class LearningObjectModel {
  constructor(learningObject) {
    if (learningObject !== undefined) {
      this.id = learningObject.id;
      this.name = learningObject.name;
      this.comment = learningObject.comment;
      this.isActive = learningObject.isActive;
      this.isPublic = learningObject.isPublic;
      this.createdDate = learningObject.createdDate;
      this.createdByUser = learningObject.createdByUser;
      this.topicId = learningObject.topicId;
      this.categoryIds = learningObject.categoryIds;
      this.recommendationIds = learningObject.recommendationIds;
      this.translations = learningObject.translations;
      this.image = learningObject.image;
      this.masterLanguage = learningObject.masterLanguage;
    }
  }
}
export default LearningObjectModel;
