import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { UncontrolledDropdown, DropdownItem, DropdownToggle, DropdownMenu, Label } from "reactstrap";
import Flag from "react-flagkit";
import languages from "../../models/Languages";
import { Badge } from "@material-ui/core";
import withStyles from "@material-ui/core/styles/withStyles";
import IconButton from "@material-ui/core/IconButton";
import EditIcon from "@material-ui/icons/Edit";

const EditIconButton = withStyles((theme) => ({
  root: {
    width: 22,
    height: 22,
    padding: 15,
    border: `2px solid #6c757d`,
  },
}))(IconButton);

class LanguageSelect extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { lang, onChangeLanguage, readOnly, style } = this.props;
    return (
      <div className="header-dots" style={style}>
        <div>
          <Flag className="me-3 opacity-8" country={languages.find((obj) => obj.lang === lang)?.flag} size="25" />
          <Label for="createdDate">{languages.find((obj) => obj.lang === lang)?.name}</Label>
        </div>
        {!readOnly && (
          <UncontrolledDropdown>
            <DropdownToggle
              color="black"
              style={{
                fontWeight: "bold",
              }}
            >
              <Badge
                overlap="circular"
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "right",
                }}
                style={{
                  marginBottom: "0.6rem",
                }}
                badgeContent={
                  <EditIconButton>
                    <EditIcon />
                  </EditIconButton>
                }
              ></Badge>
            </DropdownToggle>
            <DropdownMenu end className="rm-pointers">
              {languages.map((language, id) => (
                <DropdownItem key={id} value={language} onClick={() => onChangeLanguage(language)}>
                  <Flag className="me-3 opacity-8" country={language.flag} />
                  {language.name}
                </DropdownItem>
              ))}
            </DropdownMenu>
          </UncontrolledDropdown>
        )}
      </div>
    );
  }
}
export default withTranslation()(LanguageSelect);
