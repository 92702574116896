import LearningPathService from "../../../service/LearningPathService.js";
import LearningPathItemService from "../../../service/LearningPathItemService.js";
import LearningPathTypeService from "../../../service/LearningPathTypeService.js";
import LearningPathModel from "../../../models/learningPaths/LearningPathModel.js";
import tokenService from "../../../service/tokenService.js";
import React, { Fragment, Component } from "react";
import { withTranslation } from "react-i18next";
import classnames from "classnames";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Col, Row, Modal, ModalHeader, ModalBody, ModalFooter, CardTitle, Card, TabContent, TabPane, Nav, NavItem, NavLink, Button } from "reactstrap";
import "../LearningPath/style.css";
import "../../Metadata/Attribute/DataTable.css";
import CreateUpdateTabContent from "./CreateUpdateTabContent.js";
import ModuleTabContent from "./ModuleTabContent.js";
import RecommendationTabContent from "./RecommendationTabContent.js";
import { eventMediator } from "../../../service/eventMediator.js";
import { Loader } from "react-loaders";
import LoadingOverlay from "react-loading-overlay-ts";
import ScheduleTabContent from "./ScheduleTabContent.js";
import { CSVLink } from "react-csv";
import ScheduleService from "../../../service/ScheduleService.js";
import PerfectScrollbar from "react-perfect-scrollbar";
import { version } from "process";

class MainModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeTab: "propertyTab",
      selectedLearningPath: null,
      learningPathTypes: [],
      parents: [],

      learningPathItems: [],
      deletedLearninPathItems: [],

      schedules: [],

      loggedUser: tokenService.getUser(),
      activeModal: false,
      deleteModal: false,
      publishModal: false,
      updatePublishModal: false,
      error: {},
      loading: true,
      saving: false,

      csvNameLabel: "Name",
      csvCreatedByLabel: "Created By",
      csvVersionLabel: "Version",
      csvHeaders: [
        { label: "Name", key: "name" },
        { label: "Version", key: "version" },
        { label: "Created By", key: "createdByUser" },
      ],
    };
  }

  componentDidMount() {
    this.getAllLearningPathTypes().then(() => {
      if (this.props.learningPathId) {
        this.getLearningPathById(this.props.learningPathId).then(() => {
          this.getAllLearningPathItemsByLearningPathId(this.props.learningPathId);
          this.getSchedulesByLearningPathId(this.props.learningPathId);
        });
      } else {
        this.createDefaultLearningPath();
      }
    });
  }

  getAllLearningPathTypes() {
    return LearningPathTypeService.getAllLearningPathTypes()
      .then((response) => {
        this.setState({
          learningPathTypes: response.data,
        });
      })
      .catch((e) => {
        console.log(e);
      });
  }

  getParents(parentId) {
    return LearningPathService.getByParentId(parentId)
      .then((response) => {
        var parents = response.data.map(({ name, createdByUser, isActive, isPublic, version }) => ({
          name: name + (!isActive ? " (inactive) " : " ") + (isPublic ? " (public)" : ""),
          version: version,
          createdByUser: createdByUser,
        }));

        this.setState({
          parents: parents.filter((t) => t.name),
        });
      })
      .catch((e) => {
        console.log(e);
      });
  }

  getFavorites() {
    return LearningPathService.getFavorite().then((response) => {
      tokenService.setTrainings(response.data);
      eventMediator.dispatch("updateMenu");
    });
  }

  getLearningPathById(id) {
    return LearningPathService.getLearningPathById(id)
      .then((response) => {
        var selectedLearningPath = new LearningPathModel(response.data);
        selectedLearningPath.isTraining = this.state.learningPathTypes.find((t) => t.id === selectedLearningPath.learningPathTypeId)?.name === "Training";
        this.setState({
          selectedLearningPath: selectedLearningPath,
        });
      })
      .catch((e) => {
        console.log(e);
      });
  }

  createDefaultLearningPath = () => {
    var lp = new LearningPathModel();
    lp.createdByUser = this.state.loggedUser.firstName + " " + this.state.loggedUser.lastName;
    lp.isPublic = this.isM2LAdmin();
    lp.isActive = true;
    lp.createdDate = new Date();
    lp.language = this.props.defaultLang;
    lp.version = "0";
    this.setState({
      selectedLearningPath: lp,
      loading: false,
    });
  };

  getAllLearningPathItemsByLearningPathId(learningPathId) {
    return LearningPathItemService.getByLearningPathId(learningPathId)
      .then((response) => {
        this.bindLearningPathItems(response.data);
      })
      .catch((e) => {
        console.log(e);
      });
  }

  getSchedulesByLearningPathId(learningPathId) {
    return ScheduleService.getByLearningPathId(learningPathId)
      .then((response) => {
        this.setState({ schedules: response.data });
      })
      .catch((e) => {
        console.log(e);
      });
  }

  bindLearningPathItems(learningPathItems) {
    var bindLearningPathItems = learningPathItems.map(
      ({
        id,
        createdBy,
        createdByUser,
        learningObjectId,
        attributeIds,
        materialIds,
        comment,
        learningObjectNames,
        learningPathId,
        link,
        placeholder,
        trainingId,
        trainingName,
        position,
      }) => ({
        id: id,
        createdBy: createdBy,
        createdByUser: createdByUser,
        learningObjectId: learningObjectId,
        learningObjectNames: learningObjectNames,
        learningPathId: learningPathId,
        link: link,
        placeholder: placeholder,
        trainingId: trainingId,
        trainingName: trainingName,
        position: position,
        attributeIds: attributeIds,
        comment: comment,
        materialIds: materialIds,
        displayName: learningObjectId
          ? learningObjectNames.find((obj) => obj.language === this.state.selectedLearningPath.language)?.name
          : trainingId
          ? trainingName
          : placeholder
          ? placeholder
          : link,
        tab: learningObjectId ? "learningObjectTab" : trainingId ? "trainingTab" : placeholder ? "placeholderTab" : "linkTab",
      })
    );
    this.setState({
      learningPathItems: bindLearningPathItems.filter((i) => i.displayName),
      loading: false,
    });
  }

  changeSelectedLearningPath = (learningPath) => {
    this.setState({ selectedLearningPath: learningPath });
  };

  onScheduleChange = (schedules) => {
    this.setState({ schedules: schedules });
  };

  onLearningPathItemChange = (learningPathItems) => {
    this.setState({
      learningPathItems: learningPathItems,
      schedules: this.state.schedules.filter((f) => !f.learningPathItemId || (f.learningPathItemId && learningPathItems.find((i) => i.id === f.learningPathItemId))),
    });
  };

  update = (e) => {
    e.preventDefault();
    if (!this.isValid()) return;

    this.setState({ saving: true });
    LearningPathService.update(this.state.selectedLearningPath).then((response) => {
      if (response) {
        this.saveSchedules(this.state.selectedLearningPath.id);
        var promises = this.saveModules(this.state.selectedLearningPath.id);
        Promise.all(promises).then(() => {
          this.props.changeModalState(true);
        });
      } else {
        this.setState({ saving: false });
      }
    });
  };

  create = (e) => {
    e.preventDefault();
    if (!this.isValid()) return;

    this.setState({ saving: true });
    LearningPathService.insert(this.state.selectedLearningPath).then((response) => {
      if (response) {
        this.saveSchedules(response.data.id);
        var promises = this.saveModules(response.data.id);
        Promise.all(promises).then(() => {
          this.props.changeModalState(true);
        });
      } else {
        this.setState({ saving: false });
      }
    });
  };
  isEditable = () => {
    if (!this.state.selectedLearningPath) return false;
    return !this.state.selectedLearningPath.isTraining && (!this.state.selectedLearningPath.isPublic || this.isM2LAdmin());
  };

  isM2LAdmin = () => {
    return this.state.loggedUser?.currentRole === "SystemAdmin" && this.state.loggedUser?.currentCompanyId === null;
  };

  showDeactivate = () => {
    return (
      (!this.state.selectedLearningPath.isPublic && (this.state.loggedUser.currentRole === "ContentAdmin" || this.state.loggedUser.currentRole === "SystemAdmin")) ||
      this.isM2LAdmin()
    );
  };

  saveModules = (learningPathId) => {
    let promises = [];
    this.state.learningPathItems
      .filter((l) => !this.state.deletedLearninPathItems.find((d) => d === l.id))
      .forEach((learningPathItem) => {
        learningPathItem.learningPathId = learningPathId;
        if (learningPathItem.id) {
          promises.push(LearningPathItemService.update(learningPathItem));
        } else {
          promises.push(LearningPathItemService.insert(learningPathItem));
        }
      });
    this.state.deletedLearninPathItems.forEach((id) => promises.push(LearningPathItemService.delete(id)));

    return promises;
  };

  saveSchedules = (learningPathId) => {
    ScheduleService.batchUpsert(learningPathId, this.state.schedules);
  };

  isValid = () => {
    var updateLearningPath = new LearningPathModel(this.state.selectedLearningPath);
    let isValid = true;
    let error = this.state.error;
    if (!updateLearningPath.name) {
      error.name = "Required";
      isValid = false;
    }
    if (!updateLearningPath.version) {
      error.version = "Required";
      isValid = false;
    }

    if (!isValid) {
      this.setState({ error: error });
      return false;
    }
    return true;
  };

  changeActiveModalState = () => {
    this.setState({
      activeModal: !this.state.activeModal,
    });
  };

  changeDeleteModalState = () => {
    this.setState({
      deleteModal: !this.state.deleteModal,
    });
  };

  changePublishModalState = () => {
    this.setState({
      publishModal: !this.state.publishModal,
    });
  };

  changeUpdatePublishModalState = () => {
    this.setState({
      updatePublishModal: !this.state.updatePublishModal,
    });
  };

  confirmDeactivateLearningPath = () => {
    this.changeActiveModalState();
    LearningPathService.deactivate(this.state.selectedLearningPath.id).then(() => {
      var selectedLP = this.state.selectedLearningPath;
      selectedLP.isActive = false;
      this.setState({ selectedLearningPath: selectedLP });
      this.getFavorites();
    });
  };

  confirmActivateLearningPath = () => {
    this.changeActiveModalState();
    LearningPathService.activate(this.state.selectedLearningPath.id).then(() => {
      var selectedLP = this.state.selectedLearningPath;
      selectedLP.isActive = true;
      this.setState({ selectedLearningPath: selectedLP });
      this.getFavorites();
    });
  };

  confirmDeleteLearningPath = () => {
    this.setState({ saving: true });
    LearningPathService.delete(this.state.selectedLearningPath.id)
      .then((response) => {
        this.changeDeleteModalState();
        this.props.changeModalState(true);
      })
      .catch((e) => {
        this.changeDeleteModalState();
        this.setState({ saving: false });
      });
  };

  confirmUpdatePublishLearningPath = (e) => {
    e.preventDefault();
    if (!this.isValid()) return;

    this.changeUpdatePublishModalState();
    this.setState({ saving: true });
    LearningPathService.update(this.state.selectedLearningPath).then((response) => {
      if (response) {
        this.saveSchedules(this.state.selectedLearningPath.id);
        var promises = this.saveModules(this.state.selectedLearningPath.id);
        Promise.all(promises).then(() => {
          LearningPathService.updatePublish(this.state.selectedLearningPath.id).then(() => {
            this.getFavorites().then(() => {
              this.props.changeModalState(true);
            });
          });
        });
      } else {
        this.setState({ saving: false });
      }
    });
  };

  confirmPublishLearningPath = (e) => {
    e.preventDefault();
    if (!this.isValid()) return;

    this.changePublishModalState();
    this.setState({ saving: true });
    if (this.state.selectedLearningPath.id) {
      LearningPathService.update(this.state.selectedLearningPath).then((response) => {
        if (response) {
          this.saveSchedules(this.state.selectedLearningPath.id);
          var promises = this.saveModules(this.state.selectedLearningPath.id);
          Promise.all(promises).then(() => {
            LearningPathService.publish(this.state.selectedLearningPath.id).then(() => {
              this.getFavorites().then(() => {
                this.props.changeModalState(true);
              });
            });
          });
        } else {
          this.setState({ saving: false });
        }
      });
    } else {
      LearningPathService.insert(this.state.selectedLearningPath).then((response) => {
        if (response) {
          this.saveSchedules(response.data.id);
          var promises = this.saveModules(response.data.id);
          Promise.all(promises).then(() => {
            LearningPathService.publish(response.data.id).then(() => {
              this.getFavorites().then(() => {
                this.props.changeModalState(true);
              });
            });
          });
        } else {
          this.setState({ saving: false });
        }
      });
    }
  };

  openDeleteModel = () => {
    this.getParents(this.state.selectedLearningPath.id).then(() => {
      this.initCSVData();
      this.changeDeleteModalState();
    });
  };

  initCSVData = () => {
    let csvData = [];
    let csvHeaders = this.state.csvHeaders;

    csvHeaders[0].label = "Training";
    csvHeaders[1].label = "";
    csvHeaders[2].label = "";
    csvData.push({ name: this.state.csvNameLabel, version: this.state.csvVersionLabel, createdByUser: this.state.csvCreatedByLabel });
    csvData = csvData.concat(this.state.parents);

    this.setState({ csvData: csvData });
  };

  handleTabChange(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab,
      });
    }
  }

  render() {
    const { t, changeModalState } = this.props;
    const { saving } = this.state;

    const renderActiveDeactivateButton = () => {
      if (this.state.selectedLearningPath?.id) {
        if (this.state.selectedLearningPath?.isActive) {
          if (this.showDeactivate()) {
            return (
              <Button className="mb-2 me-2" outline color="danger" onClick={this.changeActiveModalState}>
                {t("LEARNINGPATH.DEACTIVATE_BUTTON")}
              </Button>
            );
          }
        } else {
          return (
            <Button className="mb-2 me-2 " outline color="success" onClick={this.changeActiveModalState}>
              {t("LEARNINGPATH.ACTIVATE_BUTTON")}
            </Button>
          );
        }
      }
    };

    return (
      <Fragment>
        <Modal
          isOpen={true}
          fade={false}
          toggle={() => changeModalState(false)}
          style={{
            maxWidth: "3000px",
            margin: "0.75rem 0",
          }}
        >
          <ModalHeader toggle={() => changeModalState(false)}>
            {this.state.selectedLearningPath?.id ? t("LEARNINGPATH.EDIT_MODAL_TITLE") : t("LEARNINGPATH.CREATE_MODAL_TITLE")}
          </ModalHeader>
          <ModalBody style={{ height: "570px" }}>
            <LoadingOverlay
              tag="div"
              active={saving}
              styles={{
                overlay: (base) => ({
                  ...base,
                  background: "#fff",
                  opacity: 0.5,
                }),
              }}
              spinner={<Loader active />}
            >
              <div className="card p-fluid">
                <div style={{ margin: "0 10px" }}>
                  <Row>
                    <Nav style={{ padding: "0.4rem 0.75rem" }} pills>
                      <NavItem>
                        <NavLink
                          href="#"
                          className={classnames({
                            active: this.state.activeTab === "propertyTab",
                          })}
                          onClick={() => {
                            this.handleTabChange("propertyTab");
                          }}
                        >
                          {t("LEARNINGPATH.PROPERTIES")}
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          href="#"
                          className={classnames({
                            active: this.state.activeTab === "contentTab",
                          })}
                          onClick={() => {
                            this.handleTabChange("contentTab");
                          }}
                        >
                          {t("LEARNINGPATH.CONTENT")}
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          href="#"
                          className={classnames({
                            active: this.state.activeTab === "recommendationTab",
                          })}
                          onClick={() => {
                            this.handleTabChange("recommendationTab");
                          }}
                        >
                          {t("LEARNINGPATH.RECOMMENDATION_LIST")}
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          href="#"
                          className={classnames({
                            active: this.state.activeTab === "scheduleTab",
                          })}
                          onClick={() => {
                            this.handleTabChange("scheduleTab");
                          }}
                        >
                          {t("LEARNINGPATH.SCHEDULE")}
                        </NavLink>
                      </NavItem>
                    </Nav>
                    <TabContent activeTab={this.state.activeTab} style={{ padding: "0.2rem" }}>
                      <TabPane tabId="propertyTab">
                        {this.state.selectedLearningPath && (
                          <CreateUpdateTabContent
                            readOnly={!this.isEditable()}
                            learningPath={this.state.selectedLearningPath}
                            onChangeLearningPath={this.changeSelectedLearningPath}
                          />
                        )}
                      </TabPane>
                      <TabPane tabId="contentTab">
                        {this.state.selectedLearningPath && this.state.learningPathItems && !this.state.loading && (
                          <ModuleTabContent
                            readOnly={!this.isEditable()}
                            learningPath={this.state.selectedLearningPath}
                            learningPathItems={this.state.learningPathItems}
                            deletedLearninPathItems={this.state.deletedLearninPathItems}
                            onLearningPathItemChange={(e) => this.onLearningPathItemChange(e)}
                          />
                        )}
                      </TabPane>
                      <TabPane tabId="recommendationTab">
                        {this.state.selectedLearningPath && this.state.learningPathItems && !this.state.loading && (
                          <RecommendationTabContent learningPath={this.state.selectedLearningPath} />
                        )}
                      </TabPane>

                      <TabPane tabId="scheduleTab">
                        {this.state.selectedLearningPath && this.state.learningPathItems && !this.state.loading && (
                          <ScheduleTabContent
                            readOnly={!this.isEditable()}
                            learningPath={this.state.selectedLearningPath}
                            learningPathItems={this.state.learningPathItems}
                            schedules={this.state.schedules}
                            onScheduleChange={(e) => this.onScheduleChange(e)}
                          />
                        )}
                      </TabPane>
                    </TabContent>
                  </Row>
                </div>
              </div>
            </LoadingOverlay>
          </ModalBody>
          <ModalFooter>
            <Modal isOpen={this.state.activeModal} toggle={this.changeActiveModalState} style={{ marginTop: "3rem", width: "50%" }}>
              <ModalHeader>
                {this.state.selectedLearningPath?.isActive ? t("LEARNINGPATH.CONFIRM_DEACTIVATE_MODAL_TITLE") : t("LEARNINGPATH.CONFIRM_ACTIVATE_MODAL_TITLE")}
              </ModalHeader>
              <ModalFooter>
                <Button className="mb-2 me-2   " color="secondary" onClick={this.changeActiveModalState}>
                  {t("LEARNINGPATH.NO_BUTTON")}
                </Button>
                <Button
                  className="mb-2 me-2  "
                  color="primary"
                  onClick={this.state.selectedLearningPath?.isActive ? this.confirmDeactivateLearningPath : this.confirmActivateLearningPath}
                >
                  {t("LEARNINGPATH.YES_BUTTON")}
                </Button>
              </ModalFooter>
            </Modal>

            <Modal isOpen={this.state.deleteModal} toggle={this.changeDeleteModalState} style={{ marginTop: "50px", width: "60%" }}>
              <ModalHeader> {this.state.parents.length > 0 ? t("LEARNINGPATH.DELETE_MODAL_TITLE") : t("LEARNINGPATH.CONFIRM_DELETE_MODAL_TITLE")}</ModalHeader>
              <ModalBody style={{ minHeight: "auto" }}>
                {this.state.parents.length > 0 ? (
                  <div>
                    <PerfectScrollbar>
                      {this.state.parents.length > 0 ? (
                        <Card className="main-card mb-3">
                          <CardTitle>{t("LEARNINGPATH.IS_TRAINING_COLUMN_NAME")}</CardTitle>
                          <DataTable value={this.state.parents} paginator rows={10}>
                            <Column field="name" header={t("LEARNINGPATH.NAME_COLUMN_NAME")}></Column>
                            <Column field="version" header={t("LEARNINGPATH.VERSION")}></Column>
                            <Column field="createdByUser" header={t("LEARNINGPATH.CREATED_BY_COLUMN_NAME")}></Column>
                          </DataTable>
                        </Card>
                      ) : (
                        ""
                      )}
                    </PerfectScrollbar>
                  </div>
                ) : (
                  ""
                )}
              </ModalBody>
              <ModalFooter>
                <Col>
                  {this.state.parents.length > 0 ? (
                    <CSVLink
                      data={this.state.csvData}
                      filename={"delete_training_" + this.state.selectedLearningPath.name + ".csv"}
                      headers={this.state.csvHeaders}
                      className="mb-2 me-2 btn btn-success"
                      target="_blank"
                    >
                      {t("LEARNINGPATH.EXPORT_BUTTON")}
                    </CSVLink>
                  ) : (
                    ""
                  )}
                </Col>

                {this.state.parents.length > 0 ? (
                  <div>
                    <Button className="mb-2 me-2 " color="secondary" onClick={this.changeDeleteModalState}>
                      {t("LEARNINGPATH.CANCEL_BUTTON")}
                    </Button>
                  </div>
                ) : (
                  <div>
                    <Button className="mb-2 me-2 " color="secondary" onClick={this.changeDeleteModalState}>
                      {t("LEARNINGPATH.NO_BUTTON")}
                    </Button>
                    <Button className="mb-2 me-2 " color="primary" onClick={() => this.confirmDeleteLearningPath()}>
                      {t("LEARNINGPATH.YES_BUTTON")}
                    </Button>
                  </div>
                )}
              </ModalFooter>
            </Modal>

            <Modal isOpen={this.state.publishModal} toggle={this.changePublishModalState} style={{ marginTop: "3rem", width: "50%" }}>
              <ModalHeader>{t("LEARNINGPATH.PUBLISH_MODAL_TITLE")}</ModalHeader>
              <ModalFooter>
                <Button className="mb-2 me-2   " color="secondary" onClick={this.changePublishModalState}>
                  {t("LEARNINGPATH.NO_BUTTON")}
                </Button>
                <Button className="mb-2 me-2  " color="primary" onClick={this.confirmPublishLearningPath}>
                  {t("LEARNINGPATH.YES_BUTTON")}
                </Button>
              </ModalFooter>
            </Modal>

            <Modal isOpen={this.state.updatePublishModal} toggle={this.changeUpdatePublishModalState} style={{ marginTop: "3rem", width: "50%" }}>
              <ModalHeader>{t("LEARNINGPATH.CHANGE_PUBLISH_MODAL_TITLE")}</ModalHeader>
              <ModalFooter>
                <Button className="mb-2 me-2   " color="secondary" onClick={this.changeUpdatePublishModalState}>
                  {t("LEARNINGPATH.NO_BUTTON")}
                </Button>
                <Button className="mb-2 me-2  " color="primary" onClick={this.confirmUpdatePublishLearningPath}>
                  {t("LEARNINGPATH.YES_BUTTON")}
                </Button>
              </ModalFooter>
            </Modal>

            {this.state.selectedLearningPath &&
              (!this.isEditable() ? (
                <>
                  <>
                    <Col>
                      {!this.state.selectedLearningPath.isTraining && (
                        <Button disabled={!this.state.selectedLearningPath?.isActive} className="mb-2 me-2" color="primary" onClick={this.changePublishModalState}>
                          {t("LEARNINGPATH.PUBLISH_BUTTON")}
                        </Button>
                      )}
                      {this.state.selectedLearningPath?.id ? (
                        <Button disabled={this.state.selectedLearningPath?.isActive} className="mb-2 me-2" color="danger" onClick={this.openDeleteModel}>
                          {t("LEARNINGPATH.DELETE_BUTTON")}
                        </Button>
                      ) : (
                        ""
                      )}
                      {renderActiveDeactivateButton()}
                    </Col>
                  </>
                  <Button className="mb-2 me-2" color="secondary" onClick={() => changeModalState(false)}>
                    {t("LEARNINGPATH.CLOSE_BUTTON")}
                  </Button>
                </>
              ) : (
                <>
                  <Col>
                    <Button disabled={!this.state.selectedLearningPath?.isActive} className="mb-2 me-2" color="primary" onClick={this.changePublishModalState}>
                      {t("LEARNINGPATH.PUBLISH_BUTTON")}
                    </Button>

                    {this.state.selectedLearningPath?.hasChildren && (
                      <Button disabled={!this.isEditable()} className="mb-2 me-2" color="primary" onClick={() => this.changeUpdatePublishModalState()}>
                        {t("LEARNINGPATH.UPDATE_LATEST_VERSION_BUTTON")}
                      </Button>
                    )}

                    {this.state.selectedLearningPath?.id ? (
                      <Button disabled={this.state.selectedLearningPath?.isActive} className="mb-2 me-2" color="danger" onClick={this.openDeleteModel}>
                        {t("LEARNINGPATH.DELETE_BUTTON")}
                      </Button>
                    ) : (
                      ""
                    )}
                    {renderActiveDeactivateButton()}
                  </Col>

                  <Button className="mb-2 me-2" color="secondary" onClick={() => changeModalState(false)}>
                    {t("LEARNINGPATH.CANCEL_BUTTON")}
                  </Button>
                  {this.props.learningPathId ? (
                    <Button className="mb-2 me-2" color="primary" onClick={(e) => this.update(e)}>
                      {t("LEARNINGPATH.UPDATE_BUTTON")}
                    </Button>
                  ) : (
                    <Button className="mb-2 me-2" color="primary" onClick={(e) => this.create(e)}>
                      {t("LEARNINGPATH.SAVE_BUTTON")}
                    </Button>
                  )}
                </>
              ))}
          </ModalFooter>
        </Modal>
      </Fragment>
    );
  }
}
export default withTranslation()(MainModal);
