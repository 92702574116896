import React, { Component } from "react";

class ImagePreview extends Component {
  constructor(props) {
    super(props);
    this.state = {
      base64: {},
    };
  }

  componentDidMount() {
    this.getBase64(this.props.file);
  }

  getBase64 = (fileObject) => {
    let reader = new FileReader();
    reader.readAsDataURL(fileObject);
    reader.onload = () => {
      var base64 = reader.result.substring(reader.result.indexOf(",") + 1);
      this.setState({ base64: base64 });
    };
  };

  render() {
    const { file, style } = this.props;

    return (
      <div
        style={{
          textAlign: "center",
        }}
      >
        {this.state.base64 && <img src={"data:image/jpeg;base64," + this.state.base64} style={style} alt={file.name} />}
      </div>
    );
  }
}
export default ImagePreview;
