import React, { Fragment } from "react";
import { connect } from "react-redux";

import { Slider } from "react-burgers";

import AppMobileMenu from "../AppMobileMenu";

import { setEnableClosedSidebar, setEnableMobileMenu, setEnableMobileMenuSmall } from "../../reducers/ThemeOptions";
import CompanyService from "../../service/CompanyService";
import tokenService from "../../service/tokenService";

class HeaderLogo extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      active: false,
      mobile: false,
      activeSecondaryMenuMobile: false,
    };
  }

  componentDidMount() {
    let user = tokenService.getUser();
    if (!this.props.company && user) {
      this.getCompanyById(user.currentCompanyId);
    }
  }

  getCompanyById(id) {
    CompanyService.getCompanyById(id)
      .then((response) => {
        this.setState({
          company: response.data,
        });
        tokenService.setCompany(response.data);
      })
      .catch((e) => {
        console.log(e);
      });
  }

  toggleEnableClosedSidebar = () => {
    let { enableClosedSidebar, setEnableClosedSidebar } = this.props;
    setEnableClosedSidebar(!enableClosedSidebar);
  };

  state = {
    openLeft: false,
    openRight: false,
    relativeWidth: false,
    width: 280,
    noTouchOpen: false,
    noTouchClose: false,
  };

  render() {
    return (
      <Fragment>
        <div className="app-header__logo">
          <div>
            {this.props.company ? (
              <h4 style={{ marginTop: "8px" }}>
                <img style={{ height: "30px" }} src={"data:image/png;base64," + this.props.company.logo} alt="" /> {this.props.company.name ?? "Matrix2Learn"}
              </h4>
            ) : (
              <h4 style={{ marginTop: "8px" }}>Matrix2Learn</h4>
            )}
          </div>
          <div className="header__pane ms-auto">
            <div onClick={this.toggleEnableClosedSidebar}>
              <Slider width={26} lineHeight={2} lineSpacing={5} color="#6c757d" active={this.state.active} onClick={() => this.setState({ active: !this.state.active })} />
            </div>
          </div>
        </div>
        <AppMobileMenu />
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  enableClosedSidebar: state.ThemeOptions.enableClosedSidebar,
  enableMobileMenu: state.ThemeOptions.enableMobileMenu,
  enableMobileMenuSmall: state.ThemeOptions.enableMobileMenuSmall,
});

const mapDispatchToProps = (dispatch) => ({
  setEnableClosedSidebar: (enable) => dispatch(setEnableClosedSidebar(enable)),
  setEnableMobileMenu: (enable) => dispatch(setEnableMobileMenu(enable)),
  setEnableMobileMenuSmall: (enable) => dispatch(setEnableMobileMenuSmall(enable)),
});

export default connect(mapStateToProps, mapDispatchToProps)(HeaderLogo);
