import React, { useEffect, useRef } from "react";
import List from "@material-ui/core/List";
import t from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import { Avatar, Badge } from "@material-ui/core";
import withStyles from "@material-ui/core/styles/withStyles";
import IconButton from "@material-ui/core/IconButton";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import useTheme from "@material-ui/core/styles/useTheme";
import { yellow } from "@material-ui/core/colors";

import("screw-filereader");

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    // "& > *": {
    //   margin: theme.spacing(1),
    // },
  },
  form: {
    display: "flex",
    flexDirection: "column",
    // margin: "auto",
    width: "fit-content",
  },
  input: {
    fontSize: 15,
  },
  large: {
    width: theme.spacing(25),
    height: theme.spacing(25),
    border: `4px solid #6c757d`,
  },
}));

const EditIconButton = withStyles((theme) => ({
  root: {
    width: 22,
    height: 22,
    padding: 15,
    border: `2px solid #6c757d`,
  },
}))(IconButton);

export const AvatarPicker = (props) => {
  const [base64String, setBase64String] = React.useState(props.base64Image);

  const classes = useStyles();
  const imageRef = useRef();

  const { handleChangeImage, base64Image, style, readOnly } = props;

  useEffect(() => {
    setBase64String(base64Image?.replace("_", "/"));
  }, [base64Image]);

  const renderImage = (fileObject) => {
    getBase64(fileObject);
  };

  const showOpenFileDialog = () => {
    imageRef.current.click();
  };

  const DeleteImage = () => {
    setBase64String(null);
    handleChangeImage(null);
  };

  const handleChange = (event) => {
    const fileObject = event?.target?.files[0];
    if (!fileObject) return;
    renderImage(fileObject);
  };
  const getBase64 = (fileObject) => {
    let reader = new FileReader();
    reader.readAsDataURL(fileObject);
    reader.onload = function () {
      var base64String = reader.result.substring(reader.result.indexOf(",") + 1);
      setBase64String(base64String);
      base64String = base64String.replace("/", "_");
      fileObject.image().then((img) => {
        const canvas = document.createElement("canvas");
        const ctx = canvas.getContext("2d");
        const maxWidth = 256;
        const maxHeight = 256;

        const ratio = Math.min(maxWidth / img.width, maxHeight / img.height);
        const width = (img.width * ratio + 0.5) | 0;
        const height = (img.height * ratio + 0.5) | 0;

        canvas.width = width;
        canvas.height = height;
        ctx.drawImage(img, 0, 0, width, height);

        canvas.toBlob((blob) => {
          const resizedFile = new File([blob], base64String, fileObject);
          handleChangeImage(resizedFile);
        });
      });
    };
    reader.onerror = function (error) {
      console.log("Error: ", error);
    };
  };
  return (
    <List data-testid={"image-upload"}>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
        }}
      >
        <div className={classes.root}>
          {readOnly ? (
            <Avatar alt={"avatar"} src={"data:image/*;base64," + base64String} className={classes.large} style={style} />
          ) : (
            <Badge
              overlap="circular"
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
              }}
              badgeContent={
                <>
                  <EditIconButton onClick={showOpenFileDialog} style={{ background: "#6c757d", marginRight: "5px" }}>
                    <EditIcon />
                  </EditIconButton>
                  <EditIconButton onClick={DeleteImage} style={{ background: "#6c757d" }}>
                    <DeleteIcon />
                  </EditIconButton>
                </>
              }
            >
              <Avatar alt={"avatar"} src={"data:image/*;base64," + base64String} className={classes.large} style={style} />
            </Badge>
          )}
          <input ref={imageRef} type="file" style={{ display: "none" }} accept="image/*" onChange={handleChange} />
        </div>
      </div>
    </List>
  );
};

AvatarPicker.propTypes = {
  handleChangeImage: t.func.isRequired,
  avatarImage: t.object,
};

export default AvatarPicker;
