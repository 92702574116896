import React, { useEffect, Fragment } from "react";
import { Route } from "react-router-dom";
import AppHeader from "../../Layout/AppHeader";
import AppSidebar from "../../Layout/AppSidebar";
import ThemeOptions from "../../Layout/ThemeOptions";

import Profile from "../UserPages/Profile";
import Company from "./Company";
import User from "./User";

const OrganizationPages = ({ match }) => {
  return (
    <Fragment>
      <ThemeOptions />
      <AppHeader />
      <div className="app-main">
        <AppSidebar />
        <div className="app-main__outer">
          <div className="app-main__inner">
            <Route path={`${match.url}/user`} component={User} />
            <Route path={`${match.url}/company`} component={Company} />
            <Route path={`${match.url}/profile`} component={Profile} />
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default OrganizationPages;
