import React, { Fragment } from "react";
import { Route } from "react-router-dom";
import AppHeader from "../../Layout/AppHeader";
import AppSidebar from "../../Layout/AppSidebar";
import ThemeOptions from "../../Layout/ThemeOptions";

import LearningObject from "./LearningObject";
import LearningPath from "./LearningPath";

const LearningPages = ({ match }) => (
  <Fragment>
    <ThemeOptions />
    <AppHeader />
    <div className="app-main">
      <AppSidebar />
      <div className="app-main__outer">
        <div className="app-main__inner">
          <Route path={`${match.url}/learning-path`} component={LearningPath} />
          <Route path={`${match.url}/learning-object`} component={LearningObject} />
        </div>
      </div>
    </div>
  </Fragment>
);

export default LearningPages;
