import { Route, Redirect, Switch } from "react-router-dom";
import React, { Suspense, useEffect, Fragment } from "react";

import { ToastContainer } from "react-toastify";
import MetadataPages from "../../Pages/Metadata";
import OrganizationPages from "../../Pages/Organization";
import LearningPages from "../../Pages/Learning";
import UserPages from "../../Pages/UserPages";
import tokenService from "../../service/tokenService";
import TrainingPages from "../../Pages/TrainingPages";
import ViewerPages from "../../Pages/Viewer";

const AppMain = (props) => {
  const redirect = () => {
    let user = tokenService.getUser();
    if (user) {
      if (user.currentRole === "Trainer") {
        let trainings = tokenService.getTrainings();
        if (trainings && trainings.length > 0) {
          return <Redirect to={`/training/trainings/${trainings[0].id}`} />;
        } else {
          return <Redirect to="/training/training-list" />;
        }
      } else if (user.currentRole === "MasterTrainer") {
        return <Redirect to="/learning/learning-path" />;
      } else if (user.currentRole === "ContentAdmin") {
        return <Redirect to="/learning/learning-object" />;
      } else {
        return <Redirect to="/organization/user" />;
      }
    } else {
      return <Redirect to="/auth/login" />;
    }
  };

  return (
    <Fragment>
      <Suspense fallback={false}>
        <Switch>
          <Route path="/metadata" component={MetadataPages} />

          <Route path="/organization" component={OrganizationPages} />

          <Route path="/learning" component={LearningPages} />

          <Route path="/auth" component={UserPages} />

          <Route path="/training" component={TrainingPages} />

          <Route path="/viewer" component={ViewerPages} />

          <Route exact path="/" render={() => redirect()} />
        </Switch>
      </Suspense>

      <ToastContainer />
    </Fragment>
  );
};

export default AppMain;
