import React, { Fragment, Component } from "react";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import { withTranslation } from "react-i18next";
import PageTitle from "../../../Layout/AppMain/PageTitle";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import PerfectScrollbar from "react-perfect-scrollbar";
import {
  UncontrolledDropdown,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
  Row,
  Col,
  Card,
  CardBody,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input,
  FormGroup,
  Form,
  Label,
  Button,
  CardTitle,
} from "reactstrap";
import RecommendationService from "../../../service/RecommendationService";
import { FilterMatchMode } from "primereact/api";
import authService from "../../../service/authService";
import { Calendar } from "primereact/calendar";
import Flag from "react-flagkit";
import Select from "react-select";
import "../Attribute/DataTable.css";
import { classNames } from "primereact/utils";
import { TriStateCheckbox } from "primereact/tristatecheckbox";
import { InputText } from "primereact/inputtext";
import LearningObjectService from "../../../service/LearningObjectService";
import moment from "moment/moment";
import languages from "../../../models/Languages";
import { CSVLink } from "react-csv";
import tokenService from "../../../service/tokenService";

class Recommendation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loggedUser: tokenService.getUser(),
      recommendations: [],
      filteredRecommendations: [],
      selectedRecommendation: {},
      learningObjects: [],
      modal: false,
      deleteModal: false,
      activeModal: false,

      selectedFilteringTableLanguage: languages.find((obj) => obj.lang === this.props.i18n.language),
      filterRecommendationDropdownLanguages: languages,

      creatingRecommendationTranslationLanguages: languages,
      selectedCreatingRecommendationTranslationLanguage: languages[0],
      selectedEditingRecommendationTranslationLanguage: "",

      creatingRecommendationTranslationName: "", //need join
      creatingRecommendationTranslationComment: "", //

      filterRecommendationsTemplate: {
        name: { value: null, matchMode: FilterMatchMode.CONTAINS },
        userName: { value: null, matchMode: FilterMatchMode.CONTAINS },
        comment: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
        createdDate: { value: null, matchMode: FilterMatchMode.DATE_IS },
        createdByUser: { value: null, matchMode: FilterMatchMode.CONTAINS },
        isPublic: { value: tokenService.getCompany().showPublicRecommendation ? null : false, matchMode: FilterMatchMode.EQUALS },
        isActive: { value: null, matchMode: FilterMatchMode.EQUALS },
      },
      isError: {},

      recommendationTranslations: [],

      csvHeaders: [
        { label: "Name", key: "name" },
        { label: "Created By", key: "createdByUser" },
      ],
    };
  }

  componentDidMount() {
    this.getAllRecommendations();
  }

  getRecommendationById(id) {
    return RecommendationService.getRecommendationById(id)
      .then((response) => {
        this.setState({ selectedRecommendation: response.data });
        this.setState({
          recommendationTranslations: response.data.translations,
        });
        this.filterRecommendationDropdownLanguages();
      })
      .catch((e) => {
        console.log(e);
      });
  }

  getLearningObjectByRecommendationId(id) {
    return LearningObjectService.getByRecommendationId(id)
      .then((response) => {
        var learningObjects = response.data.map(({ translations, createdByUser, isActive, isPublic }) => ({
          name:
            translations.find((obj) => obj.language === this.state.selectedFilteringTableLanguage.lang)?.name + (!isActive ? " (inactive) " : " ") + (isPublic ? " (public)" : ""),
          createdByUser: createdByUser,
        }));

        this.setState({
          learningObjects: learningObjects.filter((t) => !t.name.startsWith("undefined")),
        });
      })
      .catch((e) => {
        console.log(e);
      });
  }

  confirmDeactivateRecommendation = () => {
    RecommendationService.deactivate(this.state.selectedRecommendation.id)
      .then((response) => {
        var selected = this.state.selectedRecommendation;
        selected.isActive = false;
        this.setState({ selectedRecommendation: selected });
        this.changeActiveModalState();
        this.getAllRecommendations();
      })
      .catch((e) => {
        console.log(e);
      });
  };

  confirmActivateRecommendation = () => {
    RecommendationService.activate(this.state.selectedRecommendation.id).then(() => {
      var selected = this.state.selectedRecommendation;
      selected.isActive = true;
      this.setState({ selectedRecommendation: selected });
      this.changeActiveModalState();
      this.getAllRecommendations();
    });
  };

  confirmDeleteRecommendation = () => {
    RecommendationService.delete(this.state.selectedRecommendation.id)
      .then((response) => {
        this.getAllRecommendations().then(() => {
          this.changeDeleteModalState();
          this.changeModalState();
        });
      })
      .catch((e) => {
        console.log(e);
      });
  };

  getAllRecommendations = () => {
    return RecommendationService.getAllRecommendations()
      .then((response) => {
        this.setState({
          recommendations: response.data,
        });
        this.filterRecommendations(this.state.selectedFilteringTableLanguage.lang);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  filterRecommendations(lang) {
    var filteredRecommendations = this.state.recommendations.map(({ id, translations, createdDate, isPublic, isActive, createdByUser }) => ({
      language: translations.find((obj) => obj.language === lang)?.language,
      name: translations.find((obj) => obj.language === lang)?.name,
      comment: translations.find((obj) => obj.language === lang)?.comment,
      createdByUser: createdByUser,
      isPublic: isPublic,
      isActive: isActive,
      id: id,
      createdDate: moment(new Date(createdDate)).format("DD.MM.YYYY"),
    }));
    this.setState({
      filteredRecommendations: filteredRecommendations.filter((obj) => obj.language),
    });
  }

  updateRecommendation(e) {
    e.preventDefault();
    let recommendation = this.state.selectedRecommendation;
    recommendation.isActive = true;
    recommendation.translations = this.state.recommendationTranslations;
    RecommendationService.update(recommendation)
      .then((response) => {
        this.changeModalState();
        this.setState({
          selectedRecommendation: {},
        });
        this.getAllRecommendations();
      })
      .catch((e) => {
        console.log(e);
      });
  }

  createNewRecommendation(e) {
    e.preventDefault();
    var cat = this.state.selectedRecommendation;
    cat.translations = this.state.recommendationTranslations;
    this.setState({
      selectedRecommendation: cat,
    });
    this.setState((prevState) => ({
      selectedRecommendation: {
        ...prevState.selectedRecommendation,
        isPublic: this.state.selectedRecommendation.isPublic,
      },
    }));

    RecommendationService.insert(this.state.selectedRecommendation)
      .then((response) => {
        this.changeModalState();
        this.setState({ selectedRecommendation: {} });
        this.getAllRecommendations();
      })
      .catch((e) => {
        console.log(e);
      });
  }

  isEditable = () => {
    return !this.state.selectedRecommendation.isPublic || this.isM2LAdmin();
  };

  isM2LAdmin = () => {
    return this.state.loggedUser?.currentRole === "SystemAdmin" && this.state.loggedUser?.currentCompanyId === null;
  };

  changeModalState = () => {
    this.setState({
      modal: !this.state.modal,
      isError: {},
      creatingRecommendationTranslationName: "",
      creatingRecommendationTranslationComment: "",
    });
  };

  changeActiveModalState = () => {
    this.setState({
      activeModal: !this.state.activeModal,
    });
  };

  changeDeleteModalState = () => {
    this.setState({
      deleteModal: !this.state.deleteModal,
    });
  };

  changePublic = (e) => {
    this.setState((prevState) => ({
      selectedRecommendation: {
        ...prevState.selectedRecommendation,
        isPublic: e.target.checked,
      },
    }));
  };

  onChangeCreatingRecommendationTranslationLanguage = (e) => {
    this.setState({ selectedCreatingRecommendationTranslationLanguage: e });
  };

  onChangeEditingRecommendationTranslationLanguage = (e) => {
    this.setState({ selectedEditingRecommendationTranslationLanguage: e });
  };

  onChangeFilteringTableLanguage = (e) => {
    this.setState({ selectedFilteringTableLanguage: e });
    this.filterRecommendations(e.lang);
  };

  changeCreatingRecommendationTranslationComment = (e) => {
    this.setState({ creatingRecommendationTranslationComment: e.target.value });
    this.createNewTranslationValueChange(e);
  };

  changeCreatingRecommendationTranslationName = (e) => {
    this.setState({ creatingRecommendationTranslationName: e.target.value });
    this.createNewTranslationValueChange(e);
  };

  filterRecommendationDropdownLanguages = (e) => {
    let filteredRecommendationLanguages = this.state.recommendationTranslations.map((obj) => {
      return obj.language;
    });
    let creatingRecommendationTranslationLanguages = languages.filter((obj) => !filteredRecommendationLanguages.includes(obj.lang));

    this.setState({
      creatingRecommendationTranslationLanguages: creatingRecommendationTranslationLanguages,
      selectedCreatingRecommendationTranslationLanguage: creatingRecommendationTranslationLanguages[0],
    });
  };

  createNewRecommendationTranslation = (e) => {
    e.preventDefault();
    var isError = { ...this.state.isError };
    isError.name = this.state.creatingRecommendationTranslationName.length < 1 ? "Required" : "";
    this.setState({ isError });
    if (this.createNewTranslationFormValid({ isError: isError })) {
      var newRecommendationTranslation = {
        language: this.state.selectedCreatingRecommendationTranslationLanguage.lang,
        name: this.state.creatingRecommendationTranslationName,
        comment: this.state.creatingRecommendationTranslationComment,
      };
      this.state.recommendationTranslations.push(newRecommendationTranslation);
      this.setState({
        recommendationTranslations: this.state.recommendationTranslations,
      });
      this.filterRecommendationDropdownLanguages();

      this.setState({ creatingRecommendationTranslationComment: "" });
      this.setState({ creatingRecommendationTranslationName: "" });
    }
  };

  dateFilterTemplate = (options) => {
    return (
      <Calendar value={new Date(options.value).toLocaleString()} onChange={(e) => options.filterCallback(e.value, options.index)} dateFormat="mm/dd/yy" placeholder="mm/dd/yyyy" />
    );
  };
  dateRecommendationsTemplate = (rowData) => {
    return rowData.createdDate;
  };
  createNewTranslationFormValid = ({ isError, ...rest }) => {
    let isValid = true;
    Object.values(isError).forEach((val) => {
      if (isValid === false) {
        return;
      }
      if (val.length > 0) {
        isValid = false;
      } else {
        isValid = true;
      }
    });
    Object.values(rest).forEach((val) => {
      if (isValid === false) {
        return;
      }
      if (val === null) {
        isValid = false;
      } else {
        isValid = true;
      }
    });
    return isValid;
  };
  createNewTranslationValueChange = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    let isError = { ...this.state.isError };
    switch (name) {
      case "name":
        isError.name = value.length < 1 ? "Required" : "";
        break;
      default:
        break;
    }
    this.setState({
      isError,
      [name]: value,
    });
  };

  openDeactivateModel = () => {
    this.getLearningObjectByRecommendationId(this.state.selectedRecommendation.id).then(() => {
      this.changeActiveModalState();
    });
  };

  openDeleteModel = () => {
    this.getLearningObjectByRecommendationId(this.state.selectedRecommendation.id).then(() => {
      this.changeDeleteModalState();
    });
  };

  render() {
    const { t } = this.props;

    const renderActiveDeactivateButton = () => {
      if (this.state.selectedRecommendation.id) {
        if (this.state.selectedRecommendation.isActive) {
          return (
            <Button className="mb-2 me-2 " outline color="danger" onClick={this.openDeactivateModel}>
              {t("RECOMMENDATION.DEACTIVATE_BUTTON")}
            </Button>
          );
        } else {
          return (
            <Button className="mb-2 me-2 " outline color="success" onClick={this.changeActiveModalState}>
              {t("RECOMMENDATION.ACTIVATE_BUTTON")}
            </Button>
          );
        }
      }
    };

    const countryBodyTemplate = (rowData) => {
      return (
        <div className="country-item" style={{ display: "flex" }}>
          <div>{languages.find((obj) => obj.lang === rowData.language)?.name}</div>
        </div>
      );
    };

    const languageEditorTemplate = (options) => {
      return (
        <Select
          options={this.state.creatingRecommendationTranslationLanguages}
          onChange={this.onChangeEditingRecommendationTranslationLanguage}
          getOptionLabel={(option) => option.name}
          getOptionValue={(option) => option.name}
          key={languages.find((obj) => obj.lang === options.rowData?.language)}
          defaultValue={languages.find((obj) => obj.lang === options.rowData?.language)}
        />
      );
    };

    const openUpdateRecommendationTranslationModal = (id) => {
      this.getRecommendationById(id);
      this.changeModalState();
    };

    const openCreateRecommendation = () => {
      var recommendation = {};
      recommendation.createdByUser = this.state.loggedUser.firstName + " " + this.state.loggedUser.lastName;
      recommendation.isPublic = this.isM2LAdmin();
      recommendation.isActive = true;
      recommendation.createdDate = new Date();
      this.setState(
        {
          selectedRecommendation: recommendation,
          recommendationTranslations: [],
          creatingRecommendationTranslationLanguages: languages,
          selectedCreatingRecommendationTranslationLanguage: this.state.selectedFilteringTableLanguage,
        },
        () => {
          this.changeModalState();
        }
      );
    };

    const deleteRecommendationTranslation = (language) => {
      this.state.creatingRecommendationTranslationLanguages.push(languages.find((obj) => obj.lang === language));
      this.setState({
        recommendationTranslations: this.state.recommendationTranslations.filter((obj) => obj.language !== language),
        filterRecommendationDropdownLanguages: this.state.creatingRecommendationTranslationLanguages,
        selectedCreatingRecommendationTranslationLanguage: this.state.creatingRecommendationTranslationLanguages[0],
      });
    };

    const buttonDeleteRecommendationTranslation = (rowData) => {
      if (this.isEditable()) {
        return (
          <Button className=" " outline color="primary" onClick={() => deleteRecommendationTranslation(rowData.language)}>
            {t("RECOMMENDATION.DELETE_BUTTON")}
          </Button>
        );
      }
    };

    const buttonUpdateRecommendationTranslation = (rowData) => {
      return (
        <Button className="me-2" size="sm" outline color="primary" onClick={() => openUpdateRecommendationTranslationModal(rowData.id)}>
          {t("RECOMMENDATION.OPEN_BUTTON")}
        </Button>
      );
    };

    const formatIsPublicCheckBox = (rowData) => {
      return (
        <i
          className={classNames("pi", {
            "true-icon pi-check-circle": rowData.isPublic,
            "false-icon pi-times-circle": !rowData.isPublic,
          })}
        ></i>
      );
    };

    const formatIsActiveCheckBox = (rowData) => {
      return (
        <i
          className={classNames("pi", {
            "true-icon pi-check-circle": rowData.isActive,
            "false-icon pi-times-circle": !rowData.isActive,
          })}
        ></i>
      );
    };

    const languageTableFilter = (options) => {
      return (
        <div>
          <UncontrolledDropdown>
            <DropdownToggle className="p-0 me-2" color="black">
              <div className="icon-wrapper-bg bg-focus" />
              <div className="language-icon">
                <Flag className="me-3 opacity-8" country={this.state.selectedFilteringTableLanguage?.flag} size="30" />
              </div>
            </DropdownToggle>
            <DropdownMenu end className="rm-pointers">
              {languages.map((language, id) => (
                <DropdownItem key={id} value={language} onClick={() => this.onChangeFilteringTableLanguage(language)}>
                  <Flag className="me-3 opacity-8" country={language.flag} />
                  {language.name}
                </DropdownItem>
              ))}
            </DropdownMenu>
          </UncontrolledDropdown>
        </div>
      );
    };

    const languageBody = (option) => {
      return (
        <Flag
          className="me-3 opacity-8"
          style={{
            marginLeft: "0.75rem",
          }}
          country={languages.find((obj) => obj.lang === option.language)?.flag}
          size="30"
        />
      );
    };

    const verifiedRowFilterTemplate = (options) => {
      return <TriStateCheckbox value={options.value} onChange={(e) => options.filterApplyCallback(e.value)} />;
    };

    const textEditor = (options) => {
      return <InputText type="text" value={options.value} onChange={(e) => options.editorCallback(e.target.value)} />;
    };

    const onRowEditComplete = (e) => {
      let updateRecommendations = this.state.recommendationTranslations;
      let { newData, index } = e;
      newData.language = this.state.selectedEditingRecommendationTranslationLanguage.lang ? this.state.selectedEditingRecommendationTranslationLanguage.lang : e.data.language;
      updateRecommendations[index] = newData;
      this.setState({
        recommendationTranslations: updateRecommendations,
      });
      this.filterRecommendationDropdownLanguages();
    };

    const { isError } = this.state;
    return (
      <Fragment>
        <TransitionGroup>
          <CSSTransition component="div" classNames="TabsAnimation" appear={true} timeout={1500} enter={false} exit={false}>
            <div>
              <PageTitle heading={t("RECOMMENDATION.TITLE")} subheading={t("RECOMMENDATION.DESCRIPTION")} icon="pe-7s-medal icon-gradient bg-tempting-azure" />
              <Row>
                <Col md="12">
                  <Button className="mb-2 me-2  " outline color="primary" onClick={() => openCreateRecommendation()}>
                    {t("RECOMMENDATION.CREATE_BUTTON")}
                  </Button>
                  <Modal isOpen={this.state.modal} fade={false} toggle={this.changeModalState}>
                    <ModalHeader toggle={this.changeModalState}>
                      {this.state.selectedRecommendation?.id ? t("RECOMMENDATION.EDIT_MODAL_TITLE") : t("RECOMMENDATION.CREATE_MODAL_TITLE")}
                    </ModalHeader>
                    <ModalBody>
                      <div className="card p-fluid" style={{ fontWeight: "bold", textAlign: "center", padding: "10px" }}>
                        <Row>
                          <Col md="4">
                            <Label for="createdByUser">
                              {t("RECOMMENDATION.CREATED_BY")} {this.state.selectedRecommendation?.createdByUser}
                            </Label>
                          </Col>
                          <Col md="4">
                            <Label for="createdDate">
                              {t("RECOMMENDATION.CREATED_DATE")} {moment(new Date(this.state.selectedRecommendation?.createdDate)).format("DD.MM.YYYY")}
                            </Label>
                          </Col>
                          <Col md="4">
                            {this.isM2LAdmin() && (
                              <FormGroup check>
                                <Label check>
                                  <Input
                                    type="checkbox"
                                    checked={this.state.selectedRecommendation?.isPublic}
                                    disabled={!this.state.selectedRecommendation.isActive || this.state.loggedUser.currentRole !== "SystemAdmin"}
                                    onChange={this.changePublic}
                                  />
                                  {t("RECOMMENDATION.IS_PUBLIC")}
                                </Label>
                              </FormGroup>
                            )}
                          </Col>
                        </Row>
                      </div>
                      {this.isEditable() && (
                        <div className="card p-fluid">
                          <Form onSubmit={this.createNewRecommendationTranslation} style={{ margin: "10px" }} noValidate>
                            <Row>
                              <Col>
                                <FormGroup>
                                  <Label for="exampleSelect">{t("RECOMMENDATION.LANGUAGE")}</Label>
                                  <Select
                                    options={this.state.creatingRecommendationTranslationLanguages}
                                    value={this.state.selectedCreatingRecommendationTranslationLanguage ? this.state.selectedCreatingRecommendationTranslationLanguage : " "}
                                    onChange={this.onChangeCreatingRecommendationTranslationLanguage}
                                    getOptionLabel={(option) => option.name}
                                    getOptionValue={(option) => option.lang}
                                  />
                                </FormGroup>
                              </Col>
                              <Col>
                                <FormGroup>
                                  <Label for="name"> {t("RECOMMENDATION.NAME")}</Label>
                                  <Input
                                    type="text"
                                    name="name"
                                    id="name"
                                    onChange={this.changeCreatingRecommendationTranslationName}
                                    value={this.state.creatingRecommendationTranslationName}
                                    className={isError.name?.length > 0 ? "is-invalid form-control" : "form-control"}
                                  />
                                  {isError.name?.length > 0 && <span className="invalid-feedback">{isError?.name}</span>}
                                </FormGroup>
                              </Col>
                              <Col>
                                <FormGroup>
                                  <Label for="comment">{t("RECOMMENDATION.COMMENT")}</Label>
                                  <Input
                                    type="text"
                                    name="comment"
                                    id="comment"
                                    onChange={this.changeCreatingRecommendationTranslationComment}
                                    value={this.state.creatingRecommendationTranslationComment}
                                    className={"form-control"}
                                  />
                                </FormGroup>
                              </Col>

                              <Col>
                                <Button className="mb-2 me-2  " outline color="primary" style={{ marginTop: "30px" }} type="submit">
                                  {t("RECOMMENDATION.CREATE_BUTTON")}
                                </Button>
                              </Col>
                            </Row>
                          </Form>
                        </div>
                      )}

                      <div className="card p-fluid">
                        <DataTable
                          editMode="row"
                          value={this.state.recommendationTranslations}
                          dataKey="id"
                          className="p-datatable-customers"
                          onRowEditComplete={onRowEditComplete}
                          // responsiveLayout="scroll"
                        >
                          <Column
                            field="language"
                            header={t("RECOMMENDATION.LANGUAGE_COLUMN_NAME")}
                            body={countryBodyTemplate}
                            editor={(options) => languageEditorTemplate(options)}
                            style={{ zIndex: "2" }}
                          ></Column>
                          <Column field="name" header={t("RECOMMENDATION.NAME_COLUMN_NAME")} editor={(options) => textEditor(options)}></Column>
                          <Column field="comment" header={t("RECOMMENDATION.COMMENT_COLUMN_NAME")} editor={(options) => textEditor(options)}></Column>
                          <Column rowEditor={this.isEditable()} headerStyle={{ width: "10%", minWidth: "8rem" }} bodyStyle={{ textAlign: "center" }}></Column>
                          <Column body={buttonDeleteRecommendationTranslation} />
                        </DataTable>
                      </div>
                    </ModalBody>
                    <ModalFooter>
                      <Modal isOpen={this.state.activeModal} toggle={this.changeActiveModalState} style={{ marginTop: "50px", width: "60%" }}>
                        <ModalHeader>
                          {this.state.selectedRecommendation.isActive ? t("RECOMMENDATION.CONFIRM_DEACTIVATE_MODAL_TITLE") : t("RECOMMENDATION.CONFIRM_ACTIVATE_MODAL_TITLE")}
                        </ModalHeader>
                        <ModalBody style={{ minHeight: "auto" }}>
                          {this.state.selectedRecommendation.isActive && this.state.learningObjects.length > 0 ? (
                            <div>
                              <PerfectScrollbar>
                                {this.state.learningObjects.length > 0 ? (
                                  <Card className="main-card mb-3">
                                    <CardTitle>{t("RECOMMENDATION.LEARNING_OBJECTS")}</CardTitle>
                                    <DataTable value={this.state.learningObjects} paginator rows={10}>
                                      <Column field="name" header={t("RECOMMENDATION.LEARNING_OBJECT_NAME_COLUMN_NAME")}></Column>
                                      <Column field="createdByUser" header={t("RECOMMENDATION.LEARNING_OBJECT_CREATED_BY_COLUMN_NAME")}></Column>
                                    </DataTable>
                                  </Card>
                                ) : (
                                  ""
                                )}
                              </PerfectScrollbar>
                            </div>
                          ) : (
                            ""
                          )}
                        </ModalBody>
                        <ModalFooter>
                          <Col>
                            {this.state.selectedRecommendation.isActive && this.state.learningObjects.length > 0 ? (
                              <CSVLink
                                data={this.state.learningObjects}
                                filename={
                                  "deactivate_recommendation_" +
                                  this.state.recommendationTranslations.find((a) => a.language === this.state.selectedFilteringTableLanguage.lang)?.name +
                                  ".csv"
                                }
                                headers={this.state.csvHeaders}
                                className="mb-2 me-2 btn btn-success"
                                target="_blank"
                              >
                                {t("RECOMMENDATION.EXPORT_BUTTON")}
                              </CSVLink>
                            ) : (
                              ""
                            )}
                          </Col>
                          <Button className="mb-2 me-2 " color="secondary" onClick={this.changeActiveModalState}>
                            {t("RECOMMENDATION.NO_BUTTON")}
                          </Button>
                          <Button
                            className="mb-2 me-2 "
                            color="primary"
                            onClick={this.state.selectedRecommendation.isActive ? this.confirmDeactivateRecommendation : this.confirmActivateRecommendation}
                          >
                            {t("RECOMMENDATION.YES_BUTTON")}
                          </Button>
                        </ModalFooter>
                      </Modal>

                      <Modal isOpen={this.state.deleteModal} toggle={this.changeDeleteModalState} style={{ marginTop: "50px", width: "60%" }}>
                        <ModalHeader>{this.state.learningObjects.length > 0 ? t("RECOMMENDATION.DELETE_MODAL_TITLE") : t("RECOMMENDATION.CONFIRM_DELETE_MODAL_TITLE")}</ModalHeader>
                        <ModalBody style={{ minHeight: "auto" }}>
                          {this.state.learningObjects.length > 0 ? (
                            <div>
                              <PerfectScrollbar>
                                {this.state.learningObjects.length > 0 ? (
                                  <Card className="main-card mb-3">
                                    <CardTitle>{t("RECOMMENDATION.LEARNING_OBJECTS")}</CardTitle>
                                    <DataTable value={this.state.learningObjects} paginator rows={10}>
                                      <Column field="name" header={t("RECOMMENDATION.LEARNING_OBJECT_NAME_COLUMN_NAME")}></Column>
                                      <Column field="createdByUser" header={t("RECOMMENDATION.LEARNING_OBJECT_CREATED_BY_COLUMN_NAME")}></Column>
                                    </DataTable>
                                  </Card>
                                ) : (
                                  ""
                                )}
                              </PerfectScrollbar>
                            </div>
                          ) : (
                            ""
                          )}
                        </ModalBody>
                        <ModalFooter>
                          <Col>
                            {this.state.learningObjects.length > 0 ? (
                              <CSVLink
                                data={this.state.learningObjects}
                                filename={
                                  "delete_recommendation_" +
                                  this.state.recommendationTranslations.find((a) => a.language === this.state.selectedFilteringTableLanguage.lang)?.name +
                                  ".csv"
                                }
                                headers={this.state.csvHeaders}
                                className="mb-2 me-2 btn btn-success"
                                target="_blank"
                              >
                                {t("RECOMMENDATION.EXPORT_BUTTON")}
                              </CSVLink>
                            ) : (
                              ""
                            )}
                          </Col>
                          {this.state.learningObjects.length > 0 ? (
                            <div>
                              <Button className="mb-2 me-2 " color="secondary" onClick={this.changeDeleteModalState}>
                                {t("RECOMMENDATION.CANCEL_BUTTON")}
                              </Button>
                            </div>
                          ) : (
                            <div>
                              <Button className="mb-2 me-2 " color="secondary" onClick={this.changeDeleteModalState}>
                                {t("RECOMMENDATION.NO_BUTTON")}
                              </Button>
                              <Button className="mb-2 me-2 " color="primary" onClick={() => this.confirmDeleteRecommendation()}>
                                {t("RECOMMENDATION.YES_BUTTON")}
                              </Button>
                            </div>
                          )}
                        </ModalFooter>
                      </Modal>
                      {this.isEditable() ? (
                        <>
                          <Col>
                            {this.state.selectedRecommendation.id ? (
                              <Button disabled={this.state.selectedRecommendation.isActive} className="mb-2 me-2" color="danger" onClick={this.openDeleteModel}>
                                {t("RECOMMENDATION.DELETE_BUTTON")}
                              </Button>
                            ) : (
                              ""
                            )}
                            {renderActiveDeactivateButton()}
                          </Col>

                          <Button className="mb-2 me-2" color="secondary" onClick={this.changeModalState}>
                            {t("RECOMMENDATION.CANCEL_BUTTON")}
                          </Button>
                          {this.state.selectedRecommendation?.id ? (
                            <Button className="mb-2 me-2 " color="primary" onClick={(e) => this.updateRecommendation(e)}>
                              {t("RECOMMENDATION.UPDATE_BUTTON")}
                            </Button>
                          ) : (
                            <Button className="mb-2 me-2 " color="primary" onClick={(e) => this.createNewRecommendation(e)}>
                              {t("RECOMMENDATION.SAVE_BUTTON")}
                            </Button>
                          )}
                        </>
                      ) : (
                        <Button className="mb-2 me-2" color="secondary" onClick={this.changeModalState}>
                          {t("RECOMMENDATION.CLOSE_BUTTON")}
                        </Button>
                      )}
                    </ModalFooter>
                  </Modal>
                </Col>
                <Col md="12">
                  <Card className="main-card mb-3">
                    <CardBody>
                      <div className="card p-fluid">
                        <DataTable
                          value={this.state.filteredRecommendations}
                          paginator
                          className="p-datatable-customers"
                          rows={10}
                          dataKey="id"
                          filterDisplay="row"
                          responsiveLayout="scroll"
                          filters={this.state.filterRecommendationsTemplate}
                          emptyMessage="No recommendations found."
                        >
                          <Column
                            header={t("RECOMMENDATION.LANGUAGE_COLUMN_NAME")}
                            field="language"
                            showFilterMenu={false}
                            style={{ minWidth: "10rem" }}
                            body={languageBody}
                            filter
                            filterElement={languageTableFilter}
                          />
                          <Column header={t("RECOMMENDATION.NAME_COLUMN_NAME")} field="name" filter sortable />
                          <Column header={t("RECOMMENDATION.COMMENT_COLUMN_NAME")} field="comment" filter sortable />
                          <Column
                            field="isPublic"
                            header={t("RECOMMENDATION.IS_PUBLIC_COLUMN_NAME")}
                            dataType="boolean"
                            sortable
                            body={formatIsPublicCheckBox}
                            filter
                            filterElement={verifiedRowFilterTemplate}
                          />
                          <Column
                            field="isActive"
                            header={t("RECOMMENDATION.IS_ACTIVE_COLUMN_NAME")}
                            dataType="boolean"
                            sortable
                            body={formatIsActiveCheckBox}
                            filter
                            filterElement={verifiedRowFilterTemplate}
                          />
                          <Column field="createdByUser" header={t("RECOMMENDATION.CREATED_BY_COLUMN_NAME")} filter sortable />
                          <Column header={t("RECOMMENDATION.CREATED_DATE_COLUMN_NAME")} filterField="createdDate" dataType="date" body={this.dateRecommendationsTemplate} />
                          <Column body={buttonUpdateRecommendationTranslation} rowEditor />
                        </DataTable>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </div>
          </CSSTransition>
        </TransitionGroup>
      </Fragment>
    );
  }
}
export default withTranslation()(Recommendation);
